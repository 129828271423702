// https://vuex.vuejs.org/en/mutations.html

export default {
  steps(state, steps) {
    state.steps = steps;
  },
  step_error(state, { step, message }) {
    const stepInd = step;
    if (typeof state.steps[stepInd] !== 'undefined') {
      state.steps[stepInd].rules = false;
      state.steps[stepInd].message = message;
    }
  },
  step_complete(state, step) {
    // eslint-disable-next-line array-callback-return
    Object.keys(state.steps).map((stepInd) => {
      state.steps[stepInd].editable = stepInd <= step;
      state.steps[stepInd].rules = true;
      state.steps[stepInd].message = '';
      state.steps[stepInd].complete = stepInd <= step;
    });
  },
  current_step(state, step) {
    state.step = step;
  },
  reset(state) {
    state.steps = [];
    state.step = 1;
  },
};
