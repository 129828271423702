<template>
  <div>
    <div v-if="isSuperAdmin">{{ titleName }}</div>
    <v-edit-dialog :return-value="item[property]" large lazy v-else @save="saveInline">
      <div>{{ titleName }}</div>
      <core-error v-if="item.errors" :property="property" :errors="item.errors[property]" />
      <template v-slot:input>
        <v-autocomplete
          v-bind:value="item[property]"
          v-on:change="newObject = $event"
          :items="itemsValue"
          menu-props="auto"
          hide-details
          item-text="name"
          item-value="id"
          :label="labelName"
          hint="Pick your brand"
          chips
        >
        </v-autocomplete>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DialogBaseMixins from '../../../mixins/dialog-base';
import ProfileMixins from '../../../mixins/profile-base';

export default {
  mixins: [DialogBaseMixins, ProfileMixins],
  name: 'attributeBrand',
  data: () => ({
    type: 'marketplace.brand',
  }),
  created() {
    this.apiBrand();
  },
  computed: {
    itemsValue() {
      return this.$store.getters['api/list'](this.type);
    },
  },
  methods: {
    ...mapActions('api', {
      apiBrand: 'brand',
    }),
  },
};
</script>

<style></style>
