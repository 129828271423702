<template>
  <v-container fluid>
    <v-form v-model="valid" v-if="family">
      <material-card
        :title="`Form '${family ? family.name : 'Default'}'`"
        class="title grey--text text--lighten-1 font-weight-light col-lg-4"
      >
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="family.name"
              :rules="titleRules"
              :counter="255"
              label="family name / CSV Field name"
              required
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
             <v-switch
              v-model="family.active"
              label="Product schema active"
            ></v-switch>
          </v-col>
          <v-col cols="12" md="4">
            <autocomplete-cfm
              v-if="family.classifications"
              v-model="family.classifications"
              v-on:cfm:element="displayAttributeFromMarketplace"
              editing
            ></autocomplete-cfm>
          </v-col>
        </v-row>
      </material-card>
      <v-divider></v-divider>
        <v-row>
          <v-col cols="12" md="3">
            <v-card>
              <v-card-title class="text-h5">
                <v-text-field
                  v-model="searchAttribute"
                  label="Attributes :"
                  outlined
                  dense
                ></v-text-field>
              </v-card-title>
              <v-list
                nav
                dense
                style="overflow-y: auto;"
                max-height="600"
              >
                <vuedraggable class="list-group" tag="v-list-item-group" v-model="attributes" v-bind="dragOptions" :move="onMove" @start="isDragging=true" @end="isDragging=false">
                  <transition-group type="transition" :name="'flip-list'">
                    <div class="list-group-item"  v-for="element in attributes" :key="element.id">
                      <v-icon
                        v-if="element.fixed"
                        color="red darken-3"
                        class="mr-1"
                        @click="element.fixed=! element.fixed"
                        small
                        aria-hidden="true"
                      >
                        mdi-circle
                      </v-icon>
                      <v-icon
                        v-else
                        color="green darken-3"
                        class="mr-1"
                        @click="element.fixed=! element.fixed"
                        small
                        aria-hidden="true"
                      >
                        mdi-check-circle
                      </v-icon>
                      <span>
                        {{ element.name }}
                      </span>
                    </div>
                  </transition-group>
                </vuedraggable>
                </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" :md="getColumn('center')">
            <v-row v-for="familyAttribute in family.attributes"
              no-gutters
              style="margin-bottom: 10px;"
              :key="familyAttribute.remoteCode">
              <v-col cols="12" md="12">
                <v-card
                  :color="familyAttribute.color"
                  dark
                >
                  <v-card-title class="text-h5">
                    <text-field-familyattribute-name
                      v-model="familyAttribute.name"
                      :apiRemoteCode="familyAttribute.remoteId"
                    >
                    </text-field-familyattribute-name>
                  </v-card-title>
                  <v-card-text>
                    <vuedraggable
                      tag="span"
                      v-model="familyAttribute.defaultValue"
                      v-bind="dragOptions"
                      :move="onMove">
                      <transition-group name="no" class="list-group" tag="v-list-item-group">
                        <div
                          class="list-group-item v-list-item theme--dark v-list-item theme--dark"
                          v-for="element in familyAttribute.defaultValue"
                          :key="element.id">
                          <i
                            :class="element.fixed? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'"
                            @click="element.fixed = !element.fixed"
                            aria-hidden="true"></i>
                          <div class="v-list-item__content pa-0">
                            <div class="v-list-item__title">{{ element.name }}</div>
                            <template v-for="a in getAssociation(element, currentMarketplace)">
                              <v-list-item-subtitle :key="a">
                                {{ a }}
                              </v-list-item-subtitle>
                            </template>
                          </div>
                          <div class="v-list-item__icon ma-0">
                            <attribute-link v-model="element.remoteCode" target='_blank'>
                              <v-icon small>
                                mdi-cog-outline
                              </v-icon>
                            </attribute-link>
                            <v-icon small class="mr-1" @click="removeFromList(element, familyAttribute)">
                              mdi-delete
                            </v-icon>
                            </div>
                        </div>
                      </transition-group>
                    </vuedraggable>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row></v-row>
            <v-row></v-row>
            <v-row></v-row>
          </v-col>
          <v-col v-if="openColumnCfm" cols="12" :md="getColumn('right')">
            <v-card>
              <v-card-title class="text-h5" v-if="classificationClm">
                {{ classificationClm.name }}
                <template v-if="classificationsClm > 1">
                  ('Too many associations')
                </template>
              </v-card-title>
              <v-card-title class="text-h5 ta-error" v-else>
                No association
              </v-card-title>
              <v-list
                nav
                dense
                style="overflow-y: auto;"
                max-height="600"
                v-if="classificationClm"
              >
                <div
                  class="list-group-item"
                  v-for="element in classificationClm.options.family_attributes"
                  :key="element.code"
                >
                  <div>
                    <v-icon
                      v-if="typeof attributsCfmAssociation[element.code] !== 'undefined'"
                      color="green darken-3"
                      small
                    >
                      mdi-check-circle
                    </v-icon>
                    <v-icon
                      v-else
                      color="red darken-3"
                      small
                    >
                      mdi-circle
                    </v-icon>
                    {{ element.label }}
                  </div>
                </div>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
   <v-btn
      :disabled="!valid"
      color="success"
      class="mr-4"
      @click="validate"
    >
      Validate
    </v-btn>

    <v-btn
      color="error"
      class="mr-4"
      @click="reset"
    >
      Reset Form
    </v-btn>

    <v-btn
      color="warning"
      @click="resetValidation"
    >
      Reset Validation
    </v-btn>
    </v-form>
    <v-divider></v-divider>
    <v-row>
      <v-col>
      </v-col>
     <!--  <v-col>
        <router-view :key="$route.fullPath" class="blue lighten-1" >
        </router-view>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import vuedraggable from 'vuedraggable';

import { AttributeCore } from '@/models/attribute/core';

import TextFieldFamilyattributeName from '@/components/attributelist/textfield-familyattribute-name';
import AutocompleteCfm from '@/components/attributelist/autocomplete-cfm';
import AttributeLink from '@/components/link/attribute';

export default {
  name: 'FamilyByIdView',
  components: {
    vuedraggable,
    TextFieldFamilyattributeName,
    AutocompleteCfm,
    AttributeLink,
  },
  data: () => ({
    headers: [],
    family: {},
    currentMarketplace: null,
    attributsAssociation: null,
    attributsCfmAssociation: null,
    classificationClm: null,
    classificationsClm: 0,
    searchAttribute: '',
    associations: [],
    list2: [],
    list3: [],
    list4: [],
    list5: [],
    openColumnCfm: false,
    loading: false,
    editable: true,
    isDragging: true,
    delayedDragging: false,
    colorOk: '#006064',
    colorNok: '#f44336',
  }),
  mounted() {
    // between values or family url
    // @todo use a uniq name for each of them

    this.fetchAllAttributes()
      .then(() => this.fetchByRemoteCode(this.$route.params.remoteCode))
      .then((result) => {
        const family = { ...result };
        family.attributes = [...result.attributes].map((attribute) => {
          const defaultValue = attribute.defaultValue.map((v, i) => {
            const attr = this.attributes.find(a => a.bind === (v.code || v.bind));
            if (!attr) {
              return {
                ...AttributeCore.DEFAULT_ATTRIBUTE,
                bind: v.code,
                name: (() => {
                  try { return v.attributeLabel.fr; } catch (e) { return v.code; }
                })(),
                id: v.attributeId,
                // new option
                order: v.order,
                error: true,
                fixed: true,
              };
            }
            return {
              ...attr,
              fixed: !attr.isReady,
              order: i,
            };
          });
          const hasError = defaultValue.some(v => v.error);
          return {
            ...attribute,
            color: hasError ? this.colorNok : this.colorOk,
            defaultValue,
          };
        });
        this.family = family;
        return family;
      });
  },
  computed: {
    ...mapGetters('form/rules', { titleRules: 'title' }),
    attributes() {
      const attributes = this.$store.getters['attribute/attributes'];
      if (!this.searchAttribute
        || typeof this.searchAttribute !== 'string'
        || this.searchAttribute.length < 3) {
        return attributes.map(a => ({
          ...a,
          fixed: !a.isReady,
        }));
      }
      return attributes
        .filter(a => a.name.toLowerCase().includes(this.searchAttribute.toLowerCase()))
        .map(a => ({
          ...a,
          fixed: !a.isReady,
        }));
    },
    settings() {
      return this.$store.getters['settings/settings'];
    },
    marketplaces() {
      return this.$store.getters['config/marketplaces'];
    },
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: !this.editable,
        ghostClass: 'ghost',
      };
    },
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
  },
  methods: {
    ...mapActions('family', { fetchByRemoteCode: 'get' }),
    ...mapActions('attribute', { fetchAllAttributes: 'list' }),
    validate() {
      this.loading = true;
      setTimeout(() => this.$store
        .dispatch('family/update', this.family)
        .catch(error => this.throwError(error))
        .finally(() => {
          this.loading = false;
        }),
      2000);
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    removeFromList(element, familyAttribute) {
      const attributes = [...familyAttribute.defaultValue];
      const index = attributes.findIndex(v => v.remoteCode === element.remoteCode);
      // eslint-disable-next-line no-alert
      if (index !== -1 && window.confirm(`Are you sure you want to delete this element from ${familyAttribute.name}?`)) {
        attributes.splice(index, 1);
        Vue.set(familyAttribute, 'defaultValue', attributes);
      }
    },
    getColumn(position) {
      if (this.openColumnCfm) {
        if (position === 'center') return 6;
        return 3;
      }
      if (position === 'center') return 9;
      return 0;
    },
    displayAttributeFromMarketplace(classification) {
      Vue.set(this, 'currentMarketplace', null);
      Vue.set(this, 'classificationClm', null);
      Vue.set(this, 'attributsAssociation', null);
      Vue.set(this, 'attributsCfmAssociation', {});
      Vue.set(this, 'openColumnCfm', false);
      if (classification) {
        Vue.set(this, 'openColumnCfm', true);
        if (Array.isArray(classification.fromMarketplace) && classification.fromMarketplace.length > 0) {
          const classificationClm = classification.fromMarketplace[0];
          const marketplace = this.marketplaces.find(m => m.id === classificationClm.marketplaceId);
          if (marketplace) {
            Vue.set(this, 'currentMarketplace', marketplace);
          }
          Vue.set(this, 'attributsAssociation', {});
          classificationClm.options.family_attributes.forEach((f) => {
            Vue.set(this.attributsAssociation, f.code, `${marketplace.name} > ${f.label}`);
          });
          Vue.set(this, 'classificationClm', classificationClm);
          Vue.set(this, 'classificationsClm', classification.fromMarketplace.length);
        }
      }
    },
    reset(e) {
      console.log('reset', e);
    },
    resetValidation(e) {
      console.log('resetValidation', e);
    },
    getAssociation(element, currentMarketplace) {
      try {
        return element.settings.marketplaceOptions[currentMarketplace.remoteCode].reduce((r, o) => {
          if (typeof this.attributsAssociation[o.code] !== 'undefined') {
            Vue.set(this.attributsCfmAssociation, o.code, true);
            r.push(this.attributsAssociation[o.code]);
          }
          return r;
        }, []);
      } catch (e) {}
      return null;
    },
  },
};
</script>

<style lang="css" scoped>
.v-list-item,
.v-list-item__content {
  min-height: 12px;
}
</style>
