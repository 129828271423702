<template>
  <v-menu
    bottom
    left
    close-on-content-click
    top
    min-width="200"
    max-height="20vh"
    offset-x
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn rounded dark icon v-bind="attrs" v-on="on" color="black">
        <flag :iso="currentIconLang"></flag>
      </v-btn>
    </template>
    <v-card>
      <v-card-text class="text-sm-center d-flex justify-center">
        <v-flex v-for="lang in langs" :key="lang.icon" cols="1">
          <template v-if="lang.interface">
            <button small text-color="white" v-on:click="currentLang = lang.legacyName">
              <flag :iso="lang.icon"></flag>
            </button>
          </template>
          <template v-else>
            <button small text-color="white">
              <flag :iso="lang.icon" class="disabled"></flag>
            </button>
          </template>
        </v-flex>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import LangBaseMixins from '../../mixins/lang-base';
import langs from '../../helpers/lang';

export default {
  mixins: [LangBaseMixins],
  name: 'MenuFilter',
  mounted() {
    this.view = this.value;
  },
  data: () => ({
    langs, // override langs data from mixin lang
  }),
};
</script>

<style>
.flag-icon.disabled {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
</style>
