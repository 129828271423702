import Sku from '../pim/sku';
import { DefaultError } from '../pim/exception';

export default class SkuManager {
  static create(skuData, variantParent) {
    if (!skuData || Object.keys(skuData).length === 0) {
      throw new DefaultError('models.pim.skumanager.create.sku.invalid');
    }
    return Sku.create(skuData, variantParent);
  }

  static newObject(sku) {
    if (!(sku instanceof Sku)) {
      throw new DefaultError('models.pim.skumanager.newobject.sku.invalid');
    }
    const skuData = sku.toObject();
    // toOBject remove parent properties
    const defaultItem = SkuManager.create(skuData, sku.parent);
    defaultItem.color = defaultItem.parent.color;
    defaultItem.size = '[size]';
    // generate a random remoteCode
    defaultItem.generateRandomRemoteCode();
    defaultItem.reset();
    return defaultItem;
  }
}
