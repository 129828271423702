<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="992px"
    :retain-focus="false"
    content-class="v-dialog-fix-index"
  >
    <v-form v-model="isValid">
      <v-card>
        <v-card-title>
          <span class="headline">Media {{ item.uniq }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="8">
                <!-- arbitrary property value to avoid error-->
                <attribute-media v-if="item" :item="item" :key="item.original"> </attribute-media>
                <common-media-upload
                  :variantId="item.variantId || false"
                  :order="Number(item.order) || false"
                  :marketplace="item.marketplace || false"
                  v-on:media:upload="fromUpload"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-flex xs12>
                  <v-select
                    v-model="item.marketplace"
                    :items="formatMarketplaces(marketplacesList)"
                    menu-props="auto"
                    hide-details
                    label="Select your image type of marketplace"
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-icon small class="mr-2" @click="readonly = false">
                    mdi-pencil
                  </v-icon>
                  <v-text-field
                    v-model="item.original"
                    label="URL"
                    :readonly="readonly"
                  ></v-text-field>
                  <v-text-field v-model="item.hashUrl" label="Hash Url" readonly></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field v-model="item.path" label="Path" readonly></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field v-model="item.order" label="Order"></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-checkbox v-model="item.download" label="To download" readonly></v-checkbox>
                </v-flex>
                <v-flex xs12 md6>
                  <v-checkbox v-model="item.alias" label="Generate aliases" readonly></v-checkbox>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="item.type"
                    :items="mediaType"
                    menu-props="auto"
                    hide-details
                    label="Select your type image"
                  ></v-select>
                </v-flex>
                <v-flex xs12 md12>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Alias</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(key, metaValue) in item.data" :key="metaValue">
                          <td>{{ metaValue }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text outlined @click="close">Cancel</v-btn>
          <v-btn color="blue darken-1" dark @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { Media } from '../../models';
import Mixins from '../../mixins';
import { EventBus } from '../../helpers/event-bus';

export default {
  name: 'DialogMedia',
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    item: {
      type: Media,
      required: true,
      default: null,
    },
  },
  mixins: Mixins,
  data: () => ({
    isValid: true,
    readonly: true,
    mediaType: ['IMAGE', 'VIDEO (is coming soon)', 'YOUTUBE (is coming soon)'],
  }),
  computed: {
    // https://fr.vuejs.org/v2/guide/computed.html
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    isNew(media) {
      return typeof media.uniq === 'undefined' || !media.uniq;
    },
    save() {
      this.$log.debug('dialog.media.save.started', this.item);
      let endpoint = 'media/update';
      if (this.isNew(this.item)) {
        endpoint = 'media/new';
      }
      return (
        this.$store
          .dispatch(endpoint, this.item.toObject())
          // before emitting the event, update on the server
          .then(variantMedia => EventBus.$emit('onUpdatingMediaList', variantMedia))
          .then(() => this.close())
          .catch(error => this.throwError(error))
      );
    },
    fromUpload(mediaData) {
      this.$log.debug('dialog.media.fromupload.started', mediaData);
      this.item.update(mediaData);
      if (this.isNew(this.item)) {
        this.$emit('media/update', this.item);
      } else {
        this.$emit('media/add', this.item);
      }
      this.close();
    },
    // @see copy from src/mixins/edit-base.js:closeItem
    close() {
      this.dialog = false;
    },
    formatMarketplaces(marketplacesList) {
      return marketplacesList.map(m => m.toLowerCase());
    },
  },
};
</script>

<style lang="css">
.v-dialog-fix-index {
  z-index: auto !important;
}
</style>
