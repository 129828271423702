<template>
  <router-link
    v-if="value"
    :to="getUrlByName('settings.family.remoteCode', value)"
    target="_blank"
    >
    <v-chip
      v-if="label"
      class="ma-2"
      color="green"
      text-color="white"
      dark
      small
      :style="{ cursor: 'pointer'}"
    >
      <v-avatar
        left
        class="green darken-4"
      >
        F
      </v-avatar>
      {{ label }}
    </v-chip>
    <slot></slot>
  </router-link>
</template>

<script>
import LinkMixins from '@/mixins/link-base';

export default {
  name: 'LinkFamily',
  props: {
    value: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
  },
  mixins: [LinkMixins],
};
</script>
