// https://vuex.vuejs.org/en/mutations.html

export default {
  set_item(state, payload) {
    if (typeof state.settings[payload.property] !== 'undefined') {
      state.settings[payload.property] = payload.value;
    }
  },
  set_settings(state, settings) {
    state.settings = settings;
  },
  set_theagent_settings(state, settings) {
    state.theagent = settings;
  },
  set_profile(state, profile) {
    state.profile = profile;
  },
};
