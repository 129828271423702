<template>
  <v-select
    v-model="item[property]"
    :items="itemsValue"
    menu-props="auto"
    item-text="name"
    item-value="id"
    :label="$t('header.product.channel')"
    hint="Pick your Channel"
    persistent-hint
    :messages="getDefaultValues()"
    chips
    multiple
    :loading="isCompletudeRequired('channelIds', currentIssues)"
  ></v-select>
</template>
<script>
import { mapActions } from 'vuex';
import AttributeBaseMixins from '../../mixins/attribute-base';
import CompletudeBaseMixins from '../../mixins/completude-base';

export default {
  mixins: [AttributeBaseMixins, CompletudeBaseMixins],
  name: 'attributeChannel',
  data: () => ({
    type: 'channel',
  }),
  created() {
    this.apiChannel();
  },
  computed: {
    itemsValue() {
      return this.$store.getters['api/list'](this.type);
    },
  },
  methods: {
    ...mapActions('api', {
      apiChannel: 'channel',
    }),
  },
};
</script>
