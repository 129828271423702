import AttributeAbstract from '@/models/attribute/abstract';
import AttributeSettings from '@/models/attribute/settings';
import AttributeValue from '@/models/attribute/value';
import AttributeFromMarketplace from '@/models/attribute/frommarketplace';

const DEFAULT_ATTRIBUTE = {
  typeHinting: null,
  typeEntity: null,
  remoteCode: 'pim.a.',
  label: { fr: null },
  values: [],
  bind: '',
  name: '',
  settings: null,
  fromMarketplace: null,
};

class AttributeCore extends AttributeAbstract {
  constructor(data, options = {}) {
    super(data);
    this.hideProperty('options', options);
  }

  objectInitializer(data) {
    super.objectInitializer(data);

    this.hideProperty('deletedAt', data.deletedAt);
    this.hideProperty('createdAt', data.createdAt);

    this.remoteCode = data.remoteCode.replace('pim.a.', '');
    if (data.settings) {
      this.settings = new AttributeSettings(data.settings);
    }
    if (Array.isArray(data.fromMarketplace)) {
      this.fromMarketplace = data.fromMarketplace.map(v => new AttributeFromMarketplace(v));
    }
    if (Array.isArray(data.values)) {
      this.values = data.values.map(v => new AttributeValue(v));
    }
    this.hideProperty('searchText', data.values.reduce((r, av) => {
      r.push(av.defaultValue);
      try {
        r.push(...Object.values(av.values).map(v => v.default.value));
      } catch (e) {} // eslint-disable-line no-empty
      return r;
    }, [])
      .filter(Boolean)
      .join(' ').toLowerCase());
  }

  search(text) {
    return (this.searchText || '').indexOf(text.toLowerCase()) !== -1;
  }
}

AttributeCore.DEFAULT_ATTRIBUTE = DEFAULT_ATTRIBUTE;

export default function _(data, options = {}) {
  if (!data) {
    return new AttributeCore(DEFAULT_ATTRIBUTE);
  }
  return new AttributeCore(data, options);
}

export {
  AttributeCore,
};
