// https://vuex.vuejs.org/en/state.html

export default {
  currentVariantId: null,
  currentMarketplace: null,
  families: [],
  completudes: [],
  familyCompletude: [],
  completudeMarketplaces: ['default', 'zalando', 'laRedoute'],
};
