import { map } from 'bluebird';
import CoreList from './corelist';
import Product from './product';

export default class ProductList extends CoreList {
  applyModel() {
    if (this.length > 0) {
      this.forEach((row, index) => {
        if (typeof row === 'object') {
          this.add(Product.create(row), index);
        }
      });
      this.checkErrors();
    }
    return this;
  }

  checkErrors() {
    if (this.length > 0) {
      this.forEach((row) => {
        // reset error before
        row.resetErrors();
        if (row.parentError) {
          // eslint-disable-next-line no-param-reassign
          row.errors.id = ['errorProduct'];
        }
      });
    }
  }

  async getCompletudes(dispatch) {
    return this;
    // return map(this, product => product.getCompletude(dispatch), { concurrency: 5 }).then(
    //   () => this,
    // );
  }
}
