<template>
  <v-container fill-height fluid>
    <v-row>
      <v-col cols="12">
        <attribute-header
          v-model="headerKeySelected"
          :filter="headerFiltered"
          label-name="Choose your key field to recognize your data set"
          only-one
        ></attribute-header>
        <attribute-header
          v-model="fieldsSelected"
          :filter="fieldsAuthorizedToBeUpdated"
        ></attribute-header>
      </v-col>
      <common-sheets-upload
        v-if="fieldsSelected.length > 0"
        label="Re upload your CDV files (csv, xls, xslx)"
        :post-action="`${baseURL}/api/v1/pim/import/csv`"
        :post-data="{ ...formExtraValues, headers: fieldsSelected, keyHeader: headerKeySelected }"
        v-on:create:upload="listProductFromImport"
        v-on:upload="loader"
      >
      </common-sheets-upload>
    </v-row>
    <v-flex md12 style="min-height: 658px">
      <v-toolbar flat>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('component.zipupload.search-placeholder')"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
      </v-toolbar>
      <core-alert></core-alert>
      <v-data-table
        v-if="items.length > 0"
        v-model="rowSelected"
        :headers="filterByEntityTypes()"
        class="elevation-1 ta-fix-column ta-table-csv ta-csv"
        item-key="vueKey"
        :items="items"
        :items-per-page="itemsPerPage"
        :item-class="checkError"
        :options.sync="itemsOptions"
        :loading="loading"
        loading-text="Loading... Please wait"
        :search="search"
        :dense="settings.dense"
        show-select
        fixed-header
        @click:row="selectableRow"
        :custom-filter="defaultFilter"
        :footer-props="{ 'items-per-page-options': [100, 200, 400, 500, -1] }"
      >
        <template v-slot:item.data-table-select="{ isSelected, select, item }">
          <div class="text-center ta-expand-icon">
            <v-btn fab x-small class="ta-csv">CSV</v-btn>
            <v-simple-checkbox :value="isSelected" @input="select($event)"></v-simple-checkbox>
            <v-btn
              v-if="item.productId"
              color="blue"
              text
              x-small
              @click="gotoProduct(item.productId)"
              >link</v-btn
            >
          </div>
        </template>
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
            :items-per-page-options="[100, 200, 400, 500, -1]"
          >
          </v-data-footer>
        </template>
        <template v-for="head in filteredFields()" v-slot:[`header.${head.value}`]="{ header }">
          <material-table-header-title
            :key="head.value"
            v-model="errorHeaders[header.value].close"
            :header="errorHeaders[header.value]"
            v-on:header:filtered="errorsSelected($event)"
            v-on:header:unfiltered="resetErrorsSelected($event)"
          ></material-table-header-title>
        </template>
        <template v-for="head in filteredFields()" v-slot:[`item.${head.value}`]="{ item }">
          <div :key="head.value">
            {{ cleanValue(item[head.value]) }}
            <template v-if="hasErrorOrWarning(item, head.value)">
              <core-error
                v-if="item.errors"
                :property="head.value"
                :errors="item.errors[head.value]"
              >
                <template v-slot:warning>
                  {{ getDefaultValues(item, head.value, item.defaultValues)() }}
                </template>
              </core-error>
            </template>
            <template v-if="getDefaultValues(item, head.value, item.updatedValues)()">
              <core-error v-if="item.updatedValues && item.updatedValues[head.value]">
                <template v-slot:info>
                  {{
                    getDefaultValues(
                      item,
                      head.value,
                      item.updatedValues
                    )("Higher csv priority, by-pass value from database :")
                  }}
                </template>
              </core-error>
              <core-error v-else>
                <template v-slot:warning>
                  {{ getDefaultValues(item, head.value, item.defaultValues)() }}
                </template>
              </core-error>
            </template>
          </div>
        </template>
        <template v-slot:item.THEAGENT="{ item }">
          <core-avatar v-if="hasImage(item)" :image="firstImage(item)" customSize="140" />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil-circle
          </v-icon>
          <v-icon small class="mr-2" @click="deleteItem(item)">
            mdi-delete-circle
          </v-icon>
          <v-icon small @click="duplicateRow(item)">
            mdi-table-row-plus-after
          </v-icon>
        </template>
      </v-data-table>
      <div>
        <div class="text-center fix-on-function-btn" v-if="showProgressBar">
          <v-progress-circular
            :rotate="-90"
            :size="80"
            :width="20"
            :value="progressing"
            color="teal"
          >
            {{ progressing }}
          </v-progress-circular>
        </div>
        <v-tooltip left v-if="items.length > 0 && rowSelected.length > 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue"
              fab
              dark
              absolute
              right
              fixed
              v-bind="attrs"
              v-on="on"
              class="mx-2"
              style="bottom: 320px !important;"
              @click="storeInDb"
            >
              <v-icon dark>mdi-content-save</v-icon>
            </v-btn>
          </template>
          <span>Store in database:</span>
        </v-tooltip>
        <dialog-cdv
          v-if="hasRightActions && items.length > 0"
          v-model="editedItem"
          :dialog="dialog"
          v-on:update:dialog="dialog = $event"
          @onSave="saveDataItem"
          @onClose="closeItem"
        >
        </dialog-cdv>
        <v-tooltip left v-if="items.length > 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="purple"
              fab
              dark
              absolute
              right
              fixed
              v-bind="attrs"
              v-on="on"
              class="mx-2"
              style="bottom: 100px !important;"
              @click="exportCsv"
            >
              <v-icon dark>mdi-set-merge</v-icon>
              CDV
            </v-btn>
          </template>
          <span>Export to CDV file</span>
        </v-tooltip>
        <dialog-settings> </dialog-settings>
      </div>
    </v-flex>
  </v-container>
</template>

<script>
/* eslint no-plusplus:0 */
/* eslint no-param-reassign:0 */

import Vue from 'vue';
import { mapActions } from 'vuex';
import Mixins from '../../mixins';
import ItemsBaseMixins from '../../mixins/items-base';
import TableHeadersBaseMixins from '../../mixins/table-headers-base';
import EditMixins from '../../mixins/edit-base';
import ProfileMixins from '../../mixins/profile-base';
import ProgressMixins from '../../mixins/progress-base';
import ErrorMixins from '../../mixins/error-base';
import CdvService from '../../services/cdv';

export default {
  mixins: [
    ItemsBaseMixins,
    TableHeadersBaseMixins,
    EditMixins,
    ProfileMixins,
    ProgressMixins,
    ErrorMixins,
    ...Mixins,
  ],
  data: () => ({
    itemsPerPage: 200,
    fieldsSelected: [],
    headerKeySelected: [],
    rowSelected: [],
    panelOpened: 0,
    itemsCount: 0,
    itemsOptions: {},
    formExtraValues: {},
    loading: true,
    btnResetImport: false,
    items: [],
    search: '',
    baseURL: process.env.VUE_APP_PIM_BASEURL || 'http://localhost:3128/',
  }),
  mounted() {
    this.$log.log('components.pim.importdataview.mounted');
    this.notificationInfo({ message: 'Ready to edit your csv' });
    this.initImport();
  },
  computed: {
    defaultItem: function defaultItem() {
      const headers = [...this.fieldsSelected, ...this.headerKeySelected];
      const defaultValues = headers.reduce((item, row) => {
        // eslint-disable-next-line no-param-reassign
        item[row.value] = '';
        return item;
      }, {});
      defaultValues.vueKey = this.generateVueKey('newitem');
      return defaultValues;
    },
    settings() {
      return this.$store.getters['settings/settings'];
    },
    optionsTa() {
      return this.$store.getters['settings/theagent'];
    },
    headers() {
      return this.$store.getters['settings/headers'].csv;
    },
    itemsCleaned() {
      const headers = [...this.fieldsSelected, ...this.headerKeySelected];
      return this.rowSelected.map(i => headers.reduce((result, h) => {
        result[h] = i[h];
        return result;
      }, {}));
    },
    currentEntityType() {
      const { FIELD_EAN, FIELD_SUPPLIERREFCOLOR, FIELD_PRODUCTNAME } = this.fields;
      if (this.headerKeySelected.includes(FIELD_PRODUCTNAME)) {
        return [this.entityTypes.R_TYPE, this.entityTypes.RA_TYPE];
      }
      if (this.headerKeySelected.includes(FIELD_SUPPLIERREFCOLOR)) {
        return [this.entityTypes.RC_TYPE, this.entityTypes.RCA_TYPE];
      }
      if (this.headerKeySelected.includes(FIELD_EAN)) {
        return [this.entityTypes.RCT_TYPE, this.entityTypes.RCTA_TYPE];
      }
      return [];
    },
    fieldsAuthorizedToBeUpdated() {
      return this.$store.getters['api/attribute/csv']
        .filter(f => this.currentEntityType.includes(f.entityType))
        .map(f => f.name);
    },
    entityTypes() {
      return this.$store.getters['api/attribute/entityTypes'];
    },
    fields() {
      return this.$store.getters['api/attribute/fields'];
    },
    headerFiltered() {
      const { FIELD_EAN, FIELD_SUPPLIERREFCOLOR, FIELD_PRODUCTNAME } = this.fields;
      return [FIELD_EAN, FIELD_SUPPLIERREFCOLOR, FIELD_PRODUCTNAME];
    },
  },
  watch: {
    search() {
      this.resetRowSelected();
    },
  },
  methods: {
    ...mapActions('api', {
      apiBrand: 'brand',
      apiClassification: 'classification',
      apiDistributor: 'distributor',
    }),
    ...mapActions('import', ['csv']),
    filterByEntityTypes() {
      const headers = this.filterByColumn('csv');
      if (this.fieldsSelected.length > 0) {
        return headers.filter(h => ['Actions', ...this.headerFiltered, ...this.fieldsSelected].includes(h.text));
      }
      return headers;
    },
    listProductFromImport(data) {
      const { result: items, count: itemsCount } = data;
      this.items = [...items];
      this.itemsCount = itemsCount;
    },
    initImport() {
      this.$log.debug('components.pim.importdataview.initimport.started');
      this.loading = true;
      this.btnResetImport = true;
      this.showProgressCamembert(120);
      return Promise.all([
        this.apiDistributor(),
        this.apiBrand(),
        this.apiClassification(),
      ])
        .catch(error => this.throwError(error))
        .finally(() => {
          this.loading = false;
          this.btnResetImport = false;
          this.closeProgressCamember();
          return this.notificationSuccess({
            message: `Your import has been init. (import: ${this.$route.params.importId})`,
          });
        });
    },
    resetImport() {
      this.loading = true;
      this.btnResetImport = true;
      this.showProgressCamembert(20);
      // eslint-disable-next-line no-alert
      if (window.confirm('Do you really want to reset?')) {
        return this.$store
          .dispatch('import/reset')
          .then(() => this.$store.dispatch('import/csv', {
            id: this.$route.params.importId,
            reset: true,
          }))
          .then((data) => {
            const { items, itemsCount, itemsPerPage } = this.options(
              data.result,
              data.parameters.count,
              this.itemsOptions,
            );
            this.loading = false;
            // https://fr.vuejs.org/v2/guide/list.html#Limitations
            items.forEach((item, indexOfItem) => {
              Vue.set(this.items, indexOfItem, item);
            });
            this.itemsCount = itemsCount;
            this.itemsPerPage = itemsPerPage;
            return Promise.resolve();
          })
          .catch(error => this.throwError(error))
          .finally(() => {
            this.loading = false;
            this.btnResetImport = false;
            this.closeProgressCamember();
            this.throwSuccess({
              message: `Your data has been reseted. (import: ${this.$route.params.importId})`,
            });
            return this.stepInProgress(this.defaultStep);
          });
      }
      return false;
    },
    exportCsv() {
      this.$log.debug('components.pim.importdataview.exportcsv.started');
      this.loading = true;
      this.showProgressCamembert(6);
      if (this.itemsCleaned.length === 0) {
        return this.throwError(
          new TypeError('components.pim.importdataview.exportcsv.itemscleaned.empty'),
        );
      }
      return this.$store
        .dispatch('export/rawCdv', {
          data: this.itemsCleaned,
          headers: [...this.fieldsSelected, ...this.headerKeySelected],
        })
        .then(([filepath]) => {
          if (filepath) {
            Vue.downloadUrl(`${this.baseURL}/${filepath}`);
          } else {
            this.$log.error('components.pim.importdataview.exportcsv.filepath.not.okay');
          }
        })
        .catch((error) => {
          this.throwError(error);
        })
        .finally(() => {
          this.notificationSuccess({ message: 'Export Csv' });
          this.loading = false;
          this.closeProgressCamember();
        });
    },
    saveDataItem() {
      this.saveItem();
      this.resetItems();
    },
    storeInDb() {
      this.$log.debug('components.pim.importdataview.storeindb.started');
      this.loading = true;
      this.showProgressCamembert(260);
      if (this.itemsCleaned.length === 0) {
        return this.throwError(
          new TypeError('components.pim.importdataview.storeindb.itemscleaned.empty'),
        );
      }
      if (this.headerKeySelected.length === 0) {
        return this.throwError(
          new TypeError('components.pim.importdataview.storeindb.no.key.header.selected'),
        );
      }
      return this.$store
        .dispatch('import/storeInDb', {
          data: this.itemsCleaned,
          extraValues: this.formExtraValues || {},
          headers: this.fieldsSelected,
          keyHeader: this.headerKeySelected,
        })
        .then(() => {
          this.loading = false;
          this.closeProgressCamember();
          this.notificationSuccess({ message: 'Terminated' });
        })
        .catch((error) => {
          this.closeProgressCamember();
          if (typeof error.data !== 'undefined') {
            this.notificationError({
              message: error.data.join('<br />'),
            });
          }
          this.throwError(error);
          this.setError(error);
        });
    },
    gotoProduct(productId) {
      const routeData = this.$router.resolve({ path: `/pim/product/${productId}/edit` });
      window.open(routeData.href, '_blank');
    },
    openPanel(value) {
      if (this.panelOpened === value) {
        this.panelOpened = 0;
      } else {
        this.panelOpened = value;
      }
    },
    getDefaultValues: CdvService.getDefaultValue,
    hasImage(item) {
      const keyImages = [
        'Images par défaut',
        'Images par Galeries lafayette',
        'Images par Neutral',
        'Images par Veepee',
        'Images par Zalando',
        'Images par Sarenza',
        'Images par About you',
      ];
      return keyImages.some(k => item[k]);
    },
    firstImage(item) {
      return [
        'Images par défaut',
        'Images par Galeries lafayette',
        'Images par Neutral',
        'Images par Veepee',
        'Images par Zalando',
        'Images par Sarenza',
        'Images par About you',
      ].reduce((r, k) => {
        if (r) return r;
        if (item[k] && item[k].includes(';')) {
          return item[k].split(';')[0];
        }
        return r;
      }, null);
    },
    resetRowSelected() {
      this.rowSelected = [];
    },
    resetItems() {
      this.resetRowSelected();
      this.resetErrors();
    },
    cleanValue(value) {
      if (Array.isArray(value)) {
        return value.join(',');
      }
      return value;
    },
  },
};
</script>

<style>
.fix-on-function-btn {
  right: 12px;
  z-index: 12;
  bottom: 420px !important;
  position: fixed;
}

.v-data-table__wrapper table thead.v-data-table-header tr th {
  text-transform: uppercase;
}

.import-card button.v-btn--fab.v-btn--fixed {
  z-index: 5;
}
</style>
