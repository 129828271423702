// https://vuex.vuejs.org/en/actions.html
import Vue from 'vue';
import axios from 'axios';

const BASE_URI = '/api/v1/pim';

export default {
  set({ commit }, steps) {
    Vue.$log.info('store.step.actions.started');
    return new Promise((resolve) => {
      commit('steps', steps);
      return resolve(steps);
    }).catch((err) => {
      commit('step_error', { step: 1, message: err.message });
      return Promise.reject(err.message);
    });
  },
  step({ commit }, { step, status = true, message = '' }) {
    Vue.$log.info('store.step.actions.started');
    if (status === true) {
      // when step come from database, it s a string, need to parseInt the value
      const nextStep = parseInt(step, 10) + 1;
      Vue.$log.debug(`store.step.actions.step.${step}.nextstep.${nextStep}`);
      commit('step_complete', step);
      return nextStep;
    }
    commit('step_error', { step, message });
    return step;
  },
  reset({ commit }, step) {
    Vue.$log.info('store.step.actions.started');
    commit('reset', step);
  },
  // eslint-disable-next-line no-unused-vars
  update({ commit }, payload) {
    Vue.$log.info('store.step.actions.started');
    if (!Number.isInteger(payload.step)) {
      const message = 'Step is not setted';
      return Promise.reject(message);
    }
    return new Promise((resolve, reject) => {
      Vue.$log.debug(`store.step.actions.step.${payload.step}`);
      axios
        .put(`${BASE_URI}/import/${payload.id}/step/${payload.step}`)
        .then((response) => {
          Vue.$log.log(`this import ${payload.id} step has been uptaded`);
          Vue.$log.debug(`store.step.actions.newstep.${response.data.step}`);
          return resolve(response.data.step);
        })
        .catch(error => reject(error));
    });
  },
  // eslint-disable-next-line no-unused-vars
  get({ commit }, id) {
    Vue.$log.info('store.step.actions.started');
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URI}/import/${id}/step`)
        .then(response => resolve(response.data.step))
        .catch(error => reject(error));
    });
  },
};
