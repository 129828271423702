<template>
  <v-chip class="ma-2" close @click:close="removeValue" :color="color">
    {{ format(value) }}
  </v-chip>
</template>

<script>
import ChipsBaseMixins from '../../mixins/chip-base';

export default {
  name: 'DateChips',
  props: {
    name: {
      type: String,
      default: 'date',
    },
    value: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  mixins: [ChipsBaseMixins],
  data: () => ({}),
  methods: {
    format() {
      this.$log.debug('components.chips.date.format.started', this.value);
      const { type } = this.value;
      let { value } = this.value;
      if (typeof value.join === 'function') {
        value = `[${value.join(',')}]`;
      }
      return `${this.name}${this.formatOperator(type)}${value}`;
    },
    formatOperator(operator) {
      switch (operator) {
        case 'gte':
          return '>=';
        case 'gt':
          return '>';
        case 'lte':
          return '<=';
        case 'lt':
          return '<';
        case 'between':
        default:
          return ':';
      }
    },
    removeValue() {
      this.$log.debug('components.chips.data.removevalue.started');
      this.$emit('input', null);
      this.$emit('onClose');
    },
  },
};
</script>

<style lang="css" scoped></style>
