/* eslint no-param-reassign:0 */

export default {
  computed: {
    marketplacesList() {
      return this.$store.getters['config/marketplaces'].reduce((r, m) => {
        if (m.active) r.push(m.name.toUpperCase());
        return r;
      }, []);
    },
    imageKeyDefault() {
      return this.$store.getters['config/marketplaces'].reduce((r, m) => {
        if (r) return r;
        if (m.remoteCode === 'default' && m.options.bindImage) r = m.options.bindImage;
        return r;
      }, null);
    },
    imagesKey() {
      return this.$store.getters['config/marketplaces'].reduce((r, m) => {
        if (m.options.bindImage) r[m.remoteCode] = m.options.bindImage;
        return r;
      }, {});
    },
  },
};
