<template>
  <v-flex class="d-flex justify-center" :class="dropActiveClass()">
    <div v-show="$refs.upload" class="drop-active">
      <h3>{{ labelAction }} <material-markdown :src="markdown"></material-markdown></h3>
      <div class="footer-status float-right"></div>
      <div v-for="file in files" :key="`${file.name}-${file.version}`">
        <v-alert type="success" v-if="!file.success && !file.error" dense text>
          {{ file.name }} ({{ file.progress }} %)
        </v-alert>
        <v-alert type="success" v-else-if="file.success" dense text>
          {{ file.name }}
        </v-alert>
        <v-alert type="error" v-else-if="file.error" dense text>
          {{ file.error }}
        </v-alert>
      </div>
      <v-btn color="success" class="mr-4" v-if="zipfile" @click="generateCsv(true)">
        {{ $t("component.zipupload.generate-shotlist-example") }}
      </v-btn>
      <v-btn color="blue" v-if="extractButton" class="ma-2" @click="extractZip(true)">
        {{ $t("component.zipupload.extract-data") }}
      </v-btn>
      <v-select
        v-if="syncButton && !displayOverride"
        v-model="extractMode"
        :items="extractModeValues"
        menu-props="auto"
        hint="Choose your extraction mode"
        label="Choose your extraction mode"
        chips
      ></v-select>
      <v-btn
        color="green"
        v-if="syncButton && !displayOverride"
        class="ma-2"
        :disabled="!extractMode"
        @click="syncImages()"
      >
        {{ $t("component.zipupload.sync-images") }}
      </v-btn>
      <v-btn
        color="pink"
        v-if="syncButton && displayOverride"
        class="ma-2"
        @click="overrideImageEditing()"
      >
        {{ $t("component.zipupload.override-images") }}
      </v-btn>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
    <file-upload
      class="btn btn-primary dropdown-toggle"
      :post-action="postAction"
      :extensions="extensions"
      :accept="accept"
      :multiple="multiple"
      :thread="thread < 1 ? 1 : thread > 5 ? 5 : thread"
      :headers="headers"
      :data="data"
      :drop="drop"
      :timeout="600 * 1000"
      v-model="files"
      @input-filter="inputFilter"
      @input-file="inputFile"
      ref="upload"
    >
      <v-btn color="blue" fab dark justify-center class="mx-2">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </file-upload>
  </v-flex>
</template>

<script>
import ZipUpload from './ZipUpload';

const baseURL = process.env.VUE_APP_PIM_BASEURL || 'http://localhost:3128/';

export default {
  name: 'ZipMediaUpload',
  extends: ZipUpload,
  props: {
    brandId: {
      type: [Number],
    },
  },
  data: () => ({
    isPimUpload: true,
    supplierRefKey: 'supplierReference',
    eanKey: 'ean',
    colorKey: 'color',
    actions: {
      zip: {
        action: `${baseURL}/api/v1/import/zip/[brandId]/pim/uploadmedia`,
        i18n: 'component.zipupload.drop-zip-to-upload',
        extension: 'zip',
        regex: /\.(zip)$/i,
        markdown: 'zipstructure.md',
      },
      relations: {
        action: `${baseURL}/api/v1/import/uploadrelations/pim/[brandId]`,
        i18n: 'component.zipupload.drop-file-to-import-data',
        extension: 'csv,txt,xls,xlsx',
        regex: /\.(csv|txt|xls|xlsx)$/i,
        markdown: 'zipupload.md',
      },
    },
  }),
  methods: {
    setAction(action = 'zip') {
      this.postAction = this.actions[action].action.replace('[brandId]', this.brandId);
      this.labelAction = this.$t(this.actions[action].i18n);
      this.extensions = this.actions[action].extension;
      this.regex = this.actions[action].regex;
      this.markdown = this.actions[action].markdown;
    },
  },
};
</script>

<style lang="css" scoped></style>
