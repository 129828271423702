// https://vuex.vuejs.org/en/actions.html
import Vue from 'vue';
import axios from 'axios';
import { errorHandler } from '@/store/modules/global_helpers';
import { SkuList, SkuManager } from '../../../models';

const BASE_URI = '/api/v1/pim';
const BASE_URI_V2 = '/api/v2/pim';
const DEFAULT_PARENT = null;

export default {
  list({ commit }) {
    Vue.$log.info('store.sku.actions.started');
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URI}/sku`)
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError('/api/v1/pim/sku empty');
          }
          const skuList = SkuList.create(response.data.result);
          commit('set_skus', skuList);
          return resolve(skuList);
        })
        .catch(errorHandler(reject));
    });
  },
  // eslint-disable-next-line no-unused-vars
  new({ commit }, { sku, parent = DEFAULT_PARENT }) {
    Vue.$log.info('store.sku.actions.started');
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URI}/sku`, sku)
        .then((response) => {
          Vue.$log.log('store.sku.actions.new', sku);
          const skuObj = SkuManager.create(response.data, parent);
          return resolve(skuObj);
        })
        .catch(errorHandler(reject));
    });
  },
  // eslint-disable-next-line no-unused-vars
  update({ commit }, { sku, parent = DEFAULT_PARENT }) {
    Vue.$log.info('store.sku.actions.started');
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI}/sku/${sku.id}`, sku)
        .then((response) => {
          Vue.$log.log(`store.sku.actions.update.${sku.id}`, sku);
          const skuObj = SkuManager.create(response.data, parent);
          return resolve(skuObj);
        })
        .catch(errorHandler(reject));
    });
  },
  // eslint-disable-next-line no-unused-vars
  delete({ commit }, id) {
    Vue.$log.info('store.sku.actions.started');
    return new Promise((resolve, reject) => {
      axios
        .delete(`${BASE_URI}/sku/${id}`)
        .then((response) => {
          Vue.$log.log(`store.sku.actions.delete.${id}`);
          return resolve(response);
        })
        .catch(errorHandler(reject));
    });
  },

  // eslint-disable-next-line no-unused-vars
  status({ commit }, payload) {
    Vue.$log.info('store.sku.actions.status.started');
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI}/sku/${payload.id}/status/${payload.status}`)
        .then((response) => {
          Vue.$log.log(`sku ${payload.id} status has been updated`);
          const skuObj = SkuManager.create(response.data, payload.variant || DEFAULT_PARENT);
          return resolve(skuObj);
        })
        .catch(errorHandler(reject));
    });
  },

  // eslint-disable-next-line no-unused-vars
  active({ commit }, payload) {
    Vue.$log.info('store.sku.actions.active.started');
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI}/sku/${payload.id}/active`, {
          active: payload.active,
        })
        .then((response) => {
          Vue.$log.info(`store.sku.actions.active.${payload.id}.update`);
          const skuObj = SkuManager.create(response.data, payload.parent || DEFAULT_PARENT);
          return resolve(skuObj);
        })
        .catch((error) => {
          Vue.$log.error(`store.sku.actions.active.${payload.id}.error`, {
            error: error.message,
            stack: error.stack,
          });
          return errorHandler(reject)(error);
        });
    });
  },

  // eslint-disable-next-line no-unused-vars
  sealed({ commit }, payload) {
    Vue.$log.info('store.sku.actions.sealed.started');
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI}/sku/${payload.id}/sealed`, {
          sealed: payload.sealed,
        })
        .then((response) => {
          Vue.$log.info(`store.sku.actions.sealed.${payload.id}.update`);
          const skuObj = SkuManager.create(response.data, payload.parent || null);
          return resolve(skuObj);
        })
        .catch((error) => {
          Vue.$log.error(`store.sku.actions.sealed.${payload.id}.error`, {
            error: error.message,
            stack: error.stack,
          });
          return errorHandler(reject)(error);
        });
    });
  },
  // eslint-disable-next-line no-empty-pattern
  search({ commit }, payload) {
    Vue.$log.info('store.sku.actions.search.started');
    return new Promise((resolve) => {
      Vue.$log.debug('store.sku.actions.search.payload', payload);
      axios
        .get(`${BASE_URI}/variant/search`, payload)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError('/api/v1/pim/variant/search empty');
          }
          const skulist = SkuList.create(response.data.result);
          commit('set_variants', skulist);
          return resolve(skulist);
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return resolve({ result: [], parameters: { count: 0 } });
        });
    });
  },
  move(store, payload) {
    Vue.$log.info('store.sku.actions.move.started');
    return new Promise((resolve, reject) => {
      Vue.$log.debug('store.sku.actions.move.payload', payload);
      axios
        .put(`${BASE_URI_V2}/sku/${payload.id}/move/${payload.variantId}`, payload)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError('store.sku.actions.move.response.empty');
          }
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  duplicate(store, payload) {
    Vue.$log.info('store.sku.actions.move.started');
    return new Promise((resolve, reject) => {
      Vue.$log.debug('store.sku.actions.move.payload', payload);
      axios
        .post(`${BASE_URI_V2}/sku/${payload.id}/duplicate`, payload)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError('store.sku.actions.move.response.empty');
          }
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
};
