import Log from '@/models/utils/log';

const namespace = 'models.attribute.abstract';

export default class AttributeAbstract {
  constructor(data) {
    this.objectInitializer(data);
    Log.debug(`${namespace}.${this.constructor.name.toLowerCase()}.started`);
  }

  hideProperty(property, value) {
    Object.defineProperty(this, property, { enumerable: false, value });
  }

  objectInitializer(data) {
    Object.assign(this, data);
  }

  /**
   * convert instance in plain object
   * @return {Object}
   */
  toObject() {
    Log.debug(`${namespace}.${this.constructor.name.toLowerCase()}.toobject.started`);
    // dont use Object.assign because circular json error due to parent property
    return JSON.parse(JSON.stringify(Object.seal(this)));
  }
}