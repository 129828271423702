import LangSubMixins from './lang-sub-base';

export default {
  mixins: [LangSubMixins],
  computed: {
    brands() {
      const brands = this.$store.getters['brand/brands'];
      if (typeof this.user.preferences !== 'undefined' && this.user.preferences.brandId) {
        return brands.filter(b => this.user.preferences.brandId.includes(b.id));
      }
      return brands;
    },
  },
};
