class ProfileError extends Error {}

// node_modules/theagent-pim/models/attribute.js:39
const DEFAULT = 0;
const INTEGRATOR = 1;
const BUSINESS = 2;
const MARKETPLACE = 3;
const ADMIN = 99;

const keys = {};
keys[DEFAULT] = {
  name: 'Default',
  key: DEFAULT,
};
keys[INTEGRATOR] = {
  name: 'Integrateur',
  key: INTEGRATOR,
};
keys[BUSINESS] = {
  name: 'Commercial',
  key: BUSINESS,
};
keys[MARKETPLACE] = {
  name: 'Marketplace',
  key: MARKETPLACE,
};
keys[ADMIN] = {
  name: 'Administrator',
  key: ADMIN,
};

export default {
  DEFAULT,
  INTEGRATOR,
  BUSINESS,
  MARKETPLACE,
  ADMIN,
};

export function getProfile(key) {
  if (typeof keys[key] === 'undefined') {
    throw new ProfileError('helpers.profile.getname.key.dontexist');
  }
  return keys[key];
}

export function list() {
  return Object.keys(keys).reduce((result, profileId) => {
    result.push(keys[profileId]);
    return result;
  }, []);
}
