<template>
  <v-card-actions>
    <v-fab-transition>
      <v-btn color="grey darken-1" fab small absolute bottom left @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-fab-transition>
      <v-btn color="green" fab small absolute bottom right rounded :disabled="!value" @click="save">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-card-actions>
</template>

<script>
export default {
  name: 'ButtonAction',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    save() {
      this.$emit('input', true);
      this.$emit('save', true);
    },
    close() {
      this.$emit('input', false);
      this.$emit('close', true);
    },
  },
};
</script>
