export default {
  data: () => ({}),
  computed: {
    families() {
      return this.$store.getters['completude/families'];
    },
  },
  methods: {
    getCompletudeColor(marketplaceName = null) {
      if (!marketplaceName) return undefined;
      return {
        default: '#2196f3',
        zalando: '#009688',
        laRedoute: '#3f51b5',
        amazon: '#673ab7',
        error: 'red',
      }[marketplaceName];
    },
    getClasses(marketplaceName) {
      return `ta-mp-${marketplaceName}`;
    },
  },
};
