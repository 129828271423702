<template>
  <v-combobox
    v-model="value"
    :filter="filter"
    :items="classifications"
    :search-input.sync="search"
    item-value="remoteCode"
    item-text="name"
    label="Association between family and classification pim"
    multiple
    chips
  >
    <template v-slot:selection="{ attrs, item, parent, selected }">
      <v-chip
        v-if="item === Object(item)"
        v-bind="attrs"
        :color="`${item.color} lighten-3`"
        :input-value="selected"
        label
        small
      >
        <v-icon
          small
          @click="e => openColumn(item, e)"
        >mdi-loupe</v-icon>

        <span class="pr-2">
          {{ item.name }}
        </span>
        <v-icon
          v-if="defaultAssociation.find(a => a.remoteCode === item.remoteCode)"
          color="green darken-3"
          small
        >
          mdi-check-circle
        </v-icon>
        <v-icon
          v-else
          color="red darken-3"
          small
        >
          mdi-circle
        </v-icon>
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
import AlertBaseMixins from '@/mixins/alert-base';
import NotificationBaseMixins from '@/mixins/notification-base';

export default {
  name: 'AttributeCfm',
  mixins: [NotificationBaseMixins, AlertBaseMixins],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    defaultAssociation: [],
  }),
  computed: {
    classifications() {
      return this.$store.getters['classification/classifications'];
    },
  },
  mounted() {
    this.defaultAssociation = this.value.slice(0);
  },
  methods: {
    filter(item, queryText, itemText) {
      if (queryText.length < 2) return false;

      const hasValue = val => (val != null ? val : '');

      const text = hasValue(itemText || item.name);
      const query = hasValue(queryText);

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1;
    },
    openColumn(element, e) {
      this.$emit('cfm:element', element);
      e.stopPropagation();
    },
  },
};
</script>

<style lang="css" scoped>
</style>
