<template>
  <v-text-field
    v-model="valueAttribute"
    :label="label || keyAttr || property"
    :messages="getDefaultValues()"
    :loading="isCompletudeRequired(bindAttribute, currentIssues)"
  >
    <template v-slot:label>
      <slot name="label"></slot>
    </template>
  </v-text-field>
</template>
<script>
import AttributeBaseMixins from '../../mixins/attribute-base';
import CompletudeBaseMixins from '../../mixins/completude-base';

export default {
  mixins: [AttributeBaseMixins, CompletudeBaseMixins],
  name: 'attributesDefault',
};
</script>
