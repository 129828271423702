<template>
  <ta-span>{{ cleanValue(value) }}</ta-span>
</template>

<script>
export default {
  name: 'FieldDefault',
  props: {
    value: {
      type: [Array, Object, Number, String],
      default: '',
    },
  },
  methods: {
    cleanValue(value) {
      if (Array.isArray(value)) {
        return value.join(',');
      }
      return value;
    },
  },
};
</script>
