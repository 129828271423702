import Core from './pim/core';
import Attribute from './pim/attribute';
import Product from './pim/product';
import Variant from './pim/variant';
import Sku from './pim/sku';
import Media from './pim/media';
import ProductList from './pim/productlist';
import VariantList from './pim/variantlist';
import SkuList from './pim/skulist';
import MediaList from './pim/medialist';
import Gender from './legacy/gender';
import VariantManager from './manager/variant';
import SkuManager from './manager/sku';
import MediaManager from './manager/media';
import AttributeCore from './attribute/core';
import AttributeValue from './attribute/value';
import AttributeFromMarketplace from './attribute/frommarketplace';
import Family from './attribute/family';
import Classification from './attribute/classification';
import ClassificationFromMarketplace from './attribute/classificationfrommarketplace';

export default {
  $_attribute_class: {
    AttributeCore,
    AttributeValue,
    AttributeFromMarketplace,
    Family,
    Classification,
    ClassificationFromMarketplace,
  },
  $_pim_class: {
    Core,
    Attribute,
    Product,
    Variant,
    VariantManager,
    SkuManager,
    Sku,
    Media,
    MediaManager,
    ProductList,
    VariantList,
    SkuList,
    MediaList,
  },
  $_legacy_class: {
    Gender,
  },
};
export {
  Core,
  Attribute,
  Product,
  Variant,
  VariantManager,
  SkuManager,
  Sku,
  Media,
  MediaManager,
  ProductList,
  VariantList,
  SkuList,
  MediaList,
  Gender,
  AttributeCore,
  AttributeFromMarketplace,
  AttributeValue,
  Family,
  Classification,
  ClassificationFromMarketplace,
};
