const titleDefault = 'Value inside CSV file: ';

const isNotValue = (parentObject, property) => typeof parentObject[property] === 'undefined'
  || parentObject[property] === null
  || parentObject[property] === false
  || parentObject[property] === '';

const isBothString = (itemValue, defaultValue) => typeof itemValue === 'string' && typeof defaultValue === 'string';

const areTheSame = (item, property, defaultValues) => {
  const same = typeof defaultValues === 'undefined'
    || typeof item[property] === 'undefined'
    || isNotValue(defaultValues, property)
    || (isBothString(item[property], defaultValues[property])
      && defaultValues[property].slugify() === item[property].slugify());
  if (same) {
    return true;
  }
  if (Array.isArray(defaultValues[property]) || Array.isArray(item[property])) {
    // make a copy of this value because the reference behind defaultValues
    // create a infinite loop with getDefaultValue
    const defaultValue = typeof defaultValues[property] !== 'string'
      ? [...defaultValues[property]]
      : [defaultValues[property]];
    const itemValue = typeof item[property] !== 'string' ? [...item[property]] : [item[property]];
    if (JSON.stringify(defaultValue.sort()) === JSON.stringify(itemValue.sort())) {
      return true;
    }
  }
  return false;
};

const hasDataBaseValue = (item, property, defaultValues = {}) => {
  try {
    if (areTheSame(item, property, defaultValues)) {
      return false;
    }
    return true;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e.message);
  }
  return false;
};

const getDefaultValue = (item, property, defaultValues = {}) => (title = titleDefault) => {
  try {
    if (areTheSame(item, property, defaultValues)) {
      return '';
    }
    if (Array.isArray(defaultValues[property]) || Array.isArray(item[property])) {
      // make a copy of this value because the reference behind defaultValues
      // create a infinite loop with getDefaultValue
      const defaultValue = typeof defaultValues[property] !== 'string'
        ? [...defaultValues[property]]
        : [defaultValues[property]];
      return `${title} ${defaultValue.join(', ')}`;
    }

    return `${title} ${defaultValues[property]}`;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e.message);
    return `${title} ${defaultValues[property]}`;
  }
};

export default {
  isNotValue,
  hasDataBaseValue,
  getDefaultValue,
};
