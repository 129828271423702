<template>
  <v-autocomplete
    :chips="chips"
    :filled="filled"
    :items="selectValues"
    :label="label || keyAttr"
    :messages="getDefaultValues()"
    :loading="isCompletudeRequired(bindAttribute, currentIssues)"
    :multiple="multipleSelection"
    :hint="attribute.getHelp()"
    item-text="name"
    item-value="id"
    menu-props="auto"
    persistent-hint
    :value-comparator="compareValue"
    v-model="valueAttribute"
  ></v-autocomplete>
</template>
<script>
import AttributeBaseMixins from '../../mixins/attribute-base';
import CompletudeBaseMixins from '../../mixins/completude-base';

export default {
  mixins: [AttributeBaseMixins, CompletudeBaseMixins],
  name: 'attributeSelection',
  props: {
    itemsValue: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    type: 'selection',
  }),
  methods: {},
};
</script>
