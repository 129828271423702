/* eslint prefer-destructuring:0 */
import Vue from 'vue';
import { getInstance } from '@/plugins/auth';

const namespace = 'services/scope';

export const analyse = (scope) => {
  const arrayScope = scope.split(':');
  if (arrayScope.length < 2) {
    throw new TypeError(`${namespace}.${scope}.insufficient`);
  } else if (arrayScope.length > 5) {
    throw new TypeError(`${namespace}.${scope}.too.big`);
  }
  const structure = {
    appScope: arrayScope[0],
    mainScope: arrayScope[1],
    secondScope: null,
    thirdScope: null,
    actionScope: null,
  };
  if (arrayScope.length === 3) {
    structure.actionScope = arrayScope[2];
  } else if (arrayScope.length === 4) {
    structure.secondScope = arrayScope[2];
    structure.actionScope = arrayScope[3];
  } else {
    structure.secondScope = arrayScope[2];
    structure.thirdScope = arrayScope[3];
    structure.actionScope = arrayScope[4];
  }
  return structure;
};

export const hasRight = (scope) => {
  if (!scope) {
    return true;
  }
  const {
    appScope, mainScope, secondScope, thirdScope, actionScope,
  } = analyse(scope);
  const authService = getInstance();
  if (authService.scopes.length === 0 || !authService.scopes) {
    return false;
  }
  if (authService.scopes && authService.scopes.includes(scope)) {
    return true;
  }
  Vue.$log.debug(`${namespace}.hasright.${scope}.started`, authService.scopes);
  return authService.scopes.some((s) => {
    const {
      appScope: accessAppScope,
      mainScope: accessMainScope,
      secondScope: accessSecondScope,
      thirdScope: accessThirdScope,
      actionScope: accessActionScope,
    } = analyse(s);
    // theagentapp.vx === scopetheagentapp.vx
    if (accessAppScope !== appScope) {
      Vue.$log.debug(`${namespace}.hasright.${scope}.appaccess.${accessAppScope}.failed`);
      return false;
    }
    // theagentapp.vx:* === scopetheagentapp.vx:main
    // theagentapp.vx:main === scopetheagentapp.vx:main
    const isMainScoped = accessMainScope === '*' || accessMainScope === mainScope;
    if (!isMainScoped) {
      Vue.$log.debug(`${namespace}.hasright.${scope}.ismainscoped.${accessAppScope}.failed`);
      return false;
    }
    // theagentapp.vx:main === scopetheagentapp.vx:main:second
    // theagentapp.vx:main:* === scopetheagentapp.vx:main:second
    // theagentapp.vx:main:second === scopetheagentapp.vx:main:second
    if (secondScope) {
      const isSecondScoped = !accessSecondScope || accessSecondScope === '*' || secondScope === accessSecondScope;
      if (!isSecondScoped) {
        Vue.$log.debug(`${namespace}.hasright.${scope}.issecondscoped.${accessSecondScope}.failed`);
        return false;
      }
    } else if (accessSecondScope && !secondScope) {
      Vue.$log.debug(`${namespace}.hasright.${scope}.no.secondscope.${accessSecondScope}.failed`);
      return false;
    }
    // theagentapp.vx:main:* === scopetheagentapp.vx:main:second:third
    // theagentapp.vx:main:second:* === scopetheagentapp.vx:main:second:third
    // theagentapp.vx:main:second:* === scopetheagentapp.vx:main:second:third
    // theagentapp.vx:main:second:third === scopetheagentapp.vx:main:second:third
    if (secondScope && thirdScope) {
      const isThirdScoped = !accessThirdScope || accessThirdScope === '*' || thirdScope === accessThirdScope;
      if (!isThirdScoped) {
        Vue.$log.debug(`${namespace}.hasright.${scope}.isthirdscoped.${accessThirdScope}.failed`);
        return false;
      }
    } else if (accessThirdScope && !thirdScope) {
      Vue.$log.debug(`${namespace}.hasright.${scope}.no.thirdscope.${accessThirdScope}.failed`);
      return false;
    }
    // theagentapp.vx:main:* === scopetheagentapp.vx:main:action
    // theagentapp.vx:main:second:* === scopetheagentapp.vx:main:second:action
    // theagentapp.vx:main:second:third:* === scopetheagentapp.vx:main:second:third:action
    // theagentapp.vx:main:second:third:action === scopetheagentapp.vx:main:second:third:action
    const isActionScoped = accessActionScope === '*' || accessActionScope === actionScope;
    if (!isActionScoped) {
      Vue.$log.debug(`${namespace}.hasright.${scope}.isactionscoped.${accessActionScope}.failed`);
      return false;
    }
    return true;
  });
};
