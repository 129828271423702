// https://vuex.vuejs.org/en/actions.html
import Vue from 'vue';
import axios from 'axios';

const BASE_URI = '/api/v1/pim';

export default {
  search({ commit }, payload) {
    Vue.$log.info('store.product.actions.search.started');
    return new Promise((resolve) => {
      Vue.$log.debug('store.product.actions.search.text', payload.search);
      axios
        .get(`${BASE_URI}/size/search`, {
          params: payload,
        })
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError('/api/v1/pim/product/search empty');
          }
          commit('set_query', payload.search);
          return resolve(response.data);
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return resolve({ result: [], parameters: { count: 0 } });
        });
    });
  },
};
