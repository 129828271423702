/* eslint no-param-reassign:0 */
import { map } from 'bluebird';
import CoreList from './corelist';
import Sku from './sku';
import { CoreListError } from './exception';

export default class SkuList extends CoreList {
  applyModel(parent) {
    if (this.length > 0) {
      this.forEach((row, index) => {
        if (typeof row === 'object') {
          this.add(Sku.create(row, parent), index);
        }
      });
      this.checkErrors();
    }

    return this;
  }

  fixDependencies() {
    this.forEach((row) => {
      if (typeof row === 'object') {
        if (!row.parent.parent) {
          throw new CoreListError('models.pim.skulist.fixdependencies.variant.empty');
        }
        row.name = Sku.setName(row.parent.parent.name, row.parent.color, row.size);
        row.remoteCode = Sku.setRemoteCode(row);
        row.sku = Sku.setSku(row);
        row.color = row.parent.color;
      }
    });
  }

  checkErrors() {
    if (this.length > 0) {
      let error = {};
      this.forEach((row) => {
        // reset error before
        row.resetErrors();
        if (row.sku) {
          error = SkuList.duplicated(row, 'sku', error);
        }
        error = SkuList.duplicated(row, 'ean', error);
        error = SkuList.duplicated(row, 'size', error);
        if (Object.keys(row.errors).length > 0) {
          row.parent.parentError = true;
        }
      });
    }
  }

  async getCompletudes(dispatch) {
    return map(this, sku => sku.getCompletude(dispatch), { concurrency: 5 }).then(() => this);
  }
}
