<template>
  <div>
    <keep-alive>
      <component
        v-if="component"
        :is="component"
        v-model="item[header.value]"
      ></component>
    </keep-alive>
    <template v-if="hasErrorOrWarning(item, header.value)">
      <core-error
        v-if="hasErrors(item)"
        :property="header.value"
        :errors="item.errors[header.value]"
      >
        <template v-slot:warning>
          {{ defaultValue }}
        </template>
      </core-error>
    </template>
    <template v-if="defaultValue">
      <core-error v-if="item.updatedValues && item.updatedValues[header.value]">
        <template v-slot:info>
          {{
            getDefaultValues(
              item.updatedValues,
              header.value,
              item.defaultValues
            )("Higher database priority, by-pass value from CSV file :")
          }}
        </template>
      </core-error>
    </template>
  </div>
</template>

<script>
import CdvService from '@/services/cdv';
import ErrorMixins from '@/mixins/error-base';

export default {
  name: 'CoreValue',
  mixins: [ErrorMixins],
  props: {
    value: {
      type: Object,
      default: null,
    },
    header: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({}),
  computed: {
    item() {
      return this.value;
    },
    component() {
      try {
        if (this.header.template) {
          // eslint-disable-next-line vue/no-async-in-computed-properties
          return () => import(`./coretype/${this.header.template}`).catch(() => import('./coretype/default'));
        }
      } catch (e) {} // eslint-disable-line no-empty
      return () => import('./coretype/default');
    },
    defaultValue() {
      return this.getDefaultValues(this.item, this.header.value, this.item.defaultValues)();
    },
    fieldsRequired() {
      return this.$store.getters['api/attribute/required'] || [];
    },
  },
  methods: {
    getDefaultValues: CdvService.getDefaultValue,
  },
};
</script>

<style lang="scss">
.ta-marketplace .v-btn{
  height: 14px;
  width: 14px;
  & .ta-marketplace-text {
    font-size: 8px;
  }
}
</style>
