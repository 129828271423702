<template>
  <div>
  <v-combobox
    v-model="value"
    :filter="filter"
    :items="attributesFromMarketplace"
    :search-input.sync="search"
    item-value="remoteCode"
    :item-text="getTitle"
    label="Association between attribute pim and attribute from marketplace"
    multiple
    chips
  >
    <template v-slot:selection="{ attrs, item, parent, selected }">
      <v-chip
        v-if="item === Object(item)"
        v-bind="attrs"
        :color="`${item.color} lighten-3`"
        :input-value="selected"
        label
        small
      >
        <dialog-table-av
          v-model="item"
        ></dialog-table-av>
        <v-chip
          class="blue white--text"
          left
          small
          v-text="getMarketplace(item.marketplaceId)"
        ></v-chip>

        <span class="pr-2">
          {{ item.name }}
        </span>
        <v-icon
          v-if="item.mainAttribute"
          color="green darken-3"
          small
        >
          mdi-check-circle
        </v-icon>
        <v-icon
          v-else
          color="red darken-3"
          small
          @click="setMainAttribute(item)"
        >
          mdi-circle
        </v-icon>
        <v-icon
          small
          @click="parent.selectItem(item)"
        >
          mdi-close-circle
        </v-icon>
      </v-chip>
    </template>
  </v-combobox>
   <v-btn
        color="blue-grey darken-3"
        depressed
        @click="addFromMarketplace(value)"
      >
        <v-icon left>
          mdi-update
        </v-icon>
        Update Now
      </v-btn>
  </div>
</template>

<script>
import AlertBaseMixins from '@/mixins/alert-base';
import NotificationBaseMixins from '@/mixins/notification-base';
import DialogTableAv from '@/components/attributelist/dialog-table-av';

export default {
  name: 'attribute-from-marketplace',
  components: {
    DialogTableAv,
  },
  mixins: [NotificationBaseMixins, AlertBaseMixins],
  props: {
    value: {
      type: Array,
      default: () => false,
    },
    attributeRemoteCode: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    mainAttributeId: null,
  }),
  computed: {
    marketplaces() {
      return this.$store.getters['config/marketplaces'];
    },
    attributesFromMarketplace() {
      return this.$store.getters['attribute-from-marketplace/attributes'];
    },
  },
  mounted() {
    this.mainAttributeId = this.value;
  },
  watch: {
    value() {
      this.$emit('input', this.value);
    },
  },
  methods: {
    getTitle(a) {
      const marketplace = this.marketplaces.find(m => m.id === a.marketplaceId);
      if (!marketplace) return a.name;
      return `${marketplace.name} / ${a.name}`;
    },
    getMarketplace(marketplaceId) {
      const marketplace = this.marketplaces.find(m => m.id === marketplaceId);
      return marketplace ? marketplace.name.toLowerCase() : '';
    },
    filter(item, queryText, itemText) {
      if (queryText.length < 2) return false;

      const hasValue = val => (val != null ? val : '');

      const text = hasValue(itemText || item.name);
      const query = hasValue(queryText);

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1;
    },
    setMainAttribute(item) {
      setTimeout(() =>
      // const marketplaces = this.$store.getters['config/marketplaces'];

        // const params = Object.keys(attributeValue).reduce((r, key) => {
        //   if (marketplaces.find(m => m.remoteCode === key)) {
        //     r.marketplaces.push({ marketplace: key, value: attributeValue[key] });
        //   } else if (key.match(/^\d/)) {
        //     r.fromMarketplace.push({ attributeFromMarketplace: key, value: attributeValue[key] })
        //   } else {
        //     r[key] = attributeValue[key];
        //   }
        //   return r;
        // }, { marketplaces: [], fromMarketplace: [] });
        this.$store
          .dispatch('attribute/setMainAttribute', {
            remoteCode: this.attributeRemoteCode,
            payload: item,
          })
          .catch(error => this.throwError(error))
          .finally(() => {
            // this.loading = false;
          }),
      2000);
    },
    addFromMarketplace(item) {
      // this.loading = true;
      setTimeout(() =>
      // const marketplaces = this.$store.getters['config/marketplaces'];

        // const params = Object.keys(attributeValue).reduce((r, key) => {
        //   if (marketplaces.find(m => m.remoteCode === key)) {
        //     r.marketplaces.push({ marketplace: key, value: attributeValue[key] });
        //   } else if (key.match(/^\d/)) {
        //     r.fromMarketplace.push({ attributeFromMarketplace: key, value: attributeValue[key] })
        //   } else {
        //     r[key] = attributeValue[key];
        //   }
        //   return r;
        // }, { marketplaces: [], fromMarketplace: [] });
        this.$store
          .dispatch('attribute/addFromMarketplace', {
            remoteCode: this.attributeRemoteCode,
            payload: item,
          })
          .catch(error => this.throwError(error))
          .finally(() => {
            // this.loading = false;
          }),
      2000);
    },
  },
};
</script>

<style lang="css" scoped>
</style>
