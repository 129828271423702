<template>
  <v-container fluid ref="sku">
    <v-data-table
      v-model="skuSelected"
      :headers="filterByColumn('sku')"
      class="elevation-0 ta-fix-column ta-sku"
      fixed-header
      :item-class="checkError"
      :items="itemsRows"
      hide-default-footer
      :items-per-page="items.length"
      :single-expand="settings.singleExpand"
      :dense="settings.dense"
      :search="search"
      item-key="sku"
      :show-select="showSelect"
      :custom-filter="defaultFilter"
    >
      <template v-slot:item.data-table-expand>
        <div class="text-center ta-expand-icon">
          <v-btn fab x-small class="ta-sku">RCT</v-btn>
        </div>
      </template>
      <template v-for="head in filteredFields()" v-slot:[`header.${head.value}`]="{ header }">
        <material-table-header-title
          :key="head.value"
          v-model="errorHeaders[header.value].close"
          :header="errorHeaders[header.value]"
          v-on:header:filtered="errorsSelected($event)"
          v-on:header:unfiltered="resetErrorsSelected($event)"
        ></material-table-header-title>
      </template>
      <template v-slot:item.ean="{ item }">
        <dialog-string
          :item="item"
          property="ean"
          labelName="Choose your ean"
          store="sku"
          :saveOnApi="saveOnApi"
        >
        </dialog-string>
      </template>
      <template v-slot:item.size="{ item }">
        <dialog-size-autocomplete
          :item="item"
          property="size"
          labelName="Choose your size"
          store="sku"
          :saveOnApi="saveOnApi"
        >
        </dialog-size-autocomplete>
      </template>
      <template v-slot:item.quable="{ item }">
        <v-chip :color="getColor(item.quable)" dark>{{ getMessage(item.quable) }}</v-chip>
      </template>
      <template v-slot:item.sku="{ item }">
        <dialog-string
          :item="item"
          property="sku"
          labelName="Choose your sku"
          store="sku"
          :saveOnApi="saveOnApi"
        >
        </dialog-string>
      </template>
      <template v-slot:item.active="{ item }">
        <v-switch v-model="item.active" @change="active(item)"></v-switch>
      </template>
      <template v-slot:item.sealed="{ item }">
        <v-switch v-if="isSuperAdmin" v-model="item.sealed" @change="sealed(item)"></v-switch>
      </template>
      <template v-slot:item.percent="{ item }">
        <material-percent :percent="item.percent" color="green"></material-percent>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="!noEdition" small class="mr-1" @click="editItem(item)">
          mdi-pencil-circle
        </v-icon>
        <v-icon small class="mr-1" @click="deleteItem(item)">
          mdi-delete-circle
        </v-icon>
        <v-icon small class="mr-1" v-if="isSuperAdmin" @click="syncItem(item, 'sku')">
          mdi-sync-circle
        </v-icon>
        <v-icon small v-if="listOptions.length > 1 && noEdition" @click="openItemOptions(item)">
          mdi-dots-vertical-circle
        </v-icon>
      </template>
    </v-data-table>
    <v-btn
      v-if="!noEdition"
      color="light-blue lighten-3"
      class="mt-6"
      depressed
      block
      @click="editItemSku"
    >
      <v-icon dark>mdi-plus</v-icon> add a new variant size
    </v-btn>
    <dialog-sku
      v-if="!noEdition"
      v-model="editedItem"
      :dialog="dialog"
      v-on:update:dialog="dialog = $event"
      :saveOnApi="saveOnApi"
      @onSave="saveItemSku"
      @onClose="closeItem"
    >
    </dialog-sku>
    <dialog-list-options
      v-if="listOptions.length > 1 && noEdition"
      v-model="dialogListOption"
      :list-options="listOptions"
      :current="editedItem.copy()"
      x-large
      hide-button
    >
      <template v-slot:title>
        {{ editedItem.name }}<span v-if="editedItem.sealed"> (data sealed)</span>
      </template>
    </dialog-list-options>
  </v-container>
</template>

<script>
import Mixins from '@/mixins';
import { SkuManager, Sku, Variant } from '../../models';
import { STEPPER_VALID_IMPORT } from '../../helpers/steps';
import ItemsBaseMixins from '../../mixins/items-base';
import EditMixins from '../../mixins/edit-base';
import StepBaseMixins from '../../mixins/step-base';
import ErrorMixins from '../../mixins/error-base';
import { EventBus } from '../../helpers/event-bus';

export default {
  mixins: [EditMixins, StepBaseMixins, ItemsBaseMixins, ErrorMixins, ...Mixins],
  props: {
    items: {
      type: Array,
    },
    parentItem: {
      type: Variant,
      default: () => Variant.create(),
    },
    saveOnApi: {
      type: Boolean,
      default: true,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    noEdition: {
      type: Boolean,
      default: false,
    },
    search: {
      type: [String, Number],
      default: '',
    },
  },
  data: () => ({
    skuSelected: [],
    defaultItem: Sku.create({}),
    editedItem: Sku.create({}),
    dialogListOption: false,
    listOptionsDefault: [
      {
        name: 'Default',
      },
      {
        name: 'Move your sku',
        fields: [
          'id',
          {
            customTemplate: 'variant',
            name: 'variantId',
            label: 'Choose your new variant parent',
          },
        ],
        store: 'sku/move',
        scope: 'pimapp.v2:pim:sku:move:write',
      },
      {
        name: 'Duplicate this sku 👯‍♀️👯‍♂️',
        fields: [
          'id',
          'size',
          {
            customTemplate: 'barcode',
            name: 'ean',
          },
        ],
        store: 'sku/duplicate',
        scope: 'pimapp.v2:pim:sku:duplicate:write',
      },
    ],
  }),
  created() {
    this.defaultItem = Sku.createDefaultInstance(this.parentItem);
    this.setDefaultSkuItem(this.items[0] || this.defaultItem);
  },
  computed: {
    settings() {
      return this.$store.getters['settings/settings'];
    },
    headers() {
      return this.$store.getters['settings/headers'].sku;
    },
    listOptions() {
      return this.listOptionsDefault.filter(o => this.hasRight(o.scope || null));
    },
  },
  methods: {
    openItemOptions(item) {
      this.dialogListOption = true;
      this.editedItem = item;
    },
    /**
     * This method allow to initialize default data to all dialog
     * @param {[type]} item [description]
     */
    setDefaultSkuItem(item) {
      this.$log.debug('components.pim.skusview.setdefaultskuitem.started', item);
      const defaultItem = SkuManager.newObject(item);
      if (defaultItem.sku) {
        defaultItem.sku = defaultItem.sku.replace(`-${item.size}`, '-[newsize]');
      }
      delete defaultItem.id;
      defaultItem.sealed = false;
      defaultItem.name = defaultItem.name.replace(` ${item.size}`, ' [newsize]');
      this.defaultItem = defaultItem;
      this.editedItem = defaultItem;
    },
    editItemSku() {
      this.setDefaultSkuItem(this.items[0] || this.defaultItem);
      this.dialog = true;
    },
    saveItemSku(done = () => {}) {
      this.$log.info('components.pim.skusview.saveitemsku.started', this.editedItem);
      let endpoint = 'sku/new';
      const isOld = this.editedIndex > -1;
      const isNotNew = this.editedItem.id > 0;
      if (isOld) {
        endpoint = 'sku/update';
      }

      if (this.saveOnApi === false && !isNotNew) {
        this.$log.debug('components.pim.skusview.saveitemsku.saveonly');
        return this.saveItem(() => {
          done();
          return this.closeItemSku();
        });
      }
      return this.$store
        .dispatch(endpoint, {
          sku: this.editedItem.toObject(),
          parent: this.editedItem.parent,
        })
        .then(async (response) => {
          this.$log.debug('components.pim.skusview.saveitemsku.update');
          if (typeof this.items[this.editedIndex] !== 'undefined') {
            this.items[this.editedIndex].update(response);
            await this.items[this.editedIndex].setCompletude(this.$store.dispatch);
          } else {
            this.items.push(response);
          }
          return this.closeItemSku();
        })
        .catch(error => this.throwError(error))
        .finally(() => {
          this.loading = false;
          done();
          return EventBus.$emit('onCollectionUpdate');
        });
    },
    async closeItemSku(checkpoint = true) {
      this.$log.log('components.pim.skusview.closeitemvariant.started');
      const rollbackStep = this.currentStep >= STEPPER_VALID_IMPORT;
      this.withSku = false;
      const listProms = [this.closeItem()];
      if (rollbackStep && checkpoint) {
        // if the step is old, no need to go to the following step
        listProms.push(this.stepInProgress(STEPPER_VALID_IMPORT));
      }
      return Promise.all(listProms).then(() => {
        // reset data
        // regenerate a new defaultItem and editItem
        this.setDefaultSkuItem(this.items[0] || this.defaultItem);
      });
    },
  },
};
</script>

<style></style>
