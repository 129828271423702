export default [];

export const productHeaders = [
  {
    sortable: false,
    isForAll: true,
    text: 'image',
    i18n: 'image',
    value: 'image',
    filterable: false,
    width: 100,
    order: 0,
  },
  {
    isForAll: true,
    text: 'Id',
    i18n: 'id',
    value: 'id',
    filterable: false,
    width: 200,
    order: 1,
  },
  {
    isNotDefaultOnly: true,
    text: 'Suppliers',
    i18n: 'distributor',
    value: 'distributorIds',
    filterable: false,
    width: 200,
    order: 659,
  },
  {
    sortable: false,
    isAdmin: true,
    text: 'Pim DB',
    i18n: 'pimdb',
    value: 'quable',
    filterable: false,
    width: 50,
    order: 661,
  },
  {
    sortable: false,
    isAdmin: true,
    text: 'Marketplace DB',
    i18n: 'marketplace',
    value: 'marketplace',
    filterable: false,
    width: 50,
    order: 662,
  },
  {
    sortable: false,
    isAdmin: true,
    text: 'Enabled/Disabled',
    i18n: 'active',
    value: 'active',
    filterable: false,
    width: 50,
    order: 663,
  },
  {
    isAdmin: true,
    text: 'Created date',
    i18n: 'synccreatedat',
    value: 'syncCreatedAt',
    filterable: false,
    width: 200,
    order: 664,
  },
  // {
  //   isNotDefaultOnly: true,
  //   text: 'Completude',
  //   i18n: 'percent',
  //   value: 'percent',
  //   filterable: false,
  //   width: 130,
  //   order: 665,
  // },
  {
    sortable: false,
    isNotDefaultOnly: true,
    text: 'Actions',
    i18n: 'actions',
    value: 'actions',
    filterable: false,
    width: 100,
    order: 666,
  },
].map((h) => {
  // eslint-disable-next-line no-param-reassign
  h.i18n = `header.product.${h.i18n}`;
  return h;
});

export const variantHeaders = [
  {
    sortable: false,
    isForAll: true,
    text: 'image',
    i18n: 'image',
    value: 'image',
    filterable: false,
    width: 100,
  },
  // {
  //   isNotDefaultOnly: true,
  //   text: 'Completude',
  //   i18n: 'completudes',
  //   value: 'completudes',
  //   filterable: false,
  //   width: 130,
  // },
  {
    sortable: false,
    isForAll: true,
    text: 'Id',
    i18n: 'id',
    value: 'id',
    filterable: false,
    width: 50,
  },
  {
    isNotDefaultOnly: true,
    text: 'Completude',
    i18n: 'completudes',
    value: 'completudes',
    filterable: false,
    width: 130,
  },
  {
    isAdmin: true,
    text: 'Name',
    i18n: 'name',
    value: 'name',
    width: 200,
  },
  {
    isAdmin: true,
    text: 'Pattern',
    i18n: 'pattern',
    value: 'pattern',
    width: 200,
    order: 658,
  },
  {
    isAdmin: true,
    text: 'Pim DB',
    i18n: 'pimdb',
    value: 'quable',
    filterable: false,
    width: 200,
    order: 659,
  },
  {
    isNotDefaultOnly: true,
    text: 'Family',
    i18n: 'familyid',
    value: 'familyId',
    filterable: false,
    width: 200,
    order: 650,
  },
  {
    sortable: false,
    isNotDefaultOnly: true,
    text: 'legacyId theagent',
    i18n: 'legacyid',
    value: 'legacyId',
    filterable: false,
    width: 200,
    order: 660,
  },
  {
    sortable: false,
    isNotDefaultOnly: true,
    text: 'Images',
    i18n: 'medias',
    value: 'medias',
    filterable: false,
    width: 500,
    order: 661,
  },
  {
    sortable: false,
    isNotDefaultOnly: true,
    text: 'Enabled/Disabled',
    i18n: 'active',
    value: 'active',
    filterable: false,
    width: 50,
    order: 662,
  },
  {
    sortable: false,
    isAdmin: true,
    text: 'Sealed',
    i18n: 'sealed',
    value: 'sealed',
    filterable: false,
    width: 50,
    order: 663,
  },
  {
    isAdmin: true,
    text: 'Updated date',
    i18n: 'syncupdatedat',
    value: 'syncUpdatedAt',
    filterable: false,
    width: 200,
    order: 664,
  },
  {
    sortable: false,
    isNotDefaultOnly: true,
    text: 'Actions',
    i18n: 'actions',
    value: 'actions',
    filterable: false,
    width: 200,
    order: 666,
  },
].map((h) => {
  // eslint-disable-next-line no-param-reassign
  h.i18n = `header.variant.${h.i18n}`;
  return h;
});

export const skuHeaders = [
  {
    sortable: false,
    isForAll: true,
    text: '',
    value: 'data-table-expand',
    filterable: false,
    width: 20,
  },
  {
    sortable: false,
    isForAll: true,
    text: 'Id',
    i18n: 'id',
    value: 'id',
    filterable: false,
    width: 75,
  },
  {
    isNotDefaultOnly: true,
    text: 'Supplier Reference',
    i18n: 'supplierreference',
    value: 'supplierReference',
    width: 200,
  },
  {
    isAdmin: true,
    text: 'Name',
    i18n: 'name',
    value: 'name',
    width: 200,
  },
  {
    sortable: false,
    isAdmin: true,
    text: 'Pim DB',
    i18n: 'pimdb',
    value: 'quable',
    filterable: false,
    width: 50,
    order: 659,
  },
  {
    sortable: false,
    isAdmin: true,
    text: 'LegacyId',
    i18n: 'legacyid',
    value: 'legacyId',
    filterable: false,
    width: 200,
    order: 660,
  },
  {
    sortable: false,
    isNotDefaultOnly: true,
    text: 'Enabled/Disabled',
    i18n: 'active',
    value: 'active',
    filterable: false,
    width: 50,
    order: 661,
  },
  {
    sortable: false,
    isAdmin: true,
    text: 'Sealed',
    i18n: 'sealed',
    value: 'sealed',
    filterable: false,
    width: 50,
    order: 662,
  },
  {
    isAdmin: true,
    text: 'Created date',
    i18n: 'synccreatedat',
    value: 'syncCreatedAt',
    filterable: false,
    width: 200,
    order: 663,
  },
  {
    isAdmin: true,
    text: 'Updated date',
    i18n: 'syncupdatedat',
    value: 'syncUpdatedAt',
    filterable: false,
    width: 200,
    order: 664,
  },
  // {
  //   isNotDefaultOnly: true,
  //   text: 'Completude',
  //   i18n: 'percent',
  //   value: 'percent',
  //   filterable: false,
  //   width: 130,
  //   order: 665,
  // },
  {
    sortable: false,
    isNotDefaultOnly: true,
    text: 'Actions',
    i18n: 'actions',
    value: 'actions',
    filterable: false,
    width: 75,
    order: 666,
  },
].map((h) => {
  // eslint-disable-next-line no-param-reassign
  h.i18n = `header.sku.${h.i18n}`;
  return h;
});

export const importHeaders = [
  {
    isNotDefaultOnly: true,
    text: 'Id',
    value: 'id',
    filterable: false,
  },
  {
    isNotDefaultOnly: true,
    text: 'Name',
    value: 'name',
    filterable: false,
  },
  {
    isNotDefaultOnly: true,
    text: 'Status',
    value: 'status',
    filterable: false,
  },
  {
    isNotDefaultOnly: true,
    text: 'Steps',
    value: 'metaData.step',
    filterable: false,
  },
  {
    isNotDefaultOnly: true,
    text: 'Actions',
    value: 'actions',
    filterable: false,
  },
].map((h) => {
  // eslint-disable-next-line no-param-reassign
  h.i18n = `header.import.${h.i18n}`;
  return h;
});

export const csvHeaders = [
  {
    isForAll: true,
    text: 'THEAGENT',
    i18n: 'theagent',
    value: 'THEAGENT',
    width: 50,
    sortable: false,
    filterable: false,
    order: 0,
  },
  {
    sortable: false,
    isNotDefaultOnly: true,
    text: 'Actions',
    i18n: 'actions',
    value: 'actions',
    filterable: false,
    width: 200,
    order: 666,
  },
].map((h) => {
  // eslint-disable-next-line no-param-reassign
  h.i18n = `header.catalog.${h.i18n}`;
  return h;
});

export const catalogHeaders = [
  {
    sortable: false,
    isForAll: true,
    text: 'Image',
    width: 100,
    value: 'images.1',
    filterable: false,
  },
  {
    isForAll: true,
    text: 'Produit ID',
    width: 100,
    value: 'idProduct',
    filterable: false,
  },
  {
    isForAll: true,
    text: 'SKU',
    width: 100,
    value: 'sku',
    filterable: false,
  },
  {
    isForAll: true,
    text: 'EAN',
    width: 100,
    value: 'barcode',
    filterable: false,
  },
  {
    isNotDefaultOnly: true,
    text: 'Taille',
    width: 100,
    value: 'size',
    filterable: false,
  },
  {
    isForAll: true,
    text: 'Taille par Pays',
    width: 200,
    value: 'sizeCountry',
    filterable: false,
  },
  {
    isNotDefaultOnly: true,
    text: 'Ref. Fournisseur',
    width: 100,
    value: 'supplierIdentifier',
    filterable: false,
  },
  {
    isNotDefaultOnly: true,
    text: 'Fournisseur',
    width: 100,
    value: 'referenceWs.idSupplier',
    filterable: false,
  },
  {
    isForAll: true,
    text: 'Marque',
    width: 100,
    value: 'referenceWs.idBrand',
    filterable: false,
  },
  {
    isNotDefaultOnly: true,
    text: 'Categorie',
    width: 100,
    value: 'referenceWs.idType',
    filterable: false,
  },
  {
    isNotDefaultOnly: true,
    text: 'Saison',
    width: 100,
    value: 'referenceWs.season',
    filterable: false,
  },
  {
    isBusinessOnly: true,
    text: 'Prix achat',
    width: 100,
    value: 'boughtPrice',
    filterable: false,
  },
  {
    isBusinessOnly: true,
    text: 'Prix vente',
    width: 100,
    value: 'retailPrice',
    filterable: false,
  },
  {
    isBusinessOnly: true,
    text: 'Prix public',
    width: 100,
    value: 'purchasePrice',
    filterable: false,
  },
  {
    isBusinessOnly: true,
    text: 'Qté',
    width: 100,
    value: 'qty',
    filterable: false,
  },
  {
    isNotDefaultOnly: true,
    text: 'Actif',
    width: 100,
    value: 'active',
    filterable: false,
  },
  {
    isAdmin: true,
    text: 'Cotation qualité',
    width: 100,
    value: 'Cotation qualité',
    filterable: false,
  },
];

export const translateHeaders = [
  {
    isAdmin: true,
    text: 'Translate ID',
    i18n: 'translateid',
    width: 50,
    value: 'id',
    filterable: false,
  },
  {
    isAdmin: true,
    text: 'Hash',
    i18n: 'hash',
    width: 100,
    value: 'hash',
    filterable: false,
  },
  {
    isForAll: true,
    text: 'Francais',
    i18n: 'french',
    width: 150,
    value: 'fra',
    filterable: false,
  },
  {
    isForAll: true,
    text: 'Anglais',
    i18n: 'english',
    width: 150,
    value: 'gbr',
    filterable: false,
  },
  {
    isNotDefaultOnly: true,
    text: 'Italien',
    i18n: 'italian',
    width: 150,
    value: 'ita',
    filterable: false,
  },
  {
    isNotDefaultOnly: true,
    text: 'Portugais',
    i18n: 'portuguese',
    width: 150,
    value: 'prt',
    filterable: false,
  },
  {
    isNotDefaultOnly: true,
    text: 'Allemand',
    i18n: 'deutch',
    width: 150,
    value: 'deu',
    filterable: false,
  },
  {
    isNotDefaultOnly: true,
    text: 'Japonais',
    i18n: 'japanese',
    width: 150,
    value: 'jpn',
    filterable: false,
  },
  {
    isNotDefaultOnly: true,
    text: 'Espagnol',
    i18n: 'spanish',
    width: 150,
    value: 'esp',
    filterable: false,
  },
  {
    isNotDefaultOnly: true,
    text: 'Russe',
    i18n: 'russian',
    width: 150,
    value: 'rus',
    filterable: false,
  },
  {
    isNotDefaultOnly: true,
    text: 'Chinois',
    i18n: 'chinese',
    width: 150,
    value: 'chn',
    filterable: false,
  },
  {
    isNotDefaultOnly: true,
    text: 'Danois',
    i18n: 'netherland',
    width: 150,
    value: 'nld',
    filterable: false,
  },
  {
    isNotDefaultOnly: true,
    text: 'Type',
    i18n: 'type',
    width: 150,
    value: 'type',
    filterable: false,
  },
  {
    isAdmin: true,
    text: 'Remote ID',
    i18n: 'remoteid',
    width: 150,
    value: 'remoteId',
    filterable: false,
  },
  {
    sortable: false,
    isNotDefaultOnly: true,
    text: 'Actions',
    i18n: 'actions',
    value: 'actions',
    filterable: false,
    width: 100,
  },
].map((h) => {
  // eslint-disable-next-line no-param-reassign
  h.i18n = `header.translate.${h.i18n}`;
  return h;
});
