<template>
  <v-app-bar id="core-toolbar" flat>
    <v-app-bar-nav-icon color="black" @click.stop="set_drawer(!drawer)"></v-app-bar-nav-icon>
    <material-breadcrumb></material-breadcrumb>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-flex align-center layout py-2>
        <v-text-field class="mr-4 purple-input" :label="$t('component.toolbar.search')" hide-details color="purple" />
        <helper-lang-filter> </helper-lang-filter>
        <router-link v-ripple class="toolbar-items" to="/">
          <v-icon color="tertiary">mdi-view-dashboard</v-icon>
        </router-link>
        <router-link v-ripple class="toolbar-items" to="/user-profile">
          <div class="btn-account">
            <v-icon color="tertiary">mdi-account</v-icon>
            <div style="font-size: 8px;color: black;">{{ user.username }}</div>
          </div>
        </router-link>
        <div class="btn-menu">
          <v-menu
            open-on-hover
            bottom
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="tertiary"
                v-bind="attrs"
                v-on="on">
                mdi-dots-vertical
              </v-icon>
            </template>
            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-avatar>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ user.username }}</v-list-item-title>
                    <v-list-item-subtitle>User of PIM</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      icon
                      disabled
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title @click="logout">Log out</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </v-flex>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  data: () => ({
    responsive: false,
  }),
  computed: {
    user() {
      return this.$store.getters['app/user'];
    },
    ...mapState('app', ['drawer']),
  },
  mounted() {
    this.onResponsiveInverted();
    window.addEventListener('resize', this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted);
  },
  methods: {
    ...mapMutations('app', ['set_drawer']),
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },
    logout() {
      this.$auth.logout();
      this.$router.push({ name: 'login', query: { redirect: this.$route.path } });
    },
  },
};
</script>

<style>
#core-toolbar a {
  text-decoration: none;
}
.btn-account {
  display: flex;
  flex-direction: column;
}
</style>
