<template>

  <v-container fluid>
    <v-form v-model="valid">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="attribute.name"
              :rules="titleRules"
              :counter="255"
              label="Attribute name / CSV Field name"
              required
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="attribute.label.fr"
              :rules="titleRules"
              :counter="255"
              label="Attribute label"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">

          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <attribute-typeentity v-model="attribute.typeEntity" editing></attribute-typeentity>
          </v-col>
          <v-col cols="12" md="4">
            <attribute-typehinting v-model="attribute.typeHinting" editing></attribute-typehinting>
          </v-col>
          <v-col cols="12" md="4">
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-divider></v-divider>
          <autocomplete-afm
            v-model="associations"
            :attribute-remote-code="attribute.remoteCode"
            editing
          ></autocomplete-afm>
        <v-divider></v-divider>
   <v-btn
      :disabled="!valid"
      color="success"
      class="mr-4"
      @click="validate"
    >
      Validate
    </v-btn>

    <v-btn
      color="error"
      class="mr-4"
      @click="reset"
    >
      Reset Form
    </v-btn>

    <v-btn
      color="warning"
      @click="resetValidation"
    >
      Reset Validation
    </v-btn>
    </v-form>
    <v-divider></v-divider>
    <v-row>
      <v-col>
        <settings-attribute-value-list-view v-model="attribute" :associations="associations"></settings-attribute-value-list-view>
      </v-col>
     <!--  <v-col>
        <router-view :key="$route.fullPath" class="blue lighten-1" >
        </router-view>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AutocompleteAfm from '@/components/attributelist/autocomplete-afm';

export default {
  name: 'AttributeByIdView',
  components: {
    AutocompleteAfm,
  },
  data: () => ({
    headers: [],
    associations: [],
  }),
  mounted() {
    // between values or attribute url
    // @todo use a uniq name for each of them
    this.fetchByRemoteCode(this.$route.params.aRemoteCode || this.$route.params.remoteCode);
    this.fetchList();
  },
  computed: {
    ...mapGetters('form/rules', { titleRules: 'title' }),
    // something weirds come froom the store between attribute and attributevalue
    remoteCode() {
      return this.$route.params.aRemoteCode || this.$route.params.remoteCode;
    },
    attribute() {
      const attribute = this.$store.getters['attribute/attribute'];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.associations = attribute.fromMarketplace;
      return attribute;
    },
    attributesFromMarketplace() {
      return this.$store.getters['attribute-from-marketplace/attributes'];
    },
    settings() {
      return this.$store.getters['settings/settings'];
    },
  },
  methods: {
    ...mapActions('attribute', { fetchByRemoteCode: 'get' }),
    ...mapActions('attribute-from-marketplace', { fetchList: 'list' }),
    validate() {
      // this.loading = true;
      setTimeout(() => this.$store
        .dispatch('attribute/update', {
          remoteCode: this.remoteCode,
          payload: this.attribute,
        })
        .catch(error => this.throwError(error))
        .finally(() => {
          // this.loading = false;
        }),
      2000);
    },
  },
};
</script>

<style lang="scss">
</style>
