<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      class="elevation-1 ta-fix-column ta-family"
      :items="families"
      :server-items-length="itemsCount"
      :options.sync="itemsOptions"
      :loading="loading"
      :search="search"
      :custom-filter="filterOnlyCapsText"
      :footer-props="{ 'items-per-page-options': [100, 200, 500, -1] }"
      loading-text="Loading... Please wait"
      :dense="settings.dense"
      fixed-header
    >
      <template v-slot:top>

      </template>
      <template v-for="head in headers" v-slot:[`header.${head.value}`]="{ header }">
        <v-text-field
          v-if="head.value === 'name'"
          v-model="search"
          :key="head.value"
          label="Famille de produits"
          class="title-search"
        ></v-text-field>
        <h6 style="display: inline-block"
          v-else
          :key="head.value"
          :header="head"
        >
          {{ header.text }}
        </h6>
      </template>
      <template v-slot:item.data-table-expand="">
        <div class="text-center">
          <v-btn fab x-small class="ta-family">1</v-btn>
        </div>
      </template>
      <template v-slot:item.counByVariant="{ item }">
        <v-chip
          :color="getColor(item.counByVariant)"
          dark
          small
        >
          {{ item.counByVariant }}
        </v-chip>
      </template>
      <template v-slot:item.active="{ item }">
        <v-switch
          v-model="item.active"
           @change="active(item)"
           readonly
        ></v-switch>
      </template>
      <template v-slot:item.actions="{ item }">
        <family-link v-if="item"
          v-model="item.remoteCode">
          <v-icon small class="mr-1">
            mdi-pencil-circle
          </v-icon>
        </family-link>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import NotificationMixins from '@/mixins/notification-base';
import FamilyLink from '@/components/link/family';

export default {
  name: 'familyListView',
  mixins: [NotificationMixins],
  components: {
    FamilyLink,
  },
  data: () => ({
    headers: [
      { text: 'Nom de l\'attribut', value: 'name' },
      { text: 'Identifiant', value: 'remoteCode', filterable: false },
      {
        text: 'Active', value: 'active', filterable: false, sortable: false,
      },
      {
        text: 'Total RC', value: 'counByVariant', filterable: false, sortable: false,
      },
      {
        text: 'Actions', value: 'actions', filterable: false, sortable: false,
      },
    ],
    search: '',
    loading: false,
    endpointType: 'family',
  }),
  computed: {
    ...mapGetters('family', ['families']),
    settings() {
      return this.$store.getters['settings/settings'];
    },
  },
  created() {
    this.loading = true;
    this.fetchAllfamilies()
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions('family', { fetchAllfamilies: 'list' }),
    // eslint-disable-next-line no-unused-vars
    filterOnlyCapsText(value, search, item) {
      return value != null
        && search != null
        && typeof value === 'string'
        && value.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    },
    getColor(count) {
      if (count === 0 || !count) return 'red';
      return 'green';
    },
  },
};
</script>

<style lang="scss" scoped>
div.title-search .v-text-field .v-label {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 300 !important;
  line-height: 1.5em !important;
  font-size: 0.75rem !important;
  color: rgba(0, 0, 0, 0.6);
}
</style>
