const nc = {
  legacyId: 0,
  name: 'nc',
  langs: {
    it: 'Non specificato', es: 'No especificado', de: 'Nicht angegeben', fr: 'Non spécifié', en: 'Unspecified',
  },
};
const femme = {
  legacyId: 1,
  name: 'femme',
  langs: {
    it: 'Donna', es: 'Mujer', de: 'Damen', fr: 'Femme', en: 'Women',
  },
};
const homme = {
  legacyId: 2,
  name: 'homme',
  langs: {
    it: 'Uomo', es: 'Hombre', de: 'Herren', fr: 'Homme', en: 'Men',
  },
};
const enfant = {
  legacyId: 3,
  name: 'enfants/ados unisexe',
  langs: {
    it: 'Bambini/ragazzi unisex', es: 'Niños/jóvenes unisex', fr: 'Enfants/Ados unisexe', en: 'Kids/Teens unisex', pt: 'Crianças/adolescentes unissex', de: 'Kinder/Jugendliche Unisex',
  },
};
const unisexe = {
  legacyId: 4,
  name: 'unisexe',
  langs: {
    it: 'Unisex', es: 'Mixto', de: 'Unisex', fr: 'Unisexe', en: 'Unisex',
  },
};
const decoration = {
  legacyId: 5,
  name: 'decoration',
  langs: {
    it: 'Decorazione', es: 'Decoración', de: 'Dekoration', fr: 'Décoration', en: 'Decoration',
  },
};
const garcon = {
  legacyId: 6,
  name: 'enfants/ados garçon',
  langs: {
    it: 'Bambini/ragazzi ragazzi', es: 'Niños/adolescentes', fr: 'Enfants/Ados garçon', en: 'Kids/Teens boy', pt: 'Crianças/adolescentes meninos', de: 'Kinder/Teenager',
  },
};
const fille = {
  legacyId: 7,
  name: 'enfants/ados fille',
  langs: {
    it: 'Bambini/Ragazze adolescenti', es: 'Niños/adolescentes', fr: 'Enfants/Ados fille', en: 'Kids/Teens girl', pt: 'Crianças/adolescentes', de: 'Kinder/Teenager mädchen',
  },
};

const bebeGarcon = {
  legacyId: 8,
  name: 'bébé garçon',
  langs: {
    fr: 'Bébé garçon', en: 'Baby boy', es: 'Bebé chico', pt: 'Menino', it: 'Agazzino', de: 'Kleiner junge',
  },
};

const bebeFille = {
  legacyId: 9,
  name: 'bébé fille',
  langs: {
    fr: 'Bébé fille', en: 'Baby girl', es: 'Bebita', pt: 'Bebezinha', it: 'Neonata', de: 'Kleine',
  },
};

const bebeUnisexe = {
  legacyId: 10,
  name: 'bébé unisexe',
  langs: {
    fr: 'Bébé unisexe', en: 'Baby unisex', es: 'Bebé unisex', pt: 'Bebê unissex', it: 'Bambino unisex', de: 'Unisex-baby',
  },
};

const enfantGarcon = {
  legacyId: 11,
  name: 'enfants garçon',
  langs: {
    fr: 'Enfants garçon', en: 'Kids boy', es: 'Niños chico', pt: 'Garoto garoto', it: 'Ragazzino', de: 'Kinder Junge',
  },
};

const enfantFille = {
  legacyId: 12,
  name: 'enfants fille',
  langs: {
    fr: 'Enfants fille', en: 'Kids girl', es: 'Niños niña', pt: 'Menina das crianças', it: 'Ragazza dei bambini', de: 'Kinder Mädchen',
  },
};

const enfantUnisexe = {
  legacyId: 13,
  name: 'enfants unisexe',
  langs: {
    fr: 'Enfants unisexe', en: 'Kids unisex', es: 'Niños unisex', pt: 'Crianças unissex', it: 'Bambini unisex', de: 'Unisex-kinder',
  },
};

const adoGarcon = {
  legacyId: 14,
  name: 'ados garçon',
  langs: {
    fr: 'Ados garçon', en: 'Teens boy', es: 'Chico adolescente', pt: 'Menino adolescente', it: 'Ragazzo adolescente', de: 'Teenager Junge',
  },
};

const adoFille = {
  legacyId: 15,
  name: 'ados fille',
  langs: {
    fr: 'Ados fille', en: 'Teens girl', es: 'Chica adolescente', pt: 'Menina adolescente', it: 'Teenager', de: 'Teenager Mädchen',
  },
};

const adoUnisexe = {
  legacyId: 16,
  name: 'ados unisexe',
  langs: {
    fr: 'Ados unisexe', en: 'Teens unisex', es: 'Adolescente unisex', pt: 'Adolescente unissex', it: 'Adolescente unisex', de: '  Unisex-teenager',
  },
};

const forEveryone = {
  legacyId: 17,
  name: 'pour tous',
  langs: {
    fr: 'Pour tous', en: 'For everyone', es: 'Para todos', pt: 'Para todos', it: 'Per tutti', de: 'Für alle',
  },
};

const gendersById = {};
gendersById[nc.legacyId] = nc;
gendersById[femme.legacyId] = femme;
gendersById[homme.legacyId] = homme;
gendersById[enfant.legacyId] = enfant;
gendersById[unisexe.legacyId] = unisexe;
gendersById[decoration.legacyId] = decoration;
gendersById[garcon.legacyId] = garcon;
gendersById[fille.legacyId] = fille;
gendersById[bebeGarcon.legacyId] = bebeGarcon;
gendersById[bebeFille.legacyId] = bebeFille;
gendersById[bebeUnisexe.legacyId] = bebeUnisexe;
gendersById[enfantGarcon.legacyId] = enfantGarcon;
gendersById[enfantFille.legacyId] = enfantFille;
gendersById[enfantUnisexe.legacyId] = enfantUnisexe;
gendersById[adoGarcon.legacyId] = adoGarcon;
gendersById[adoFille.legacyId] = adoFille;
gendersById[adoUnisexe.legacyId] = adoUnisexe;
gendersById[forEveryone.legacyId] = forEveryone;

const genders = {
  femme,
  femmes: femme,
  feminin: femme,
  woman: femme,
  women: femme,
  homme,
  hommes: homme,
  man: homme,
  men: homme,
  masculin: homme,
  unisex: unisexe,
  unisexe,
  enfant,
  enfants: enfant,
  children: enfant,
  child: enfant,
  garcon,
  garcons: garcon,
  fille,
  filles: fille,
  decoration,
  decorations: decoration,
  pourtous: forEveryone,
  nc,
};

genders['bebe-garcon'] = bebeGarcon;
genders['bebe-garcons'] = bebeGarcon;
genders['bebe-fille'] = bebeFille;
genders['bebe-filles'] = bebeFille;
genders['bebe-unisexe'] = bebeUnisexe;
genders['enfants-garcon'] = enfantGarcon;
genders['enfants-garcons'] = enfantGarcon;
genders['enfant-garcons'] = enfantGarcon;
genders['enfant-garcon'] = enfantGarcon;
genders['enfants-fille'] = enfantFille;
genders['enfants-filles'] = enfantFille;
genders['enfant-filles'] = enfantFille;
genders['enfant-fille'] = enfantFille;
genders['enfants-unisexe'] = enfantUnisexe;
genders['enfant-unisexe'] = enfantUnisexe;
genders['ados-garcon'] = adoGarcon;
genders['ados-garcons'] = adoGarcon;
genders['ado-garcons'] = adoGarcon;
genders['ado-garcon'] = adoGarcon;
genders['ados-fille'] = adoFille;
genders['ados-filles'] = adoFille;
genders['ado-filles'] = adoFille;
genders['ado-fille'] = adoFille;
genders['ados-unisexe'] = adoUnisexe;
genders['ado-unisexe'] = adoUnisexe;
genders['enfants-ados-garcon'] = garcon;
genders['enfant-ados-garcon'] = garcon;
genders['enfants-ado-garcon'] = garcon;
genders['enfant-ado-garcon'] = garcon;
genders['enfants-ados-fille'] = fille;
genders['enfant-ados-filles'] = fille;
genders['enfants-ado-filles'] = fille;
genders['enfant-ado-fille'] = fille;
genders['enfants-ados-unisexe'] = unisexe;
genders['enfant-ado-unisexe'] = unisexe;
genders['pour-tous'] = forEveryone;

export default class Gender {
  static fetchById(id) {
    if (!gendersById[id]) {
      return gendersById[0].name;
    }
    return gendersById[id].name || gendersById[0].name;
  }

  static fetchIdByname(name) {
    const property = name;
    if (!name || !genders[property.toLowerCase()]) {
      return genders.nc.name;
    }
    return genders[property.toLowerCase()].legacyId || genders.nc.legacyId;
  }

  static fetchQuableNameByname(name) {
    const property = name.toLowerCase();
    if (!name || !genders[property]) {
      return genders.nc.name;
    }
    return genders[property].name || genders.nc.name;
  }

  static fetchLangsByName(name) {
    const property = name.toLowerCase();
    if (!name || !genders[property]) {
      return genders.nc.name;
    }
    return genders[property].langs || genders.nc.langs;
  }

  static fetchList() {
    return genders;
  }

  static fetchListById() {
    return gendersById;
  }
}
