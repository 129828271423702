<template>
  <v-container fill-height fluid>
    <v-row>
      <v-col cols="12">
        <pim-stepper-view :steps="steps" @onRollBack="rollback"></pim-stepper-view>
      </v-col>
    </v-row>
    <v-row v-if="isEditing || (mediasImported.length > 0 && isEditing)">
      <common-zip-upload v-if="isEditing" v-on:list:csv="listProductFromImport" v-on:btn:disabled="btnDisabled = $event"></common-zip-upload>
      <common-media-grid-view v-if="mediasImported.length > 0 && isEditing" :items="mediasImported">
      </common-media-grid-view>
    </v-row>
    <v-flex md12 style="min-height: 658px">
      <v-toolbar flat>
        <v-flex xs12>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('component.zipupload.search-placeholder')"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-flex>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn color="blue" rounded @click="openPanel(1)">
          <v-icon>mdi-package</v-icon>
          {{ $tc("component.common.files", (importObj.metaData || { files: [] }).files.length) }}
        </v-btn>
        <v-btn v-if="stats.errors" rounded small text @click="openPanel(2)">
          <chips-stats
            :count="stats.errors"
            label="error"
            color="red"
            style="cursor: pointer;"
            noIcon
          >
          </chips-stats>
        </v-btn>
        <v-btn v-if="rowSelected.length" rounded small text>
          <chips-stats :count="rowSelected.length" label="selection" color="green" noIcon>
          </chips-stats>
        </v-btn>
      </v-toolbar>
      <pim-list-files v-if="importedfiles.length > 0 && panelOpened === 1" v-model="importedfiles">
      </pim-list-files>
      <pim-list-errors v-if="panelOpened === 2" v-model="items"> </pim-list-errors>
      <core-alert></core-alert>
      <v-data-table
        v-model="rowSelected"
        :headers="filterByColumn('csv')"
        class="elevation-1 ta-fix-column ta-table-csv ta-csv"
        item-key="vueKey"
        :items="itemsRows"
        :items-per-page="itemsPerPage"
        :item-class="checkError"
        :options.sync="itemsOptions"
        :loading="loading"
        loading-text="Loading... Please wait"
        :search="search"
        :dense="settings.dense"
        show-select
        fixed-header
        @click:row="selectableRow"
        :custom-filter="defaultFilter"
        :footer-props="{ 'items-per-page-options': [100, 200, 400, 500, -1] }"
      >
        <template v-slot:item.data-table-select="{ item, isSelected, select, expand, isExpanded }">
          <div class="text-center ta-expand-icon">
            <v-btn fab x-small class="ta-csv">CSV</v-btn>
            <v-simple-checkbox :value="isSelected" @input="select($event)"></v-simple-checkbox>
          </div>
          <div class="text-center ta-expand-icon" v-if="item.completude">
            <v-btn fab x-small text class="ta-mp-default">{{ item.completude.default.percent }} </v-btn>
          </div>
          <div class="text-center ta-expand-icon" v-if="hasErrors(item)">
            <v-btn fab x-small :value="isExpanded" @click="expand(!isExpanded)"  class="ta-error">{{ Object.keys(item.errors).length }}E</v-btn>
          </div>
        </template>
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
            :items-per-page-options="[100, 200, 400, 500, -1]"
          >
          </v-data-footer>
        </template>
        <template v-for="head in filteredFields()" v-slot:[`header.${head.value}`]="{ header }">
          <material-table-header-title
            :key="head.value"
            v-model="errorHeaders[header.value].close"
            :header="errorHeaders[header.value]"
            v-on:header:filtered="errorsSelected($event)"
            v-on:header:unfiltered="resetErrorsSelected($event)"
          ></material-table-header-title>
        </template>
        <template
          v-for="head in filteredFields()"
          v-slot:[`item.${head.value}`]="{ item }"
        >
          <div v-if="hasValue(item[head.value], head.value)" :key="head.value">
            <core-value
              v-model="item"
              :header="head">
            </core-value>
          </div>
        </template>
        <template v-slot:expanded-item="{ item }">
          <td v-if="hasErrors(item)" colspan="7">
            <pim-list-errors v-model="item"> </pim-list-errors>
          </td>
        </template>
        <template v-slot:item.THEAGENT="{ item }">
          <core-avatar v-if="hasImage(item)" :image="firstImage(item)" customSize="140" />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil-circle
          </v-icon>
          <v-icon small class="mr-2" @click="deleteItem(item)">
            mdi-delete-circle
          </v-icon>
          <v-icon small @click="duplicateRow(item)">
            mdi-table-row-plus-after
          </v-icon>
        </template>
      </v-data-table>
      <div>
        <div class="text-center fix-on-function-btn" v-if="showProgressBar">
          <v-progress-circular
            :rotate="-90"
            :size="80"
            :width="20"
            :value="progressing"
            color="teal"
          >
            {{ progressing }}
          </v-progress-circular>
        </div>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="currentFunction.color"
              fab
              dark
              absolute
              right
              fixed
              v-bind="attrs"
              v-on="on"
              class="mx-2"
              style="bottom: 420px !important;"
              :disabled="btnFunctionStepped || btnDisabled"
              @click="functionStepped"
            >
              <v-icon dark>{{ currentFunction.icon }}</v-icon>
            </v-btn>
          </template>
          <span>Next step: {{ currentFunction.title }}</span>
        </v-tooltip>
        <v-tooltip left v-if="hasRight('dashboardapp.v1:button:resetimport:read')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="gray"
              fab
              dark
              absolute
              right
              fixed
              v-bind="attrs"
              v-on="on"
              class="mx-2"
              style="bottom: 360px !important;"
              :disabled="btnResetImport"
              @click="resetImport"
            >
              <v-icon dark>mdi-rotate-left</v-icon>
            </v-btn>
          </template>
          <span>Reset workflow</span>
        </v-tooltip>
        <dialog-column
          v-if="hasRightActions && rowSelected.length > 0"
          :items="rowSelected"
          @onSave="saveItemCdv(false)"
        >
        </dialog-column>
        <dialog-column-multi
          v-if="hasRightActions && rowSelected.length > 0"
          :items="rowSelected"
          @onSave="saveItemCdv(false)"
        >
        </dialog-column-multi>
        <dialog-cdv
          v-if="hasRightActions"
          v-model="editedItem"
          :dialog="dialog"
          v-on:update:dialog="dialog = $event"
          @onSave="saveItemCdv"
          @onClose="closeItem"
        >
        </dialog-cdv>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="purple"
              fab
              dark
              absolute
              right
              fixed
              v-bind="attrs"
              v-on="on"
              class="mx-2"
              style="bottom: 100px !important;"
              @click="exportCsv"
            >
              <v-icon dark>mdi-set-merge</v-icon>
              CDV
            </v-btn>
          </template>
          <span>Export to CDV file</span>
        </v-tooltip>
        <dialog-settings> </dialog-settings>
      </div>
    </v-flex>
  </v-container>
</template>

<script>
/* eslint no-plusplus:0 */
/* eslint no-param-reassign:0 */

import Vue from 'vue';
import { mapActions } from 'vuex';
import {
  pimFunctions,
  STEPPER_CONVERT_PIM,
  STEPPER_INTEGRITY_CSV,
  STEPPER_START,
} from '@/helpers/steps';
import Mixins from '@/mixins';
import ItemsBaseMixins from '@/mixins/items-base';
import TableHeadersBaseMixins from '@/mixins/table-headers-base';
import StepBaseMixins from '@/mixins/step-base';
import EditMixins from '@/mixins/edit-base';
import ProfileMixins from '@/mixins/profile-base';
import ProgressMixins from '@/mixins/progress-base';
import ErrorMixins from '@/mixins/error-base';
import CompletudesMixins from '@/mixins/completudes-base';

export default {
  mixins: [
    ItemsBaseMixins,
    TableHeadersBaseMixins,
    StepBaseMixins,
    EditMixins,
    ProfileMixins,
    ProgressMixins,
    ErrorMixins,
    CompletudesMixins,
    ...Mixins,
  ],
  data: () => ({
    itemsPerPage: 200,
    rowSelected: [],
    panelOpened: 0,
    itemsCount: 0,
    itemsOptions: {},
    loading: true,
    btnFunctionStepped: false,
    btnResetImport: false,
    btnDisabled: false,
    items: [{}],
    search: '',
    functionsStepped: pimFunctions,
    baseURL: process.env.VUE_APP_PIM_BASEURL || 'http://localhost:3128/',
  }),
  mounted() {
    this.$log.log('components.pim.importcsvedit.mounted');
    const { importId } = this.$route.params;
    this.notificationInfo({ message: `Ready to edit your csv. (import: ${importId})` });
    this.editedItem = Object.assign({}, this.defaultItem);
    this.getStep(importId)
      .then((stepNumber) => {
        const workflows = Object.values(pimFunctions).map(fnct => fnct.workflow);
        return this.setSteps(workflows)
          .then(() => {
            if (stepNumber > STEPPER_CONVERT_PIM) {
              this.$router.push({ path: `/pim/import/${importId}/edit` });
              return Promise.resolve(true);
            }
            // eslint-disable-next-line no-param-reassign
            return this.setNextStep(--stepNumber || this.defaultStep);
          });
      });
    this.initImport();
  },
  computed: {
    defaultItem: function defaultItem() {
      const defaultValues = this.$store.getters['settings/headers'].csv.reduce((item, row) => {
        // eslint-disable-next-line no-param-reassign
        item[row.value] = '';
        return item;
      }, {});
      defaultValues.vueKey = this.generateVueKey('newitem');
      return defaultValues;
    },
    importObj() {
      return this.$store.getters['import/import'];
    },
    settings() {
      return this.$store.getters['settings/settings'];
    },
    optionsTa() {
      return this.$store.getters['settings/theagent'];
    },
    headers() {
      return this.$store.getters['settings/headers'].csv;
    },
    mediasImported() {
      return this.$store.getters['import/mediasImported'];
    },
    isEditing() {
      return STEPPER_INTEGRITY_CSV === this.currentStep;
    },
    /**
     * calculate stas
     * @return {[type]} [description]
     */
    stats() {
      const rows = this.$store.getters['import/rows'];
      const stats = {
        rows: rows.length,
        errors: 0,
      };
      stats.errors = rows.reduce((errors, i) => {
        if (typeof i.errors !== 'undefined') {
          errors += Object.keys(i.errors).length;
        }
        return errors;
      }, 0);
      return stats;
    },
    importedfiles() {
      return this.$store.getters['import/files'];
    },
    fields() {
      return this.$store.getters['api/attribute/fields'];
    },
    fieldsRequired() {
      return this.$store.getters['api/attribute/required'] || [];
    },
  },
  watch: {
    search() {
      this.resetRowSelected();
    },
  },
  beforeCreate() {
    this.$store.dispatch('api/attribute/list');
    this.$store.dispatch('completude/families');
  },
  methods: {
    ...mapActions('import', ['csv']),
    listProductFromImport() {
      return new Promise((resolve) => {
        const { page, itemsPerPage } = this.itemsOptions;
        const params = {
          id: this.$route.params.importId,
          params: {
            limit: parseInt(itemsPerPage, 10),
            page,
          },
        };
        return this.csv(params).then((data) => {
          const { items, itemsCount } = this.options(
            data.result,
            data.parameters.count,
            this.itemsOptions,
          );
          this.loading = false;
          resolve({
            items,
            itemsCount,
            itemsPerPage,
          });
        });
      }).then(({ items, itemsCount, itemsPerPage }) => {
        this.items.length = items.length;
        // https://fr.vuejs.org/v2/guide/list.html#Limitations
        items.forEach((item, indexOfItem) => {
          Vue.set(this.items, indexOfItem, item);
        });
        this.itemsCount = itemsCount;
        this.itemsPerPage = itemsPerPage;
      });
    },
    initImport() {
      this.$log.debug('components.pim.importcsvedit.initimport.started');
      this.loading = true;
      this.btnResetImport = true;
      this.showProgressCamembert(120);
      return this.listProductFromImport()
        .catch(error => this.throwError(error))
        .finally(() => {
          this.loading = false;
          this.btnResetImport = false;
          this.closeProgressCamember();
          return this.notificationSuccess({
            message: `Your import has been init. (import: ${this.$route.params.importId})`,
          });
        });
    },
    resetImport() {
      this.loading = true;
      this.btnResetImport = true;
      this.showProgressCamembert(20);
      // eslint-disable-next-line no-alert
      if (window.confirm('Do you really want to reset?')) {
        return this.$store
          .dispatch('import/reset')
          .then(() => this.$store.dispatch('import/csv', {
            id: this.$route.params.importId,
            reset: true,
          }))
          .then((data) => {
            const { items, itemsCount, itemsPerPage } = this.options(
              data.result,
              data.parameters.count,
              this.itemsOptions,
            );
            this.loading = false;
            // https://fr.vuejs.org/v2/guide/list.html#Limitations
            items.forEach((item, indexOfItem) => {
              Vue.set(this.items, indexOfItem, item);
            });
            this.itemsCount = itemsCount;
            this.itemsPerPage = itemsPerPage;
            return Promise.resolve();
          })
          .catch(error => this.throwError(error))
          .finally(() => {
            this.loading = false;
            this.btnResetImport = false;
            this.closeProgressCamember();
            this.throwSuccess({
              message: `Your data has been reseted. (import: ${this.$route.params.importId})`,
            });
            return this.stepInProgress(this.defaultStep);
          });
      }
      return false;
    },
    /**
     * This function allow to duplicate  csv row
     *
     * @param  {Object} item a row of csv
     */
    duplicateRow(item) {
      this.$log.debug('components.pim.importcsvedit.duplicaterow.started', item);

      this.loading = true;
      this.btnResetImport = true;
      const editItem = Object.assign({}, item);
      editItem[this.fields.FIELD_COLOR] = '[newcolor]';
      // fix duplicate keys detected on vuejs
      editItem.vueKey = this.generateVueKey(editItem[this.fields.FIELD_PRODUCTNAME] || 'duplicateitem');
      editItem['Taille (FR)'] = '[newsize]';
      editItem[this.fields.FIELD_SUPPLIERREFCOLOR] = '[supplierreference]';
      editItem[this.fields.FIELD_COLOR] = '[color]';
      editItem['Couleur spécifique'] = '';
      editItem[this.fields.FIELD_EAN] = '[ean]';
      this.editedItem = editItem;
      this.editedIndex = -1;
      this.dialog = true;
    },
    saveItemCdv(save = true) {
      this.$log.debug('components.pim.importcsvedit.saveitemcdv.started');
      const checkPoint = STEPPER_START;
      // to save new item
      if (save) {
        this.saveItem();
      }
      return this.stepInProgress(checkPoint).then(() => this.closeItem());
    },
    dataIntegrity() {
      this.$log.debug('components.pim.importcsvedit.dataintegrity.started');
      this.loading = true;
      this.btnFunctionStepped = true;
      this.showProgressCamembert(36);
      if (this.items.length > 0) {
        // initialiaze items errors
        this.items.map((item) => {
          item.errors = {};
          return item;
        });
        // clean items before calling dispatching data
        this.resetItems();
        return this.$store
          .dispatch('import/reset')
          .then(() => this.$store.dispatch('import/update', {
            id: this.$route.params.importId,
            data: {
              result: this.items,
              parameters: {
                count: this.itemsCount,
                page: 1,
                limit: this.itemsPerPage,
              },
            },
          }))
          .then(() => this.$store.dispatch('import/integrityCsv', this.$route.params.importId))
          .then((data) => {
            const { items, itemsCount, itemsPerPage } = this.options(
              data.result,
              data.parameters.count,
              this.itemsOptions,
            );
            // https://fr.vuejs.org/v2/guide/list.html#Limitations
            items.forEach((item, indexOfItem) => {
              Vue.set(this.items, indexOfItem, item);
            });
            this.itemsCount = itemsCount;
            this.itemsPerPage = itemsPerPage;
            return Promise.resolve(true);
          })
          .then(() => this.stepInProgress(this.currentStep))
          .catch((error) => {
            this.throwError(error);
            this.setError(error);
          })
          .finally(() => {
            this.notificationSuccess({
              message: `Terminated. (import: ${this.$route.params.importId})`,
            });
            this.loading = false;
            this.btnFunctionStepped = false;
            this.closeProgressCamember();
          });
      }
      return false;
    },
    exportCsv() {
      this.$log.debug('components.pim.importcsvedit.exportcsv.started');
      this.loading = true;
      this.btnFunctionStepped = true;
      this.showProgressCamembert(6);
      return this.$store
        .dispatch('import/reset')
        .then(() => this.$store.dispatch('import/update', {
          id: this.$route.params.importId,
          data: {
            result: this.items,
            parameters: {
              count: this.itemsCount,
              page: 1,
              limit: this.itemsPerPage,
            },
          },
        }))
        .then(() => this.$store
          .dispatch('export/cdv', this.$route.params.importId)
          .then(([filepath]) => {
            if (filepath) {
              Vue.downloadUrl(`${this.baseURL}/${filepath}`);
            } else {
              this.$log.error('components.pim.importcsvedit.exportcsv.filepath.not.okay');
            }
          })
          .catch((error) => {
            this.throwError(error);
            this.setError(error);
          })
          .finally(() => {
            this.notificationSuccess({
              message: `Export Csv. (import: ${this.$route.params.importId})`,
            });
            this.loading = false;
            this.btnFunctionStepped = false;
            this.closeProgressCamember();
          }));
    },
    convertStructuredData() {
      this.$log.debug('components.pim.importcsvedit.convertstructureddata.started');
      this.loading = true;
      this.btnFunctionStepped = true;
      this.showProgressCamembert(260);
      // clean items before calling dispatching data
      this.resetItems();
      const { importId } = this.$route.params;
      return this.$store
        .dispatch('import/reset')
        .then(() => this.$store.dispatch('import/update', {
          id: this.$route.params.importId,
          data: {
            result: this.items,
            parameters: {
              count: this.itemsCount,
              page: 1,
              limit: this.itemsPerPage,
            },
          },
          // need to export cdv due to endpoint import/csv/convert dependencies
        }))
        .then(() => this.$store.dispatch('export/cdv', importId))
        .then(() => this.$store
          .dispatch('import/convert', {
            id: importId,
            reset: true,
          })
          .then(() => this.stepInProgress(this.currentStep))
          .then(() => {
            this.loading = false;
            this.btnFunctionStepped = false;
            this.closeProgressCamember();
            this.notificationSuccess({ message: `Terminated. (import: ${importId})` });
            return this.$router.push({ path: `/pim/import/${importId}/edit` });
          }))
        .catch((error) => {
          this.btnFunctionStepped = false;
          this.closeProgressCamember();
          if (Array.isArray(error.data) && error.data.length > 0) {
            this.notificationError({
              message: error.data.reduce((message, datum) => {
                const i18nKey = `error.import.${datum.description}`;
                if (typeof datum.description !== 'undefined' && this.$te(i18nKey)) {
                  message = `${message}${this.$t(i18nKey, {
                    ean: datum.data[this.fields.FIELD_EAN],
                    refColor: datum.data[this.fields.FIELD_SUPPLIERREFCOLOR],
                    size: datum.data[this.fields.FIELD_SIZE],
                    id: datum.data.extraValue,
                  })}<br />`;
                } else if (datum.name === 'RCTCdvError') {
                  message = `${message}${datum.data[this.fields.FIELD_SUPPLIERREFCOLOR]} with size ${datum.data[this.fields.FIELD_SIZE]} and ean ${datum.data[this.fields.FIELD_EAN]} has an issue<br />`;
                } else if (datum.name === 'ErrorCdv') {
                  message = `${message} - Ref: ${datum.identifyName} -> ${datum.description}<br />`;
                }
                return message;
              }, ''),
            });
            const exception = new Error('Open your console debug, there are a lot of error here');
            exception.name = error.data[0].name;
            exception.data = error.data.reduce((r, d) => {
              r[d.identifyName] = d.description;
              return r;
            }, {});
            this.throwError(exception);
          } else {
            this.throwError(error);
          }
          this.setError(error);
        });
    },
    goToLink(url) {
      window.open(`${process.env.VUE_APP_PIM_BASEURL}/${url}`, '_blank');
    },
    rollback({ nextStep, editable }) {
      this.$log.debug('components.pim.importcsvedit.rollback.started');
      if (editable === false) {
        return Promise.resolve(false);
      }
      // clean items before calling dispatching data
      this.resetItems();
      const stepInd = parseInt(nextStep, 10);
      return this.updateStep({
        step: stepInd,
        id: this.$route.params.importId,
      }).then(() => {
        this.notificationInfo({ message: `step ${stepInd}` });
        if (stepInd > STEPPER_CONVERT_PIM) {
          this.$router.push({ path: `/pim/import/${this.$route.params.importId}/edit` });
          return Promise.resolve(true);
        }
        // eslint-disable-next-line no-param-reassign
        return this.setNextStep(stepInd || this.defaultStep);
      });
    },
    openPanel(value) {
      if (this.panelOpened === value) {
        this.panelOpened = 0;
      } else {
        this.panelOpened = value;
      }
    },
    hasImage(item) {
      const keyImages = [
        'Images par défaut',
        'Images par Galeries lafayette',
        'Images par Neutral',
        'Images par Veepee',
        'Images par Zalando',
        'Images par Sarenza',
        'Images par About you',
      ];
      return keyImages.some(k => item[k]);
    },
    firstImage(item) {
      return [
        'Images par défaut',
        'Images par Galeries lafayette',
        'Images par Neutral',
        'Images par Veepee',
        'Images par Zalando',
        'Images par Sarenza',
        'Images par About you',
      ].reduce((r, k) => {
        if (r) return r;
        if (item[k] && item[k].includes(';')) {
          return item[k].split(';')[0];
        }
        return r;
      }, null);
    },
    resetRowSelected() {
      this.rowSelected = [];
    },
    resetItems() {
      this.resetRowSelected();
      this.resetErrors();
    },
    hookDialog(editedItem) {
      if (typeof this.families[editedItem.familyId] !== 'undefined') {
        const family = this.families[editedItem.familyId];
        // rebuild family coompletude from here without  calling api/completude
        // src/mixins/completude-base.js
        this.$store.commit('completude/set_familyCompletude', Object.keys(family).reduce((r, field) => {
          family[field].forEach((m) => {
            if (typeof r[m] === 'undefined') {
              r[m] = [];
            }
            r[m].push({
              bind: field,
            });
          });
          return r;
        }, {}));
      }
      return editedItem;
    },
    hasValue(value, header) {
      return (
        (
          typeof value !== 'undefined'
          && value !== null
          && (value !== '' || this.fieldsRequired.map(f => f.name).includes(header))
        ) || (
          Array.isArray(value)
          && value.filter(Boolean).length > 0
        )
      );
    },
  },
};
</script>

<style>
.fix-on-function-btn {
  right: 12px;
  z-index: 12;
  bottom: 420px !important;
  position: fixed;
}

.v-data-table__wrapper table thead.v-data-table-header tr th {
  text-transform: uppercase;
}

.import-card button.v-btn--fab.v-btn--fixed {
  z-index: 5;
}
</style>
