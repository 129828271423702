<template>
  <v-container>
    <material-card
      color="blue"
      title="Import list"
      text="Here is a subtitle for this table"
      offset="10"
    >
      <v-data-table :headers="headers" :items="items" hide-default-footer>
        <template v-slot:item.status="{ item }">
          <v-chip :color="getColor(item.status, 'import')" dark>{{
            getMessage(item.status, "import")
          }}</v-chip>
        </template>
        <template v-slot:item.metaData.step="{ item }">
          <v-chip :color="getStepColor(item.metaData.step)" dark
            >step:{{ item.metaData.step || 0 }}</v-chip
          >
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" v-on:click.stop @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small class="mr-2" v-on:click.stop @click="gotoImportCsv(item)">
            mdi-table-edit
          </v-icon>
          <v-icon small class="mr-2" v-on:click.stop v-if="!item.sealed" @click="deleteItem(item)">
            mdi-delete-circle
          </v-icon>
        </template>
      </v-data-table>
    </material-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Mixins from '../../mixins';

export default {
  mixins: Mixins,
  data: () => ({}),
  computed: {
    ...mapGetters('import', { imports: 'imports', status: 'statusForSelect' }),
    ...mapGetters('form/rules', { textRules: 'text' }),
    items: {
      get() {
        return this.imports;
      },
    },
    headers() {
      return this.$store.getters['settings/headers'].import;
    },
  },
  // called when page is created before dom
  created() {
    this.list();
  },
  methods: {
    gotoImportCsv(item) {
      this.$router.push({ path: `/pim/import/${item.id}/csv` });
    },
    ...mapActions('import', ['list', 'save']),
    editItem(item) {
      this.reset();
      this.$store
        .dispatch('import/get', item.id)
        .then((importObj) => {
          if (typeof importObj.metaData.item === 'undefined' || !importObj.metaData.item) {
            throw new TypeError('common.importlist.importObj.metadata.items.null');
          }
          this.$store.commit('import/set_edit', importObj);
        })
        .catch(error => this.notificationError({ message: error.message }));
    },
    deleteItem(item) {
      this.reset();
      this.loading = true;
      const index = this.items.indexOf(item);
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure you want to delete this item?')) {
        this.$store
          .dispatch('import/delete', item.id)
          .then(() => this.items.splice(index, 1))
          .catch(error => this.notificationError({ message: error.message }))
          .finally(() => {
            this.loading = false;
            this.reset();
          });
      }
    },
    reset() {
      this.$store.commit('import/set_edit_reset');
    },
  },
};
</script>

<style></style>
