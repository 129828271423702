/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue';
import VueAnalytics from 'vue-gtag';
import Router from 'vue-router';
import { getInstance } from '@/plugins/auth';

// Routes
import routes from './paths';

Vue.use(Router);
// Create a new router
const router = new Router({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

// Route guard checks to see if you are logged in, if not reroutes to login
// to is where you are going, matched.some is to find which routes have requiresAuth
router.beforeEach((to, from, next) => {
  const instance = getInstance();
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (instance.isAuthenticated) {
      next();
      return;
    }
    next({
      name: 'login',
      query: { redirect: to.path },
    });
  } else {
    next();
  }
});

// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
  Vue.use(
    VueAnalytics,
    {
      config: {
        id: process.env.VUE_APP_GOOGLE_ANALYTICS,
        pageTrackerScreenviewEnabled: true,
      },
    },
    router,
  );
}

export default router;
