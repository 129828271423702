import Vue from 'vue';
/**
 * due to some constraint on vuetify
 * the parent is not the parent template
 * when using v-edit-dialog, here the following parents
 *   1. vsimpletable
 *   2. vdata
 *   3. vdatatable
 *   4. varianview|skuview (the parent desired)
 *
 * @note if the emit function is broken check if some vuecomponent
 * havent not been added or removed around the dialog
 * @see https://www.digitalocean.com/community/tutorials/vuejs-global-event-bus
 */
// eslint-disable-next-line
export const EventBus = new Vue();
