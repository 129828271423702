import { getInstance } from '@/plugins/auth';

// eslint-disable-next-line import/prefer-default-export
export const authenticationGuard = (to, from, next) => {
  const authService = getInstance();
  const guardAction = () => {
    const { redirect } = to.query || { redirect: null };
    if (authService.isAuthenticated) {
      return redirect ? next(redirect) : next();
    }
    return authService.getRefreshToken().then((res) => {
      if (res.status !== 200) {
        throw new Error('app.interceptors.error.refresh.token.status.invalid');
      }
      return redirect ? next(redirect) : next();
    });
  };
  // If the TaAuthPlugin has loaded already, check the authentication state
  if (!authService.isLoading) {
    return guardAction();
  }
  return new Promise((resolve) => {
    // eslint-disable-next-line consistent-return
    authService.$watch('isLoading', (isLoading) => {
      if (isLoading === false) {
        return resolve(guardAction());
      }
    });
  });
};
