// https://vuex.vuejs.org/en/mutations.html

export default {
  set_skus(state, skus) {
    state.skus = skus;
  },
  set_sku(state, sku) {
    state.sku = sku;
  },
};
