<template>
  <div>
    <div v-if="!hasRightActions || noDialog">{{ cleanValue(titleName.fr || titleName) }}</div>
    <v-edit-dialog :return-value="valueAttribute" large lazy v-else @save="saveInline">
      <div>{{ cleanValue(titleName.fr || titleName) }}</div>
      <core-error
        v-if="item.errors"
        :property="property"
        :errors="item.errors[keyAttr || property]"
      />
      <template v-slot:input>
        <v-autocomplete
          v-bind:value="valueAttribute"
          v-on:change="newObject = $event"
          :items="selectValues"
          :label="labelName || keyAttr"
          :hint="attribute.getHelp()"
          :multiple="multipleSelection"
          item-text="name"
          item-value="id"
          menu-props="auto"
          persistent-hint
          :readonly="readonly"
          @update:error="showError"
        ></v-autocomplete>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
/* eslint max-len:0 */
import DialogBaseMixins from '../../mixins/dialog-base';
import ProfileMixins from '../../mixins/profile-base';

export default {
  mixins: [DialogBaseMixins, ProfileMixins],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    type: 'selection',
  }),
  computed: {
    itemsKey: () => undefined,
    itemsValue() {
      if (
        !this.attribute
        || typeof this.attribute.values === 'undefined'
        || this.attribute.values.length === 0
      ) {
        return [];
      }
      if (Array.isArray(this.attribute.values)) {
        return this.attribute.values.map(value => ({ name: value, id: value }));
      }
      return this.attribute.values;
    },
  },
  beforeCreate() {
    this.$store.dispatch('api/attribute/list');
  },
  methods: {
    cleanValue(value) {
      if (Array.isArray(value)) {
        return value.join(',');
      }
      return value;
    },
  },
};
</script>

<!-- Using the BEM convention -->
<style></style>
