/* eslint no-param-reassign:0 */
const DEFAULT_WIDTH = 150;

export default {
  data: () => ({
    itemsPerPage: 10,
    page: 1,
  }),
  computed: {
    itemsRows() {
      // src/mixins/error-base.js
      if (Array.isArray(this.itemsErrors) && this.itemsErrors.length > 0) {
        return this.itemsErrors;
      }
      return this.items;
    },
    debug() {
      return this.$store.getters['settings/theagent'].sillyMode;
    },
    showHeaders() {
      return this.$store.getters['settings/settings'].showHeaders || false;
    },
  },
  methods: {
    options(items, itemsCount, itemsOptions) {
      const { sortBy, sortDesc, itemsPerPage } = itemsOptions;
      itemsCount = parseInt(itemsCount, 10);
      if (items.length === 0) {
        return {
          items,
          itemsCount,
          itemsPerPage,
        };
      }
      if (sortBy && sortBy.length === 1 && sortDesc && sortDesc.length === 1) {
        items.sort((a, b) => {
          const sortA = a[sortBy[0]];
          const sortB = b[sortBy[0]];

          if (sortDesc[0]) {
            if (sortA < sortB) return 1;
            if (sortA > sortB) return -1;
            return 0;
          }
          if (sortA < sortB) return -1;
          if (sortA > sortB) return 1;
          return 0;
        });
      }
      return {
        items,
        itemsCount,
        itemsPerPage,
      };
    },
    itemsOptionsDefault() {
      const isItemsPerPage = typeof this.$route.query.limit !== 'undefined' && this.$route.query.limit;
      const ispage = typeof this.$route.query.page !== 'undefined' && this.$route.query.page;
      return {
        itemsPerPage: isItemsPerPage ? parseInt(this.$route.query.limit, 10) : this.itemsPerPage,
        page: ispage ? parseInt(this.$route.query.page, 10) : this.page,
      };
    },
    getWithByColumn(items) {
      let widthHeaders = {};
      const factorWith = 3;
      items.forEach((item) => {
        widthHeaders = Object.keys(item).reduce((r, itemKey) => {
          if (['attributes', 'attributes_temp', 'infos', 'dataJson'].includes(itemKey)) {
            Object.keys(item[itemKey]).forEach((subItemKey) => {
              if (typeof widthHeaders[itemKey] === 'undefined') {
                widthHeaders[itemKey] = {};
              }
              if (typeof widthHeaders[itemKey][subItemKey] === 'undefined') {
                if (typeof item[itemKey][subItemKey] === 'string' || typeof item[itemKey][subItemKey] === 'number' || !item[itemKey][subItemKey]) {
                  r[subItemKey] = Math.max(DEFAULT_WIDTH, `${item[itemKey][subItemKey] || ''}`.length * factorWith);
                } else if (typeof item[itemKey][subItemKey] === 'object') {
                  try {
                    r[subItemKey] = Math.max(DEFAULT_WIDTH * 2, `${item[itemKey][subItemKey].fr || ''}`.length * factorWith);
                  } catch (e) {
                    r[subItemKey] = DEFAULT_WIDTH * factorWith;
                  }
                }
              }
            });
          } else {
            if (typeof widthHeaders[itemKey] === 'undefined') {
              if (typeof item[itemKey] === 'string' || typeof item[itemKey] === 'number' || !item[itemKey]) {
                r[itemKey] = Math.max(DEFAULT_WIDTH, `${item[itemKey] || ''}`.length * factorWith);
              } else if (typeof item[itemKey] === 'object') {
                try {
                  r[itemKey] = Math.max(DEFAULT_WIDTH * 2, `${item[itemKey].fr || ''}`.length * factorWith);
                } catch (e) {
                  r[itemKey] = DEFAULT_WIDTH * factorWith;
                }
              }
              return r;
            }
            if (typeof item[itemKey] === 'string' || typeof item[itemKey] === 'number') {
              r[itemKey] = Math.min(DEFAULT_WIDTH * factorWith, Math.max(r[itemKey], `${item[itemKey] || ''}`.length * factorWith));
            } else if (typeof item[itemKey] === 'object') {
              try {
                r[itemKey] = Math.min(DEFAULT_WIDTH * 2, Math.max(r[itemKey], `${item[itemKey].fr || ''}`.length * factorWith));
              } catch (e) {} // eslint-disable-line no-empty
            }
          }
          return r;
        }, widthHeaders);
      });
      return widthHeaders;
    },
    /**
     * this method filter all header colmuns according to the selected headers
     * @return {[type]} [description]
     */
    filterByColumn(key = 'product') {
      let selectedHeaders = [];
      let widthHeaders = {};
      // if itemsRow exist, try to define the width
      if (Array.isArray(this.itemsRows) && this.itemsRows.length > 0) {
        widthHeaders = this.getWithByColumn(this.itemsRows);
      }
      if (
        Array.isArray(this.settings.headers[key])
        && this.settings.headers[key].length > 0
        && this.showHeaders
      ) {
        selectedHeaders = this.settings.headers[key].map(e => e.text);
      } else {
        selectedHeaders = this.headers.map(e => e.text);
      }
      return this.headers
        .filter(header => selectedHeaders.indexOf(header.text) !== -1)
        .map(h => ({
          ...h,
          width: this.getWidth(h, widthHeaders),
        }));
    },
    getWidth(header, widthHeaders) {
      if (typeof header.width !== 'undefined' && header.width) {
        return header.width;
      }
      if (header.value.indexOf('.') !== -1) {
        const [, secondKey] = header.value.split('.');
        if (typeof widthHeaders[secondKey] === 'undefined') {
          return DEFAULT_WIDTH;
        }
        return widthHeaders[secondKey] || DEFAULT_WIDTH;
      }
      return widthHeaders[header.text] || DEFAULT_WIDTH;
    },
    filteredFields(excludedHeadersSuppl = []) {
      const excludedHeaders = ['Actions', 'errors', 'defaultValues', 'alreadyExisted', 'vueKey'];
      return this.headers.filter(header => [
        ...excludedHeaders,
        ...excludedHeadersSuppl,
      ].indexOf(header.text) === -1);
    },
    resetItems() {
      if (this.debug) this.$log.error('mixins.items-base.resetitems.is.empty');
    },
  },
};
