<template>
  <div>
    <div v-if="!hasRightActions || noDialog">{{ titleName }}</div>
    <v-edit-dialog :return-value="item[property]" large lazy v-else @save="saveInline">
      <div>{{ titleName }}</div>
      <core-error v-if="item.errors" :property="property" :errors="item.errors[property]" />
      <template v-slot:input>
        <v-autocomplete
          v-bind:value="item[property]"
          v-on:change="newObject = $event"
          :items="itemsValue"
          menu-props="auto"
          hide-details
          item-text="name"
          item-value="id"
          :label="labelName"
          hint="Pick your brand"
          chips
        >
        </v-autocomplete>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
import DialogBaseMixins from '@/mixins/dialog-base';
import ProfileMixins from '@/mixins/profile-base';
import BrandSubMixins from '@/mixins/config/brand-sub-base';

export default {
  mixins: [DialogBaseMixins, ProfileMixins, BrandSubMixins],
  data: () => ({}),
  computed: {
    itemsValue() {
      return this.brands;
    },
    titleName() {
      const brand = this.brands.find(c => c.id === this.item[this.property]);
      if (!brand) return '';
      return brand.name;
    },
  },
  methods: {},
};
</script>

<style></style>
