const namespace = 'mixins.notification-base';

const getMessage = (data) => {
  if (typeof data === 'string') {
    return { text: data };
  }
  if (data instanceof Error) {
    return {
      text: data.message,
    };
  }
  if (typeof data === 'object') {
    data.text = data.message || data.text;
    return data;
  }
  throw new TypeError(`${namespace}.getmessage.datatype.invalid`);
};

export default {
  methods: {
    notificationSuccess(message) {
      const data = getMessage(message);
      return this.$notify({
        group: 'ta-info',
        type: 'success',
        title: 'Hello there',
        ...data,
      });
    },
    notificationInfo(message) {
      const data = getMessage(message);
      return this.$notify({
        group: 'ta-info',
        type: 'success',
        title: 'Hey you',
        ...data,
      });
    },
    notificationWarning(message) {
      const data = getMessage(message);
      return this.$notify({
        group: 'ta-alfred',
        type: 'warn',
        title: 'Wow, man',
        ...data,
      });
    },
    notificationError(message) {
      const data = getMessage(message);
      return this.$notify({
        group: 'ta-alfred',
        type: 'error',
        title: 'Wow-wow',
        ...data,
      });
    },
    notificationSystem(message) {
      const data = getMessage(message);
      return this.$notify({
        group: 'ta-alfred',
        type: 'error',
        title: 'Call Administrator 👨🏾‍💻',
        ...data,
        duration: 10000,
      });
    },
    notificationAlfred(message) {
      const data = getMessage(message);
      return this.$notify({
        group: 'ta-alfred',
        type: 'info',
        title: '👨🏼‍✈️ Alfred :',
        ...data,
        duration: 2000,
      });
    },
  },
};
