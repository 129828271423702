<template>
  <div>
    <div v-if="!hasRightActions || noDialog">{{ titleName }}</div>
    <v-edit-dialog :return-value="item[property]" large lazy v-else @save="saveInline">
      <div>{{ titleName }}</div>
      <core-error v-if="item.errors" :property="property" :errors="item.errors[property]" />
      <template v-slot:input>
        <v-select
          v-bind:value="valueAttribute"
          v-on:change="newObject = $event"
          :items="itemsValue"
          menu-props="auto"
          hide-details
          :item-text="item => `${item.name} (${item.season})`"
          item-value="season"
          :label="labelName"
          single-line
        ></v-select>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DialogBaseMixins from '../../mixins/dialog-base';
import ProfileMixins from '../../mixins/profile-base';

export default {
  props: {
    store: {
      type: String,
      default: 'variant',
    },
  },
  mixins: [DialogBaseMixins, ProfileMixins],
  created() {
    this.apiCollection();
  },
  computed: {
    ...mapGetters('collection', ['collections']),
    itemsValue() {
      return this.collections;
    },
    itemsKey: () => undefined,
  },
  methods: {
    ...mapActions('collection', { apiCollection: 'list' }),
  },
};
</script>

<style></style>
