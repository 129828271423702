<template>
  <v-container fluid>
    <v-combobox
      v-model="model"
      :filter="filter"
      :hide-no-data="!search"
      :items="items"
      :search-input.sync="search"
      hide-selected
      label="Search for a size"
      multiple
      small-chips
      solo
    >
      <template v-slot:no-data>
        <v-list-item>
          <span class="subheading">Create</span>
          <template v-if="search && search.indexOf(':') !== -1">
            <v-chip :color="`${colors[nonce - 1]} lighten-3`" label small>
              <v-avatar
                class="accent white--text"
                left
                v-text="format(search, 0).toUpperCase()"
              ></v-avatar>
              <span class="pr-2">
                ean={{ format(search, 1) }} (length:{{ format(search, 1).length }})
              </span>
            </v-chip>
          </template>
          <template v-else>
            <v-chip :color="`${colors[nonce - 1]} lighten-3`" label small>
              {{ search }}
            </v-chip>
          </template>
        </v-list-item>
      </template>
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip
          v-if="item === Object(item)"
          v-bind="attrs"
          :color="`${item.color} lighten-3`"
          :input-value="selected"
          label
          small
        >
          <template v-if="item.text.indexOf(':') !== -1">
            <v-avatar
              class="accent white--text"
              left
              v-text="format(item.text, 0).toUpperCase()"
            ></v-avatar>
            <span class="pr-2"> ean={{ format(item.text, 1) }} </span>
          </template>
          <template v-else>
            <span class="pr-2">
              {{ item.text }}
            </span>
          </template>
          <v-icon small @click="parent.selectItem(item)">mdi-close</v-icon>
        </v-chip>
      </template>
      <template v-slot:item="{ index, item }">
        <v-text-field
          v-if="editing === item"
          v-model="editing.text"
          autofocus
          flat
          background-color="transparent"
          hide-details
          solo
          @keyup.enter="edit(index, item)"
        ></v-text-field>
        <v-chip v-else :color="`${item.color} lighten-3`" dark label small>
          {{ item.text }}
        </v-chip>
        <v-spacer></v-spacer>
        <v-list-item-action @click.stop>
          <v-btn icon color="black" @click.stop.prevent="edit(index, item)">
            <v-icon>{{ editing !== item ? "mdi-pencil" : "mdi-check" }}</v-icon>
          </v-btn>
        </v-list-item-action>
      </template>
    </v-combobox>
  </v-container>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    activator: null,
    attach: null,
    colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
    editing: null,
    index: -1,
    items: [
      { header: 'Select your size or create new one' },
      { text: '34', color: 'red' },
      { text: '36', color: 'red' },
      { text: '38', color: 'red' },
      { text: '40', color: 'red' },
      { text: '42', color: 'red' },
      { text: '44', color: 'red' },
      { text: '46', color: 'red' },
      { text: 'S', color: 'green' },
      { text: 'M', color: 'green' },
      { text: 'L', color: 'green' },
      { text: 'XL', color: 'green' },
    ],
    nonce: 1,
    menu: false,
    model: [],
    x: 0,
    search: '',
    y: 0,
  }),
  mounted() {
    this.model = this.value;
  },
  watch: {
    model(val, prev) {
      if (val.length === prev.length) return;

      this.model = val.map((v) => {
        if (typeof v === 'string') {
          // eslint-disable-next-line no-param-reassign
          v = {
            // eslint-disable-line no-param-reassign
            text: v,
            color: this.colors[this.nonce - 1],
          };
          this.items.push(v);
          this.nonce += 1;
        }

        return v;
      });
      this.$emit('input', this.model);
    },
  },

  methods: {
    format(text, index) {
      if (text.indexOf(':') !== -1) {
        return text.split(':')[index];
      }
      return text;
    },
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.index = index;
      } else {
        this.editing = null;
        this.index = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = val => (val != null ? val : '');

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
  },
};
</script>
