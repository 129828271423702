// https://vuex.vuejs.org/en/mutations.html
import stateDefault from './state';

const getClean = (state) => {
  state.isLoaded = stateDefault.isLoaded;
  state.distributors = stateDefault.distributors;
  state.distributor = stateDefault.distributor;
  state.state = stateDefault.state;
};

export default {
  set_distributors(state, payload) {
    state.distributors = payload;
  },
  set_distributor(state, payload) {
    if (!Array.isArray(state.distributors)) {
      getClean(state);
    }
    const index = state.distributors.findIndex(a => a.id === payload.id);
    if (typeof state.distributors[index] !== 'undefined') {
      state.distributors[index] = payload;
    } else {
      state.distributors.push(payload);
    }
    state.distributor = payload;
  },
  set_state(state, payload) {
    state.state = payload;
  },
  clear(state) {
    getClean(state);
  },
};
