// https://vuex.vuejs.org/en/mutations.html
import stateDefault from './state';

const getClean = (state) => {
  state.attribute_av = stateDefault.attribute_av;
  state.state = stateDefault.state;
};

export default {
  set_attribute_av(state, payload) {
    state.attribute_av = payload;
  },
  set_state(state, payload) {
    state.state = payload;
  },
  clear(state) {
    getClean(state);
  },
};
