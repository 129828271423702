<template>
  <v-autocomplete
    v-model="item[property]"
    :items="itemsValue"
    menu-props="auto"
    item-text="name"
    item-value="id"
    :label="label || 'Pick your family'"
    hint="Pick your family"
    :messages="getDefaultValues()"
    :loading="isCompletudeRequired('classificationId', currentIssues)"
    :readonly="!isSuperAdmin"
    :append-icon="!isSuperAdmin && item.sealed ? 'mdi-cancel' : undefined"
    persistent-hint
  ></v-autocomplete>
</template>
<script>
import ProfileBaseMixins from '../../mixins/profile-base';
import AttributeBaseMixins from '../../mixins/attribute-base';
import CompletudeBaseMixins from '../../mixins/completude-base';

export default {
  mixins: [ProfileBaseMixins, AttributeBaseMixins, CompletudeBaseMixins],
  name: 'attributeFamily',
  data: () => ({
    type: 'family',
  }),
  computed: {
    itemsValue() {
      return this.$store.getters['family/families'];
    },
  },
  methods: {},
};
</script>
