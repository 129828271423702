// https://vuex.vuejs.org/en/actions.html

import axios from 'axios';
import { workflow } from 'theagent-status';

const {
  IN_PROGRESS, DONE, ERROR, RE_TRY,
} = workflow;

// milli seconds
const DELAY = 5000;

const BASE_URI = '/api/v1';
const namespace = 'store.modules.api.attribute.actions';

export default {
  list({
    state, commit, getters, dispatch,
  }) {
    if (state.state === ERROR) {
      return Promise.reject(new Error(`${namespace}.something.occured.on.server`));
    }
    if (state.state === RE_TRY) {
      return Promise.resolve([]);
    }
    if (state.state === DONE) {
      return Promise.resolve(getters);
    }
    if (state.state === IN_PROGRESS) {
      commit('set_state', RE_TRY);
      return new Promise(resolve => setTimeout(() => resolve(dispatch('list')), DELAY));
    }
    commit('set_state', IN_PROGRESS);
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URI}/pim/attributes`)
        .then((response) => {
          if (typeof response === 'undefined') {
            commit('set_state', ERROR);
            throw new TypeError(`${namespace}.response.failed`);
          }
          commit('set_state', DONE);
          commit('set_attributes', response.data.result);
          commit('set_entity_types', response.data.entity_types);
          commit('set_fields', response.data.fields);
          commit('set_profiles', response.data.profiles);
          return resolve(response.data);
        })
        .catch((error) => {
          commit('set_state', ERROR);
          return reject(error);
        });
    });
  },
};
