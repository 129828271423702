<template>
  <v-dialog v-model="dialog" persistent max-width="960px">
    <!-- https://vuetifyjs.com/en/components/menus/#activator-and-tooltip -->
    <template v-slot:activator="{ on: openDialog }">
      <v-tooltip left>
        <template v-slot:activator="{ on: tooltip, attrs }">
          <v-btn
            color="red"
            fab
            dark
            absolute
            right
            fixed
            class="mx-2"
            style="bottom: 220px !important;"
            v-bind="attrs"
            v-on="{ ...tooltip, ...openDialog }"
          >
            <v-icon dark>mdi-table-edit</v-icon>
          </v-btn>
        </template>
        <span>Mass edition by row</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-form v-model="isValid">
        <v-select
          v-model="columnSelected"
          :items="filterByFields()"
          menu-props="auto"
          hide-details
          item-text="text"
          item-value="value"
          label="Select your field"
        ></v-select>
        <v-container>
          <v-row
            align="center"
            justify="center"
            class="fill-height"
            v-for="item in items"
            :key="item.vueKey"
          >
            <v-col cols="2">
              <v-row align="center" justify="center" class="fill-height">
                <core-avatar
                  v-if="firstImage(item['Images par défaut'])"
                  :image="firstImage(item['Images par défaut'])"
                  customSize="140"
                />
              </v-row>
            </v-col>
            <v-col cols="10">
              <v-simple-table dark>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">{{ fields.FIELD_PRODUCTNAME }}</th>
                      <th class="text-left">{{ fields.FIELD_SUPPLIERREFCOLOR }}</th>
                      <th class="text-left">{{ fields.FIELD_COLOR }}</th>
                      <th class="text-left">{{ fields.FIELD_EAN }}</th>
                      <th class="text-left">{{ fields.FIELD_SIZE }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ item[fields.FIELD_PRODUCTNAME] }}</td>
                      <td>{{ item[fields.FIELD_SUPPLIERREFCOLOR] }}</td>
                      <td>{{ item[fields.FIELD_COLOR] }}</td>
                      <td>{{ item[fields.FIELD_EAN] }}</td>
                      <td>{{ item[fields.FIELD_SIZE] }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-row>
                <v-col cols="12">
                  <core-attribute
                    v-if="columnSelected"
                    :csv="true"
                    :property="columnSelected"
                    :item="item"
                    item-value="name"
                    filled
                  ></core-attribute>
                </v-col>
              </v-row>
            </v-col>
            <v-divider></v-divider>
          </v-row>
        </v-container>
        <v-spacer />
        <v-card-actions>
          <v-btn color="grey darken-1" text outlined @click="close">Cancel</v-btn>
          <v-btn color="blue darken-1" dark :disabled="!isValid" @click="save">Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import ChipBaseMixins from '../../mixins/chip-base';
import ConfigBaseMixins from '../../mixins/config-base';

export default {
  mixins: [ChipBaseMixins, ConfigBaseMixins],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    dialog: false,
    columnSelected: false,
    isValid: true,
  }),
  computed: {
    ...mapGetters('form/rules', { textRules: 'text' }),
    headers() {
      return this.$store.getters['settings/headers'].csv;
    },
    fields() {
      return this.$store.getters['api/attribute/fields'];
    },
  },
  methods: {
    filterByFields() {
      const excluded = ['THEAGENT', 'Errors', 'Actions'];
      return this.headers.filter(headerField => excluded.indexOf(headerField.text) === -1);
    },
    save() {
      this.dialog = false;
      return this.$emit('onSave');
    },
    close() {
      this.dialog = false;
      return this.$emit('onClose');
    },
    firstImage(images) {
      if (!images || images.length === 0) {
        return null;
      }
      return images.split(';')[0] || null;
    },
  },
};
</script>

<style></style>
