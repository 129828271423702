<template>
  <router-link v-if="value"
    :to="getUrlByName('settings.attribute.remoteCode', value)"
    :target="target"
    >
    <template v-if="label">
      {{ label }}
    </template>
    <slot v-else></slot>
  </router-link>
</template>

<script>
import LinkMixins from '@/mixins/link-base';

export default {
  name: 'LinkAttribute',
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: undefined,
    },
  },
  mixins: [LinkMixins],
};
</script>
