/* eslint array-callback-return: 0 */
/* eslint no-param-reassign: 0 */

export default {};

/**
 * This function allow to identify the main image in the api
 *
 * @param  {Array} products [description]
 * @return {Array}          [description]
 */
export const init = (products) => {
  if (products.length > 0) {
    // map for products
    for (let i = 0; i < products.length; i += 1) {
      // for each product, we need to initialize image property
      products[i].image = false;
      // map for variants
      for (let j = 0; j < products[i].variant.length; j += 1) {
        // if medias didn't exist we create
        if (typeof products[i].variant[j].medias === 'undefined') {
          products[i].variant[j].medias = [];
        }
        // for each product, we need to initialize image property
        products[i].variant[j].image = false;
        // map for medias from variant
        for (let k = 0; k < products[i].variant[j].medias.length; k += 1) {
          if (
            typeof products[i].variant[j].medias[k] === 'object'
            && products[i].variant[j].medias[k]
          ) {
            products[i].variant[j].image = products[i].variant[j].medias[k];
            if (products[i].image === false) {
              products[i].image = products[i].variant[j].image;
            }
            break;
          }
        }
        products[i].variant[j].attributes_temp = {};
        for (let l = 0; l < products[i].variant[j].attributes.length; l += 1) {
          const attribute = products[i].variant[j].attributes[l];
          Object.keys(attribute.dataJson).map((property) => {
            if (typeof products[i].variant[j].attributes_temp[property] === 'undefined') {
              products[i].variant[j].attributes_temp[property] = attribute.dataJson[property];
            }
          });
        }
      }
    }
  }
  return products;
};

export const errorHandler = (reject = null) => (error) => {
  if (reject === null) {
    // eslint-disable-next-line func-names
    reject = function (message) {
      if (message instanceof Error) {
        throw message;
      }
      throw new Error(message);
    };
  }
  if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined') {
    return reject(error.response.data);
  }
  return reject(error);
};
