<template>
  <div>
    <div v-if="!hasRightActions || noDialog">{{ titleName }}</div>
    <v-edit-dialog :return-value="item[property]" large lazy v-else @save="saveInline">
      <div>{{ titleName }}</div>
      <core-error v-if="item.errors" :property="property" :errors="item.errors[property]" />
      <template v-slot:input>
        <v-autocomplete
          v-bind:value="valueAttribute"
          v-on:change="newObject = $event"
          :items="itemsValue"
          menu-props="auto"
          hide-details
          item-text="name"
          item-value="key"
          :label="labelName"
          :value-comparator="compareValue"
          hint="Choose your country"
        >
        </v-autocomplete>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
import DialogBaseMixins from '@/mixins/dialog-base';
import ProfileMixins from '@/mixins/profile-base';
import CountrySubMixins from '@/mixins/config/country-sub-base';

export default {
  mixins: [DialogBaseMixins, ProfileMixins, CountrySubMixins],
  computed: {
    itemsValue() {
      return this.states;
    },
    countries() {
      return this.$store.getters['config/countriesByKey'];
    },
    titleName() {
      try {
        const country = this.countries[this.valueAttribute.slugify()];
        if (country) {
          if (country.translations[this.currentLang]) {
            return country.translations[this.currentLang];
          }
          return country.translations.en;
        }
      } catch (e) {} // eslint-disable-line no-empty
      return '';
    },
  },
  methods: {
    compareValue(selected, current) {
      if (![selected, current].every(v => typeof v === 'string')) {
        return false;
      }
      return selected.slugify() === current.slugify();
    },
  },
};
</script>

<style></style>
