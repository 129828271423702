// https://vuex.vuejs.org/en/mutations.html

export default {
  set_collections(state, collections) {
    state.collections = collections;
  },
  set_collection(state, collectionData) {
    state.collection = collectionData;
  },
  set_products(state, products) {
    state.products = products;
  },

  set_state(state, status) {
    state.status = status;
  },
};
