// https://vuex.vuejs.org/en/actions.html
import Vue from 'vue';
import axios from 'axios';
import { workflow } from 'theagent-status';

const { NO_STATUS } = workflow;

const BASE_URI = '/api/v1/pim';

export default {
  // eslint-disable-next-line no-unused-vars
  new({ commit }, payload) {
    Vue.$log.info(`store.api.crud.actions.new.${payload.endpoint}.started`);
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URI}/${payload.endpoint}`, payload.data)
        .then((response) => {
          commit('api/set_state', { key: payload.endpoint, value: NO_STATUS }, { root: true });
          return resolve(response.data);
        })
        .catch(error => reject(error));
    });
  },
  // eslint-disable-next-line no-unused-vars
  update({ commit }, payload) {
    Vue.$log.info(`store.api.crud.actions.update.${payload.endpoint}.${payload.data.id}.started`);
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI}/${payload.endpoint}/${payload.data.id}`, payload.data)
        .then((response) => {
          commit('api/set_state', { key: payload.endpoint, value: NO_STATUS }, { root: true });
          return resolve(response.data);
        })
        .catch(error => reject(error));
    });
  },
  // eslint-disable-next-line no-unused-vars
  delete({ commit }, payload) {
    Vue.$log.info(`store.api.crud.actions.delete.${payload.endpoint}.${payload.data.id}.started`);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${BASE_URI}/${payload.endpoint}/${payload.data.id}`)
        .then((response) => {
          commit('api/set_state', { key: payload.endpoint, value: NO_STATUS }, { root: true });
          return resolve(response);
        })
        .catch(error => reject(error));
    });
  },
};
