<template>
  <span>
    <v-btn rounded small class="ta-product" text-color="white" @click="view = 0">
      r <v-icon right>mdi-filter-variant</v-icon>
    </v-btn>
    <v-btn rounded small class="ta-variant" text-color="white" @click="view = 1">
      rc <v-icon right>mdi-filter-variant</v-icon>
    </v-btn>
    <v-btn rounded small class="ta-sku" text-color="white" @click="view = 2">
      rct <v-icon right>mdi-filter-variant</v-icon>
    </v-btn>
  </span>
</template>

<script>
export default {
  name: 'switchview',
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.view = this.value;
  },
  data: () => ({
    view: 0, // [0, 1, 2]
  }),
  watch: {
    view() {
      this.$emit('input', this.view);
    },
  },
};
</script>
