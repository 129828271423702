// https://vuex.vuejs.org/en/state.html
/* eslint no-param-reassign:0 */
export default {
  editedItem: state => state.currentImportEdit,
  status: state => state.status,
  statusForSelect: state => Object.keys(state.status).map(status => ({
    value: state.status[status],
    key: status,
  })),
  statusKey: state => Object.keys(state.status).reduce((result, status) => {
    result[state.status[status]] = status;
    return result;
  }, {}),
  imports: state => state.imports,
  import: state => state.import,
  importMeta: state => state.importMeta,
  products: state => state.products,
  variantsKey: state => state.variantsKey,
  rows: state => state.rows,
  files: state => state.files,
  mediasImported: state => state.mediasImported,
};
