<template>
  <keep-alive>
    <component
      v-if="component"
      v-bind="$attr"
      :is="component"
      :item="value"
      :property="itemOptions.property"
      :keyAttr="itemOptions.keyAttr"
      :labelName="labelName"
      :store="store"
      :saveOnApi="saveOnApi"
      :disable-button="itemOptions.disableButton"
      :update-children="itemOptions.updateChildren"
    ></component>
  </keep-alive>
</template>

<script>
import ErrorMixins from '@/mixins/error-base';

export default {
  name: 'CoreDialog',
  props: {
    value: {
      type: Object,
      required: true,
      default: null,
    },
    header: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    store: {
      type: String,
      default: 'product',
    },
    saveOnApi: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [ErrorMixins],
  data: () => ({
    displayMp: false,
  }),
  computed: {
    component() {
      try {
        if (this.header.template) {
          const template = this.getTemplate(this.header.template);
          // eslint-disable-next-line vue/no-async-in-computed-properties
          return () => import(`../dialog/${template}`).catch(() => import('../dialog/default'));
        }
      } catch (e) {} // eslint-disable-line no-empty
      return () => import('../dialog/default');
    },
    itemOptions() {
      if (!this.header || Object.keys(this.header).length === 0) return {};
      const [property = null, keyAttr = null] = this.header.value.split('.');
      const template = this.getTemplate(this.header.template);
      let disableButton;
      let updateChildren;
      if (template === 'composition') {
        disableButton = true;
      }
      if (property === 'color') {
        updateChildren = true;
      }
      return {
        property,
        keyAttr,
        template,
        disableButton,
        updateChildren,
      };
    },

    labelName() {
      return `Choose your field ${this.header.text}`;
    },
  },
  methods: {
    getTemplate(template) {
      if (template === 'select_lang') {
        template = 'select';
      }
      return template;
    },
  },
};
</script>
