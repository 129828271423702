<template>
  <material-card color="blue" :title="title" class="col-lg-12">
    <template v-slot:dialog>
      <dialog-attribute-base
        :attribute="editedItem"
        :dialog="dialog"
        v-on:update:dialog="dialog = $event"
        @onSave="saveItem(true)"
        @onClose="closeItem"
      >
      </dialog-attribute-base>
    </template>
    <v-text-field
      v-model="search"
      label="Search"
      single-line
      dark
      flat
      solo-inverted
      clearable
      clear-icon="mdi-close-circle-outline"
      hide-details
    >
    </v-text-field>
    <v-spacer></v-spacer>
    <v-row no-border>
      <v-col cols="4">
        <v-treeview
          :items="treeItems"
          :search="search"
          :active.sync="activeItem"
          :open.sync="open"
          activatable
          color="warning"
          expand-icon="mdi-chevron-down"
          transition
        >
          <template v-slot:prepend="{ item, activeItem }">
            <v-icon v-text="`mdi-roman-numeral-${item.level}`"></v-icon>
          </template>
        </v-treeview>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="7">
        <v-scroll-y-transition mode="out-in">
          <div
            v-if="!selected"
            class="title grey--text text--lighten-1 font-weight-light"
            style="align-self: center;"
          >
            Select your item
          </div>
          <v-card v-else :key="selected.id">
            <v-card-text>
              <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-text-field
                  readonly
                  v-model="selected.id"
                  label="Identify"
                  disabled
                ></v-text-field>
                <v-text-field
                  v-model="selected.name"
                  :counter="25"
                  label="Name"
                  required
                ></v-text-field>
                <v-text-field
                  readonly
                  v-if="selected.path"
                  v-model="selected.path"
                  label="Path"
                  disabled
                ></v-text-field>
                <v-text-field
                  readonly
                  v-if="selected.remoteCode"
                  v-model="selected.remoteCode"
                  label="Code"
                  disabled
                ></v-text-field>
                <v-select
                  v-if="typeof selected.parentId !== 'undefined'"
                  v-model="selected.parentId"
                  :items="items"
                  :menu-props="{ maxHeight: '400' }"
                  label="Select"
                  hint="Pick your parent classification"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                ></v-select>
                <v-row class="text-center ta-expand-icon">
                  <v-col cols="10">
                    <attribute-family
                      v-if="typeof selected.familyId !== 'undefined'"
                      ref="family"
                      :item="selected"
                      property="familyId"
                      label="Pick your family (optional)"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      v-if="selected.familyId"
                      color="blue"
                      text
                      x-small
                      @click="goToAttributeFamily(selected.familyId)"
                      >family</v-btn
                    >
                  </v-col>
                </v-row>
                <v-btn :disabled="!valid" color="success" class="mr-4" @click="saveItem(false)">
                  Validate
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
  </material-card>
</template>

<script>
import Vue from 'vue';
import EditMixins from '../../mixins/edit-base';
import Mixins from '../../mixins';

export default {
  name: 'AttributeTree',
  mixins: [EditMixins, ...Mixins],
  props: {
    mainAttribute: {
      type: String,
      default: 'default',
    },
    items: {
      type: Array,
      default: () => [{ children: [] }],
    },
  },
  data: () => ({
    search: '',
    valid: true,
    lazy: false,
    activeItem: [],
    open: [],
    headers: [
      {
        sortable: false,
        text: 'Id',
        value: 'id',
      },
      {
        sortable: true,
        text: 'Name',
        value: 'name',
      },
      {
        sortable: true,
        text: 'Remote Code',
        value: 'remoteCode',
      },
      {
        sortable: false,
        text: 'Actions',
        value: 'actions',
      },
    ],
    defaultItem: {},
  }),
  // @see cycle of life vuejs
  updated() {
    this.defaultAttributeTree();
  },
  computed: {
    title() {
      return `Classification Tree: ${this.mainAttribute.replace('Tree', '')}`;
    },
    selected() {
      if (!this.activeItem.length) return undefined;
      const id = this.activeItem[0];
      this.setQueryUrl(id);
      return this.items.find(item => item.id === id);
    },
    treeItems() {
      const treeItems = this.buildHierarchicalTree(this.items);
      if (treeItems.length === 0) {
        return [];
      }
      return treeItems[0].children || [];
    },
  },
  methods: {
    /**
     * Build a hiereachical tree wih an aarray of classification object
     * @param  {Array} classifications  array of classofication instance
     * @return {Object}                 hierachical tere
     *
     * @see https://stackoverflow.com/questions/12831746/javascript-building-a-hierarchical-tree
     */
    buildHierarchicalTree(classifications) {
      const roots = [];
      const children = {};

      // find the top level nodes and hash the children based on parent
      for (let i = 0; i < classifications.length; i += 1) {
        const item = classifications[i];
        const p = item.parentId;
        const target = !p ? roots : children[p] || (children[p] = []);
        // this part is a trick to identify level based on '/' separator
        if (typeof item.path !== 'undefined') {
          item.level = item.id === 1 ? 0 : (item.path.match(/\//g) || []).length;
        }
        target.push(item);
      }

      // function to recursively build the tree
      const findChildren = function findChildren(parent) {
        if (children[parent.id]) {
          // eslint-disable-next-line no-param-reassign
          parent.children = children[parent.id];
          for (let i = 0, len = parent.children.length; i < len; i += 1) {
            findChildren(parent.children[i]);
          }
        }
      };

      // enumerate through to handle the case where there are multiple roots
      for (let i = 0; i < roots.length; i += 1) {
        findChildren(roots[i]);
      }

      return roots;
    },
    defaultAttributeTree() {
      if (typeof this.items !== 'undefined' && this.items.length > 0) {
        this.defaultItem = Object.keys(this.items[0]).reduce((result, key) => {
          // eslint-disable-next-line no-param-reassign
          result[key] = '';
          return result;
        }, {});
        this.editedItem = Object.assign({}, this.defaultItem);
      }
    },
    /**
     * delete item
     *
     * @mixim see src/mixins/edit-base.js
     * @param  {[type]} item [description]
     * @return {[type]}      [description]
     */
    deleteItem(item) {
      Vue.$log.info(`component.pim.attributeview.deleteitem.${this.editedItem.id}.started`);
      const index = this.items.indexOf(item);
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure you want to delete this item?')) {
        this.$store
          .dispatch('api/crud/delete', {
            endpoint: this.mainAttribute,
            data: {
              id: item.id,
            },
          })
          .then(() => this.items.splice(index, 1))
          .catch((error) => {
            Vue.$log.error(`component.pim.attributeview.deleteitem.${item.id}`);
            Vue.$log.error(error);
          });
      }
    },
    saveItem(isNew = false) {
      Vue.$log.info(`component.pim.attributeview.saveitem.${this.editedItem.id}.started`);
      let endpoint = 'api/crud/new';
      if (!isNew) {
        endpoint = 'api/crud/update';
        this.editedItem = this.selected;
        this.editedIndex = this.items.findIndex(item => item.id === this.editedItem.id);
      }
      this.$store
        .dispatch(endpoint, {
          // for classificationTree, need to be classification
          endpoint: this.mainAttribute.replace('Tree', ''),
          data: this.editedItem,
        })
        .then((result) => {
          if (this.editedIndex === -1) {
            this.items.push(result);
          } else {
            Vue.set(this.items, this.editedIndex, result);
          }
        })
        .catch((error) => {
          Vue.$log.error(`component.pim.attributeview.saveitem.${this.editedItem.id}`);
          Vue.$log.error(error);
        })
        .finally(() => {
          this.$store.dispatch('api/reset', this.mainAttribute);
          this.$emit('property:reload');
          this.closeItem();
        });
    },
    setQueryUrl(itemId = null) {
      const query = {};
      if (this.mainAttribute) {
        query.attribute = this.mainAttribute;
      }
      if (itemId) {
        query.itemId = itemId;
      }
      if (Object.keys(query).length > 0) {
        this.$router
          .push({
            name: 'settings.attribute',
            query,
          })
          .catch(() => {}); // NavigawstionDuplicated error quiet
      }
    },
    goToAttributeFamily(familyId) {
      const routeData = this.$router.resolve({
        name: 'settings.attribute',
        query: {
          attribute: 'family',
          itemId: familyId,
        },
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
