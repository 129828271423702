import LangSubMixins from './lang-sub-base';

export default {
  mixins: [LangSubMixins],
  computed: {
    states() {
      const countries = this.$store.getters['config/countries'];
      if (!countries) {
        return [];
      }
      return countries.map((item) => {
        if (
          typeof item.translations !== 'undefined'
          && typeof item.translations[this.currentLang] !== 'undefined'
        ) {
          return {
            name: item.translations[this.currentLang],
            key: item.key,
          };
        }
        return { key: item.key, name: item.key };
      });
    },
  },
};
