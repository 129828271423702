// https://vuex.vuejs.org/en/actions.html
import Vue from 'vue';
import axios from 'axios';
import { workflow } from 'theagent-status';
import { init } from '../global_helpers';

const {
  IN_PROGRESS, DONE, ERROR, RE_TRY, NO_STATUS,
} = workflow;

// milli seconds
const DELAY = 5000;

const BASE_URI = '/api/v1/pim';

export default {
  list({
    commit, state, getters, dispatch,
  }) {
    Vue.$log.info('store.collection.actions.list.started');
    if (state.status === RE_TRY) {
      return Promise.resolve([]);
    }
    if (state.status === DONE) {
      return Promise.resolve(getters.collections);
    }
    if (state.status === IN_PROGRESS) {
      commit('set_state', RE_TRY);
      return new Promise(resolve => setTimeout(() => resolve(dispatch('list')), DELAY));
    }
    commit('set_state', IN_PROGRESS);
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URI}/collection`)
        .then((response) => {
          if (typeof response === 'undefined') {
            commit('set_state', ERROR);
            throw new TypeError('/api/v1/pim/collection empty');
          }
          commit('set_state', DONE);
          commit('set_collections', response.data.result);
          return resolve(response.data);
        })
        .catch((error) => {
          commit('set_state', ERROR);
          return reject(error);
        });
    });
  },
  products({ commit }, payload) {
    Vue.$log.info('store.collection.actions.started');
    return new Promise((resolve, reject) => {
      const endpoint = `${BASE_URI}/collection/${payload.id}/products`;
      axios
        .get(endpoint, payload)
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${endpoint} empty`);
          }
          // eslint-disable-next-line no-param-reassign
          response.data.result.products = init(response.data.result.products);
          commit('set_products', response.data.result.products);
          Vue.$log.debug(`store.collection.actions.length.${response.data.result.products.length}`);
          return resolve(response.data);
        })
        .catch(error => reject(error));
    });
  },
  // eslint-disable-next-line no-unused-vars
  new({ commit }, collectionObj) {
    Vue.$log.info('store.collection.actions.started');
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URI}/collection`, collectionObj)
        .then((response) => {
          Vue.$log.log('this collection is created');
          return resolve(response.data);
        })
        .catch(error => reject(error));
    });
  },
  // eslint-disable-next-line no-unused-vars
  update({ commit }, collectionObj) {
    Vue.$log.info('store.collection.actions.started');
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI}/collection/${collectionObj.id}`, collectionObj)
        .then((response) => {
          Vue.$log.log(`this collection ${collectionObj.id} has been uptaded`);
          return resolve(response.data);
        })
        .catch(error => reject(error));
    });
  },
  // eslint-disable-next-line no-unused-vars
  get({ commit }, id) {
    Vue.$log.info('store.collection.actions.started');
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URI}/collection/${id}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  // eslint-disable-next-line no-unused-vars
  delete({ commit }, id) {
    Vue.$log.info('store.collection.actions.started');
    return new Promise((resolve, reject) => {
      axios
        .delete(`${BASE_URI}/collection/${id}`)
        .then((response) => {
          Vue.$log.log(`this collection ${id} has been deleted`);
          return resolve(response);
        })
        .catch(error => reject(error));
    });
  },
  reset({ commit }) {
    Vue.$log.info('store.collection.actions.started');
    return new Promise((resolve) => {
      commit('set_state', NO_STATUS);
      commit('set_collections', []);
      commit('set_products', []);
      return resolve({});
    });
  },
};
