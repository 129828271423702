<template>
</template>

<script>
export default {

  name: 'SettingsView',

  data() {
    return {

    };
  },
};
</script>

<style lang="css" scoped>
</style>
