<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="drawer"
    :dark="color !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :right="$vuetify.rtl"
    :src="image"
    mobile-breakpoint="960"
    absolute
    bottom
    temporary
    width="260"
    v-bind="$attrs"
  >
    <v-list class="layout fill-height column" nav dense>
      <v-container>
        <v-img :src="logo"></v-img>
        <ul>
          <li style="font-size:8px; color: white;">App version : {{ appVersion }}</li>
          <li style="font-size:8px; color: white;">Api version : {{ restApi.version }}</li>
          <li style="font-size:8px; color: white;">Api commit : {{ restApi.versionCommit }}</li>
        </ul>
      </v-container>

      <v-divider></v-divider>
      <div v-for="(item, i) in links" :key="i">
        <v-list-item v-if="!item.subLinks" :key="i" :to="item.to">
          <v-list-item-action v-if="!pageIsHidden(item)">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>

          <v-list-item-title v-text="item.title" />
        </v-list-item>
        <v-list-group
          v-else-if="!pageIsHidden(item)"
          :key="i"
          :prepend-icon="item.icon"
          no-action
          :value="isCurrentMenu(item)"
          color="white"
          active-class="highlighted"
          :class="item.to === $route.path ? 'highlighted' : ''"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="subItem in item.subLinks" :to="subItem.to" :key="subItem.title">
            <v-list-item-title v-text="subItem.title" v-if="!pageIsHidden(subItem)">
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from 'vuex';

export default {
  data: () => ({
    links: [
      {
        title: 'Dashboard',
        to: '/',
        icon: 'mdi-home-outline',
      },
      {
        title: 'Pim',
        icon: 'mdi-clipboard-outline',
        subLinks: [
          {
            title: 'Dashboard',
            to: '/pim',
            icon: 'mdi-home-outline',
          },
          {
            title: 'Import Catalog',
            to: '/pim/import',
            icon: 'mdi-application-import',
          },
          {
            title: 'Import data',
            to: '/pim/import-data',
            icon: 'mdi-application-export',
          },
          {
            title: 'Export data',
            to: '/pim/export',
            icon: 'mdi-application-export',
          },
          {
            title: 'List products',
            to: '/pim/product',
            icon: 'mdi-clipboard-outline',
          },
        ],
      },
      {
        title: 'Export',
        // icon: 'mdi-camera-burst',
        subLinks: [
          {
            title: 'Settings',
            to: '/export/settings',
            icon: 'mdi-cogs',
          },
          {
            title: 'Marketplaces',
            to: '/export/marketplace',
            icon: 'mdi-camera',
          },
        ],
      },
      {
        title: 'Dam',
        icon: 'mdi-camera-burst',
        subLinks: [
          {
            title: 'Dashboard',
            to: '/dam',
            icon: 'mdi-camera-burst',
          },
          {
            title: 'List medias',
            to: '/dam/medias',
            icon: 'mdi-camera',
          },
          {
            title: 'Import medias',
            to: '/dam/import',
            icon: 'mdi-camera',
          },
          {
            title: 'Export medias',
            to: '/dam/export',
            icon: 'mdi-application-export',
          },
          {
            title: 'Download medias',
            to: '/dam/download',
            icon: 'mdi-download',
          },
        ],
      },
      {
        title: 'Settings',
        icon: 'mdi-cogs',
        subLinks: [
          {
            title: 'Properties',
            to: '/settings/properties',
          },
          {
            title: 'Attribute',
            to: '/settings/attribute',
          },
          {
            title: 'Classication',
            to: '/settings/classification',
          },
          {
            title: 'Family',
            to: '/settings/family',
          },
          {
            title: 'Translations',
            to: '/settings/translations',
          },
        ],
      },
      {
        title: 'Pages spéciales',
        icon: 'mdi-factory',
        hidden: true,
        subLinks: [
          {
            title: 'Dashboard',
            to: '/marketplace',
            icon: 'mdi-folder-home',
          },
          {
            title: 'Catalog',
            to: '/marketplace/catalog',
            icon: 'mdi-library-shelves',
          },
          {
            title: 'Checking size',
            to: '/pim/size/checking',
            icon: 'mdi-format-size',
          },
        ],
      },
    ],
    responsive: false,
  }),
  computed: {
    ...mapState('app', ['image', 'logo', 'color']),
    drawer: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(value) {
        this.$store.commit('app/set_drawer', value, { root: true });
      },
    },
    optionsTa() {
      return this.$store.getters['settings/theagent'];
    },
    appVersion() {
      return this.$store.getters['app/appVersion'];
    },
    restApi() {
      return this.$store.getters['app/restApi'];
    },
  },
  mounted() {
    this.onResponsiveInverted();
    window.addEventListener('resize', this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted);
  },
  methods: {
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },
    /**
     * this function check is the page is hidden or not
     * @param  {Object} link  Object with all properties
     * @return {Boolean}      true|false
     */
    pageIsHidden(link) {
      if (typeof link.hidden === 'undefined') {
        return false;
      }
      if (this.optionsTa.showHiddenPages) {
        return false;
      }
      return link.hidden;
    },
    isCurrentMenu(item) {
      const to = `/${item.title.toLowerCase()}`;
      return this.$route.path.indexOf(to) === 0;
    },
  },
};
</script>

<style lang="scss">
#app-drawer {
  .v-list__tile {
    border-radius: 4px;

    &--buy {
      margin-top: auto;
      margin-bottom: 17px;
    }
  }
  .v-navigation-drawer__image {
    .v-image {
      opacity: 0.2;
    }
  }
}
</style>
