<template>
  <v-text-field v-model="item[property][keyAttr]"
    disabled
    :label="label || keyAttr"
    :loading="isCompletudeRequired(bindAttribute, currentIssues)">
  </v-text-field>
</template>
<script>
import AttributeBaseMixins from '../../mixins/attribute-base';
import CompletudeBaseMixins from '../../mixins/completude-base';

export default {
  mixins: [AttributeBaseMixins, CompletudeBaseMixins],
  name: 'attributesDefault',
};
</script>
