<template>
  <v-row align="center">
    <v-col cols="12">
      <v-autocomplete
        v-if="items.length > 0"
        v-model="value"
        :items="items"
        :menu-props="{ top: true, offsetY: true }"
        chips
        deletable-chips
        small-chips
        dense
        rounded
        :readonly="readonly"
      >
        <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="remove(data.item)"
            >
              {{ data.item }}
            </v-chip>
          </template>
      </v-autocomplete>
      <v-input
        v-else
        :error-messages="getErrors()"
        error
        disabled
      >
        {{ value }}
      </v-input>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DialogAttributeValue',
  props: {
    value: {
      type: [String, Object],
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value() {
      if (this.items.includes(this.value)) {
        this.$emit('input', this.value);
      }
    },
  },
  methods: {
    remove() {
      this.$emit('input', null);
    },
    getErrors() {
      const errors = [];
      return errors;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
