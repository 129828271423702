// https://vuex.vuejs.org/en/actions.html
import Vue from 'vue';
import axios from 'axios';

const BASE_URI = '/api/v1/pim/completude';
const namespace = 'store.completude';

export default {
  families({ commit, getters }) {
    Vue.$log.info(`${namespace}.families.started`);
    return new Promise((resolve) => {
      axios
        .get(`${BASE_URI}/families`)
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.product.empty`);
          }
          const completudeMarketplaces = getters.bymarkerplaces;
          if (!response.data) {
            return {};
          }
          const families = response.data.reduce((r, familyCompletude) => {
            if (typeof r[familyCompletude.familyId] === 'undefined') {
              // eslint-disable-next-line no-param-reassign
              r[familyCompletude.familyId] = {};
            }
            completudeMarketplaces.forEach((marketplaceName) => {
              if (Array.isArray(familyCompletude[marketplaceName])) {
                familyCompletude[marketplaceName].forEach((attribute) => {
                  if (typeof r[familyCompletude.familyId][attribute.name] === 'undefined') {
                    // eslint-disable-next-line no-param-reassign
                    r[familyCompletude.familyId][attribute.name] = [];
                    r[familyCompletude.familyId][attribute.name].push(marketplaceName);
                  } else if (!r[familyCompletude.familyId][attribute.name].includes(marketplaceName)) {
                    r[familyCompletude.familyId][attribute.name].push(marketplaceName);
                  }
                });
              }
            });
            return r;
          }, {});
          commit('set_families', families);
          return resolve(families);
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return resolve(0);
        });
    });
  },
  product({ commit }, id) {
    Vue.$log.info(`${namespace}.product.${id}.started`);
    return new Promise((resolve) => {
      axios
        .get(`${BASE_URI}/product/${id}`)
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.product.empty`);
          }
          commit('set_completudes', response.data.result.map(r => ({
            variantId: r.variantId,
            ...r.completude,
          })));
          commit('set_familyCompletude', response.data.familyCompletude);
          return resolve(response.data.result);
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return resolve(0);
        });
    });
  },
  variant(context, id) {
    Vue.$log.info(`${namespace}.variant.${id}.started`);
    return new Promise((resolve) => {
      axios
        .get(`${BASE_URI}/variant/${id}`)
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.variant.empty`);
          }
          return resolve(response.data.result);
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return resolve(0);
        });
    });
  },
  sku(context, id) {
    Vue.$log.info(`${namespace}.sku.${id}.started`);
    return new Promise((resolve) => {
      axios
        .get(`${BASE_URI}/reference/${id}`)
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.sku.empty`);
          }
          return resolve(response.data.result);
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return resolve(0);
        });
    });
  },
  calc(context, payload) {
    Vue.$log.info(`${namespace}.update.started`, payload);
    if (typeof payload.endpoint === 'undefined' || !payload.endpoint) {
      throw new TypeError(`${namespace}.update.endpoint.empty`);
    }
    if (['product', 'variant', 'reference'].indexOf(payload.endpoint) === -1) {
      throw new TypeError(`${namespace}.update.endpoint.${payload.endpoint}.invalid`);
    }
    return new Promise((resolve) => {
      axios
        .put(`${BASE_URI}/${payload.endpoint}`, payload.data)
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.update.empty`);
          }
          return resolve(response.data.result);
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return resolve(0);
        });
    });
  },
  update(context, payload) {
    Vue.$log.info(`${namespace}.update.started`, payload);
    if (typeof payload.endpoint === 'undefined' || !payload.endpoint) {
      throw new TypeError(`${namespace}.update.endpoint.empty`);
    }
    if (['product', 'variant', 'reference'].indexOf(payload.endpoint) === -1) {
      throw new TypeError(`${namespace}.update.endpoint.${payload.endpoint}.invalid`);
    }
    return new Promise((resolve) => {
      axios
        .post(`${BASE_URI}/${payload.endpoint}`, payload.data)
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.update.empty`);
          }
          return resolve(response.data.result);
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return resolve(0);
        });
    });
  },
};
