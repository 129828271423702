import Vue from 'vue';

// Sync router with store
import { sync } from 'vuex-router-sync';

// Plugins
import './plugins';
// Components
import './components';

import App from './App';
import router from './router';
import store from './store';
// eslint-disable-next-line import/order
import { TaAuthPlugin } from '@/plugins/auth';
import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify';

// configuration
// sets the app in development mode.
Vue.config.silent = process.env.VUE_APP_SILENT === 'true' || true;
Vue.config.productionTip = process.env.VUE_APP_PRODUCTION_TIP === 'true' || false;
Vue.config.performance = process.env.VUE_APP_PERFORMANCE === 'true' || false;
Vue.config.devtools = process.env.VUE_APP_DEV_TOOLS === 'true' || false;

// Sync store with router
sync(store, router);
Vue.use(TaAuthPlugin, {
  clientId: process.env.VUE_APP_CLIENT_ID || null,
  accessTokenLifetime: 60 * 60,
  store,
  onRedirectCallback: (appState) => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  },
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');

// Global error handler
Vue.config.errorHandler = (error, instance, info) => {
  // Handle the error globally
  console.error("Global error:", error);
  console.log("Vue instance:", instance);
  console.log("Error info:", info);

  // Add code for UI notifications, reporting or other error handling logic
};