<template>
  <v-autocomplete
    v-if="editing"
    class="attribute-marketplaces"
    v-model="marketplace"
    :items="itemsValue"
    label="Pick your marketplace"
    hint="Pick your marketplace"
    persistent-hint
    chips
  >
  </v-autocomplete>
  <v-template v-else>
    <v-chip v-for="(v, i) in marketplace"
      color="#283593"
      dark
      x-small
      :key="i">
      {{ v }}
    </v-chip>
  </v-template>
</template>

<script>
import MarketplaceBaseMixins from '@/mixins/marketplace-base';

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    editing: {
      type: Boolean,
      required: false,
    },
  },
  mixins: [MarketplaceBaseMixins],
  name: 'attributeMarketplace',
  computed: {
    itemsValue() {
      return this.marketplacesList;
    },
  },
  mounted() {
    this.marketplace = this.value;
  },
  data: () => ({
    marketplace: 'DEFAULT',
  }),
  watch: {
    marketplace() {
      this.$emit('input', this.marketplace);
    },
  },
};
</script>
