<template>
  <v-chip class="ma-2" :color="color" text-color="white">
    {{ count }} {{ label }}{{ count > 1 ? "s" : "" }}
    <v-avatar v-if="!noIcon" right>
      <template v-if="statIcon.type === 'icon'">
        <v-icon :class="`${color} darken-4`">{{ statIcon.value }}</v-icon>
      </template>
      <template v-else>
        {{ statIcon }}
      </template>
    </v-avatar>
  </v-chip>
</template>

<script>
export default {
  name: 'stats',
  props: {
    label: {
      type: String, // String, Number, Boolean, Function, Object, Array
      required: true,
    },
    count: {
      type: [Number, String], // String, Number, Boolean, Function, Object, Array
      required: true,
    },
    statIcon: {
      type: [Object, String],
      default: () => ({
        type: 'icon',
        value: 'mdi-account-circle',
      }),
    },
    noIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String, // String, Number, Boolean, Function, Object, Array
      default: 'gray',
    },
  },
};
</script>

<style lang="css" scoped></style>
