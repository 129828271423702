/* eslint no-param-reassign:0 */

import Vue from 'vue';
import langs from '../helpers/lang';
import LangSubMixins from './config/lang-sub-base';

export default {
  mixins: [LangSubMixins],
  data: () => ({
    langs: langs.reduce((r, lang) => {
      r[lang.icon] = lang.legacyName;
      return r;
    }, {}),
    iso3Langs: langs.reduce((r, lang) => {
      r[lang.iso3] = lang.icon;
      return r;
    }, {}),
  }),
  computed: {
    currentIconLang() {
      if (this.currentLang === 'en') {
        return 'gb';
      }
      return this.currentLang;
    },
  },
  methods: {
    initTextLang() {
      // convert string datatype in object datatype
      const value = this.item[this.property][this.keyAttr];
      let langValues = {};
      langValues = Object.values(this.langs).reduce((result, lang) => {
        if (typeof value === 'object' && value) {
          result[lang] = value[lang] ? value[lang] : ''; // eslint-disable-line no-param-reassign
        } else {
          result[lang] = value && lang === 'fr' ? value : ''; // eslint-disable-line no-param-reassign
        }
        return result;
      }, {});
      this.item[this.property][this.keyAttr] = langValues;
    },
    initLongTextLang() {
      // convert string datatype in object datatype
      if (!this.item[this.property]) {
        this.item[this.property] = {};
        Vue.set(this.item[this.property], this.keyAttr, {});
      }
      let value = this.item[this.property][this.keyAttr];
      let langValues = {};
      try {
        if (!value) {
          throw new TypeError(`attribute: ${this.keyAttr} is empty`);
        }
        if (typeof value !== 'object') {
          value = value.replace(/(?:\n)/g, '\\n');
          value = JSON.parse(value);
        }
        langValues = Object.values(this.langs).reduce((result, lang) => {
          result[lang] = '';
          if (typeof value[lang] !== 'undefined') {
            result[lang] = value[lang];
          } else if (
            typeof value[this.keyAttr] !== 'undefined'
            && typeof value[this.keyAttr][lang] !== 'undefined'
          ) {
            result[lang] = value[this.keyAttr][lang];
          }
          return result;
        }, {});
      } catch (err) {
        langValues = Object.values(this.langs).reduce((result, lang) => {
          result[lang] = value && lang === 'fr' ? value : '';
          return result;
        }, {});
      }
      this.item[this.property][this.keyAttr] = langValues;
    },
  },
};
