<template>
  <div>
    <v-alert :value="!!message" :type="type" dismissible outlined border="left" @input="closeError">
      <h3 class="text-h5">{{ name }}</h3>
      <div>{{ message }}</div>
      <v-divider v-if="list" :class="`my-4 ${type}`" style="opacity: 0.22"></v-divider>
      <ul v-if="list">
        <li v-for="{ value, key } in list" :key="key">{{ key }} : {{ value }}</li>
      </ul>
    </v-alert>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'coreError',
  computed: {
    ...mapState('error', ['name', 'message', 'data', 'type']),
    list() {
      if (!this.data) {
        return null;
      }
      return Object.keys(this.data).map(key => ({ key, value: this.data[key] }));
    },
  },
  methods: {
    ...mapMutations('error', ['set_error']),
    closeError() {
      this.set_error({});
    },
  },
};
</script>

<style></style>
