/* eslint max-len:0 */
import FormBaseMixins from '@/mixins/form-base';
import langs from '@/helpers/lang';
import CdvService from '@/services/cdv';

const namespace = 'mixins.attribute-base';

export default {
  mixins: [FormBaseMixins],
  props: {
    /**
     * this is the key from quable
     *
     * @type {String}
     * @example
     *   - attr_descrption_r
     */
    keyAttr: {
      type: String,
    },
    item: {
      type: Object,
      required: true,
    },
    property: {
      type: String,
      required: true,
    },
    label: {
      type: String | Boolean, // eslint-disable-line no-bitwise
      required: false,
    },
    help: {
      type: String | Boolean, // eslint-disable-line no-bitwise
      required: false,
    },
    chips: {
      type: Boolean,
      default: false,
      required: false,
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false,
    },
    filled: {
      type: Boolean,
      default: false,
      required: false,
    },
    itemValue: {
      type: String | Boolean, // eslint-disable-line no-bitwise
      required: false,
      default: 'id',
    },
  },
  data: () => ({
    hasDataModel: false,
    defaultValues: null,
    identifyName: null,
    titleDefault: 'Default value :',
  }),
  created() {
    // this item comes from variant
    if (typeof this.item.dataJson !== 'undefined') {
      this.hasDataModel = true;
      this.defaultValues = { ...this.item.dataJson };
    }
    this.identifyName = this.hasDataModel ? this.keyAttr : this.property;
  },
  beforeDestroy() {
    this.defaultValues = null;
    this.hasDataModel = false;
  },
  methods: {
    isNotValue: CdvService.isNotValue,
    /**
     * check if object has language correct
     * @param  {Object} row [description]
     * @return {[type]}     [description]
     *
     * @see theagent-node-pim:app/utils/semantic/lang.js
     */
    isLang(row) {
      if (Object.keys(row).length === 0) {
        return false;
      }
      const legaciesLangName = langs.map(l => l.legacyName);
      return Object.keys(row).every(lang => legaciesLangName.indexOf(lang) !== -1);
    },
    getLabel(label) {
      const defaultValue = this.getDefaultValues();
      if (defaultValue) {
        return `${label} | ${defaultValue}`;
      }
      return label;
    },
    getDefaultValues() {
      if (this.hasDataModel && this.defaultValues && this.keyAttr) {
        return this.variantDefaultValues();
      }
      if (this.keyAttr && this.identifyName !== 'infos') {
        throw new TypeError(
          `${namespace}.getdefaultvalues.keyattr.${this.identifyName}.not.manage`,
        );
      }
      return CdvService.getDefaultValue(this.item, this.property, this.item.defaultValues)();
    },
    variantDefaultValues() {
      if (
        typeof this.defaultValues[this.keyAttr] === 'undefined'
        || this.isNotValue(this.defaultValues, this.keyAttr)
      ) {
        return '';
      }
      if (this.isLang(this.defaultValues[this.keyAttr])) {
        if (
          JSON.stringify(this.defaultValues[this.keyAttr])
          === JSON.stringify(this.item.dataJson[this.keyAttr])
        ) {
          return '';
        }
        return this.defaultValues[this.keyAttr];
      }
      if (this.defaultValues[this.keyAttr] === this.item.dataJson[this.keyAttr]) {
        return '';
      }
      return `${this.titleDefault} ${this.defaultValues[this.keyAttr]}`;
    },
    compareValue(selected, current) {
      if (![selected, current].every(v => typeof v === 'string')) {
        return false;
      }
      return selected.slugify() === current.slugify();
    },
  },
};
