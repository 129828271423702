<template>
  <v-menu
    ref="allowMenuDate"
    v-model="allowMenuDate"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="item[property]"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="item[property]"
      @input="allowMenuDate = false"
      :loading="isCompletudeRequired(bindAttribute, currentIssues)"> </v-date-picker>
  </v-menu>
</template>
<script>
import AttributeBaseMixins from '../../mixins/attribute-base';
import CompletudeBaseMixins from '../../mixins/completude-base';

export default {
  mixins: [AttributeBaseMixins, CompletudeBaseMixins],
  name: 'attributeDate',
  data: () => ({
    allowMenuDate: false,
  }),
};
</script>
