/**
 * <thead class="v-data-table-header">
 *   <tr>
 *     <th>
 *       <v-checkbox
 *         v-model="selected"
 *         primary
 *         hide-details
 *         @click.native="selectCheckboxes"
 *       ></v-checkbox>
 *     </th>
 *     <th v-for="header in filterByColumn()"
 *       :key="header.text"
 *       role="columnheader"
 *       scope="col"
 *       :class="['text-start']"
 *       :aria-label="header.text"
 *       aria-sort="descending"
 *       :style="{ width: header.width + 'px', minWidth: header.width + 'px'}"
 *     >
 *       {{ header.text }}
 *       <v-icon v-if="!header.sortable"
 *         class="v-data-table-header__icon"
 *         small>mdi-arrow-up</v-icon>
 *       <v-icon
 *         class="v-data-table-header__icon darken-2"
 *         small
 *         @click="editColumnItem(header.value)">mdi-pencil-circle</v-icon>
 *       <v-icon
 *         class="v-data-table-header__icon darken-2"
 *         small
 *         @click="editColumnItem(header.value)">mdi-table-column-plus-after
 *       </v-icon>
 *   </th>
 *   </tr>
 * </thead>
 */

export default {
  data: () => ({
    rowSelected: [],
    selectAll: false,
  }),
  methods: {
    selectCheckboxes() {
      this.rowSelected = [];
      if (!this.selectAll) {
        this.selectAll = true;
        // eslint-disable-next-line array-callback-return
        this.items.map((item) => {
          this.rowSelected.push(item);
        });
      } else {
        this.selectAll = false;
      }
    },
    selectableRow(item, row) {
      row.select(true);
    },
  },
};
