<template>
  <v-autocomplete
    class="attribute-distributor"
    v-model="item[property]"
    :items="itemsValue"
    menu-props="auto"
    item-text="name"
    item-value="id"
    :label="label || 'Pick your distributor (supplier term is deprecated)'"
    hint="Pick your distributor (supplier is deprecated)"
    persistent-hint
    chips
    multiple
    :messages="getDefaultValues()"
    :loading="isCompletudeRequired(bindAttribute, currentIssues)"
  >
  </v-autocomplete>
</template>

<script>
import AttributeBaseMixins from '@/mixins/attribute-base';
import CompletudeBaseMixins from '@/mixins/completude-base';
import DistributorSubBaseMixins from '@/mixins/config/distributor-sub-base';

export default {
  mixins: [AttributeBaseMixins, CompletudeBaseMixins, DistributorSubBaseMixins],
  name: 'AttributeFournisseur',
  data: () => ({}),
  computed: {
    itemsValue() {
      return this.distributors;
    },
  },
  methods: {},
};
</script>
