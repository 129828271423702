import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

const requireComponent = require.context('@/components', true, /\.vue$/);

const excludedDirs = [
  'attributelist',
  'link',
];

requireComponent.keys().forEach((fileName) => {
  const isNotExcluded = !excludedDirs.some(d => fileName.indexOf(`./${d}/`) !== -1);
  if (isNotExcluded) {
    const componentConfig = requireComponent(fileName);

    const componentName = upperFirst(camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')));
    const templateName = `template-${fileName.replace(/\.\w+$/, '').slugify()}`;
    let params = componentConfig.default || componentConfig;

    // Backup a reference of an existing *.mounted() method, or just an empty function
    const onMounted = params.mounted || function mounted() {};

    const defaultParams = {
      // This part auto-adds a class name matching the registered component-name
      mounted() {
        const element = this.$el;
        try {
          element.classList.add(templateName);
          // eslint-disable-next-line no-empty
        } catch (err) {}
        // Then forward this to any potential custom 'mounted' methods we had in 'params'  :
        onMounted.call(this);
      },
    };

    params = { ...params, ...defaultParams };

    Vue.component(componentName, params);
  }
});
