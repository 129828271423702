<template>
  <v-container fluid ref="variant">
    <v-data-table
      v-model="variantSelected"
      :headers="filterByColumn('variant')"
      class="elevation-0 ta-fix-column ta-variant"
      :item-class="checkError"
      :items="itemsRows"
      item-key="remoteCode"
      hide-default-footer
      fixed-header
      :items-per-page="items.length"
      :show-expand="settings.showExpand"
      :single-expand="settings.singleExpand"
      :search="search"
      :dense="settings.dense"
      :show-select="showSelect"
      :custom-filter="defaultFilter"
    >
      <template v-slot:item.data-table-expand="{ expand, isExpanded }">
        <div class="text-center ta-expand-icon">
          <v-btn fab x-small @click="expand(!isExpanded)">RC</v-btn>
          <v-icon v-if="isExpanded" @click="expand(!isExpanded)">mdi-chevron-up</v-icon>
          <v-icon v-else @click="expand(!isExpanded)">mdi-chevron-down</v-icon>
        </div>
      </template>
      <template v-for="head in filteredFields()" v-slot:[`header.${head.value}`]="{ header }">
        <material-table-header-title
          :key="head.value"
          v-model="errorHeaders[header.value].close"
          :header="errorHeaders[header.value]"
          v-on:header:filtered="errorsSelected($event)"
          v-on:header:unfiltered="resetErrorsSelected($event)"
        ></material-table-header-title>
      </template>
      <template v-for="head in filteredFields()" v-slot:[`item.${head.value}`]="{ item }">
        <div :key="head.value">
          <core-dialog
            v-model="item"
            :header="head"
            :saveOnApi="saveOnApi"
            store="variant"> </core-dialog>
        </div>
      </template>
      <template v-slot:item.id="{ item }">
        <dialog-noedit :item="item" property="id"> </dialog-noedit>
      </template>
      <template v-slot:item.image="{ item }">
        <core-avatar
          v-if="typeof item.image !== 'undefined' || item.image !== null"
          :image="$pimUrl(item.image, 'sq60')"
          size="36px"
        />
      </template>
      <template v-slot:item.familyId="{ item }">
        <dialog-family
          :item="item"
          property="familyId"
          labelName="Choose your family"
          :saveOnApi="saveOnApi"
        >
        </dialog-family>
      </template>
      <template v-slot:item.legacyId="{ item }">
        <dialog-noedit :item="item" property="legacyId">
          <ul v-if="smallestLegacyId !== item.legacyId">
            <li>(marketplace db : {{ smallestLegacyId }})</li>
            <li>(legacy db : {{ item.legacyId }})</li>
          </ul>
          <material-markdown src="equivalencedatabases.md"></material-markdown>
        </dialog-noedit>
      </template>
      <template v-slot:item.attributes_temp.att_composition_rc="{ item }">
        <dialog-composition
          v-model="item.attributes_temp.att_composition_rc"
          disable-button
          v-on:save="updateAttribute(item, 'att_composition_rc')"
        >
        </dialog-composition>
      </template>
      <template v-slot:item.medias="{ item }">
        <common-media-list-view
          :hasMedia="item.hasMedias()"
          :variantMedia="item.medias"
          :mini="true"
          :variantId="item.id"
          style="max-width: 960px;"
        >
        </common-media-list-view>
        <core-error
          v-if="item.errors && item.errors.medias"
          property="medias"
          :errors="item.errors.medias"
        />
      </template>
      <template v-slot:item.quable="{ item }">
        <v-chip :color="getColor(item.quable)" dark>{{ getMessage(item.quable) }}</v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="!noEdition" :visible="!noEdition" small class="mr-1" @click="editItem(item)">
          mdi-pencil-circle
        </v-icon>
        <v-icon small class="mr-1" @click="deleteItem(item)">
          mdi-delete-circle
        </v-icon>
        <v-icon small class="mr-1" v-if="isSuperAdmin" @click="syncItem(item, 'variant')">
          mdi-sync-circle
        </v-icon>
        <v-icon small v-if="listOptions.length > 1 && noEdition" @click="openItemOptions(item)">
          mdi-dots-vertical-circle
        </v-icon>
      </template>
      <template v-slot:item.active="{ item }">
        <v-switch v-model="item.active" @change="active(item)"></v-switch>
      </template>
      <template v-slot:item.sealed="{ item }">
        <v-switch v-if="isSuperAdmin" v-model="item.sealed" @change="sealed(item)"></v-switch>
      </template>
      <template v-slot:item.completudes="{ item }">
        <template v-for="(completude, keyMarketplace) in item.completudes">
          <span :key="keyMarketplace">
            <b>{{ keyMarketplace }}</b>
            <material-percent :percent="completude" color="blue-grey"></material-percent>
          </span>
        </template>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="filterByColumn('variant').length + 1">
          <v-expansion-panels v-if="item.hasMedias()">
            <v-expansion-panel v-for="(row, i) in 1" :key="i">
              <v-expansion-panel-header>
                Medias
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <common-media-list-view
                  :hasMedia="item.hasMedias()"
                  :variantMedia="item.medias"
                  :variantId="item.id"
                  v-on:delete:item="deleteMedia"
                  style="max-width: 100vw;"
                >
                </common-media-list-view>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <pim-skus-view
            :items="item.skus"
            v-if="item.skus"
            :parentItem="item"
            :saveOnApi="saveOnApi"
            :show-select="showSelect"
            endpointType="sku"
            :search="search"
            style="max-width: 100vw;"
            :no-edition="noEdition"
          ></pim-skus-view>
        </td>
      </template>
    </v-data-table>
    <v-btn
      v-if="!noEdition"
      color="deep-purple lighten-1"
      class="mt-6"
      depressed
      block
      @click="editItemVariant"
    >
      <v-icon dark>mdi-plus</v-icon> add a new variant color
    </v-btn>
    <dialog-variant
      v-if="!noEdition"
      v-model="editedItem"
      :dialog="dialog"
      :withSku="withSku"
      v-on:update:dialog="dialog = $event"
      :saveOnApi="saveOnApi"
      @onSave="saveItemVariant"
      @onClose="closeItem"
    >
    </dialog-variant>
    <dialog-list-options
      v-if="listOptions.length > 1 && noEdition"
      hide-button
      v-model="dialogListOption"
      :list-options="listOptions"
      :current="editedItem.copy()"
      x-large
    >
      <template v-slot:title>
        {{ editedItem.name }}<span v-if="editedItem.sealed"> (data sealed)</span>
      </template>
    </dialog-list-options>
  </v-container>
</template>

<script>
import Vue from 'vue';
import {
  VariantManager, SkuManager, SkuList, Variant,
} from '@/models';
import Mixins from '@/mixins';
import { STEPPER_VALID_IMPORT } from '@/helpers/steps';
import ItemsBaseMixins from '@/mixins/items-base';
import SyncedBaseMixins from '@/mixins/synced-base';
import EditMixins from '@/mixins/edit-base';
import StepBaseMixins from '@/mixins/step-base';
import ErrorMixins from '@/mixins/error-base';
import { EventBus } from '@/helpers/event-bus';

const namespace = 'components.pim.variantview';

export default {
  mixins: [SyncedBaseMixins, EditMixins, StepBaseMixins, ItemsBaseMixins, ErrorMixins, ...Mixins],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    saveOnApi: {
      type: Boolean,
      default: true,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    noEdition: {
      type: Boolean,
      default: false,
    },
    search: {
      type: [String, Number],
      default: '',
    },
  },
  data: () => ({
    variantSelected: [],
    defaultItem: Variant.create({}),
    editedItem: Variant.create({}),
    withSku: false,
    dialogListOption: false,
    listOptionsDefault: [
      {
        name: 'Default',
      },
      {
        name: 'Change color 🟥🟧🟨🟩🟦🟪⬛️',
        fields: [
          'id',
          'color',
          'att_couleur_detail_rc',
          {
            customTemplate: 'checkbox',
            name: 'unsealed',
            label: 'Unsealed this variant',
          },
        ],
        store: 'variant/changeColor',
        scope: 'pimapp.v2:pim:variant:changecolor:write',
        payload: {
          children: true,
        },
      },
      {
        name: 'Move this variant',
        fields: [
          'id',
          {
            customTemplate: 'product',
            name: 'productId',
            label: 'Choose your new product parent',
          },
        ],
        store: 'variant/move',
        scope: 'pimapp.v2:pim:variant:move:write',
      },
      {
        name: 'Duplicate this variant 👯‍♀️👯‍♂️',
        fields: ['id', 'color', 'supplierReference'],
        store: 'variant/duplicate',
        scope: 'pimapp.v2:pim:variant:duplicate:write',
        payload: {
          children: true,
        },
      },
    ],
  }),
  computed: {
    settings() {
      return this.$store.getters['settings/settings'];
    },
    headers() {
      return this.$store.getters['settings/headers'].variant;
    },
    smallestLegacyId() {
      if (!this.itemsRows || this.itemsRows.length === 0) {
        return false;
      }
      return this.itemsRows.reduce((variant, current) => {
        if (variant.legacyId <= current.legacyId) {
          return variant;
        }
        return current;
      }, false).legacyId;
    },
    listOptions() {
      return this.listOptionsDefault.filter(o => this.hasRight(o.scope || null));
    },
  },
  created() {
    this.getCompletudes();
    // this.setDefaultVariantItem(this.items[0]);
  },
  methods: {
    openItemOptions(item) {
      this.dialogListOption = true;
      this.editedItem = item;
    },
    /**
     * This method allow to initialize default data to all dialog
     * @param {[type]} item [description]
     */
    setDefaultVariantItem(item, viewSkus = false) {
      this.$log.info(`${namespace}.setdefaultvariantitem.started`);
      const defaultItem = VariantManager.newObject(item);
      if (viewSkus) {
        defaultItem.skusValues = defaultItem.skus.map(sku => ({ text: sku.size, color: 'blue' }));
      } else {
        delete defaultItem.skusValues;
      }
      defaultItem.sealed = false;
      this.withSku = true;
      this.$log.debug(`${namespace}.setdefaultvariantitem.defaultitem`, defaultItem);
      this.defaultItem = defaultItem;
      this.editedItem = defaultItem;
    },
    editItemVariant() {
      this.setDefaultVariantItem(this.items[0], true);
      this.dialog = true;
    },
    async saveItemVariant(done = () => {}) {
      this.$log.info(`${namespace}.saveitemvariant.started`, this.editedItem);
      let endpoint = 'variant/new';
      const isOld = this.editedIndex > -1;
      const isNotNew = this.editedItem.id > 0;
      if (isOld) {
        endpoint = 'variant/update';
      }
      const { skus } = this.editedItem;
      const skusList = new SkuList();
      if (
        typeof this.editedItem.skusValues !== 'undefined'
        && this.editedItem.skusValues.length > 0
      ) {
        // add new sku
        this.editedItem.skusValues.forEach((sku) => {
          const isEnhanced = sku.text.indexOf(':') !== -1;
          let size = sku.text;
          let ean = false;
          if (isEnhanced) {
            const values = sku.text.split(':');
            size = values[0]; // eslint-disable-line prefer-destructuring
            if (typeof values[1] === 'string' && values[1].length === 13) {
              ean = values[1]; // eslint-disable-line prefer-destructuring
            }
          }
          let index = null;
          if (ean) {
            index = skus.findIndex(s => s.size === size && s.ean === ean);
          } else {
            index = skus.findIndex(s => s.size === size);
          }
          if (index === -1) {
            this.$log.debug(`components.pim.variantview.saveitemvariant.newsku.${size}`);
            const newSku = skus[0];
            const skuItem = SkuManager.newObject(newSku);
            skuItem.size = size;
            if (ean) {
              skuItem.ean = ean;
            }
            skusList.add(skuItem);
          }
        });
        Vue.set(this.editedItem, 'skus', skusList);
        this.editedItem.skus.fixDependencies();
      }

      if (this.saveOnApi === false && !isNotNew) {
        this.$log.debug('components.pim.variantview.saveitemvariant.saveonly');
        this.saveItem();
        done();
        return this.closeItemVariant();
      }
      return this.$store
        .dispatch(endpoint, this.editedItem.toObject())
        .then(async (response) => {
          if (this.editedIndex === -1) {
            this.items.push(response);
          } else {
            this.items[this.editedIndex].update(response);
            await this.items[this.editedIndex].setCompletude(this.$store.dispatch);
          }
          return this.closeItemVariant();
        })
        .catch(error => this.throwError(error))
        .finally(() => {
          done();
          return this.onCollectionUpdate();
        });
    },
    async closeItemVariant(checkpoint = true) {
      this.$log.log(`${namespace}.closeitemvariant.started`);
      const rollbackStep = this.currentStep >= STEPPER_VALID_IMPORT;
      this.withSku = false;
      const listProms = [this.closeItem()];
      if (rollbackStep && checkpoint) {
        // if the step is old, no need to go to the following step
        listProms.push(this.stepInProgress(STEPPER_VALID_IMPORT));
      }
      return Promise.all(listProms).then(() => {
        // reset data
        // regenerate a new defaultItem and editItem
        this.setDefaultVariantItem(this.items[0], true);
      });
    },
    updateAttribute(item, keyAttribute) {
      // for some dialog component like textarea we use directement v-model and not v-on:change
      // This portion code is a hack because for some unknow reason
      // on dialog-textarea (att_description_rc) attribute_temp.att_description_rc is not
      // synchronized with attribute.dataJson.att_description_rc the first time.
      // We need to force it before sending it in the database
      // src/mixins/dialog-base.js
      this.$log.log(`${namespace}.updateattribute.${keyAttribute}.started`);
      this.editItem(item, false);
      let endpoint = 'variantattribute/new';
      const isOld = this.editedIndex > -1;
      const isNotNew = this.editedItem.id > 0;
      if (isOld) {
        endpoint = 'variantattribute/update';
      }
      const findAttributeInDataJson = a => Object.keys(a.dataJson).includes(keyAttribute);
      const attribute = this.editedItem.attributes.find(findAttributeInDataJson);
      if (!attribute) {
        return this.throwError(
          new TypeError(`${namespace}.updateattribute.${keyAttribute}.attribute.not.found`),
        );
      }
      Vue.set(attribute.dataJson, keyAttribute, this.editedItem.attributes_temp[keyAttribute]);
      if (this.saveOnApi === false && !isNotNew) {
        this.$log.debug(`${namespace}.updateattribute.${keyAttribute}.saveonly`);
        return this.saveItem();
      }
      return this.$store
        .dispatch(endpoint, attribute)
        .catch(error => this.throwError(error))
        .finally(() => this.onCollectionUpdate());
    },
    onCollectionUpdate() {
      this.$log.log(`${namespace}.oncollectionupdate.started`);
      return EventBus.$emit('onCollectionUpdate');
    },
    getCompletudes() {
      this.$log.info(`${namespace}.getcompletudes.started`);
      if (typeof this.items.getCompletudes === 'function') {
        this.items.getCompletudes(this.$store.dispatch);
      } else {
        this.$log.error(`${namespace}.getcompletudes.items.is.not.variantlist.instance`);
      }
    },
  },
};
</script>

<style></style>
