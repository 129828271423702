/* eslint no-param-reassign:0 */
// https://vuex.vuejs.org/en/getters.html

import i18n from '@/plugins/i18n';
// @todo create a function to generate automatically theses properties
// authorized lets you know if the token is true or not
export default {
  list: state => (type) => {
    const key = `${type}List`;
    if (typeof state[key] === 'undefined') {
      return [];
    }
    try {
      return state[key].map((row) => {
        // http://kazupon.github.io/vue-i18n/api/#vue-injected-methods
        if (i18n.te(`${type}.${row.remoteCode}`)) {
          row.nameI18n = `${i18n.t(`${type}.${row.remoteCode}`)}`;
        } else {
          row.nameI18n = row.name;
        }
        return row;
      });
    } catch (err) {
      return Promise.reject(new Error('store.modules.api.helpers.translation.failed'));
    }
  },
  count: state => (type) => {
    const key = `${type}List`;
    return typeof state[key] !== 'undefined' ? state[key].result_count : 0;
  },
  parameters: state => (type) => {
    const key = `${type}List`;
    return typeof state[key] !== 'undefined' ? state[key].parameters : {};
  },
  keys: state => (type) => {
    const key = `${type}ListKey`;
    return typeof state[key] !== 'undefined' ? state[key] : 0;
  },
  history: state => state.history,
};
