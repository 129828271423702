<template>
  <v-dialog v-model="dialog" persistent max-width="960px">
    <!-- https://vuetifyjs.com/en/components/menus/#activator-and-tooltip -->
    <template v-slot:activator="{ on: openDialog }">
      <v-tooltip left>
        <template v-slot:activator="{ on: tooltip, attrs }">
          <v-btn
            color="green"
            fab
            dark
            absolute
            right
            fixed
            class="mx-2"
            style="bottom: 160px;"
            v-bind="attrs"
            v-on="{ ...tooltip, ...openDialog }"
          >
            <v-icon dark>mdi-table-row-plus-after</v-icon>
          </v-btn>
        </template>
        <span>Create a new row</span>
      </v-tooltip>
    </template>
    <div>
      <v-btn color="blue darken-1" fab dark fixed top right :disabled="!isValid" @click="save">
        {{ $t("component.button.save") }}
      </v-btn>
      <v-btn
        color="grey darken-1"
        fab
        dark
        fixed
        top
        right
        outlined
        style="top: 80px; background-color: white !important;"
        @click="close"
      >
        {{ $t("component.button.cancel") }}
      </v-btn>
    </div>
    <v-card>
      <v-form v-model="isValid">
        <v-card-title>Traduction {{ item.fra }} :</v-card-title>
        <v-card-text>
          <v-row>
            <template v-for="(row, key) in fields">
              <v-col :key="key" v-if="filterByFields(key)" cols="12" md="6">
                <attribute-translate-type
                  v-if="key === 'type'"
                  ref="type"
                  :item="item"
                  property="type"
                  chips
                />
                <core-attribute
                  v-else
                  :csv="true"
                  :property="key"
                  auto-grow
                  :item="item"
                  item-value="name"
                  filled
                >
                  <template v-slot:label>
                    <flag :iso="iso3Langs[key]"></flag>
                    {{ key }}
                  </template>
                </core-attribute>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Mixins from '../../mixins';
import ConfigBaseMixins from '../../mixins/config-base';

export default {
  mixins: [ConfigBaseMixins, ...Mixins],
  props: {
    item: {
      type: Object,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isValid: true,
    fieldsUnsed: ['id', 'createdAt', 'deletedAt', 'updatedAt'],
  }),
  computed: {
    /**
     * this method filter all header colmuns according to the selected headers
     * @return {[type]} [description]
     */
    fields() {
      return Object.keys(this.item).reduce((items, property) => {
        if (
          this.fieldsUnsed.indexOf(property) === -1
          && typeof this.item[property] !== 'undefined'
        ) {
          // eslint-disable-next-line no-param-reassign
          items[property] = this.item[property];
        }
        return items;
      }, {});
    },
  },
  methods: {
    filterByFields(header) {
      if (['remoteId', 'type'].includes(header) || typeof this.iso3Langs[header] !== 'undefined') {
        return true;
      }
      return false;
    },
    save() {
      return this.$emit('onSave');
    },
    close() {
      // https://vuejs.org/v2/guide/components-custom-events.html#sync-Modifier
      this.$emit('update:dialog', false);
      return this.$emit('onClose');
    },
  },
};
</script>

<style></style>
