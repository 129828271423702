export default [];

export const STEPPER_START = 0;
export const STEPPER_INTEGRITY_CSV = 1;
export const STEPPER_CONVERT_PIM = 2;
export const STEPPER_GENERATE_PIM = 3;
export const STEPPER_VALID_IMPORT = 4;

const functions = {};
functions[STEPPER_START] = {
  function: false,
  color: 'black',
  workflow: {
    name: 'Import initialization',
    i18n: 'stepper.steps.import-initialization',
    step: STEPPER_START,
    rules: false,
    complete: true,
    messages: '',
  },
};
functions[STEPPER_INTEGRITY_CSV] = {
  editable: false,
  function: 'dataIntegrity',
  color: 'blue',
  icon: 'mdi-arrow-right',
  label: 'Check',
  workflow: {
    name: 'Check your csv file before converting',
    i18n: 'stepper.steps.check-csv',
    step: STEPPER_INTEGRITY_CSV,
    rules: true,
    complete: false,
    messages: '',
  },
};
functions[STEPPER_CONVERT_PIM] = {
  editable: false,
  function: 'convertStructuredData',
  color: 'black',
  icon: 'mdi-arrow-right',
  label: 'Convert',
  workflow: {
    name: 'Convert in PIM data model',
    i18n: 'stepper.steps.convert-datamodel',
    step: STEPPER_CONVERT_PIM,
    rules: true,
    complete: false,
    messages: '',
  },
};
functions[STEPPER_GENERATE_PIM] = {
  editable: false,
  function: 'generateIdImport',
  color: 'black',
  icon: 'mdi-arrow-right',
  label: 'Ids',
  workflow: {
    name: 'Saving in PIM database, creating sku name',
    i18n: 'stepper.steps.storing-in-pim',
    step: STEPPER_GENERATE_PIM,
    rules: true,
    complete: false,
    messages: '',
  },
};

functions[STEPPER_VALID_IMPORT] = {
  // @todo need to change exportQuable name for another function name
  // @see {@link src/components/pim/CollectionEditView.vue:477}
  editable: false,
  function: 'syncMarketplace',
  color: 'red',
  icon: 'mdi-arrow-right',
  label: 'Export',
  workflow: {
    name: 'Send to marketplaces',
    i18n: 'stepper.steps.valid-import',
    step: STEPPER_VALID_IMPORT,
    rules: true,
    complete: false,
    messages: '',
  },
};

export const pimFunctions = functions;
