<template>
  <material-card color="blue" :title="title" class="col-lg-12">
    <v-row class="pa-4" justify="space-between">
      <v-col cols="5">
        <v-data-table
          :headers="headers"
          :items="items"
          :footer-props="{ 'items-per-page-options': [15, 30, 50, 100, -1] }"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" v-on:click.stop @click="editAttribute(item)">
              mdi-pencil-circle
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col>
        <v-row>
          <v-col cols="12" lg="12">
            <material-card
              :title="`Form '${editedItem.name || 'Default'}'`"
              class="title grey--text text--lighten-1 font-weight-light col-lg-4"
            >
              <template v-if="editedItem.name">
                <v-form ref="form1" v-model="isValid">
                  <v-card-text>
                    <v-text-field v-model="editedItem.name" label="Family name"> </v-text-field>
                    <v-text-field v-model="editedItem.remoteId" label="Family identifier">
                    </v-text-field>
                  </v-card-text>
                  <template v-for="(attribute, index) in editedItem.attributes">
                    <v-card-text :key="index">
                      <v-card-title>{{ `Form '${attribute.name || "Default"}'` }}</v-card-title>
                      <v-text-field v-model="attribute.name" label="Family Group name">
                      </v-text-field>
                      <v-divider></v-divider>
                      <v-card-subtitle>Attribute by group</v-card-subtitle>
                      <template v-for="(rowAttribute, rowIndex) in attribute.defaultValue">
                        <v-text-field
                          :key="rowIndex"
                          append-icon="mdi-delete"
                          v-model="rowAttribute.code"
                          @click:append="removeAttribute(attribute, rowIndex)"
                          :label="`${rowAttribute.label || rowAttribute.code}`"
                        >
                        </v-text-field>
                      </template>
                      <v-btn color="green darken-1" @click="addAttribute(attribute)">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-card-text>
                  </template>
                  <material-button-action
                    v-model="isValid"
                    v-on:save="saveAttribute"
                    v-on:close="closeItem"
                  ></material-button-action>
                </v-form>
              </template>
            </material-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </material-card>
</template>

<script>
import Vue from 'vue';
import AttributeEditMixins from '../../mixins/attribute-edit-base';
import Mixins from '../../mixins';

export default {
  name: 'AttributeTree',
  mixins: [AttributeEditMixins, ...Mixins],
  props: {
    mainAttribute: {
      type: String,
      default: 'default',
    },
    items: {
      type: Array,
      default: () => [{ children: [] }],
    },
  },
  data: () => ({
    isValid: false,
    isValidAttribute: false,
    editedItem: {},
    defaultItem: {},
    headers: [
      {
        sortable: false,
        text: 'Id',
        value: 'id',
      },
      {
        sortable: true,
        text: 'Name',
        value: 'name',
      },
      {
        sortable: true,
        text: 'Identifier',
        value: 'remoteId',
      },
      {
        sortable: false,
        text: 'Actions',
        value: 'actions',
      },
    ],
  }),
  computed: {
    title() {
      return `Configuration: ${this.mainAttribute} (Beta)`;
    },
  },
  methods: {
    /**
     * close item
     *
     * @mixim see src/mixins/edit-base.js
     * @param  {[type]} item [description]
     * @return {[type]}      [description]
     */
    closeItem() {
      // reset data
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    addAttribute(attribute) {
      Vue.$log.info(`component.pim.attributeview.saveitem.${attribute.id}.started`);
      attribute.defaultValue.push({ code: 'att_new_attribute_rc' });
    },
    removeAttribute(attribute, index) {
      Vue.$log.info(`component.pim.attributeview.removeattribute.${attribute.id}.started`);
      attribute.defaultValue.splice(index, 1);
    },
  },
};
</script>
