<template>
  <material-chart-card
    :data="data"
    :options="options"
    :color="color"
    hover-reveal
    :type="graphType"
  >
    <h4 class="card-title font-weight-light mt-2 ml-2">
      {{ title[productType] }}
    </h4>

    <p class="d-inline-flex font-weight-light ml-2 mt-1">
      Graph of {{ title[productType].toLowerCase() }} created this {{ period }}
    </p>

    <template v-slot:actions>
      <v-icon class="mr-1" small>
        mdi-clock-outline
      </v-icon>
      <span class="caption grey--text font-weight-light">updated 1 minutes ago</span>
    </template>
  </material-chart-card>
</template>

<script>
import ChartBaseMixins from '../../mixins/chart-base';

export default {
  name: 'NumberGraph',
  mixins: [ChartBaseMixins],
  methods: {
    hookForChartist() {
      this.$log.log('components.chart.numbergraph.hookforchartist.started');
      return this.$store
        .dispatch('chart/numberBy', {
          productType: this.productType,
          chartType: this.chartType || this.period,
          period: this.period,
        })
        .then((result) => {
          if (result) {
            this.data = result.data;
            this.options = result.options;
          }
        });
    },
  },
};
</script>

<style lang="css" scoped></style>
