// https://vuex.vuejs.org/en/getters.html
/* eslint no-param-reassign:0 */
import { workflow } from 'theagent-status';

const { DONE } = workflow;

const getAttributeEntityByType = (attributes, entity = false) => attributes.reduce((r, attr) => {
  const { templateOptions = undefined } = attr;
  const hasTemplateOptions = typeof templateOptions !== 'undefined';
  if (entity === false) {
    const defaultOrder = 69;
    const hasCsvOptions = hasTemplateOptions && typeof templateOptions.csv !== 'undefined';
    attr.order = hasCsvOptions ? (templateOptions.csv.order || defaultOrder) : defaultOrder;
    attr.width = hasCsvOptions
      ? templateOptions.csv.width
      : undefined;
  } else if (entity === true) {
    // @deprecated to order value should be deprecated because not efficient
    const defaultOrder = attr.order || 69;
    const hasEntityOptions = hasTemplateOptions && typeof templateOptions.entity !== 'undefined';
    attr.order = hasEntityOptions ? (templateOptions.entity.order || defaultOrder) : defaultOrder;
    attr.width = hasEntityOptions
      ? templateOptions.entity.width
      : undefined;
  }
  // from dashboard.csv and quable.csv
  if (attr.entity === entity) {
    r.push(attr);
  }
  return r;
}, []);

const getAttributeByType = (attributes, type) => {
  if (!type) {
    return [];
  }
  return getAttributeEntityByType(attributes, true)
    .reduce((r, attr) => {
      if (attr.entityType === type) {
        r.push(attr);
      }
      return r;
    }, []);
};

// @todo create a function to generate automatically theses properties
// authorized lets you know if the token is true or not
export default {
  attributes: state => state.attributes || [],
  fields: state => state.fields || null,
  profiles: state => state.profiles || null,
  entityTypes: state => state.entityTypes || null,
  product: state => getAttributeByType(state.attributes, state.entityTypes.R_TYPE),
  // remove all attribute with att_*_rc.*
  productAttribute: state => getAttributeByType(state.attributes, state.entityTypes.RA_TYPE).filter(
    a => a.name.indexOf('.') === -1,
  ),
  variant: state => getAttributeByType(state.attributes, state.entityTypes.RC_TYPE),
  sku: state => getAttributeByType(state.attributes, state.entityTypes.RCT_TYPE),
  variantAttribute: state => getAttributeByType(state.attributes, state.entityTypes.RCA_TYPE),
  skuAttribute: state => getAttributeByType(state.attributes, state.entityTypes.RCTA_TYPE),
  csv: state => getAttributeEntityByType(state.attributes),
  attribute: state => (attributeKey) => {
    const attribute = state.attributes.find(a => a.name === attributeKey.trim() || a.bind === attributeKey.trim());
    if (!attribute) {
      return [{ name: 'default' }];
    }
    return attribute;
  },
  isLoaded: state => state.state === DONE,
  required: state => state.attributes.reduce((r, a) => {
    if (a.required) r.push(a);
    return r;
  }, []),
};
