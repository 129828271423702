import { Gender } from '../models';

export default {
  computed: {
    genders() {
      return Gender.fetchList();
    },
    classificationsByKey() {
      return this.$store.getters['api/keys']('classification');
    },
    brandsByKey() {
      return this.$store.getters['api/keys']('brand');
    },
  },
  methods: {
    classificationFormat(id) {
      return typeof this.classificationsByKey[id] !== 'undefined'
        ? this.classificationsByKey[id].name
        : '';
    },
    brandFormat(id) {
      return typeof this.brandsByKey[id] !== 'undefined' ? this.brandsByKey[id].name : '';
    },
    genderFormat(id) {
      return Gender.fetchById(id);
    },
  },
};
