import Vue from 'vue';

export default {
  props: {
    mainAttribute: {
      type: String,
      default: 'default',
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemId: {
      type: Number,
      default: null,
    },
  },
  watch: {
    itemId() {
      if (this.itemId) {
        const item = this.findAttribute(this.itemId);
        if (item && typeof this.editAttribute === 'function') {
          this.editAttribute(item);
        }
      }
    },
  },
  methods: {
    editAttribute(item) {
      Vue.$log.debug('attributelist.family.editAttribute.started', item);
      this.editedIndex = this.items.findIndex(i => i.id === item.id);
      this.editedItem = Object.assign({}, item);
      this.setQueryUrl(item.id);
    },
    /**
     * delete item
     *
     * @mixim see src/mixins/edit-base.js
     * @param  {[type]} item [description]
     * @return {[type]}      [description]
     */
    deleteAttribute(item) {
      Vue.$log.info(`mixins.attributeedit.deleteitem.${this.editedItem.id}.started`);
      const index = this.items.indexOf(item);
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure you want to delete this item?')) {
        this.$store
          .dispatch('api/crud/delete', {
            endpoint: this.mainAttribute,
            data: {
              id: item.id,
            },
          })
          .then(() => this.items.splice(index, 1))
          .catch((error) => {
            Vue.$log.error(`mixins.attributeedit.deleteitem.${item.id}`);
            Vue.$log.error(error);
          });
      }
    },
    saveAttribute() {
      Vue.$log.info(`mixins.attributeedit.saveitem.${this.editedItem.id}.started`);
      let endpoint = 'api/crud/new';
      const isNew = this.editedIndex > -1;
      if (isNew) {
        endpoint = 'api/crud/update';
      }
      this.$store
        .dispatch(endpoint, {
          endpoint: this.mainAttribute,
          data: this.editedItem,
        })
        .then((response) => {
          if (isNew) {
            Vue.set(this.items, this.editedIndex, response);
          } else {
            this.items.unshift(response);
          }
        })
        .catch((error) => {
          Vue.$log.error(`mixins.attributeedit.saveitem.${this.editedItem.id}`);
          Vue.$log.error(error);
        })
        .finally(() => {
          this.closeItem();
        });
    },
    findAttribute(itemId = null) {
      if (!itemId || this.items.length === 0) {
        return null;
      }
      return this.items.find(i => i.id === itemId);
    },
    setQueryUrl(itemId = null) {
      const query = {};
      if (this.mainAttribute) {
        query.property = this.mainAttribute;
      }
      if (this.itemId || itemId) {
        query.itemId = this.itemId || itemId;
      }
      if (Object.keys(query).length > 0) {
        this.$router
          .push({
            name: 'settings.properties',
            query,
          })
          .catch(() => {}); // NavigationDuplicated error quiet
      }
    },
  },
};
