<template>
  <v-autocomplete
    chips
    :items="itemsValue"
    :label="'Pick your brand (required)'"
    hint="Pick your brand"
    item-text="name"
    item-value="id"
    menu-props="auto"
    persistent-hint
    v-model="brandId"
  >
  </v-autocomplete>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'AttributeBrandNative',
  props: {
    value: {
      type: [Number, Object],
      default: () => false,
    },
  },
  data: () => ({
    type: 'brand',
    brandId: null,
  }),
  mounted() {
    this.brandId = this.value;
  },
  computed: {
    itemsValue() {
      return this.$store.getters['brand/brands'];
    },
  },
  watch: {
    brandId() {
      this.apiBrandData(this.brandId).then((data) => {
        this.$store.commit('import/set_rows', data, { root: true });
        // @see src/store/modules/import/actions.js:getVariantsKeys
        this.$store.commit(
          'import/set_variant_keys',
          data.reduce(
            (result, row) => {
              if (typeof result[row.ean] === 'undefined') {
                result.ean[row.ean] = row.id; // eslint-disable-line no-param-reassign
              }
              if (typeof result[row.supplierReference] === 'undefined') {
                // eslint-disable-next-line max-len
                // eslint-disable-next-line no-param-reassign
                result.supplierReference[row.supplierReference] = row.id;
              }
              return result;
            },
            {
              supplierReference: {},
              ean: {},
            },
          ),
          { root: true },
        );
      });
      this.$emit('input', this.brandId);
    },
  },
  methods: {
    ...mapActions('api', {
      apiBrandData: 'brand.data',
    }),
  },
};
</script>
