// https://vuex.vuejs.org/en/state.html

import { workflow } from 'theagent-status';

const { NO_STATUS } = workflow;

const editItemDefault = {
  metaData: {},
  data: {},
};

export default {
  status: workflow,
  import: {},
  importMeta: {},
  imports: [],
  products: [],
  files: [],
  variantsKey: {},
  rows: [],
  state: {
    imports: NO_STATUS,
  },
  /**
   * [display_form_edi description]
   * @type {Boolean}
   */
  currentImportEdit: Object.assign({}, editItemDefault),
  editItemDefault,
  mediasImported: [],
};
