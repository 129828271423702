<template>
  <material-stats-card
    :color="color"
    icon="mdi-hanger"
    :title="titleComponent"
    :value="data"
    sub-icon="mdi-tag"
    sub-text="Tracked from Database"
  >
  </material-stats-card>
</template>

<script>
import ChartBaseMixins from '../../mixins/chart-base';

export default {
  name: 'NumberCount',
  mixins: [ChartBaseMixins],
  data: () => ({
    data: 0,
  }),
  computed: {
    titleComponent() {
      let title = this.title[this.productType].toLowerCase();
      if (this.period && this.period !== 'full') {
        title += ` for this ${this.period}`;
      }
      return `number of ${title}`;
    },
  },
  methods: {
    hookForChartist() {
      this.$log.log('components.chart.numberforamonth.hookforchartist.started');
      return this.$store
        .dispatch('chart/count', {
          productType: this.productType,
          period: this.period,
        })
        .then((result) => {
          this.data = result || 0;
        })
        .catch((err) => {
          this.$log.error(err);
          this.data = 0;
        });
    },
  },
};
</script>
