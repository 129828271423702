import Vue from 'vue';

export default class VariantAttribute {
  static updateAttribute(attribute, familyAttribute) {
    const scopename = `models.pim.variantattribute.updateattribute.${familyAttribute.name}`;
    Vue.$log.debug(`${scopename}.started`);
    const listCodes = familyAttribute.defaultValue.map(af => af.code);
    // add all new code from attribute family in this variant attribute
    listCodes.forEach((code) => {
      // add new code attribute family
      if (typeof attribute.dataJson[code] === 'undefined') {
        Vue.set(attribute.dataJson, code, '');
      }
    });
    // remove old attribute from varian attribute
    Object.keys(attribute.dataJson).forEach((code) => {
      if (listCodes.indexOf(code) === -1) {
        Vue.delete(attribute.dataJson, code);
      }
    });
  }

  static addAttribute(variant, familyAttribute) {
    const scopename = `models.pim.variantattribute.addattribute.${familyAttribute.name}`;
    Vue.$log.debug(`${scopename}.started`);
    variant.attributes.push({
      name: familyAttribute.name,
      variantId: variant.id,
      familyAttributeId: familyAttribute.id,
      type: 'JSON',
      dataJson: familyAttribute.defaultValue.reduce((result, value) => {
        // eslint-disable-next-line no-param-reassign
        Vue.set(result, value.code, '');
        return result;
      }, {}),
      active: 1,
    });
  }

  static deleteAttribute(variant, familyObj) {
    const scopename = `models.pim.variantattribute.deleteattribute.${familyObj.name}`;
    Vue.$log.debug(`${scopename}.started`);
    // delete all group attribute
    variant.attributes.forEach((attribute, index) => {
      // const isJsonContent = familyAttribute.type === 'JSON';
      // eslint-disable-next-line max-len
      const familyIndex = familyObj.attributes.findIndex(
        f => f.id === parseInt(attribute.familyAttributeId, 10),
      );
      if (familyIndex === -1) {
        variant.attributes.splice(index, 1);
      }
    });
  }
}
