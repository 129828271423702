<template>
  <v-card>
    <v-row>
      <v-col cols="2">
        <material-number-card
          title="CSV rows"
          icon="csv"
          :number="stats.csv"
          color="black"
        ></material-number-card>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="2">
        <material-number-card
          title="Pictures"
          icon="mdi-camera"
          :number="stats.medias"
          color="green"
        ></material-number-card>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="2">
        <material-number-card
          title="Products"
          icon="mdi-hanger"
          :number="stats.references"
          color="indigo"
        ></material-number-card>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="2">
        <material-number-card
          title="Variant color"
          icon="mdi-hanger"
          :number="stats.referencescol"
          color="orange"
        ></material-number-card>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="2">
        <material-number-card
          title="Variant size"
          icon="mdi-camera"
          :number="stats.skus"
          color="purple"
        ></material-number-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    stats: {
      type: Object,
      default: () => ({
        csv: 0,
        pictures: 0,
        references: 0,
        referencescol: 0,
        skus: 0,
        errors: 0,
      }),
    },
  },
};
</script>
