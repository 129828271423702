import BrandSubMixins from './config/brand-sub-base';
import DistributorSubMixins from './config/distributor-sub-base';
import ClassificationSubMixins from './config/classification-sub-base';
import CountrySubMixins from './config/country-sub-base';

export default {
  mixins: [
    BrandSubMixins,
    DistributorSubMixins,
    ClassificationSubMixins,
    CountrySubMixins,
  ],
};
