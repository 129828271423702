<template>
  <div>
    <div v-if="!hasRightActions || noDialog">{{ titleName }}</div>
    <v-edit-dialog :return-value="item[property]" large lazy v-else @save="saveInline">
      <div>{{ titleName }}</div>
      <core-error v-if="item.errors" :property="property" :errors="item.errors[property]" />
      <template v-slot:input>
        <v-autocomplete
          v-bind:value="item[property]"
          v-on:change="newObject = $event"
          :items="itemsValue"
          menu-props="auto"
          hide-details
          item-text="name"
          item-value="id"
          :label="labelName || 'Pick your distributor (supplier term is deprecated)'"
          hint="Pick your distributor"
          persistent-hint
          chips
          multiple
        >
        </v-autocomplete>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DialogBaseMixins from '../../mixins/dialog-base';
import ProfileMixins from '../../mixins/profile-base';

export default {
  mixins: [DialogBaseMixins, ProfileMixins],
  data: () => ({
    type: 'distributor',
  }),
  created() {
    this.apiDistributor();
  },
  computed: {
    titleName: function titleName() {
      if (typeof this.item[this.property] !== 'undefined' && this.item[this.property].length > 0) {
        return this.item[this.property].reduce((title, distributorId, index) => {
          if (typeof this.itemsKey[distributorId] === 'undefined') {
            return title;
          }
          const newtitle = this.itemsKey[distributorId].name;
          return index === 0 ? newtitle : `${title}, ${newtitle}`;
        }, '');
      }
      return '';
    },
  },
  methods: {
    ...mapActions('api', {
      apiDistributor: 'distributor',
    }),
  },
};
</script>

<style></style>
