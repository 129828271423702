<template>
  <v-container v-bind="$attrs" v-on="$listeners">
    <template v-for="(langPim, lang) in langs">
      <flag
        :key="langPim"
        :iso="lang"
        :class="getClasses(value, langPim)"
        style="margin-right: 5px;"
      >
      </flag>
    </template>
  </v-container>
</template>
<script>
import LangBaseMixins from '@/mixins/lang-base';

export default {
  mixins: [LangBaseMixins],
  name: 'AttributeTitleLang',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  methods: {
    // src/components/dialog/langs.vue
    getClasses(value, lang = 'fr') {
      if (!value || !value[lang] || this.hasNullValue(value[lang])) return 'disabled';
      return '';
    },
    hasNullValue(value) {
      if (typeof value === 'string' && value.indexOf('null') !== -1) return true;
      if (typeof value === 'string' && value.trim().match(/^(\d+\s?(cm|mm|dl|cl|%))$/)) return true;
      if (typeof value === 'string' && value.trim().length < 3) return true;
      return false;
    },
  },
};
</script>
