import Vue from 'vue';
import { Core } from '../models';
import { EventBus } from '../helpers/event-bus';

const updateStatus = (item, response) => {
  if (typeof response?.status?.pim !== 'undefined') {
    Vue.set(item.status, 'pim', response?.status?.pim);
  }
  if (typeof response?.status?.marketplace !== 'undefined') {
    Vue.set(item.status, 'marketplace', response?.status?.marketplace);
  }
  Vue.set(item, 'updatedAt', response.updatedAt);
  if (typeof item.variant !== 'undefined' && item.variant.length > 0) {
    item.variant.forEach((variant) => {
      const indexV = response.variant.findIndex(v => v.id === variant.id);
      if (indexV !== -1) {
        updateStatus(variant, response.variant[indexV]);
      }
    });
  }
  if (typeof item.skus !== 'undefined' && item.skus.length > 0) {
    item.skus.forEach((sku) => {
      const indexS = response.skus.findIndex(v => v.id === sku.id);
      if (indexS !== -1) {
        updateStatus(sku, response.skus[indexS]);
      }
    });
  }
};

export default {
  props: {
    endpointType: {
      type: String,
      default: 'product',
    },
  },
  data: () => ({
    dialog: false,
    dialogListOption: false,
    editedItem: {},
    editedIndex: -1,
  }),
  computed: {
    fields() {
      return this.$store.getters['api/attribute/fields'];
    },
  },
  methods: {
    index(item) {
      return (row) => {
        // if errors occurent in a array loop.
        // findIndex doesnt throw the error but cut the loop
        if (
          typeof item.remoteCode !== 'undefined'
          && row.remoteCode
          && row.remoteCode === item.remoteCode
        ) {
          return true;
        }
        if (typeof item.vueKey !== 'undefined' && row.vueKey && row.vueKey === item.vueKey) {
          return true;
        }
        if (typeof item.id !== 'undefined' && row.id && row.id === item.id) {
          return true;
        }
        if (typeof item.name !== 'undefined' && row.name && row.name === item.name) {
          return true;
        }
        return false;
      };
    },
    editItem(item, dialog = true) {
      Vue.$log.debug('mixin.editbase.edititem.started', item);
      this.editedIndex = this.items.findIndex(this.index(item));
      if (item instanceof Core) {
        this.editedItem = item;
      } else {
        this.editedItem = Object.assign({}, item);
      }
      if (typeof this.hookDialog === 'function') {
        this.editedItem = this.hookDialog(this.editedItem);
      }
      this.dialog = dialog;
    },
    deleteItem(item) {
      Vue.$log.debug('mixin.editbase.deleteitem.started', item);
      const index = this.items.findIndex(this.index(item));
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure you want to delete this item?')) {
        this.items.splice(index, 1);
        this.$emit('delete:item', item);
        EventBus.$emit('onCollectionUpdate');
      }
    },
    saveItem(done = () => {}) {
      Vue.$log.debug('mixin.editbase.saveitem.started', this.editedItem);
      const { FIELD_PRODUCTNAME } = this.fields;
      if (typeof this.editedItem.vueKey === 'undefined' || !this.editedItem.vueKey) {
        this.editedItem.vueKey = this.generateVueKey(this.editedItem[FIELD_PRODUCTNAME]);
      }
      if (this.editedIndex === -1) {
        this.items.push(this.editedItem);
      } else {
        Vue.set(this.items, this.editedIndex, this.editedItem);
      }
      this.$emit('update:item', this.editedItem);
      this.closeItem(done);
    },
    closeItem(done = () => {}) {
      this.dialog = false;
      // reset data
      setTimeout(() => {
        Vue.$log.debug('mixin.editbase.closeitem.started');
        if (typeof this.defaultItem.clone === 'function') {
          this.editedItem = this.defaultItem.clone();
        } else {
          this.editedItem = Object.assign({}, this.defaultItem);
        }
        this.editedIndex = -1;
        done();
      }, 300);
    },
    syncItem(item) {
      Vue.$log.debug('mixin.editbase.syncitem.started', item);
      const endpoint = `${this.endpointType}/status`;
      this.editedIndex = Array.isArray(this.items) && this.items.findIndex(this.index(item));
      this.$store
        .dispatch(endpoint, {
          id: item.id,
          status: 'done',
          arent: item.parent,
        })
        .then((response) => {
          // need to do that to update only theses attributes
          // if not medias and skus are erased
          if (Array.isArray(this.items) && this.editedIndex !== -1) {
            updateStatus(this.items[this.editedIndex], response);
          } else {
            updateStatus(item, response);
          }
          EventBus.$emit('onCollectionUpdate');
        })
        .catch(error => this.notificationError({ message: error.message }))
        .finally(() => {
          this.closeItem();
        });
    },
    active(item) {
      Vue.$log.debug('mixin.editbase.enabled.started', item);
      const endpoint = `${this.endpointType}/active`;
      this.editedIndex = Array.isArray(this.items) && this.items.findIndex(this.index(item));
      this.$store
        .dispatch(endpoint, {
          id: item.id,
          active: item.active,
          parent: item.parent,
        })
        .then((response) => {
          if (Array.isArray(this.items) && this.editedIndex !== -1) {
            // need to do that to update only theses attributes
            // if not medias and skus are erased
            this.items[this.editedIndex].active = response.active;
            this.items[this.editedIndex].updatedAt = response.updatedAt;
          } else {
            Vue.set(item, 'active', response.active);
            Vue.set(item, 'updatedAt', response.updatedAt);
          }
          EventBus.$emit('onCollectionUpdate');
          this.closeItem();
        })
        .catch(error => this.notificationError({ message: error.message }));
    },
    sealed(item) {
      Vue.$log.debug('mixin.editbase.enabled.started', item);
      const endpoint = `${this.endpointType}/sealed`;
      this.editedIndex = Array.isArray(this.items) && this.items.findIndex(this.index(item));
      this.$store
        .dispatch(endpoint, {
          id: item.id,
          sealed: item.sealed,
          parent: item.parent,
        })
        .then((response) => {
          // need to do that to seal only theses attributes
          // if not medias and skus are erased
          if (Array.isArray(this.items) && this.editedIndex !== -1) {
            this.items[this.editedIndex].sealed = response.sealed;
            this.items[this.editedIndex].updatedAt = response.updatedAt;
          } else {
            Vue.set(item, 'sealed', response.sealed);
            Vue.set(item, 'updatedAt', response.updatedAt);
          }
          EventBus.$emit('onCollectionUpdate');
          this.closeItem();
        })
        .catch(error => this.notificationError({ message: error.message }));
    },
    /**
     * generate vue key
     * fix duplicate keys detected on vuejs
     *
     * @return {string} vue key
     */
    generateVueKey(hash = 'csv') {
      return `tempkey-${hash}-${Math.floor(Math.random() * 999999999) + 1}`;
    },
    openListOptions(item) {
      Vue.$log.debug('mixin.editbase.edititem.started', item);
      this.editedIndex = Array.isArray(this.items) && this.items.findIndex(this.index(item));
      if (item instanceof Core) {
        this.editedItem = item;
      } else {
        this.editedItem = { ...item };
      }
      this.dialogListOption = true;
    },
  },
};
