// https://vuex.vuejs.org/en/mutations.html
import Vue from 'vue';

export default {
  set_edit(state, payload) {
    state.currentImportEdit = payload;
  },
  set_edit_reset(state) {
    state.currentImportEdit = Object.assign({}, state.editItemDefault);
  },
  set_imports(state, imports) {
    state.imports = imports;
  },
  set_import(state, importData) {
    state.import = importData;
    state.importMeta = {
      name: importData.name,
      brandId: importData.metaData.item.brandId,
      distributorId: importData.metaData.item.distributorId,
      sealed: importData.sealed,
    };
    if (importData.metaData && typeof importData.metaData.files !== 'undefined') {
      state.files = importData.metaData.files;
    }
  },
  set_rows(state, rows) {
    state.rows = rows;
  },
  set_files(state, files) {
    state.files = files;
  },
  add_files(state, files) {
    state.files = [...state.files, ...files];
  },
  set_products(state, products) {
    state.products = products;
  },
  set_variant_keys(state, variantsKey) {
    state.variantsKey = variantsKey;
  },
  set_product(state, payload) {
    const { products } = state;
    Vue.set(products, payload.index, payload.product);
    state.products = products;
  },
  set_state_imports(state, status) {
    state.state.imports = status;
  },
  set_medias_imported(state, medias) {
    state.mediasImported = medias;
  },
};
