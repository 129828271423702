<template>
  <v-autocomplete
    class="attribute-country"
    v-model="valueAttribute"
    :items="states"
    menu-props="auto"
    item-text="name"
    item-value="key"
    :label="label || 'Choose your country'"
    hint="Choose your country"
    persistent-hint
    :messages="getDefaultValues()"
    :value-comparator="compareValue"
    :loading="isCompletudeRequired(bindAttribute, currentIssues)"
  >
  </v-autocomplete>
</template>
<script>
import AttributeBaseMixins from '@/mixins/attribute-base';
import CompletudeBaseMixins from '@/mixins/completude-base';
import CountrySubMixins from '@/mixins/config/country-sub-base';

export default {
  mixins: [AttributeBaseMixins, CompletudeBaseMixins, CountrySubMixins],
  name: 'attributeBrand',
  computed: {
    itemsValue() {
      return this.states;
    },
  },
  methods: {
    compareValue(selected, current) {
      if (![selected, current].every(v => typeof v === 'string')) {
        return false;
      }
      return selected.slugify() === current.slugify();
    },
  },
};
</script>
