<template>
  <div>
    <div v-if="!hasRight('pimapp.v2:pim:variant:changefamily:write')">{{ titleName }}</div>
    <v-edit-dialog :return-value="item[property]" large v-else lazy @save="saveInline">
      <div>{{ titleName }}</div>
      <core-error v-if="item.errors" :property="property" :errors="item.errors[property]" />
      <template v-slot:input>
        <v-select
          v-bind:value="item[property]"
          v-on:change="newObject = $event"
          :items="itemsValue"
          menu-props="auto"
          hide-details
          item-text="name"
          item-value="id"
          :label="labelName"
        ></v-select>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import DialogBaseMixins from '@/mixins/dialog-base';
import ProfileMixins from '@/mixins/profile-base';
import { EventBus } from '@/helpers/event-bus';

export default {
  mixins: [DialogBaseMixins, ProfileMixins],
  data: () => ({
    type: 'family',
    endpoint: 'variant/changeFamily',
  }),
  created() {
    this.apiFamily();
  },
  methods: {
    ...mapActions('api', {
      apiFamily: 'family',
    }),
    saveInline() {
      const namespace = `components.dialog.family.${this.item[this.property]}.saveinline`;
      this.$log.info(`${namespace}.started`);
      if (this.isClicked) {
        return null;
      }
      const isValid = true;
      this.isClicked = true;
      if (!isValid) {
        this.showError(true);
        this.$log.error(`${namespace}.error`);
      }
      const isNotNew = this.item.id > 0;
      if (!isNotNew && this.saveOnApi) {
        // for some vue constraints, we can add Mixins global here
        // we need to add the mixins directly in the component parent
        this.defaultValues();
        return this.notificationError({ message: this.messageError });
      }
      // on some component dialog, newObject is not setted
      if (this.newObject) {
        this.$log.debug(`${namespace}.newobject`);
        this.valueAttribute = this.newObject;
      }
      // for some dialog component like textarea we use directement v-model and not v-on:change
      // This portion code is a hack because for some unknow reason
      // on dialog-textarea (att_description_rc) attribute_temp.att_description_rc is not
      // synchronized with attribute.dataJson.att_description_rc the first time.
      // We need to force it before sending it in the database
      if (
        typeof this.keyAttr !== 'undefined'
        && this.keyAttr
        && typeof this.item.attributes !== 'undefined'
      ) {
        this.$log.debug(
          `${namespace}.property.${this.property}.keyattr.${this.keyAttr}`,
          this.item[this.property][this.keyAttr],
        );
        this.item.attributes.forEach((attribute) => {
          if (typeof attribute.dataJson[this.keyAttr] !== 'undefined') {
            Vue.set(attribute.dataJson, this.keyAttr, this.item[this.property][this.keyAttr]);
          }
        });
      }
      const data = this.item.toObject();
      if ((this.saveOnApi === false && !isNotNew) || !isValid) {
        this.$log.info(`${namespace}.noapi.saving`);
        this.defaultValues();
        return this.item;
      }
      this.$log.info(`${namespace}.dispatch.wip`, data);
      return (
        this.$store
          .dispatch(this.endpoint, { id: data.id, familyId: data.familyId })
          .then((response) => {
            this.item = response;
            return Promise.resolve(true);
          })
          // for some vue constraints, we can add Mixins global (notification-base) here
          // we need to add the mixins (notification-base) directly in the component parent
          .catch(error => this.throwError(error))
          .finally(() => EventBus.$emit('onCollectionUpdate', () => this.defaultValues()))
      );
    },
  },
};
</script>

<style></style>
