export default {
  computed: {
    currentMarketplace() {
      return this.$store.getters['completude/currentMarketplace'];
    },
    currentFamilyCompletude() {
      const familyCompletude = this.$store.getters['completude/familyCompletude'] || [];
      if (Object.keys(familyCompletude).length === 0) {
        return {};
      }
      if (typeof familyCompletude[this.currentMarketplace] === 'undefined') {
        return {};
      }
      return familyCompletude[this.currentMarketplace].reduce((r, f) => {
        // eslint-disable-next-line no-param-reassign
        r[f.bind] = f;
        return r;
      }, {});
    },
    currentCompletude() {
      const completudes = this.$store.getters['completude/completudes'];
      const variantId = this.$store.getters['completude/currentVariantId'];
      const completude = completudes.find(c => c.variantId === variantId);
      if (completude) {
        return completude;
      }
      if (typeof this.item !== 'undefined') {
        return this.item.completude || {};
      }
      return {};
    },
    currentIssues() {
      if (!this.currentMarketplace || !this.currentCompletude || typeof this.currentCompletude[this.currentMarketplace] === 'undefined') return [];
      return this.currentCompletude[this.currentMarketplace].issues || [];
    },
  },
  methods: {
    isCompletudeRequired(bind, errors = []) {
      if (!this.currentFamilyCompletude || !this.currentMarketplace) return undefined;
      if (this.currentFamilyCompletude[bind] && errors.length > 0 && errors.includes(bind)) {
        return this.getCompletudeColor('error');
      }
      if (this.currentFamilyCompletude[bind]) {
        return this.getCompletudeColor(this.currentMarketplace);
      }
      return undefined;
    },
    getCompletudeColor(marketplaceName = null) {
      if (!marketplaceName) return undefined;
      return {
        default: '#2196f3',
        zalando: '#009688',
        laRedoute: '#3f51b5',
        amazon: '#673ab7',
        error: 'red',
      }[marketplaceName];
    },
    setMarketplace(marketplaceName) {
      return this.$store.commit('completude/set_marketplace', marketplaceName);
    },
    setCurrent(variantId) {
      return this.$store.commit('completude/set_current', variantId);
    },
    getPercent(marketplaceCompletude = 'default') {
      try {
        if (this.currentCompletude && Object.keys(this.currentCompletude).length > 0) {
          return this.currentCompletude[marketplaceCompletude].percent;
        }
      } catch (e) {} // eslint-disable-line no-empty
      return 0;
    },
  },
};
