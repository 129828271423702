// https://vuex.vuejs.org/en/mutations.html

export default {
  set_query(state, query) {
    state.query = query;
  },
  set_types(state, types) {
    state.types = types;
  },
};
