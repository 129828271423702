/* eslint func-names:0 */
/* eslint no-param-reassign:0 */

import { Media } from '../models';

const Pim = {};
let count = 0;

/**
 * Could also use the link-click method.
 * @param  {String} url [description]
 */
const downloadUrl = function downloadUrl(url) {
  // eslint-disable-next-line no-plusplus
  const hiddenIFrameID = `hiddenDownloader${count++}`;
  const iframe = document.createElement('iframe');
  iframe.id = hiddenIFrameID;
  iframe.style.display = 'none';
  document.body.appendChild(iframe);
  iframe.src = url.replace('/tmp/storage/', '/storage/');
};

Pim.install = function (Vue, options = {}) {
  const {} = options; // eslint-disable-line no-empty-pattern
  if (typeof Vue.$log === 'undefined') {
    Vue.$log = console;
  }
  // add global method or property
  Vue.pimUrl = Media.$url;

  // 4. add an instance method
  Vue.prototype.$pimUrl = function (media, alias = false) {
    try {
      if (!media) {
        throw new TypeError('plugins.pim.pimurl.media.empty');
      }
      const mediaObj = Media.create(media);
      return mediaObj.url(alias);
    } catch (err) {
      // Vue.$log.warn(err.message);
      // Vue.$log.warn(media);
      return false;
    }
  };

  Vue.downloadUrl = downloadUrl;
};

export default Pim;
