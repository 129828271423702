<template>
  <v-container v-bind="$attrs" v-on="$listeners">
    <div v-for="(langPim, lang) in langs" :key="langPim">
      <flag v-if="hasValue(value, langPim)" :iso="lang"></flag>
      {{ getValue(value, langPim) }}
    </div>
  </v-container>
</template>
<script>
import LangBaseMixins from '@/mixins/lang-base';

export default {
  mixins: [LangBaseMixins],
  name: 'AttributeTitleLang',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  methods: {
    // src/components/dialog/langs.vue
    hasValue(value, lang = 'fr') {
      if (!value) return false;
      return !!value[lang] || false;
    },
    // src/components/dialog/langs.vue
    getValue(value, lang = 'fr') {
      if (!value) return '';
      if (lang === 'fr') {
        return value.fra || value.fr;
      }
      return value[lang] || null;
    },
  },
};
</script>
