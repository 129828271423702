// https://vuex.vuejs.org/en/mutations.html

const getKey = (key) => {
  if (key) return key.slugify();
  return null;
};

export default {
  set_loaded(state, isLoaded) {
    state.loaded = isLoaded;
  },
  set_countries(state, countries) {
    state.countries = countries;
  },
  set_countries_by_key(state, countries) {
    state.countries_by_key = countries.reduce((r, c) => {
      const mainKey = getKey(c.key);
      const keyFr = getKey(c.translations.fr);
      const keyEn = getKey(c.translations.en);
      r[mainKey] = c;
      if (keyFr && keyFr !== mainKey) r[keyFr] = c;
      if (keyEn && keyEn !== mainKey) r[keyEn] = c;
      return r;
    }, {});
  },
  set_marketplaces(state, marketplaces) {
    state.marketplaces = marketplaces;
  },
  set_attributes_fields(state, fields) {
    state.fields = fields;
  },
  set_attributes_profiles(state, profiles) {
    state.profiles = profiles;
  },
  set_attributes_type_entities(state, typeEntities) {
    state.type_entities = typeEntities;
  },
  set_attributes_type_hinting(state, typeHinting) {
    state.type_hinting = typeHinting;
  },
  set_config(state, config) {
    state.config = config;
  },
};
