import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const theme = {
  light: true,
  themes: {
    //   light: {
    //     primary: '#3f51b5',
    //     secondary: '#b0bec5',
    //     accent: '#8c9eff',
    //     error: '#b71c1c',
    //   },
    light: {
      primary: '#673ab7',
      secondary: '#3f51b5',
      accent: '#cddc39',
      error: '#f44336',
      warning: '#ff5722',
      info: '#2196f3',
      success: '#009688',
    },
  },
};

export default new Vuetify({
  // no need because by default on vuetify
  // it's already loaded
  iconfont: 'mdi',
  theme,
});
