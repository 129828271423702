<template>
  <v-flex class="d-flex justify-center" :class="dropActiveClass()">
    <div v-show="$refs.upload" class="drop-active">
      <h3>Drop here your image file</h3>
      <div v-for="file in files" :key="`${file.name}-${file.version}`">
        <v-alert type="success" v-if="!file.success && !file.error" dense text>
          {{ file.name }} ({{ file.progress }} %)
        </v-alert>
        <v-alert type="success" v-else-if="file.success" dense text>
          {{ file.name }}
        </v-alert>
        <v-alert type="error" v-else-if="file.error" dense text>
          {{ file.error }}
        </v-alert>
      </div>
    </div>
    <file-upload
      class="btn btn-primary dropdown-toggle"
      :post-action="postAction"
      :extensions="extensions"
      :accept="accept"
      :multiple="multiple"
      :thread="thread < 1 ? 1 : thread > 5 ? 5 : thread"
      :headers="headers"
      :data="data"
      :drop="drop"
      v-model="files"
      @input-filter="inputFilter"
      @input-file="inputFile"
      ref="upload"
    >
      <v-btn color="blue" fab dark justify-center class="mx-2">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </file-upload>
  </v-flex>
</template>

<script>
/* eslint no-param-reassign: 0 */
import FileUpload from 'vue-upload-component';
import Mixins from '../../mixins';

const baseURL = process.env.VUE_APP_PIM_BASEURL || 'http://localhost:3128/';

export default {
  name: 'ImportUpload',
  components: {
    FileUpload,
  },
  props: {
    variantId: {
      type: [Number, Boolean], // String, Number, Boolean, Function, Object, Array
      required: false,
      default: null,
    },
    order: {
      type: [Number, Boolean],
      required: false,
      default: null,
    },
    marketplace: {
      type: [String, Boolean],
      required: false,
      default: null,
    },
  },
  mixins: Mixins,
  data: () => ({
    files: [],
    accept: 'text/plain',
    extensions: 'gif,jpeg,jpg,tiff,png,webp,bmp',
    postAction: `${baseURL}/api/v1/pim/media/upload`,
    multiple: false,
    drop: true,
    data: {},
    thread: 1,
  }),
  mounted() {
    this.updatePostAction();
  },
  watch: {
    order() {
      this.updatePostAction();
    },
    marketplace() {
      this.updatePostAction();
    },
  },
  computed: {
    headers() {
      return {
        Authorization: `Bearer ${this.$auth.accessToken}`,
      };
    },
  },
  methods: {
    updatePostAction() {
      this.postAction = `${baseURL}/api/v1/pim/media/upload/${this.variantId}/${this.marketplace
        || 'DEFAULT'}/${this.order || 0}`;
    },
    dropActiveClass() {
      if (this.$refs.upload && this.$refs.upload.dropActive) {
        return 'blue lighten-4 elevation-24';
      }
      return '';
    },
    // eslint-disable-next-line consistent-return
    inputFilter: function inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        // Will not be added to files
        if (!/\.(gif|jpe?g|tiff|png|webp|bmp)$/i.test(newFile.name)) {
          this.notificationError('The file format is not good');
          return prevent();
        }
      }

      if (newFile && oldFile) {
        // Update file
        // Increase the version number
        if (!newFile.version) {
          newFile.version = 0;
        }
        newFile.version += 1;
      }

      if (!newFile && oldFile) {
        // Remove file

        // Refused to remove the file
        return prevent();
      }
    },
    /**
     * Has changed
     * @param  Object|undefined   newFile   Read only
     * @param  Object|undefined   oldFile   Read only
     * @return undefined
     */
    inputFile: function inputFile(newFile, oldFile) {
      // eslint-disable-line consistent-return
      // Automatically activate upload
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (!this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        try {
          const res = JSON.parse(newFile.xhr.response);
          // Get update / response data
          if (newFile.xhr && [200, 201].indexOf(newFile.xhr.status) !== -1) {
            this.$emit('media:upload', res);
          }
        } catch (err) {
          this.notificationError({ message: 'XHR  has failed' });
        }
      }
    },
  },
};
</script>

<style lang="css" scoped></style>
