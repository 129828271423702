<template>
  <v-container fill-height fluid>
    <v-layout wrap>
      <v-flex md12>
        <material-card
          color="blue"
          title="Evaluation grid for your collection"
          text="Here is a subtitle for this table"
        >
          <template v-slot:dialog>
            <dialog-product
              v-model="editedItem"
              :dialog="dialog"
              @onSave="saveItem"
              @onClose="closeItem"
            >
            </dialog-product>
            <dialog-settings :dialog="dialog"> </dialog-settings>
          </template>
          <v-text-field v-model="search" label="Search" hide-details> </v-text-field>
          <v-spacer></v-spacer>
          <core-alert></core-alert>
          <v-data-table
            :headers="filterByColumn()"
            class="elevation-2"
            :items="items"
            :server-items-length="itemsCount"
            :options.sync="itemsOptions"
            :loading="loading"
            loading-text="Loading... Please wait"
            :search="search"
            :show-expand="settings.showExpand"
            :single-expand="settings.singleExpand"
            :dense="settings.dense"
            :footer-props="{ 'items-per-page-options': [15, 30, 50, 100, -1] }"
          >
            <template v-slot:item.image="{ item }">
              <core-avatar
                v-if="typeof item.image !== 'undefined' || item.image !== null"
                :image="$pimUrl(item.image, 'sq60')"
              />
            </template>
            <template v-slot:item.name="{ item }">
              <dialog-name
                :item="item"
                property="name"
                labelName="Choose your name"
                :updateChildren="true"
              >
              </dialog-name>
            </template>
            <template v-slot:item.infos.att_titre_produit_r="{ item }">
              <dialog-textarea
                :item="item"
                property="infos"
                keyAttr="att_titre_produit_r"
                labelName="Write a product title"
                :saveOnApi="saveOnApi"
              >
              </dialog-textarea>
            </template>
            <template v-slot:item.from="{ item }">
              <dialog-country :item="item" property="from" labelName="Choose your country">
              </dialog-country>
            </template>
            <template v-slot:item.brandId="{ item }">
              <dialog-brand :item="item" property="brandId" labelName="Choose your brand">
              </dialog-brand>
            </template>
            <template v-slot:item.distributorIds="{ item }">
              <dialog-distributor
                :item="item"
                property="distributorIds"
                labelName="Choose your supplier"
              >
              </dialog-distributor>
            </template>
            <template v-slot:item.marketplace="{ item }">
              <v-chip :color="getColor(item.marketplace)" dark>{{ item.marketplace }}</v-chip>
            </template>
            <template v-slot:item.quable="{ item }">
              <v-chip :color="getColor(item.quable)" dark>{{ item.quable }}</v-chip>
            </template>
            <template v-slot:item.classificationId="{ item }">
              <dialog-classification
                :item="item"
                property="classificationId"
                labelName="Choose your classification"
              >
              </dialog-classification>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <pim-variant-view :items="item.variant" v-if="item.variant"></pim-variant-view>
              </td>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil-circle
              </v-icon>
              <v-icon small @click="deleteItem(item)">
                mdi-delete-circle
              </v-icon>
              <v-icon small @click="syncItem(item)">
                mdi-sync-circle
              </v-icon>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import ItemsBaseMixins from '../../mixins/items-base';
import EditMixins from '../../mixins/edit-base';
import Mixins from '../../mixins';

export default {
  mixins: [ItemsBaseMixins, EditMixins, ...Mixins],
  data: () => ({
    search: '',
    itemsCount: 0,
    itemsOptions: {},
    loading: true,
    defaultItem: {},
  }),
  watch: {
    itemsOptions: {
      handler() {
        this.listProductsWithCollectionId().then(({ items, itemsCount, itemsPerPage }) => {
          this.items = items;
          this.itemsCount = itemsCount;
          this.itemsPerPage = itemsPerPage;
        });
      },
      deep: true,
    },
    search() {
      this.listProductsWithCollectionId();
    },
  },
  created() {
    this.itemsOptions = this.itemsOptionsDefault();
  },
  computed: {
    settings() {
      return this.$store.getters['settings/settings'];
    },
    headers() {
      return this.$store.getters['settings/headers'].product;
    },
  },
  mounted() {
    this.apiDistributor();
    this.apiBrand();
    this.apiClassification();
  },
  methods: {
    ...mapActions('api', {
      apiBrand: 'brand',
      apiClassification: 'classification',
      apiDistributor: 'distributor',
    }),
    ...mapActions('import', ['products']),
    listProductsWithCollectionId() {
      this.loading = true;
      return new Promise((resolve) => {
        const { page, itemsPerPage } = this.itemsOptions;
        const params = {
          id: this.$route.params.importId,
          params: {
            limit: parseInt(itemsPerPage, 10),
            page,
          },
        };
        this.$router
          .push({
            path: `/pim/import/${this.$route.params.importId}/products`,
            query: {
              limit: params.params.limit,
              page: params.params.page,
            },
          })
          .catch(() => {}); // NavigationDuplicated error quiet
        return this.products(params).then((data) => {
          const { items, itemsCount } = this.options(
            data.result.products,
            data.parameters.count,
            this.itemsOptions,
          );
          this.loading = false;
          resolve({
            items,
            itemsCount,
            itemsPerPage,
          });
        });
      });
    },
    saveItem() {
      this.loading = true;
      let endpoint = 'product/new';
      const isOld = this.editedIndex > -1;
      if (isOld) {
        endpoint = 'product/update';
      }
      this.$store
        .dispatch(endpoint, this.editedItem)
        .then((response) => {
          if (isOld) {
            Object.assign(this.items[this.editedIndex], response);
          } else {
            this.items.push(response);
          }
        })
        .catch(error => this.throwError(error))
        .finally(() => {
          this.loading = false;
          this.closeItem();
        });
    },
  },
};
</script>

<style></style>
