<template>
  <v-autocomplete
    :chips="chips"
    :filled="filled"
    :item-value="itemValue"
    :items="itemsValue"
    :label="label || 'Pick your brand'"
    :multiple="multiple"
    hint="Pick your brand"
    :messages="getDefaultValues()"
    :loading="isCompletudeRequired(bindAttribute, currentIssues)"
    item-text="name"
    menu-props="auto"
    persistent-hint
    v-model="valueAttribute"
  >
  </v-autocomplete>
</template>
<script>
import AttributeBaseMixins from '@/mixins/attribute-base';
import CompletudeBaseMixins from '@/mixins/completude-base';
import BrandSubBaseMixins from '@/mixins/config/brand-sub-base';

export default {
  mixins: [AttributeBaseMixins, CompletudeBaseMixins, BrandSubBaseMixins],
  name: 'attributeBrand',
  data: () => ({}),
  computed: {
    itemsValue() {
      return this.brands;
    },
    titleName() {
      return 'src/components/attribute/brand.vue';
    },
  },
  methods: {},
};
</script>
