<template>
  <v-card>
    <v-card-text>
      <v-data-table
        dense
        :headers="headers"
        :items="value.options.family_attributes">
        <template v-slot:item.attributesAssociated="{ item }">
          <div>
            <template v-for="n in item.attributesAssociated">
              <attribute-link
                :value="n.remoteCode"
                :key="n.remoteCode"
                target='_blank'
                >
                <v-chip
                  class="ma-2"
                  color="blue"
                  text-color="white"
                  dark
                  small
                  :style="{ cursor: 'pointer'}"
                >
                  <v-avatar
                    left
                    class="blue darken-4"
                  >
                    A
                  </v-avatar>
                  <template v-if="n.name">
                    {{ n.name }}
                  </template>
                  <slot></slot>
                </v-chip>
              </attribute-link>
            </template>
            <v-icon small class="mr-2" @click="editAFmAssociation(item.attributesAssociated, item.remoteCode)">
              mdi-pencil-circle
            </v-icon>
          </div>
        </template>
      </v-data-table>
      <dialog-list-attributes
        v-model="editedItem"
        :dialog="dialog"
        v-on:update:dialog="dialog = $event"
        @onSave="saveAFmAssociation"
        @onClose="closeAFmAssociation"
      >
      </dialog-list-attributes>
    </v-card-text>
  </v-card>
</template>

<script>

import ClassificationFromMarketplace from '@/models/attribute/classificationfrommarketplace';
import { EventBus } from '@/helpers/event-bus';
import LangSubMixins from '@/mixins/config/lang-sub-base';
import AttributeLink from '@/components/link/attribute';

const headers = [{
    text: 'Label',
    align: 'start',
    sortable: false,
    value: 'label',
    lang: true,
  },
  { text: 'Code Marketplace', value: 'code' },
  { text: 'Remote Code', value: 'remoteCode' },
  { text: 'Pim attributes', value: 'attributesAssociated' },
];
const namespace = 'components.attributelist.table-afm';

export default {

  name: 'table-afm',
  props: {
    value: {
      type: [ClassificationFromMarketplace, Object],
      default: null,
    },
  },
  mixins: [LangSubMixins],
  components: {
    AttributeLink,
  },
  data: () => ({
    editedItem: [],
    editedIndex: null,
    dialog: false,
  }),
  computed: {
    headers() {
      const currentLang = this.currentLang || process.env.VUE_APP_I18N_LOCALE || 'fr';
      return headers.map((h) => {
        if (h.lang) {
          h.value = `label.${currentLang}`;
        }
        return h;
      });
    },
  },
  methods: {
    editAFmAssociation(attributes, remoteCode = null) {
      this.editedItem = attributes;
      this.editedIndex = remoteCode;
      this.dialog = true;
    },
    saveAFmAssociation(attributes) {
      if (this.editedIndex === null) {
        throw new TypeError(`${namespace}.saveAFmAssociation.editedindex.is.null`);
      }
      EventBus.$emit('update-classification:afm', {
        afmRemoteCode: this.editedIndex,
        attributes,
      });
      setTimeout(() => {
        this.dialog = false;
        this.editedIndex = null;
        this.editedItem = [];
      }, 300);
    },
    closeAFmAssociation(done = () => {}) {
      this.dialog = false;
      // reset data
      setTimeout(() => {
        this.editedIndex = null;
        this.editedItem = [];
        done();
      }, 300);
    },
  },
};
</script>

<style lang="css" scoped>
</style>
