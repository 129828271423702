<template>
  <v-form v-model="valid" v-if="attributeValue">
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="attributeValue.name"
          label="Attribute Value id"
          required
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="attributeValue.remoteCode"
          label="Attribute Value Remote Code"
          required
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          v-model="marketplacesSelected"
          :items="marketplaces"
          label="Select"
          item-text="name"
          item-value="remoteCode"
          item-color="#283593"
          multiple
          chips
          deletable-chips
          small-chips
          hint="Pick your favorite states"
          persistent-hint
        ></v-select>
      </v-col>
    </v-row>
    <template v-for="marketplaceSelected in marketplacesSelected">
      <form-afm
        v-model="attributeValue"
        :marketplace="marketplaceSelected"
        :key="marketplaceSelected"
        >
        {{ marketplaceSelected }}
      </form-afm>
    </template>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormAfm from '@/components/attributelist/form-afm';

export default {

  name: 'AttributeValueByIdView',
  components: {
    FormAfm,
  },
  data: () => ({
    marketplacesSelected: [],
  }),
  //   Only the itemId route param is changing. In this situation,
  //   since the same route components are being used before and
  //   after the transition, vue-router will reuse the existing
  //   route components; they don't get destroyed and recreated.
  //   So if you're loading data in the component created or mounted
  //   hooks then they won't be called again after the route changes.
  //   Instead you need to use beforeRouteUpdate or watch $route for changes.
  //   If you want to force the component to be destroyed and recreated
  //   (not recommended) then you need to key the <router-view> on the
  //   route:
  created() {
    this.fetchByRemoteCode({
      remoteCode: this.$route.params.remoteCode,
      aRemoteCode: this.$route.params.aRemoteCode,
    });
  },
  computed: {
    attributeValue() {
      const av = this.$store.getters['attribute-values/attributeValue'];
      if (!av) return null;
      // I dont know if I should call getter here or retrieve the data from the store
      this.marketplacesSelected = av.getMarketplaces();
      return av;
    },
    ...mapGetters('config', ['marketplaces']),
  },
  methods: {
    ...mapActions('attribute-values', { fetchByRemoteCode: 'get' }),
    validate() {
      console.log('this.attribute', this.attribute);
    },
  },
};
</script>

<style lang="css" scoped>
</style>
