<template>
  <v-footer id="core-footer">
    <div class="footer-items">
      <span v-for="link in links" :key="link.name">
        <a :href="link.Link" class="footer-links">{{ link.name }}</a>
      </span>
    </div>
    <v-spacer />
    <span class="font-weight-light copyright">
      &copy;Theagent
      {{ new Date().getFullYear() }}
    </span>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    links: [
      { name: 'Home', Link: '/' },
      { name: 'Backoffice', Link: process.env.VUE_APP_THEAGENT_BO_URL },
      { name: 'Client FrontOffice', Link: process.env.VUE_APP_THEAGENT_FO_URL },
      { name: 'Website', Link: process.env.VUE_APP_THEAGENT_URL },
      { name: 'Docs Quable', Link: 'https://docs.quable.com' },
      { name: 'Quable', Link: process.env.VUE_APP_QUABLE_URL },
    ],
  }),
};
</script>

<style>
#core-footer {
  z-index: 0;
}
</style>
