// https://vuex.vuejs.org/en/actions.html
import Vue from 'vue';
import axios from 'axios';

import { MediaList } from '../../../models';

const BASE_URI = '/api/v1/pim/media';

export default {
  list({ commit }, payload) {
    Vue.$log.info('store.media.actions.list.started');
    return new Promise((resolve) => {
      Vue.$log.log('store.media.actions.payload', payload);
      axios
        .get(BASE_URI, payload)
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError('/api/v1/pim/media empty');
          }
          const mediaList = MediaList.create(response.data.result);
          commit('set_medias', mediaList);
          return resolve({
            ...response.data,
            result: mediaList,
          });
        })
        .catch((error) => {
          Vue.$log.info('store.media.actions.list.error', error);
          return resolve({ result: [], parameters: { count: 0 } });
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  new({ commit }, media) {
    Vue.$log.info('store.media.actions.new.started');
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URI, media)
        .then((response) => {
          Vue.$log.log('media has been created');
          commit('set_media', response.data);
          return resolve(response.data);
        })
        .catch(error => reject(error));
    });
  },
  // eslint-disable-next-line no-unused-vars
  update({ commit }, media) {
    Vue.$log.info('store.media.actions.update.started');
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI}/${media.variantId}`, media)
        .then((response) => {
          Vue.$log.log(`media ${media.variantId} has been updated`);
          commit('set_media', response.data);
          return resolve(response.data);
        })
        .catch(error => reject(error));
    });
  },
  // eslint-disable-next-line no-unused-vars
  editing({ commit }, { variantId, medias }) {
    Vue.$log.info('store.media.actions.editing.started');
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI}/${variantId}/editing`, medias)
        .then((response) => {
          Vue.$log.log(`variant ${variantId} for medias has been edited`);
          commit('set_media', response.data);
          return resolve(response.data);
        })
        .catch(error => reject(error));
    });
  },
  async injectInProduct({ commit, rootGetters }, variantMedia) {
    Vue.$log.info('store.media.actions.injectinproduct.started');
    const products = rootGetters['import/products'];
    products.every((p) => {
      const index = p.variant.findIndex(v => v.id === variantMedia.variantId);
      if (index !== -1) {
        Vue.$log.log(`media ${variantMedia.variantID} has been updated`);
        // eslint-disable-next-line no-param-reassign
        variantMedia.medias = MediaList.create(variantMedia.medias, p.variant[index]);
        // eslint-disable-next-line no-param-reassign
        p.variant[index].medias = variantMedia;
        return false;
      }
      return true;
    });
    commit('import/set_products', products, { root: true });
  },
  // eslint-disable-next-line no-unused-vars
  delete({ commit }, payload) {
    Vue.$log.info('store.media.actions.delete.started');
    return new Promise((resolve, reject) => {
      axios
        .delete(`${BASE_URI}/${payload.variantId}/${payload.uniq}`)
        .then((response) => {
          Vue.$log.log(`store.media.actions.delete.${payload.variantId}`);
          commit('set_media', response.data);
          return resolve(response.data);
        })
        .catch(error => reject(error));
    });
  },
  marketplaces({ commit, getters }) {
    Vue.$log.info('store.media.actions.marketplaces.started');
    if (Object.keys(getters.marketplaces).length > 0) {
      return Promise.resolve(getters.marketplaces);
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URI}/marketplaces`)
        .then((response) => {
          commit('set_marketplaces', response.data);
          return resolve(getters.marketplaces);
        })
        .catch(error => reject(error));
    });
  },
};
