/* eslint no-param-reassign:0 */
import { map } from 'bluebird';
import CoreList from './corelist';
import Variant from './variant';

export default class VariantList extends CoreList {
  applyModel(parent) {
    if (this.length > 0) {
      this.forEach((row, index) => {
        if (typeof row === 'object') {
          this.add(Variant.create(row, parent), index);
        }
      });
      this.checkErrors();
    }
    return this;
  }

  getFirstImage() {
    if (this.length === 0) {
      return false;
    }
    // eslint-disable-next-line max-len
    const index = this.findIndex(
      v => v.medias && v.medias.medias && Object.keys(v.medias.medias).length > 0,
    );
    if (index === -1) {
      return false;
    }
    return this[index].getFirstImage();
  }

  checkErrors() {
    if (this.length > 0) {
      let error = {};
      this.forEach((row) => {
        // reset error before
        row.resetErrors();
        if (typeof row.parentError !== 'undefined' && row.parentError) {
          // eslint-disable-next-line no-param-reassign
          row.errors.id = ['errorVariant'];
        }
        error = VariantList.duplicated(row, 'color', error);
        error = VariantList.duplicated(row, 'supplierReference', error);
        if (Object.keys(row.errors).length > 0) {
          if (typeof row.parent === 'undefined') {
            row.parent = {};
          }
          // eslint-disable-next-line no-param-reassign
          row.parent.parentError = true;
        }
      });
    }
  }

  setVariantsName() {
    this.forEach((row) => {
      if (typeof row === 'object') {
        // eslint-disable-next-line no-param-reassign
        row.name = Variant.setName(row.parent.name, row.color);
      }
    });
  }

  async getCompletudes(dispatch) {
    return map(this, variant => variant.getCompletude(dispatch), { concurrency: 5 }).then(
      () => this,
    );
  }
}
