<template>
  <v-container fill-height fluid>
    <v-layout wrap>
      <material-card
        color="green"
        title="Properties"
        text="Select your properties (supplier, import etc...)"
        class="col-lg-12"
      >
        <v-select
          v-model="mainProperty"
          :items="list_properties"
          menu-props="auto"
          hide-details
          item-text="value"
          item-value="key"
          label="Choose your property"
        ></v-select>
      </material-card>
      <keep-alive>
        <component
          :is="component"
          :items="items"
          :mainProperty="mainProperty"
          :itemId="itemId"
          @property:reload="listPropertyValues(mainProperty)"
          v-if="component"
        />
      </keep-alive>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import Mixins from '../../mixins';

export default {
  mixins: Mixins,
  data: () => ({
    itemsOptions: {
      sortBy: [],
      sortDesc: [],
      itemsPerPage: 0,
      page: 0,
    },
    list_properties: [
      {
        value: 'Default',
        key: 'default',
      },
      {
        value: 'Distributors',
        key: 'distributor',
      },
      {
        value: 'Brands',
        key: 'brand',
      },
      {
        value: 'Classifications',
        key: 'classificationTree',
      },
      {
        value: 'Families',
        key: 'family',
      },
      {
        value: 'Sizes',
        key: 'size',
      },
    ],
    mainProperty: 'default',
    items: [],
    itemId: null,
  }),
  created() {
    this.$log.debug('components.pim.propertyview.created');
    const { property = null } = this.$route.query;
    if (property) {
      this.mainProperty = property;
    }
  },
  watch: {
    mainProperty() {
      this.$log.debug('components.pim.propertyview.mainproperty.watched');
      if (typeof this[this.mainProperty] === 'function') {
        return this.listPropertyValues(this.mainProperty, this.itemId);
      }
      this.resetValue();
      return null;
    },
  },
  computed: {
    component() {
      if (!this.mainProperty) {
        return () => import('../propertylist/default');
      }
      // eslint-disable-next-line vue/no-async-in-computed-properties
      return () => import(`../propertylist/${this.mainProperty}`).catch(() => import('../propertylist/default'));
    },
  },
  methods: {
    ...mapActions('api', [
      'brand',
      'classificationTree',
      'family',
      'distributor',
      'size',
    ]),
    resetValue() {
      this.items = [];
      this.$router
        .push({
          name: 'settings.properties',
        })
        .catch(() => {}); // NavigationDuplicated error quiet
    },
    listPropertyValues(propertyValue) {
      this.$log.debug('components.pim.propertyview.listpropertyvalues.started');
      return this[propertyValue](false)
        .then(() => {
          const { itemId = null } = this.$route.query;
          this.items = this.$store.getters['api/list'](propertyValue);
          this.itemId = Number(itemId);
          this.notificationInfo({ message: `${propertyValue} loaded` });
        })
        .catch((err) => {
          this.$log.error(err);
          this.resetValue();
        });
    },
  },
};
</script>

<style></style>
