// https://vuex.vuejs.org/en/actions.html
import Vue from 'vue';
import settingsService from '@/services/settingsstorage';
import theagentStorage from '@/services/theagentstorage';

// The login action passes vuex commit helper that we will use to trigger mutations.
export default {
  save({ commit, getters }, settings) {
    const { sillyMode: debug } = getters.theagent || {};
    if (debug) Vue.$log.info('store.settings.actions.save.started');
    return new Promise((resolve) => {
      settingsService.set(settings);
      commit('set_settings', settings);
      commit('set_profile', settings.profile);
      return resolve(settings);
    });
  },
  saveItem({ commit, getters }, payload) {
    const { sillyMode: debug } = getters.theagent || {};
    if (debug) Vue.$log.info('store.settings.actions.saveitem.started');
    return new Promise((resolve) => {
      settingsService.setItem(payload);
      commit('set_item', payload);
      return resolve(getters.settings);
    });
  },
  saveTheAgent({ commit, getters }, settings) {
    const { sillyMode: debug } = getters.theagent || {};
    if (debug) Vue.$log.info('store.settings.actions.savetheagent.started');
    return new Promise((resolve) => {
      theagentStorage.set(settings);
      commit('set_theagent_settings', theagentStorage.get());
      return resolve(settings);
    });
  },
  clear({ commit, getters }) {
    const { sillyMode: debug } = getters.theagent || {};
    if (debug) Vue.$log.info('store.settings.actions.clear.started');
    return new Promise((resolve) => {
      settingsService.clear();
      commit('set_settings', getters.settingsDefault);
      return resolve({});
    });
  },
};
