// https://vuex.vuejs.org/en/actions.html
import Vue from 'vue';
import axios from 'axios';
import { errorHandler } from '@/store/modules/global_helpers';
import { Product, ProductList } from '../../../models';

const BASE_URI = '/api/v1/pim';
const BASE_URI_V2 = '/api/v2/pim';

export default {
  search({ commit }, payload) {
    Vue.$log.info('store.product.actions.search.started');
    return new Promise((resolve) => {
      Vue.$log.debug('store.product.actions.search.payload', payload);
      axios
        .get(`${BASE_URI}/product/search`, payload)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError('/api/v1/pim/product/search empty');
          }
          const productlist = ProductList.create(response.data.result);
          commit('set_products', productlist);
          return resolve({
            ...response.data,
            result: productlist,
          });
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return resolve({ result: [], parameters: { count: 0 } });
        });
    });
  },
  get({ commit }, id) {
    Vue.$log.info(`store.product.actions.get.${id}.started`);
    return new Promise((resolve) => {
      axios
        .get(`${BASE_URI}/product/${id}`)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`.api.v1.pim.product.${id}.not.found`);
          }
          const product = Product.create(response.data);
          commit('set_product', product);
          return resolve(product);
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return resolve({ result: [], parameters: { count: 0 } });
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  new({ commit }, product) {
    Vue.$log.info('store.product.actions.new.started');
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URI}/product`, product)
        .then((response) => {
          Vue.$log.log('product has been created');
          return resolve(Product.create(response.data));
        })
        .catch(errorHandler(reject));
    });
  },
  // eslint-disable-next-line no-unused-vars
  update({ commit }, product) {
    Vue.$log.info('store.product.actions.update.started');
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI}/product/${product.id}`, product)
        .then((response) => {
          Vue.$log.log(`product ${product.id} has been updated`);
          return resolve(Product.create(response.data));
        })
        .catch(errorHandler(reject));
    });
  },
  // eslint-disable-next-line no-unused-vars
  delete({ commit }, id) {
    Vue.$log.info('store.product.actions.delete.started');
    return new Promise((resolve, reject) => {
      axios
        .delete(`${BASE_URI}/product/${id}`)
        .then((response) => {
          Vue.$log.log(`product ${id} has been deleted`);
          return resolve(response);
        })
        .catch(errorHandler(reject));
    });
  },
  // eslint-disable-next-line no-unused-vars
  status({ commit }, payload) {
    Vue.$log.info('store.product.actions.status.started');
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI}/product/${payload.id}/status/${payload.status}`)
        .then((response) => {
          Vue.$log.log(`product ${payload.id} status has been updated`);
          return resolve(Product.create(response.data));
        })
        .catch(errorHandler(reject));
    });
  },
  // eslint-disable-next-line no-unused-vars
  active({ commit }, payload) {
    Vue.$log.info('store.product.actions.active.started');
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI}/product/${payload.id}/active`, {
          active: payload.active,
        })
        .then((response) => {
          Vue.$log.info(`store.product.actions.active.${payload.id}.updated`);
          return resolve(Product.create(response.data));
        })
        .catch((error) => {
          Vue.$log.error(`store.product.actions.active.${payload.id}.error`, {
            error: error.message,
            stack: error.stack,
          });
          Vue.$log.error(error.message);
          return errorHandler(reject)(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  sealed({ commit }, payload) {
    Vue.$log.info('store.product.actions.sealed.started');
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI}/product/${payload.id}/sealed`, {
          sealed: payload.sealed,
        })
        .then((response) => {
          Vue.$log.info(`store.product.actions.sealed.${payload.id}.updated`);
          return resolve(Product.create(response.data));
        })
        .catch((error) => {
          Vue.$log.error(`store.product.actions.sealed.${payload.id}.error`, {
            error: error.message,
            stack: error.stack,
          });
          Vue.$log.error(error.message);
          return errorHandler(reject)(error);
        });
    });
  },
  duplicate(store, payload) {
    Vue.$log.info('store.product.actions.duplicate.started');
    return new Promise((resolve, reject) => {
      Vue.$log.debug('store.product.actions.duplicate.payload', payload);
      axios
        .post(`${BASE_URI_V2}/product/${payload.id}/duplicate`, payload)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError('store.product.actions.duplicate.response.empty');
          }
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  rename(store, payload) {
    Vue.$log.info('store.product.actions.rename.started');
    return new Promise((resolve, reject) => {
      Vue.$log.debug('store.product.actions.rename.payload', payload);
      axios
        .post(`${BASE_URI_V2}/product/${payload.id}/rename`, payload)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError('store.product.actions.rename.response.empty');
          }
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
};
