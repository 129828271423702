<template>
  <v-dialog v-model="dialog" persistent max-width="960px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn fab dark x-small class="mx-2" v-bind="attrs" v-on="on">
        <v-icon dark>mdi-map</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Traduction : {{ title }}</span>
      </v-card-title>
      <v-card-text>
        <attribute-title-lang v-model="valuesLang"> </attribute-title-lang>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text disabled>
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const namespace = 'components.dialog.langs';

export default {
  props: {
    value: {
      type: [Object, String],
      required: true,
    },
    items: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data: () => ({
    isValid: true,
    dialog: false,
  }),
  computed: {
    title() {
      if (!this.value) return '';
      if (typeof this.value === 'string') return this.value;
      return this.value.fra || this.value.fr || '';
    },
    valuesLang() {
      if (typeof this.value === 'string') {
        this.checkComponentProps();
        if (!this.value) {
          return '';
        }
        const index = this.items.findIndex(v => v.id.slugify() === this.value.slugify());
        if (index === -1) {
          throw new TypeError(`${namespace}.valuelang.${this.value}.cant.be.setted`);
        }
        return this.items[index].name;
      }
      if (Array.isArray(this.value)) {
        this.checkComponentProps();
        return this.value.reduce((result, val) => {
          if (!val) {
            return result;
          }
          const index = this.items.findIndex(v => v.id.slugify() === val.slugify());
          if (index === -1) {
            throw new TypeError(`${namespace}.valuelang.${val}.cant.be.setted.for.array`);
          }
          if (Object.keys(result).length === 0) {
            // eslint-disable-next-line no-param-reassign
            result = this.items[index].name;
          } else {
            const resultDefault = { ...result };
            // eslint-disable-next-line no-param-reassign
            result = Object.keys(result).reduce((r, lang) => {
              if (typeof this.items[index].name[lang] !== 'undefined') {
                // eslint-disable-next-line no-param-reassign
                r[lang] += `, ${this.items[index].name[lang]}`;
              }
              return r;
            }, resultDefault);
          }
          return result;
        }, {});
      }
      if (typeof this.value === 'object') {
        return this.value;
      }
      throw new TypeError(`${namespace}.valuelang.type.not.managed`);
    },
  },
  methods: {
    checkComponentProps() {
      if (this.items.length === 0) {
        throw new TypeError(`${namespace}.checkcomponentprops.items.is.required`);
      }
    },
    save() {
      return this.$emit('save');
    },
    close() {
      return this.$emit('close');
    },
  },
};
</script>

<style></style>
