<template>
  <v-row>
    <v-col cols="10">
      <v-textarea
        v-model="valueIntermediary"
        filled
        :label="label || keyAttr"
        :loading="isCompletudeRequired(bindAttribute, currentIssues)"
        auto-grow
        disabled
      ></v-textarea>
    </v-col>
    <v-col cols="2">
      <dialog-composition v-model="valueIntermediary"
        :label="label || keyAttr"
        :type="attribute.name">
      </dialog-composition>
      <dialog-langs v-if="valueLangs" v-model="valueLangs"></dialog-langs>
      <attribute-map-lang v-model="valueLangs"> </attribute-map-lang>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
import AttributeBaseMixins from '@/mixins/attribute-base';
import LangBaseMixins from '@/mixins/lang-base';
import CompletudeBaseMixins from '../../mixins/completude-base';

export default {
  mixins: [AttributeBaseMixins, LangBaseMixins, CompletudeBaseMixins],
  name: 'attributeSelection',
  props: {
    value: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    isCsv() {
      return typeof this.keyAttr === 'undefined' && (typeof this.value === 'string' || this.value === null);
    },
    valueIntermediary: {
      get() {
        // for Composition in csv mode
        if (this.isCsv) {
          return this.value || '';
        }
        if (!this.value) {
          return '';
        }
        // for att_composition_rc in entity mode
        if (
          typeof this.value[this.keyAttr] === 'string'
          || typeof this.value[this.keyAttr] === 'number'
        ) {
          return this.value[this.keyAttr];
        }
        return this.value[this.keyAttr] ? this.value[this.keyAttr].fr : '';
      },
      set(value) {
        // for Composition in csv mode
        if (this.isCsv) {
          this.$emit('input', value.fr);
        } else {
          // for att_composition_rc in entity mode
          this.value[this.keyAttr] = value;
        }
      },
    },
    ...mapGetters('form/rules', {
      percentRules: 'percent',
    }),
    valueLangs() {
      try {
        if (this.isCsv) {
          return { fr: this.value };
        }
        return this.value[this.keyAttr];
      } catch (err) {
        return this.langs.reduce((r, l) => {
          // eslint-disable-next-line no-param-reassign
          r[l] = '';
          return r;
        }, {});
      }
    },
  },
};
</script>
