<template>
  <v-dialog v-model="openDialog" persistent max-width="960px">
    <template v-slot:activator="{ on: openDialogViaBtn }">
      <v-tooltip left>
        <template v-slot:activator="{ on: tooltip, attrs }">
          <v-btn
            color="green"
            fab
            dark
            absolute
            right
            fixed
            class="mx-2"
            style="bottom: 280px !important;"
            v-bind="attrs"
            v-on="{ ...tooltip, ...openDialogViaBtn }"
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Create a new attribute</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-form v-model="isValid">
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md4>
                <v-text-field v-model="attribute.name" :rules="textRules" label="Name" />
                <v-switch
                  v-if="typeof attribute.active !== 'undefined'"
                  v-model="attribute.active"
                  label="Enabled/Disabled"
                  color="info"
                  hide-details
                ></v-switch>
                <attribute-classification
                  v-if="typeof attribute.parentId !== 'undefined'"
                  ref="classification"
                  :item="attribute"
                  property="parentId"
                  required
                  label="Pick your parent classification"
                />
                <attribute-family
                  v-if="typeof attribute.familyId !== 'undefined'"
                  ref="family"
                  :item="attribute"
                  property="familyId"
                  label="Pick your family (optional)"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-spacer />
        <v-card-actions>
          <v-btn color="grey darken-1" text outlined @click="close">Cancel</v-btn>
          <v-btn color="blue darken-1" dark :disabled="!isValid" @click="save">Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'attribute-base',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    attribute: {
      type: Object,
    },
  },
  data: () => ({
    isValid: true,
    openDialog: false,
  }),
  watch: {
    dialog() {
      this.openDialog = this.dialog;
    },
  },
  computed: {
    ...mapGetters('form/rules', { textRules: 'text' }),
  },
  methods: {
    save() {
      return this.$emit('onSave');
    },
    close() {
      this.openDialog = false;
      // https://vuejs.org/v2/guide/components-custom-events.html#sync-Modifier
      this.$emit('update:dialog', this.openDialog);
      return this.$emit('onClose');
    },
  },
};
</script>
