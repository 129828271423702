/* eslint no-param-reassign:0 */

/**
 * matching color are css based color
 *
 * @type {Object}
 */
const colors = {
  beige: ['beige', 'creme', 'sable'],
  blanchedalmond: ['amande'],
  navajowhite: ['biscuit'],
  ivory: ['ivoire', 'ecru', 'platine'],
  white: ['blanc'],
  blue: ['bleu'],
  darkred: ['bordeaux', 'auburn', 'bourgogne', 'cerise', 'cognac'],
  midnightblue: ['brut', 'lapis'],
  steelblue: ['cobalt', 'denim'],
  azure: ['azure'],
  turquoise: ['turquoise'],
  skyblue: ['ciel'],
  floralwhite: ['ecru'],
  grey: ['gris'],
  darkgrey: ['acier'],
  dimgrey: ['anthracite', 'gunmetal'],
  silver: ['argent'],
  '#48383A': ['reglisse'],
  yellow: ['jaune'],
  lightyellow: ['champagne'],
  mintcream: ['menthe'],
  chartreuse: ['chartreuse'],
  yellowgreen: ['ble'],
  darkolivegreen: ['bronze'],
  forestgreen: ['emeraude', 'sapin'],
  maroon: ['marron', 'cafe'],
  brown: ['brun'],
  firebrick: ['brique', 'fauve'],
  '#985717': ['rouille'],
  burlywood: ['bois'],
  chocolate: ['chocolat', 'camel', 'caramel'],
  saddlebrown: ['cacao', 'cigare', 'havane', 'tabac'],
  '#FBF2B7': ['moka', 'mocha'],
  multicolore: ['multicolore'],
  black: ['noir'],
  pink: ['nude'],
  olive: ['olive'],
  orange: ['orange', 'miel'],
  khaki: ['khaki', 'kaki'],
  gold: ['or', 'curry', 'dore', 'laiton'],
  '#DFAF2C': ['ocre'],
  lavender: ['lavande'],
  darkorange: ['abricot'],
  hotpink: ['rose'],
  fuchsia: ['fuchsia', 'fushia'],
  mediumvioletred: ['framboise', 'lie'],
  coral: ['corail'],
  '#F0C300': ['ambre', 'safran'],
  salmon: ['saumon'],
  darksalmon: ['cuivre'],
  peachpuff: ['peche'],
  red: ['rouge', 'coquelicot', 'grenadine', 'vermeil'],
  '#DB1702': ['vermillon'],
  green: ['vert'],
  violet: ['violet'],
  plum: ['prune', 'lilas'],
  '#C0B5AB': ['taupe'],
  '#C2C3C1': ['tourterelle'],
  purple: ['mauve', 'parme'],
  indigo: ['indigo'],
};

/**
 * @see app/models/color.js
 * @todo refactor
 */
export default {
  methods: {
    convertBackgroundColor(colorFr) {
      if (!colorFr) {
        return 'white';
      }
      colorFr = colorFr.slugify();
      const index = Object.keys(colors).findIndex((color) => {
        if (color === colorFr) {
          return true;
        }
        if (colors[color].indexOf(colorFr) !== -1) {
          return true;
        }
        return false;
      });
      if (index !== -1) {
        return Object.keys(colors)[index];
      }
      return colorFr;
    },
    convertColor(colorFr) {
      colorFr = colorFr.slugify();
      if (colorFr === 'noir') {
        return 'white';
      }
      return 'rgba(0, 0, 0, 0.87)';
    },
  },
};
