// https://vuex.vuejs.org/en/mutations.html

export default {
  set_name(state, name) {
    state.name = name;
  },
  set_files(state, files) {
    state.files = files;
  },
  set_relations(state, relations) {
    state.relations = relations;
  },
  reset(state) {
    state.name = null;
    state.files = [];
    state.relations = [];
  },
};
