/**
 * Thrown when Server cannot throw an error value.
 */
class ModelBase extends Error {
  constructor(name) {
    super(name);
    // Ensure the name of this error is the same as the class name
    this.name = this.constructor.name;

    // This clips the constructor invocation from the stack trace.
    // It's not absolutely essential, but it does make the stack trace a little nicer.
    //  @see Node.js reference (bottom)
    Error.captureStackTrace(this, this.constructor);
  }
}

class CoreError extends ModelBase {}
class CoreListError extends ModelBase {}
class MediaError extends ModelBase {}
class DefaultError extends ModelBase {}

export default {
  $_pim_error: {
    CoreListError,
    CoreError,
    MediaError,
    DefaultError,
  },
};
export {
  CoreListError, CoreError, MediaError, DefaultError,
};
