<template>
  <v-stepper :value="current" alt-labels>
    <v-stepper-header>
      <template v-for="step in steps">
        <v-stepper-step
          :step="step.step"
          :key="`${step.step}-step`"
          :rules="[() => step.rules]"
          :complete="step.complete"
          :editable="isEditable(step.editable)"
          v-on:click="
            $emit('onRollBack', {
              nextStep: step.step,
              editable: isEditable(step.editable)
            })
          "
        >
          {{ $t(step.i18n) }}
          <small v-if="step.rules">{{ step.message }}</small>
        </v-stepper-step>
        <v-divider v-if="steps.length > step.step" :key="`${step.step}-divider`"></v-divider>
      </template>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
import ProfileMixins from '@/mixins/profile-base';

export default {
  name: 'StepperView',
  mixins: [
    ProfileMixins,
  ],
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({}),
  computed: {
    current() {
      return this.$store.getters['step/step'];
    },
    optionsTa() {
      return this.$store.getters['settings/theagent'];
    },
  },
  methods: {
    isEditable(editable = false) {
      return this.hasRight('dashboardapp.v1:button:stepper:read') && editable;
    },
  },
};
</script>

<style lang="css" scoped></style>
