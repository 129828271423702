<template>
  <v-container fluid>
    <v-row align="center" dense>
      <v-col cols="12" md="10" align="center">
        <v-chip :color="sku.sealed ? 'red' : 'black'" dark small>
          {{ sku.sealed ? "sealed" : "non-sealed" }}
        </v-chip>
        <v-chip :color="sku.active ? 'red' : 'black'" dark small>
          {{ sku.active ? "active" : "non-active" }}
        </v-chip>
        <v-chip color="green" dark small> pim.id:{{ sku.id }} </v-chip>
        <v-chip color="green" dark small> pim.remotecode:{{ sku.remoteCode }} </v-chip>
        <v-chip v-if="sku.legacyId" color="green" dark small>
          marketplace.id:{{ sku.legacyId }}
        </v-chip>
        <v-chip color="orange" dark small> pim.createdat:{{ sku.createdAt }} </v-chip>
        <v-chip color="orange" dark small> pim.updatedat:{{ sku.updatedAt }} </v-chip>
        <v-chip color="pink" dark small> pim.variant.id:{{ sku.variantId }} </v-chip>
        <v-chip color="pink" dark small> pim.variant.color:{{ sku.color }} </v-chip>
        <v-chip color="red" dark small> pim.variant.legacy:{{ sku.variantLegacyId }} </v-chip>
      </v-col>
      <v-col cols="12" md="3" align-self="center">
        <material-percent :percent="sku.percent" color="red" circular></material-percent>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="10" md="3">
        <v-text-field
          v-model="sku.name"
          :append-icon="userCanAccess ? undefined : 'mdi-cancel'"
          :readonly="!userCanAccess"
          :rules="textRules"
          :counter="255"
          label="Sku name"
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="10" md="3">
        <v-text-field
          v-model="sku.sku"
          :append-icon="userCanAccess ? undefined : 'mdi-cancel'"
          :readonly="!userCanAccess"
          :rules="textRules"
          :counter="255"
          label="SKU value"
        >
        </v-text-field>
      </v-col>
      <v-col cols="10" md="3">
        <v-text-field
          v-model="sku.ean"
          :rules="barcodeRules"
          counter="13"
          maxlength="13"
          label="EAN">
        </v-text-field>
      </v-col>
      <v-col cols="10" md="3">
        <v-text-field
          v-model="sku.size"
          :rules="[...textRules,rules.size]"
          v-on:change="updateFromSize"
          label="Size"
        >
        </v-text-field>
      </v-col>
      <v-col cols="10" md="3">
        <v-text-field v-model="sku.price" :rules="textRules" prefix="€" label="Price">
        </v-text-field>
      </v-col>
      <v-col cols="10" md="3">
        <v-text-field v-model="sku.retailPrice" :rules="textRules" prefix="€" label="Retail price">
        </v-text-field>
      </v-col>
    </v-row>
    <dialog-json :item="sku" property="dataJson"> </dialog-json>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Mixins from '@/mixins';
import { Sku } from '../../models';
import ChipBaseMixins from '../../mixins/chip-base';

export default {
  name: 'SkuEdit',
  mixins: [ChipBaseMixins, ...Mixins],
  props: {
    value: {
      type: [Sku, Object],
      default: () => Sku.create(),
    },
    withSku: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    sku() {
      this.$emit('input', this.sku);
    },
  },
  computed: {
    ...mapGetters('form/rules', { textRules: 'text' }),
    sku: {
      get() {
        // update from database all info datas
        let { dataJson } = this.value;
        // by default dataJson is null
        if (!dataJson) {
          dataJson = {};
        }
        const attributes = this.$store.getters['api/attribute/skuAttribute'];
        if (attributes.length > 0) {
          Vue.set(
            this.value,
            'dataJson',
            attributes.reduce((r, a) => {
              if (typeof r[a.name] === 'undefined') {
                // eslint-disable-next-line no-param-reassign
                Vue.set(r, a.name, '');
              }
              return r;
            }, dataJson),
          );
        }
        return this.value;
      },
      set(value) {
        this.value = value;
      },
    },
    ...mapGetters('form/rules', {
      textRules: 'text',
      barcodeRules: 'barcode',
    }),
    ...mapGetters('collection', ['collections']),
    families() {
      return this.$store.getters['api/list']('family');
    },
    currentFamily() {
      if (this.sku && this.sku.familyId) {
        const index = this.families.findIndex(f => f.id === this.sku.familyId);
        return index !== -1 ? this.families[index] : false;
      }
      return false;
    },
    userCanAccess() {
      return this.hasRight('pimapp.v2:pim:sku:edit:write');
    },
    familyId: {
      get() {
        return this.sku.familyId;
      },
      set(value) {
        if (this.archive === null) {
          // fall back if cancel
          // this.archive = Object.assign([], this.sku.attributes);
        }
        // to identify if it s an updating or a creation
        Vue.set(this.sku, 'previousFamily', this.sku.familyId);
        this.sku.familyId = value;
      },
    },
  },
  mounted() {
    this.apiFamily();
    this.apiCollection();
  },
  data: () => ({
    rules: {
      size: v => (v && v !== '[size]') || 'Required.',
    },
  }),
  methods: {
    ...mapActions('api', {
      apiFamily: 'family',
    }),
    ...mapActions('collection', { apiCollection: 'list' }),
    filters(attributes) {
      this.$log.log(`components.pim.skuedit.${this.$route.params.productId}.started`);
      if (!attributes) {
        return [];
      }
      // @todo need to remove all theses attribute from infos fields
      const keysToRemove = ['Validation'];
      return attributes.reduce((r, va) => {
        if (keysToRemove.indexOf(va.name) === -1) {
          r.push(va);
        }
        return r;
      }, []);
    },
    checkColor(color) {
      return color !== 'error' ? [] : ['Some error occured on this color'];
    },
    updateFromSize(size) {
      if (typeof this.sku.parent.name === 'undefined' || !this.sku.parent.name) {
        throw new TypeError('components.dialog.sku.parent.name.invalid');
      }
      const { name } = this.sku.parent;
      this.sku.name = `${name} ${size}`;
      this.sku.sku = Sku.setSku({
        ...this.sku,
        size,
      });
    },
  },
};
</script>

<style lang="css" scoped></style>
