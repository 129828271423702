// https://vuex.vuejs.org/en/state.html

export default {
  passwordRules: [
    v => !!v || 'Password is required',
    v => (v && v.length >= 5) || 'Password must have 5+ characters',
    v => /(?=.*[A-Z])/.test(v) || 'Must have one uppercase character',
    v => /(?=.*\d)/.test(v) || 'Must have one number',
    v => /([!@$%])/.test(v) || 'Must have one special character [!@#$%]',
  ],
  emailRules: [
    v => !!v || 'Email is required',
    v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
  ],
  textRules: [v => !!v || 'Required', v => (v && v.length <= 255) || 'Input too long!'],
  titleRules: [v => !!v || 'Required', v => (v && v.length <= 255) || 'Title too long!'],
  skuRules: [v => !!v || 'Required', v => (v && v.length <= 60) || 'sku too long!'],
  barcodeRules: [
    v => !!v || 'Required',
    v => (v && `${v}`.length === 13) || 'Barcode is invalid!',
    v => String.isNumeric(v) || 'Barcode is not numeric!',
  ],
  percentRules: [
    v => String.isNumeric(v) || 'This value is not a percent!',
    v => (v >= 0 && v <= 100) || 'Percent is invalid!',
  ],
  colorRules: [
    v => !!v || 'Required',
    v => /^([a-zA-Z \-']+$)/.test(v) || 'Must have only alphabetic',
    v => (v && v.length >= 2) || 'Password must have 3+ characters',
  ],
  decimalRules: [v => /^-?(?:0|0[.,]\d*|[1-9]\d*[.,]?\d*)$/.test(v) || 'This field only accept decimal number'],
};
