/* eslint no-param-reassign:0 */
import Vue from 'vue';
import { mapActions } from 'vuex';
import { STEPPER_START } from '../helpers/steps';

const baseStep = STEPPER_START;

export default {
  data: () => ({
    defaultStep: baseStep,
    functionsStepped: {},
    defaultFunctionIcon: '',
  }),
  computed: {
    steps() {
      return this.$store.getters['step/steps'];
    },
    currentStep() {
      const currentStep = this.$store.getters['step/step'];
      this.$log.debug(`mixins.step-base.currentstep.${currentStep}.started`);
      return currentStep;
    },
    functionStepped() {
      if (Object.keys(this.functionsStepped).length === 0) {
        this.$log.error('mixins.step-base.functionstepped.is.missing.inside.data');
        return () => {};
      }
      if (typeof this.functionsStepped[this.currentStep] === 'undefined') {
        this.$log.error(`mixins.step-base.functionstepped.step.${this.currentStep}.not.found`);
        return () => {};
      }
      const methodName = this.functionsStepped[this.currentStep].function;
      if (!methodName || typeof this[methodName] !== 'function') {
        this.$log.error(`mixins.step-base.functionstepped.method.${methodName}.not.found`);
        return () => {};
      }
      return this[methodName];
    },
    currentFunction() {
      const defaultColor = 'black';
      try {
        const key = this.functionsStepped[this.currentStep].workflow.i18n;
        const currentFct = this.functionsStepped[this.currentStep];
        return {
          color: currentFct ? currentFct.color : defaultColor,
          icon: currentFct ? currentFct.icon : this.defaultFunctionIcon,
          title: this.$te(key) ? this.$t(key) : this.functionsStepped[this.currentStep].workflow.name,
        };
      } catch (e) {
        this.$log.error(`mixins.step-base.currentfunction.${this.currentStep}.not.found`);
      }
      return {
        color: defaultColor,
        icon: this.defaultFunctionIcon,
        title: 'Error title',
      };
    },
    isLastStep() {
      const lengthSteps = this.$store.getters['step/steps'].length;
      return this.currentStep + 1 >= lengthSteps;
    },
  },
  methods: {
    ...mapActions('step', {
      setSteps: 'set',
      setStep: 'step',
      resetStep: 'reset',
      getStep: 'get',
      updateStep: 'update',
    }),
    setNextStep(step = baseStep) {
      return this.setStep({ step }).then((current) => {
        this.$store.commit('step/current_step', current);
        return current;
      });
    },
    setError(error) {
      Vue.$log.error(error);
      return this.setStep({ step: this.currentStep, status: false, message: error.message });
    },
    stepInProgress(currentStep) {
      if (this.$route.params.importId) {
        return this.setNextStep(currentStep).then(stepNumber => this.updateStep({
          step: stepNumber,
          id: this.$route.params.importId,
        }));
      }
      return Promise.resolve(false);
    },
  },
};
