// https://vuex.vuejs.org/en/actions.html
import Vue from 'vue';
import axios from 'axios';
import { errorHandler } from '@/store/modules/global_helpers';

const BASE_URI = '/api/v1/pim';

export default {
  search({ commit }, payload) {
    Vue.$log.info('store.translate.actions.search.started');
    return new Promise((resolve, reject) => {
      Vue.$log.debug('store.translate.actions.search.text', payload.search);
      axios
        .get(`${BASE_URI}/translate/search`, payload)
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError('/api/v1/pim/translate/search empty');
          }
          commit('set_query', payload.search);
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  types({ commit }) {
    Vue.$log.info('store.translate.actions.types.started');
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URI}/translate/types`)
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError('/api/v1/pim/translate/types empty');
          }
          commit('set_types', response.data.result);
          return resolve(response.data.result);
        })
        .catch(errorHandler(reject));
    });
  },
  // eslint-disable-next-line no-unused-vars
  new({ commit }, payload) {
    Vue.$log.info('store.translate.actions.new.started');
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URI}/translate`, payload)
        .then((response) => {
          Vue.$log.log('translate has been created');
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  // eslint-disable-next-line no-unused-vars
  update({ commit }, translate) {
    Vue.$log.info('store.translate.actions.update.started');
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI}/translate/${translate.id}`, translate)
        .then((response) => {
          Vue.$log.log(`translate ${translate.id} has been updated`);
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  // eslint-disable-next-line no-unused-vars
  delete({ commit }, id) {
    Vue.$log.info('store.translate.actions.delete.started');
    return new Promise((resolve, reject) => {
      axios
        .delete(`${BASE_URI}/translate/${id}`)
        .then((response) => {
          Vue.$log.log(`translate ${id} has been deleted`);
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
};
