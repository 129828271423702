<template>
  <span>{{ valueAttribute }}</span>
</template>

<script>
import DialogBaseMixins from '@/mixins/dialog-base';

export default {
  mixins: [DialogBaseMixins],
};
</script>

<!-- Using the BEM convention -->
<style></style>
