<template>
  <material-card
    color="pink"
    v-if="marketplaceNames && fromMarketplace && marketplaceNames[fromMarketplace.marketplaceId]"
    :title="`${marketplaceNames[fromMarketplace.marketplaceId]} / ${fromMarketplace.name}`"
    class="getTitle pink-title text--lighten-1 font-weight-light col-lg-4 col"
    >
    <v-row class="ma-6 mt-auto">
      <v-col>
        For informations purpose only, please call the API to get the full list of attributes
        <v-text-field
          filled
          label="Title"
          v-model="fromMarketplace.name"
          disabled
        ></v-text-field>
        <v-text-field
          filledysq
          label="Code"
          v-model="fromMarketplace.options.code"
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-switch
          v-model="isSynced"
          :label="`This markeplace classification is synced with our pim classification automatically`"
          color="indigo darken-3"
          disabled
          hide-details
        ></v-switch>
        <v-item-group multiple v-if="Array.isArray(fromMarketplace.path.split('/').filter(Boolean))">
          <v-subheader>Hieraechical classification</v-subheader>
          <template v-for="(n, i) in fromMarketplace.path.split('/').filter(Boolean)">
            <v-item :key="n">
              <v-chip >
                {{ n }}
              </v-chip>
            </v-item>
            <template v-if="i !== fromMarketplace.path.split('/').filter(Boolean).length - 1">
              <v-icon small :key="n">mdi-arrow-right-bold-box-outline</v-icon>
            </template>
          </template>
        </v-item-group>
      </v-col>
    </v-row>
    <table-afm :value="fromMarketplace"></table-afm>
  </material-card>
</template>

<script>
import { EventBus } from '@/helpers/event-bus';
import AlertMixins from '@/mixins/alert-base';
import NotificationMixins from '@/mixins/notification-base';
import ClassificationFromMarketplace from '@/models/attribute/classificationfrommarketplace';
import TableAfm from '@/components/attributelist/table-afm';

export default {

  name: 'CardCfm',
  props: {
    value: {
      type: [ClassificationFromMarketplace, Object],
      default: null,
    },
    isSynced: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [AlertMixins, NotificationMixins],
  components: {
    TableAfm,
  },
  mounted() {
    if (this.value instanceof ClassificationFromMarketplace) {
      this.fromMarketplace = this.value;
    } else if (typeof this.value === 'object') {
      const { marketplaceId, code } = this.value;
      const remoteCode = this.getCfmRemoteCode(marketplaceId, code);
      this.getCfm(remoteCode)
        .finally((result) => {
          if (result) {
            this.fromMarketplace = result;
          } else {
            const marketplaceName = this.marketplaceNames[marketplaceId];
            this.fromMarketplace = ClassificationFromMarketplace.buildNew({
              label: this.capitalize(code),
              code,
              remoteCode,
              marketplaceId,
              marketplaceName,
            });
          }
        });
    }
    EventBus.$on('update-classification:afm', (payload) => {
      this.$store.dispatch('classification-from-marketplace/addAttributeForAttributeFromMarketplace', {
        cfmRemoteCode: `pim.cfm.${this.value.remoteCode}`,
        ...payload,
      });
    });
  },
  data: () => ({
    fromMarketplace: null,
  }),
  computed: {
    marketplaceNames() {
      return this.$store.getters['config/marketplaces'].reduce((r, m) => {
        r[m.id] = m.name;
        return r;
      }, {});
    },
  },
  methods: {
    capitalize(s) {
      s = s.replace(/[-_.]/g, ' ');
      return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    },
    getCfmRemoteCode(marketplaceId, code) {
      // see theagent-pim/models/classificationfrommarketplace
      return `${marketplaceId}.${code.slugify()}`;
    },
    getCfm(remoteCode) {
      if (!remoteCode) return null;
      return this.$store
        .dispatch('classification-from-marketplace/get', remoteCode)
        .catch(error => this.throwError(error));
    },
  },
};
</script>

<style lang="css" scoped>
</style>
