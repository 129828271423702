<template>
  <v-lazy
    v-model="isActive"
    :options="{
      threshold: 0.5
    }"
    transition="fade-transition"
    class="d-flex"
  >
    <v-img
      @mouseover="hover = true"
      @mouseleave="hover = false"
      :src="imageUrl"
      class="grey lighten-2"
    >
      <slot></slot>
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey lighten-5"> </v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </v-lazy>
</template>

<script>
export default {
  name: 'CoreImage',
  props: {
    value: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data: () => ({
    hover: false,
    isActive: false,
  }),
  computed: {
    imageUrl() {
      if (!this.value) {
        return '';
      }
      if (this.value.original) {
        return this.value.original;
      }
      if (typeof this.value.url !== 'function') {
        this.$log.error('components.attribute.value.imageurl.invalid');
        return '';
      }
      return this.value.url(this.value.alias);
    },
  },
};
</script>

<style lang="scss">
.v-image {
  ul > li {
    font-size: 0.8em;
  }
}
</style>
