// https://vuex.vuejs.org/en/mutations.html

export default {
  set_message(state, message) {
    state.message = message;
  },
  set_name(state, name) {
    state.name = name;
  },
  set_data(state, data) {
    state.data = data;
  },
  set_type(state, type) {
    if (!['info', 'success', 'error', 'warning'].includes(type)) {
      throw new TypeError(`store.modules.error.mutations.${type}.invalid`);
    }
    state.type = type;
  },
  set_success(state, payload) {
    state.name = payload.name || 'Success';
    state.message = payload.message || null;
    state.data = null;
    state.type = 'success';
  },
  set_error(state, payload) {
    state.name = payload.name || 'Error system';
    state.message = payload.message || null;
    state.data = payload.data || null;
    state.type = 'error';
  },
  set_warning(state, payload) {
    state.name = payload.name || 'This is a warning';
    state.message = payload.message || null;
    state.data = payload.data || null;
    state.type = 'warning';
  },
  set_info(state, payload) {
    state.name = payload.name || null;
    state.message = null;
    state.data = null;
    state.type = 'info';
  },
};
