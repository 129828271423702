<template>
  <v-container fluid>
    <v-form v-model="valid" v-if="classification">
      <material-card
        :title="`Classification product '${classification ? classification.name : 'Default'}'`"
        class="title grey--text text--lighten-1 font-weight-light col-lg-4"
      >
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="classification.name"
              :rules="titleRules"
              :counter="255"
              label="classification name"
              required
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            Product family :
            <family-link v-if="classification.family"
              v-model="classification.family.remoteCode"
              :label="classification.family.name">
            </family-link>
            <error-link v-else></error-link>
          </v-col>
          <v-col cols="12" md="4" v-if="classification.path">
              <template v-for="(i, index) in buildPath(classification.path, classification.parentId)">
              <classification-link :key="i.remoteCode"
                v-model="i.remoteCode"
                :label="i.name">
                <template v-if="index !== buildPath(classification.path).length - 1">
                  <v-icon small>mdi-arrow-right-bold-box-outline</v-icon>
                </template>
              </classification-link>
            </template>
          </v-col>
        </v-row>
        <v-btn
           :disabled="!valid"
           color="success"
           class="mr-4"
           @click="validate"
         >
           Validate
         </v-btn>
         <v-btn
           color="error"
           class="mr-4"
           @click="reset"
         >
           Reset Form
         </v-btn>
         <v-btn
           color="warning"
           @click="resetValidation"
         >
           Reset Validation
         </v-btn>
      </material-card>
    </v-form>
    <v-divider></v-divider>
    <template v-for="fromMarketplace in (classification.fromMarketplace || [])">
      <card-cfm
        v-if="fromMarketplace && marketplaces"
        :value="fromMarketplace"
        :isSynced="isSynced(fromMarketplace)"
        :key="fromMarketplace.name">
      </card-cfm>
    </template>
    <template v-if="cfmNoAssociated.length > 0">
      <card-cfm v-for="cmfObj in cfmNoAssociated" :key="cmfObj.code" :value="cmfObj">
      </card-cfm>
    </template>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import ClassificationLink from '@/components/link/classification';
import FamilyLink from '@/components/link/family';
import ErrorLink from '@/components/link/error';
import CardCfm from '@/components/attributelist/card-cfm';

export default {
  name: 'FamilyByIdView',
  components: {
    ClassificationLink,
    FamilyLink,
    ErrorLink,
    CardCfm,
  },
  data: () => ({
    headers: [],
    currentMarketplace: null,
    attributsAssociation: null,
    attributsCfmAssociation: null,
    classificationClm: null,
    classificationsClm: 0,
    searchAttribute: '',
    associations: [],
    openColumnCfm: false,
    loading: false,
    editable: true,
    isDragging: true,
    delayedDragging: false,
    colorOk: '#006064',
    colorNok: '#f44336',
  }),
  mounted() {
    this.fetchByRemoteCode(this.$route.params.remoteCode);
    this.fetchAllAttributes();
  },
  computed: {
    ...mapGetters('form/rules', { titleRules: 'title' }),
    classification() {
      return this.$store.getters['classification/classification'];
    },
    settings() {
      return this.$store.getters['settings/settings'];
    },
    marketplaces() {
      return this.$store.getters['config/marketplaces'];
    },
    cfmNoAssociated() {
      const remoteCodes = [];
      if (!this.classification
        || !this.marketplaces
        || Object.keys(this.classification).length === 0
        || !this.codes) return remoteCodes;
      const codesFromMarketplace = this.classification.fromMarketplace.reduce((r, f) => {
        if (typeof r[f.marketplaceId] === 'undefined') {
          r[f.marketplaceId] = [];
        }
        r[f.marketplaceId].push(f.options.code);
        return r;
      }, {});
      this.marketplaces.forEach((marketplace) => {
        try {
          const marketplaceObj = this.getMarketplace(marketplace.remoteCode);
          const listCodesAssociated = this.codes[marketplaceObj.id] || [];
          const listCodesFMAssociated = codesFromMarketplace[marketplaceObj.id] || [];
          if (listCodesAssociated.length > 0) {
            listCodesAssociated.forEach((c) => {
              if (!listCodesFMAssociated.includes(c)) {
                remoteCodes.push({
                  marketplaceId: marketplaceObj.id,
                  code: c,
                });
              }
            });
          }
        } catch (e) {}
      });
      return remoteCodes;
    },
    codes() {
      const listCodes = {};
      this.marketplaces.forEach((m) => {
        try {
          if (typeof listCodes[m.id] === 'undefined') {
            listCodes[m.id] = [];
          }
          if (typeof this.classification.settings[m.remoteCode].code !== 'undefined') {
            listCodes[m.id].push(...this.classification.settings[m.remoteCode].code);
          }
        } catch (e) {}
      });

      return listCodes;
    },
    classificationsByName() {
      return this.$store.getters['classification/classifications'].reduce((r, c) => {
        if (typeof r[c.name.slugify()] === 'undefined') {
          r[c.name.slugify()] = [];
        }
        r[c.name.slugify()].push(c);
        return r;
      }, {});
    },
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
  },
  methods: {
    ...mapActions('classification', { fetchByRemoteCode: 'get' }),
    ...mapActions('attribute', { fetchAllAttributes: 'list' }),
    validate() {
      this.loading = true;
      setTimeout(() => this.$store
        .dispatch('classification/update', this.classification)
        .catch(error => this.throwError(error))
        .finally(() => {
          this.loading = false;
        }),
      2000);
    },
    getMarketplace(marketplace) {
      return this.marketplaces.find(m => m.remoteCode === marketplace) || { name: '' };
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    removeFromList(element, classificationAttribute) {
      const attributes = [...classificationAttribute.defaultValue];
      const index = attributes.findIndex(v => v.remoteCode === element.remoteCode);
      // eslint-disable-next-line no-alert
      if (index !== -1 && window.confirm(`Are you sure you want to delete this element from ${classificationAttribute.name}?`)) {
        attributes.splice(index, 1);
        Vue.set(classificationAttribute, 'defaultValue', attributes);
      }
    },
    getColumn(position) {
      if (this.openColumnCfm) {
        if (position === 'center') return 6;
        return 3;
      }
      if (position === 'center') return 9;
      return 0;
    },
    isSynced(fromMarketplaceObj) {
      if (!this.codes[fromMarketplaceObj.marketplaceId]) {
        return false;
      }
      return this.codes[fromMarketplaceObj.marketplaceId].includes(fromMarketplaceObj.options.code);
    },
    reset(e) {
      console.log('reset', e);
    },
    resetValidation(e) {
      console.log('resetValidation', e);
    },
    getAssociation(element, currentMarketplace) {
      try {
        return element.settings.marketplaceOptions[currentMarketplace.remoteCode].reduce((r, o) => {
          if (typeof this.attributsAssociation[o.code] !== 'undefined') {
            Vue.set(this.attributsCfmAssociation, o.code, true);
            r.push(this.attributsAssociation[o.code]);
          }
          return r;
        }, []);
      } catch (e) {}
      return null;
    },
    buildPath(p, pId) {
      const paths = [];
      if (!this.classificationsByName) return paths;
      const splitP = p.split('/');
      return splitP.reduce((r, s) => {
        if (s === '') return r;
        if (typeof this.classificationsByName[s] !== 'undefined') {
          let [classification] = this.classificationsByName[s];
          if (this.classificationsByName[s].length > 1) {
            // eslint-disable-next-line max-len, prefer-destructuring
            classification = this.classificationsByName[s].filter(c => c.parentId === pId || c.id === pId)[0];
          }
          r.push({
            name: classification.name,
            remoteCode: `${classification.remoteCode}`,
          });
        }
        return r;
      }, paths);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
