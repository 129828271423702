<template>
  <router-link
    v-if="value"
    :to="getUrlByName('settings.classification.remoteCode', value)"
    target="_blank"
    >
    <v-chip
      class="ma-2"
      color="orange"
      text-color="white"
      dark
      small
      :style="{ cursor: 'pointer'}"
    >
      <v-avatar
        left
        class="orange darken-4"
      >
        C
      </v-avatar>
      <template v-if="label">
        {{ label }}
      </template>
      <slot></slot>
    </v-chip>
  </router-link>
</template>

<script>
import LinkMixins from '@/mixins/link-base';

export default {
  name: 'LinkClassification',
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
  },
  mixins: [LinkMixins],
};
</script>
