<template>
  <v-container fluid style="padding-right: 80px !important;">
    <v-row style="background-color: rgb(128 128 128 / 5%);">
      <v-col cols="4">
        <dam-product-media-view v-model="medias"> </dam-product-media-view>
      </v-col>
      <v-col cols="8">
        <pim-product-edit v-model="product"></pim-product-edit>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <core-alert></core-alert>
    <v-row style="background-color: rgb(128 128 128 / 5%);">
      <v-col cols="12">
        <v-tabs vertical color="blackgrey darken-3" v-model="tabColumn" >
          <v-tab
            v-for="variant in variants"
            :key="variant.id"
            :style="styleColorTitle(variant.color)"
          >
            <v-icon left>
              mdi-account
            </v-icon>
            {{ variant.color }}/{{ variant.pattern }}
          </v-tab>
          <v-tab-item v-for="(variant, index) in variants" :key="variant.id">
            <v-card flat>
              <v-tabs v-model="tabSlider" grow centered color="blackgrey darken-3">
                <v-tabs-slider
                  :style="`background-color:${convertBackgroundColor(variant.color)}`"
                ></v-tabs-slider>
                <v-tab v-for="tabLabel in tabLabels" :key="tabLabel">
                  {{ tabLabel }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabSlider">
                <v-tab-item>
                  <pim-variant-edit v-model="variants[index]"></pim-variant-edit>
                </v-tab-item>
                <v-tab-item>
                  <pim-skus-view
                    :items="variant.skus"
                    v-if="variant.skus"
                    :parentItem="variant"
                    endpointType="sku"
                  ></pim-skus-view>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
            <common-media-list-view
              :hasMedia="variant.hasMedias()"
              :variantMedia="variant.medias"
              :variantId="variant.id"
              actions
              upload
            >
            </common-media-list-view>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <div>
      <v-btn
        color="primary"
        style="top: 60px !important;"
        dark
        fixed
        absolute
        large
        top
        right
        fab
        :disabled="isClicked"
        @click="save"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
      <v-btn color="error" style="top: 140px !important;" dark fixed absolute large top right fab>
        <v-icon>mdi-cancel</v-icon>
      </v-btn>
      <v-btn
        color="secondary"
        @click="syncItem(product)"
        style="top: 220px !important;"
        dark
        fixed
        absolute
        large
        top
        right
        fab
        :disabled="productSynced"
      >
        <v-icon>mdi-sync-circle</v-icon>
      </v-btn>
      <v-btn style="top: 300px !important;"
        dark
        fixed
        absolute
        large
        top
        right
        fab
        @click="setMarketplace('default')">
        <material-percent
          :percent="getPercent('default')"
          :color="getCompletudeColor('default')"
          size="100"
          circular
          title-button="Défaut"></material-percent>
      </v-btn>
      <v-btn style="top: 400px !important;"
        dark
        fixed
        absolute
        large
        top
        right
        fab
        @click="setMarketplace('zalando')">
        <material-percent
          :percent="getPercent('zalando')"
          :color="getCompletudeColor('zalando')"
          size="100"
          circular
          title-button="zalando"></material-percent>
      </v-btn>
      <v-btn style="top: 500px !important;"
        dark
        fixed
        absolute
        large
        top
        right
        fab
        @click="setMarketplace('laRedoute')">
        <material-percent
          :percent="getPercent('laRedoute')"
          :color="getCompletudeColor('laRedoute')"
          size="100"
          circular
          title-button="laRedoute"></material-percent>
      </v-btn>
      <dialog-settings :dialog="dialog"> </dialog-settings>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { map } from 'bluebird';
import { marketplace } from 'theagent-status';
import { EventBus } from '@/helpers/event-bus';
import Mixins from '@/mixins';
import EditMixin from '@/mixins/edit-base';
import CompletudeBaseMixins from '@/mixins/completude-base';
import { VariantManager, Variant } from '@/models';

export default {
  name: 'ProductByIdView',
  mixins: [...Mixins, EditMixin, CompletudeBaseMixins],
  data: () => ({
    tabColumn: null,
    tabSlider: null,
    tabLabels: ['Variant color', 'Variant size', 'History'],
    complete: 0,
    defaultItem: Variant.create({}),
    editedItem: Variant.create({}),
    isVariantValid: true,
    isClicked: false,
    marketplaceStatus: marketplace,
  }),
  created() {
    this.$log.log(`components.pim.productbyidview.${this.$route.params.productId}.hook.created`);
    this.$store.dispatch('completude/families');
    this.getProductById(this.$route.params.productId)
      .then(() => this.getFullCompletude(this.$route.params.productId))
      .then(() => this.setDefaultVariantItem(this.variants ? this.variants[0] : null));
  },
  mounted() {
    EventBus.$on('import.importid.edit:reload', () => {
      this.getProductById(this.$route.params.productId)
        .then(() => this.getFullCompletude(this.$route.params.productId))
        .then(() => this.setDefaultVariantItem(this.variants ? this.variants[0] : null));
    });
  },
  computed: {
    product: {
      get() {
        return this.$store.getters['product/product'];
      },
      set(value) {
        this.$store.commit('product/set_product', value);
      },
    },
    currentCompletudeVariant: {
      get() {
        return this.$store.getters['completude/currentVariantId'];
      },
      set(value) {
        this.setCurrent(value);
      },
    },
    medias() {
      const index = Number.isInteger(this.tabColumn) ? this.tabColumn : 0;
      if (typeof this.variants[index] === 'undefined') return [];
      return this.variants[index].medias.medias;
    },
    productSynced() {
      return this.product?.status?.pim === marketplace.DONE
        && [marketplace.DONE, marketplace.NO_STATUS].includes(this.product.status.marketplace);
    },
    variants() {
      try {
        return this.product.variant.slice(0).sort((a, b) => {
          if (a.color < b.color) { return -1; }
          if (a.color > b.color) { return 1; }
          if (a.pattern < b.pattern) { return -1; }
          if (a.pattern > b.pattern) { return 1; }
          return 0;
        });
      } catch (err) {
        return [];
      }
    },
  },
  watch: {
    tabColumn() {
      this.currentCompletudeVariant = this.variants[this.tabColumn].id;
    },
  },
  methods: {
    ...mapActions('product', {
      getProductById: 'get',
    }),
    ...mapGetters('form/rules', { textRules: 'text' }),
    save() {
      this.$log.info('components.pim.productbyidview.save.started', this.product);
      const product = this.product.toObject();
      if (this.isClicked) {
        return null;
      }
      this.isClicked = true;
      return this.saveProduct(product)
        .then(() => this.getFullCompletude(this.$route.params.productId))
        .catch(error => this.throwError(error))
        .finally(() => this.defaultValues());
    },
    saveProduct(product) {
      this.$log.debug('components.pim.productbyidview.saveproduct.started');
      return this.$store
        .dispatch('product/update', product)
        .then((response) => {
          this.product.update(response);
          this.product.status.update(response.status);
          // return this.product.setCompletude(this.$store.dispatch);
        })
        .then(() => map(this.variants, variant => this.saveVariant(variant)))
        .catch(error => this.throwError(error));
    },
    saveVariant(variant) {
      this.$log.debug('components.pim.productbyidview.savevariant.started');
      const variantObj = variant.toObject();
      return this.$store
        .dispatch('variant/update', variantObj)
        .then((response) => {
          variant.update(response);
          // return variant.setCompletude(this.$store.dispatch);
        })
        .catch(error => this.throwError(error));
    },
    getFullCompletude(productId) {
      return this.$store.dispatch('completude/product', productId).then((completude) => {
        this.complete = completude;
      });
    },
    /**
     * This method allow to initialize default data to all dialog
     * @param {[type]} item [description]
     */
    setDefaultVariantItem(item) {
      this.$log.info('components.pim.productbyidview.setdefaultvariantitem.started');
      if (item) {
        const defaultItem = VariantManager.newObject(item);
        defaultItem.skusValues = defaultItem.skus.map(sku => ({ text: sku.size, color: 'blue' }));
        defaultItem.sealed = false;
        defaultItem.id = false;
        this.$log.debug(
          'components.pim.productbyidview.setdefaultvariantitem.defaultitem',
          defaultItem,
        );
        this.defaultItem = defaultItem;
        this.editedItem = defaultItem;
      }
    },
    defaultValues() {
      this.isVariantValid = true;
      this.isClicked = false;
    },
    styleColorTitle(color) {
      return `background-color:${this.convertBackgroundColor(color)};color:${this.convertColor(color)}`;
    },
  },
};
</script>

<style lang="css" scoped></style>
