<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        small
        v-bind="attrs"
        v-on="on"
      >mdi-eye-circle-outline</v-icon>
    </template>

    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >Attribut Marketplace : {{ getTitle(value) }}</v-toolbar>
        <v-card-text>
          <v-row>
            <v-card
              class="mx-auto col-sm-5"
              max-width="400"
            >
            <v-list dense>
              <v-subheader inset>Pim values</v-subheader>
              <v-list-item v-for="val in attribute.values.filter(v => v.defaultValue)"
                :key="val.remoteCode">
                <v-list-item-content>
                  <v-list-item-title >{{ val.defaultValue }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            </v-card>
            <v-col cols="2"></v-col>
            <v-card
              class="mx-auto col-sm-5"
              max-width="400"
            >
              <v-list dense>
                <v-subheader inset>{{ value.name }} values</v-subheader>
                <v-list-item v-for="val in value.values" :key="val.code">
                  <v-list-item-content>
                    <v-list-item-title >{{ val.label }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogCompareAttribute',
  props: {
    value: {
      type: Object,
      default: () => [],
    },
  },
  data: () => ({
  }),
  computed: {
    marketplaces() {
      return this.$store.getters['config/marketplaces'];
    },
    attribute() {
      return this.$store.getters['attribute/attribute'] || {};
    },
  },
  methods: {
    getTitle(a) {
      const marketplace = this.marketplaces.find(m => m.id === a.marketplaceId);
      if (!marketplace) return a.name;
      return `${marketplace.name} / ${a.name}`;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
