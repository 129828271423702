export default {
  methods: {
    // eslint-disable-next-line no-unused-vars
    defaultFilter(values, search, item) {
      if (!search) return true;
      if (typeof values === 'object' && values) {
        // eslint-disable-next-line no-param-reassign
        values = JSON.stringify(values);
      }
      // check if a value is present inside defaultValues
      if (typeof item.defaultValues !== 'undefined') {
        const targetKey = Object.keys(item).reduce((key, currentKey) => {
          if (key) return key;
          if (item[currentKey] === values) {
            return currentKey;
          }
          return key;
        }, null);
        if (
          targetKey
          && typeof item.defaultValues[targetKey] !== 'undefined'
          && item.defaultValues[targetKey]
        ) {
          // eslint-disable-next-line no-param-reassign
          values = `${values} ${item.defaultValues[targetKey]}`;
        }
      }
      return (
        values != null
        && search != null
        && typeof values !== 'boolean'
        && values
          .toString()
          .toLocaleLowerCase()
          .indexOf(search.toLocaleLowerCase()) !== -1
      );
    },
  },
};
