// https://vuex.vuejs.org/en/mutations.html

export default {
  set_data(state, payload) {
    const { key, value } = payload;
    if (typeof state[key] !== 'undefined') {
      state[key] = value;
    }
  },
  set_state(state, payload) {
    const { key, value } = payload;
    if (typeof state.state[key] !== 'undefined') {
      state.state[key] = value;
    }
  },
  set_history(state, payload) {
    const { key, value } = payload;
    if (typeof state.history[key] !== 'undefined') {
      state.history[key] = value;
    }
  },
};
