// https://vuex.vuejs.org/en/actions.html

import Vue from 'vue';
import axios from 'axios';
import { errorHandler } from '@/store/modules/global_helpers';

const BASE_URI = '/api/v1/export';
const BASE_PIM_URI = '/api/v1/pim';
const namespace = 'store.export.actions';

export default {
  shotlist({ commit }, id) {
    Vue.$log.info(`${namespace}.shotList.started`);
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URI}/csv/${id}/shotlist`, {
          timeout: 240000,
        })
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`store.export.actions.shotList.${id}.shotlist empty`);
          }
          commit('import/add_files', response.data, { root: true });
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  shotlistExample({ commit }, payload) {
    Vue.$log.info(`${namespace}.shotlistexample.started`);
    let endpoint = '';
    if (typeof payload.brandId !== 'undefined') {
      endpoint = `${BASE_URI}/csv/${payload.brandId}/pim/shotlist/example`;
    } else if (typeof payload.importId !== 'undefined') {
      endpoint = `${BASE_URI}/csv/${payload.importId}/shotlist/example`;
    } else {
      endpoint = `${BASE_URI}/csv/shotlist/example`;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(endpoint, payload)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(
              `store.export.actions.shotlist.${payload.brandId || payload.importId}.not.found`,
            );
          }
          commit('import/add_files', response.data, { root: true });
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  cdv({ commit }, id) {
    Vue.$log.info(`${namespace}.started`);
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URI}/csv/${id}/cdv`)
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError('api.v1.export.csv.[:id].cdv.empty');
          }
          commit('import/add_files', response.data, { root: true });
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  cdvfrompim({ commit }, id) {
    Vue.$log.info(`${namespace}.cdvfrompim.started`);
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_PIM_URI}/export/${id}/cdv`, {
          timeout: 120000,
        })
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError('api.v1.export.csv.[:id].cdv.empty');
          }

          commit('import/add_files', response.data, { root: true });
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  rawCdv({}, payload) { // eslint-disable-line no-empty-pattern
    Vue.$log.info(`${namespace}.cdvfrompim.started`);
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_PIM_URI}/export/cdv`, payload)
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError('api.v1.export.csv.[:id].cdv.empty');
          }
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  search(store, payload) {
    Vue.$log.info(`${namespace}.search.started`);
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_PIM_URI}/export/search`, {
          ...payload,
          timeout: 600000,
        })
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.search.failed`);
          }
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  searchMedias(store, payload) {
    Vue.$log.info(`${namespace}.searchmedias.started`);
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_PIM_URI}/export/search/medias`, {
          ...payload,
          timeout: 600000,
        })
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.searchmedias.failed`);
          }
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  searchShootListMedias(store, payload) {
    Vue.$log.info(`${namespace}.searchmedias.started`);
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URI}/csv/search/medias/shotlist`, payload, {
          timeout: 600000,
        })
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.searchmedias.failed`);
          }
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  searchZipMedias(store, payload) {
    Vue.$log.info(`${namespace}.searchmedias.started`);
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URI}/csv/search/medias/zip`, payload, {
          timeout: 600000,
        })
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.searchmedias.failed`);
          }
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  marketplace(store, payload) {
    Vue.$log.info(`${namespace}.marketplace.started`);
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URI}/marketplace`, payload)
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.marketplace.failed`);
          }
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  check(store, payload) {
    Vue.$log.info(`${namespace}.marketplace.check.started`);
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URI}/marketplace/check`, payload)
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.marketplace.check.failed`);
          }
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  files() {
    Vue.$log.info(`${namespace}.marketplace.files.started`);
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URI}/marketplace/files.from.edi`)
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.marketplace.check.failed`);
          }
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  removeFile(store, payload) {
    Vue.$log.info(`${namespace}.marketplace.removefile.started`);
    return new Promise((resolve, reject) => {
      if (!Array.isArray(payload)) {
        payload = [payload];
      }
      axios
        .delete(`${BASE_URI}/marketplace/files.in.edi`, {
          data: { files: payload },
        })
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.marketplace.check.failed`);
          }
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
};
