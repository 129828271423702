<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      class="elevation-1 ta-fix-column ta-attribute"
      :items="attributes"
      :server-items-length="itemsCount"
      :options.sync="itemsOptions"
      :loading="loading"
      :search="search"
      :custom-filter="filterOnlyCapsText"
      :footer-props="{ 'items-per-page-options': [100, 200, 500, -1] }"
      loading-text="Loading... Please wait"
      :dense="settings.dense"
      fixed-header
    >
      <template v-for="head in headers" v-slot:[`header.${head.value}`]="{ header }">
        <v-text-field
          v-if="head.value === 'name'"
          v-model="search"
          :key="head.value"
          label="Nom de l'attribut"
          class="title-search"
        ></v-text-field>
        <h6 style="display: inline-block"
          v-else
          :key="head.value"
          :header="head"
        >
          {{ header.text }}
        </h6>
      </template>
      <template v-slot:item.data-table-expand="">
        <div class="text-center">
          <v-btn fab x-small class="ta-attribute">1</v-btn>
        </div>
      </template>
      <template v-slot:item.typeEntity="{ item }">
        <attribute-typeentity v-model="item.typeEntity"></attribute-typeentity>
      </template>
      <template v-slot:item.typeHinting="{ item }">
        <attribute-typehinting v-model="item.typeHinting"></attribute-typehinting>
      </template>
      <template v-slot:item.isReady="{ item }">
        <v-switch
          v-model="item.isReady"
           @change="active(item)"
        ></v-switch>
      </template>
      <template v-slot:item.actions="{ item }">
        <attribute-link v-model="item.remoteCode">
          <v-icon small class="mr-1">
            mdi-pencil-circle
          </v-icon>
        </attribute-link>
        <v-icon small class="mr-1" @click="deleteAttribute(item)">
          mdi-delete-circle
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AttributeLink from '@/components/link/attribute';
import AttributeTypeMixins from '@/mixins/attribute-type-base';
import NotificationMixins from '@/mixins/notification-base';

export default {
  name: 'AttributeListView',
  mixins: [AttributeTypeMixins, NotificationMixins],
  components: {
    AttributeLink,
  },
  data: () => ({
    headers: [
      { text: 'Nom de l\'attribut', value: 'name' },
      {
        text: 'Entité', value: 'typeEntity', filterable: false, sortable: false,
      },
      {
        text: 'Typage Pim', value: 'typeHinting', filterable: false, sortable: false,
      },
      {
        text: 'Active', value: 'isReady', filterable: false, sortable: false,
      },
      {
        text: 'Actions', value: 'actions', filterable: false, sortable: false,
      },
    ],
    search: '',
    loading: false,
    endpointType: 'attribute',
  }),
  computed: {
    ...mapGetters('attribute', ['attributes']),
    settings() {
      return this.$store.getters['settings/settings'];
    },
  },
  created() {
    this.loading = true;
    this.fetchAllAttributes()
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions('attribute', { fetchAllAttributes: 'list' }),
    filterOnlyCapsText(value, search, item) {
      if (search && ['av:', 'attributevalue:'].some(av => search.indexOf(av) === 0)) {
        search = search.replace(/(attributevalue|av):/i, '');
        return item.search(search);
      }
      return value != null
        && search != null
        && typeof value === 'string'
        && value.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    },
  },
};
</script>

<style lang="scss" scoped>
div.title-search .v-text-field .v-label {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 300 !important;
  line-height: 1.5em !important;
  font-size: 0.75rem !important;
  color: rgba(0, 0, 0, 0.6);
}
</style>
