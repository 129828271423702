<template>
  <v-select
    v-if="editing"
    v-model="value"
    :items="itemTypes"
    menu-props="auto"
    :label="label || 'Pick your stock type'"
    hint="Pick your stock type"
    persistent-hint
  >
    <template v-slot:selection="{ item, index }">
      <v-chip :color="getColor(item)" dark text-color="black">{{ item }}</v-chip>
    </template>
  </v-select>
  <v-chip v-else :color="getColor(value)" dark text-color="black">{{ value }}</v-chip>
</template>

<script>
export default {
  name: 'EntityType',
  props: {
    value: {
      type: Number,
      required: false,
    },
    editing: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    types: {
      composition: '#ffebee',
      country: '#fce4ec',
      ean: '#f3e5f5',
      float: '#ede7f6',
      number: '#e8eaf6',
      number_size: '#e3f2fd',
      percent: '#e1f5fe',
      price: '#e0f7fa',
      radio: '#e0f2f1',
      string: '#e8f5e9',
      url: '#f1f8e9',
    },
  }),
  computed: {
    itemTypes() {
      const typeHintings = this.$store.getters['config/attribute.typeHinting'];
      typeHintings.forEach((t) => {
        if (typeof this.types[t] === 'undefined') {
          this.$log.error(`attribute.typehinting.${t}.color.doesnt.exist`);
        }
      });
      return typeHintings;
    },
  },
  methods: {
    getColor(typeHinting) {
      if (this.types[typeHinting]) return this.types[typeHinting];
      this.$log.error(`attribute.typehinting.${typeHinting}.color.doesnt.exist`);
      return '';
    },
  },
};
</script>
