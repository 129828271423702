<template>
  <material-card color="blue" :title="title" class="col-lg-12">
    <template v-slot:dialog>
      <dialog-size
        :attribute="editedItem"
        :dialog="dialog"
        v-on:update:dialog="dialog = $event"
        @onSave="saveAttribute"
        @onClose="closeItem"
      >
      </dialog-size>
    </template>
    <v-text-field v-model="search" label="Search" single-line hide-details> </v-text-field>
    <v-spacer></v-spacer>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :footer-props="{ 'items-per-page-options': [15, 30, 50, 100, -1] }"
      :item-class="classes"
    >
      <template v-slot:item.gender="{ item }">
        <attribute-gender :item="item" property="gender"> </attribute-gender>
      </template>
      <template v-slot:item.classificationId="{ item }">
        <dialog-classification
          :item="item"
          property="classificationId"
          labelName="Choose your classification"
        >
        </dialog-classification>
      </template>
      <template v-slot:item.brandId="{ item }">
        <dialog-brand :item="item" property="brandId" labelName="Choose your brand"> </dialog-brand>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" v-on:click.stop @click="editAttribute(item)">
          mdi-pencil-circle
        </v-icon>
        <v-icon small v-on:click.stop @click="deleteAttribute(item)">
          mdi-delete-circle
        </v-icon>
      </template>
    </v-data-table>
    <helper-size-legend />
  </material-card>
</template>

<script>
import EditMixins from '../../mixins/edit-base';
import AttributeEditMixins from '../../mixins/attribute-edit-base';
import SizeMixins from '../../mixins/size-base';
import Mixins from '../../mixins';

export default {
  name: 'AttributeList',
  mixins: [EditMixins, AttributeEditMixins, SizeMixins, ...Mixins],
  computed: {
    title() {
      return `Property: ${this.mainAttribute}`;
    },
  },
  data: () => ({
    search: '',
    headers: [
      {
        sortable: false,
        text: 'Id',
        value: 'id',
      },
      {
        sortable: true,
        text: 'French',
        value: 'fra',
      },
      {
        text: 'English',
        value: 'gbr',
      },
      {
        text: 'Italy',
        value: 'ita',
      },
      {
        text: 'Deutch',
        value: 'deu',
      },
      {
        text: 'Spanish',
        value: 'esp',
      },
      {
        text: 'Gender',
        value: 'gender',
      },
      {
        sortable: true,
        text: 'Classification',
        value: 'classificationId',
      },
      {
        sortable: true,
        text: 'Brand',
        value: 'brandId',
      },
      {
        sortable: false,
        text: 'Actions',
        value: 'actions',
      },
    ],
    defaultItem: {},
  }),
};
</script>
