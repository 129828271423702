// https://vuex.vuejs.org/en/state.html
import { collection } from 'theagent-status';

const { NO_STATUS } = collection;

export default {
  collection: {},
  collections: [],
  products: [],
  status: NO_STATUS,
};
