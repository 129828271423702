// https://vuex.vuejs.org/en/state.html
/* eslint no-param-reassign : 0 */
/**
 * example :
 *   type: example
 *   state:
 *     exampleList: [],
 *     exampleListKey: {},
 */

const types = [
  'brand',
  'classification',
  'classificationTree',
  'distributor',
  'size',
  'family',
  'marketplace.brand',
  'marketplace.supplier',
  'marketplace.reference',
  'marketplace.variant',
  'brand.data',
  'channel',
];
const states = types.reduce((array, type) => {
  if (typeof array.state === 'undefined') {
    array.history = {};
    array.state = {};
  }
  array[`${type}List`] = [];
  array[`${type}ListKey`] = [];
  array.state[type] = false;
  return array;
}, {});

export default {
  ...states,
};
