// https://vuex.vuejs.org/en/state.html
export default {
  status: state => state.status,
  statusForSelect: state => Object.keys(state.status).map(status => ({
    value: state.status[status],
    key: status,
  })),
  products: state => state.products,
  product: state => state.product,
};
