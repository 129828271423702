import Vue from 'vue';
import VariantAttribute from './variantattribute';
import Variant from '../pim/variant';
import { DefaultError } from '../pim/exception';

const lastCode = (name) => {
  let hash = name.slugify();
  if (name.indexOf(' ') !== -1) {
    hash = name.substr(0, name.indexOf(' '));
  }
  if (hash.length > 16) {
    hash = hash.substring(0, 16);
  }
  return hash.slugify();
};

export default class VariantManager {
  static newObject(variant) {
    if (!(variant instanceof Variant)) {
      throw new DefaultError('models.pim.variantmanager.newobject.variant.invalid');
    }
    const variantData = variant.toObject();
    // toOBject remove parent properties
    variantData.parent = variant.parent;
    const defaultItem = Variant.create(variantData);
    defaultItem.color = '[newcolor]';
    // generate a random remoteCode
    defaultItem.generateRandomRemoteCode();
    defaultItem.reset();
    return defaultItem;
  }

  static newFamily(variant, familyObj) {
    const scopename = `models.pim.variantmanager.newfamily.${familyObj.name}`;
    Vue.$log.debug(`${scopename}.started`);
    familyObj.attributes.forEach((familyAttribute) => {
      const isJsonContent = familyAttribute.type === 'JSON';
      const index = variant.attributes.findIndex(
        a => familyAttribute.remoteId.split('.')[2] === lastCode(a.name),
      );
      // detect if some group attribute already exist like informations, composition
      if (index !== -1 && isJsonContent) {
        const attribute = variant.attributes[index];
        VariantAttribute.updateAttribute(attribute, familyAttribute);
        Vue.set(attribute, 'name', familyAttribute.name);
        Vue.set(attribute, 'familyAttributeId', familyAttribute.id);
      } else if (isJsonContent) {
        Vue.$log.debug(`${scopename}.addattribute.${familyAttribute.name}.wip`);
        VariantAttribute.addAttribute(variant, familyAttribute);
      } else {
        Vue.$log.error(`${scopename}.type.${familyAttribute.type}.not.supported`);
      }
    });
    // delete all group attribute
    VariantAttribute.deleteAttribute(variant, familyObj);
  }

  /**
   * display only attribute present inside family attribute.
   * all extra attribute from old variant attribute and not used
   * @param  {[type]} variant   [description]
   * @param  {[type]} familyObj [description]
   * @return {[type]}           [description]
   */
  static updateFamily(variant, familyObj) {
    const scopename = `models.pim.variantmanager.updatefamily.${familyObj.name}`;
    Vue.$log.debug(`${scopename}.started`);
    // @todo need to remove all theses attribute from infos fields
    const keysToRemove = ['Validation'];
    if (Array.isArray(familyObj.attributes) && familyObj.attributes.length > 0) {
      Vue.$log.debug(`${scopename}.familyid.${familyObj.id}.attributes.found`);
      familyObj.attributes.forEach((familyAttribute) => {
        const isJsonContent = familyAttribute.type === 'JSON';
        const isForbidden = keysToRemove.includes(familyAttribute.name);
        // eslint-disable-next-line max-len
        const index = variant.attributes.findIndex(
          a => parseInt(a.familyAttributeId, 10) === familyAttribute.id,
        );
        // check if it s a update from family attributes
        if (index !== -1 && isJsonContent && !isForbidden) {
          Vue.$log.debug(`${scopename}.updateattribute.${familyAttribute.name}.wip`);
          const attribute = variant.attributes[index];
          VariantAttribute.updateAttribute(attribute, familyAttribute);
        } else if (isJsonContent && !isForbidden) {
          Vue.$log.debug(`${scopename}.addattribute.${familyAttribute.name}.wip`);
          VariantAttribute.addAttribute(variant, familyAttribute);
        } else if (!isForbidden) {
          Vue.$log.error(`${scopename}.name.${familyAttribute.name}.has.been.removed`);
        } else {
          Vue.$log.error(
            `${scopename}.name.${familyAttribute.name}.type.${familyAttribute.type}.not.supported`,
          );
        }
      });
      // delete all group attribute
      VariantAttribute.deleteAttribute(variant, familyObj);
    } else {
      Vue.$log.error(`${scopename}.familyid.${familyObj.id}.attributes.not.found`);
    }
  }

  static saveFamily(variant, familyObj) {
    const scopename = `models.pim.variantmanager.savefamily.${familyObj.name}`;
    if (!(variant instanceof Variant)) {
      throw new DefaultError(`${scopename}.variant.invalid`);
    }
    if (typeof familyObj !== 'object') {
      throw new DefaultError(`${scopename}.familyobj.invalid`);
    }
    Vue.$log.log(`${scopename}.started`);
    // let create a new variant familys
    if (variant.familyId && variant.previousFamily && variant.previousFamily !== variant.familyId) {
      return VariantManager.newFamily(variant, familyObj);
    }
    if (variant.familyId && typeof variant.previousFamily === 'undefined') {
      return VariantManager.updateFamily(variant, familyObj);
    }

    return Vue.$log.error(`${scopename}.no.action`);
  }
}
