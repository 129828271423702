// https://vuex.vuejs.org/en/state.html
/* eslint no-param-reassign : 0 */
/**
 * example :
 *   type: example
 *   state:
 *     exampleList: [],
 *     exampleListKey: {},
 */

export default {
  isLoaded: false,
  attributes: [],
  state: null,
  entityTypes: {},
  fields: {},
  profiles: {},
};
