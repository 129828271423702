// https://vuex.vuejs.org/en/actions.html
import Vue from 'vue';
import axios from 'axios';
import { errorHandler } from '@/store/modules/global_helpers';

const BASE_URI = '/api/v1/pim/family_attribute';
const BASE_URI_V2 = '/api/v2/pim/family_attribute';
const namespace = 'store.modules.family_attribute.actions';

export default {
  get({ commit }, remoteCode) {
    Vue.$log.debug(`${namespace}.get.${remoteCode}.started`);
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URI}/${remoteCode}`)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.get.${remoteCode}.not.found`);
          }
          commit('set_family', response.data.result);
          return resolve(response.data.result);
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return errorHandler(reject)(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  update({ commit }, payload) {
    Vue.$log.debug(`${namespace}.update.${payload.remoteCode}.started`);
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI_V2}/${payload.remoteCode}`, payload)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.update.${payload.remoteCode}.not.found`);
          }
          commit('family/set_family', response.data.result, { root: true });
          return resolve(response.data.result);
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return errorHandler(reject)(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  updateName({ commit }, payload) {
    Vue.$log.debug(`${namespace}.updatename.${payload.remoteCode}.name.tarted`);
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI_V2}/${payload.remoteCode}/name`, payload)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.update.${payload.remoteCode}.name.not.found`);
          }
          commit('family/set_family', response.data.result, { root: true });
          return resolve(response.data.result);
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return errorHandler(reject)(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  active({ commit }, payload) {
    Vue.$log.debug(`${namespace}.active.${payload.remoteCode}.active.started`);
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI_V2}/${payload.remoteCode}/active`, {
          active: payload.active,
        })
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.update.${payload.remoteCode}.active.not.found`);
          }
          return resolve(response.data.result);
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return errorHandler(reject)(error);
        });
    });
  },
};
