<template>
  <v-select
    v-model="stockType"
    :items="itemsValue"
    menu-props="auto"
    item-text="name"
    item-value="id"
    :label="label || 'Pick your stock type'"
    hint="Pick your stock type"
    :messages="getDefaultValues()"
    :loading="isCompletudeRequired(bindAttribute, currentIssues)"
    persistent-hint
  ></v-select>
</template>
<script>
import Vue from 'vue';
import AttributeBaseMixins from '../../mixins/attribute-base';
import CompletudeBaseMixins from '../../mixins/completude-base';

export default {
  mixins: [AttributeBaseMixins, CompletudeBaseMixins],
  name: 'attributeFamily',
  data: () => ({
    itemsValue: [
      { name: 'Défault (Dropshippé)', id: 'drop' },
      { name: 'Confié', id: 'cnf' },
      // @aurelien : never
      // { name: 'Acheté', id: 'ach' },
      { name: 'Dropshippé', id: 'drop' },
    ],
  }),
  computed: {
    stockType: {
      get() {
        if (
          typeof this.item[this.property] !== 'undefined'
          && typeof this.item[this.property][this.keyAttr] !== 'undefined'
        ) {
          return this.item[this.property][this.keyAttr];
        }
        if (typeof this.item[this.property] !== 'undefined') {
          return this.item[this.property];
        }
        return '';
      },
      set(value) {
        if (
          typeof this.item[this.property] !== 'undefined'
          && typeof this.item[this.property][this.keyAttr] !== 'undefined'
        ) {
          Vue.set(this.item[this.property], this.keyAttr, value);
        } else {
          Vue.set(this.item, this.property, value);
        }
      },
    },
  },
};
</script>
