import Log from '@/models/utils/log';

const defaultAttribute = {
  entity: null,
  entityType: null,
  label: { fr: '' },
  marketplaces: null,
  name: '',
  order: 0,
};

class AttributeCore {
  constructor(data, options = {}) {
    this.objectInitializer(data);
    Log.debug(`models.pim.attribute.core.${this.constructor.name.toLowerCase()}.started`);
    this.defineProperty();
    this.options = options;
  }

  objectInitializer(data) {
    if (!data || Object.keys(data).length === 0) {
      return;
    }
    Object.assign(this, data);
  }

  /**
   * convert instance in plain object
   * @return {Object}
   */
  toObject() {
    Log.debug(`models.pim.attribute.core.${this.constructor.name.toLowerCase()}.toobject.started`);
    // dont use Object.assign because circular json error due to parent property
    return JSON.parse(JSON.stringify(Object.seal(this)));
  }

  defineProperty() {
    Log.debug(
      `models.pim.attribute.core.${this.constructor.name.toLowerCase()}.defineproperty.started`,
    );
  }

  isMain() {
    if (typeof this.options.entityTypes === 'undefined') {
      return false;
    }
    const AttributeType = [
      this.options.entityTypes.R_TYPE,
      this.options.entityTypes.RCT_TYPE,
      this.options.entityTypes.RC_TYPE,
      this.options.entityTypes.RCM_TYPE,
    ];
    if (!AttributeType.includes(this.entityType)) {
      return false;
    }
    return true;
  }

  isAttribute() {
    if (typeof this.options.entityTypes === 'undefined') {
      return false;
    }
    const AttributeType = [
      this.options.entityTypes.RCA_TYPE,
      this.options.entityTypes.RA_TYPE,
      this.options.entityTypes.RCTA_TYPE,
    ];
    if (!AttributeType.includes(this.entityType)) {
      return false;
    }
    return true;
  }

  isMedia() {
    if (typeof this.options.entityTypes === 'undefined' || this.options.entityTypes.RCM_TYPE !== this.entityType) {
      return false;
    }
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  isMultiple() {
    return false;
  }

  getHelp() {
    try {
      if (typeof this.help !== 'undefined') {
        return `⚠️ ${this.help[this.options.currentLang] || this.help.en || this.help || this.defaultHelp()}`;
      }
    } catch (err) {
    }
    return this.defaultHelp();
  }

  getLabel() {
    if (typeof this.label === 'undefined') {
      return '';
    }
    return `${this.label[this.options.currentLang] || this.label}`;
  }

  defaultHelp() {
    return 'Choose or fill your data';
  }
}

class AttributeSelect extends AttributeCore {
  defaultHelp() {
    return 'Select your data';
  }

  isMultiple() {
    return this.multiple || false;
  }
}


export default function Attribute(data, options = {}) {
  if (!data) {
    return new AttributeCore(defaultAttribute);
  }
  if (typeof data.select !== 'undefined') {
    return new AttributeSelect(data, options);
  }
  return new AttributeCore(data, options);
}
