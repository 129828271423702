<template>
  <v-container fill-height fluid>
    <v-layout wrap>
      <v-flex md12 sm12 lg12>
        <common-import-upload />
      </v-flex>
      <v-flex md6 sm6 lg6>
        <common-import-list />
      </v-flex>
      <v-flex md6 sm6 lg6>
        <common-import-edit />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ImportView',
  data: () => ({}),
};
</script>

<style></style>
