<template>
  <div v-if="hasError">
    <ul>
      <li class="info--text" v-if="hasSlot('info')">
        <slot name="info"></slot>
      </li>
      <li class="warning--text" v-if="hasSlot('warning')">
        <slot name="warning"></slot>
      </li>
      <li
        v-for="(error, index) in getErrors(errors)"
        :key="index"
        :class="`${getClass(error)}--text`"
      >
        {{ getMessage(property, error) }}
      </li>
      <li class="error--text" v-if="hasSlot('error') || hasSlot('default')">
        <slot name="error"></slot>
        <slot></slot>
      </li>
    </ul>
  </div>
</template>

<script>
import ErrorBaseMixins from '../../mixins/error-base';

export default {
  name: 'coreError',
  mixins: [ErrorBaseMixins],
  props: {
    property: {
      type: String,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    hasError() {
      return (
        this.errors.length > 0
        || this.hasSlot('warning')
        || this.hasSlot('error')
        || this.hasSlot('default')
      );
    },
    hasSlot(name = 'default') {
      if (!!this.$slots[name] && this.$slots[name].length > 0) {
        return this.$slots[name][0].text.trim().length > 0;
      }
      return false;
    },
  },
};
</script>

<style></style>
