<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      class="elevation-1 ta-fix-column ta-classification"
      :items="classifications"
      :server-items-length="itemsCount"
      :options.sync="itemsOptions"
      :loading="loading"
      :search="search"
      :custom-filter="filterOnlyCapsText"
      :footer-props="{ 'items-per-page-options': [100, 200, 500, -1] }"
      loading-text="Loading... Please wait"
      :dense="settings.dense"
      fixed-header
    >
      <template v-slot:top>

      </template>
      <template v-for="head in headers" v-slot:[`header.${head.value}`]="{ header }">
        <v-text-field
          v-if="head.value === 'name'"
          v-model="search"
          :key="head.value"
          label="Classification des produits"
          class="title-search"
        ></v-text-field>
        <h6 style="display: inline-block"
          v-else
          :key="head.value"
          :header="head"
        >
          {{ header.text }}
        </h6>
      </template>
      <template v-slot:item.data-table-expand="">
        <div class="text-center">
          <v-btn fab x-small class="ta-classification">1</v-btn>
        </div>
      </template>
      <template v-slot:item.path="{ item }">
        <template v-for="(i, index) in buildPath(item.path)">
          <classification-link :key="i.remoteCode"
            v-model="i.remoteCode"
            :label="i.name">
            <template v-if="index !== buildPath(item.path).length - 1">
              <v-icon small>mdi-arrow-right-bold-box-outline</v-icon>
            </template>
          </classification-link>
        </template>
      </template>
      <template v-slot:item.family="{ item }">
        <family-link v-if="item.familyId"
          v-model="buildFamily(item.familyId).remoteCode"
          :label="buildFamily(item.familyId).name">
        </family-link>
        <error-link v-else></error-link>
      </template>
      <template v-slot:item.active="{ item }">
        <v-switch
          v-model="item.active"
           @change="active(item)"
           readonly
        ></v-switch>
      </template>
      <template v-slot:item.actions="{ item }">
        <router-link :to="getRouteLink(item)">
          <v-icon small class="mr-1">
            mdi-pencil-circle
          </v-icon>
        </router-link>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import ClassificationLink from '@/components/link/classification';
import FamilyLink from '@/components/link/family';
import ErrorLink from '@/components/link/error';
import NotificationMixins from '@/mixins/notification-base';

export default {
  name: 'classificationListView',
  components: {
    ClassificationLink,
    FamilyLink,
    ErrorLink,
  },
  mixins: [NotificationMixins],
  data: () => ({
    headers: [
      { text: 'Nom de l\'attribut', value: 'name' },
      { text: 'Path', value: 'path', filterable: false },
      { text: 'Family', value: 'family', filterable: false },
      { text: 'Identifiant', value: 'remoteCode', filterable: false },
      {
        text: 'Actions', value: 'actions', filterable: false, sortable: false,
      },
    ],
    search: '',
    loading: false,
    endpointType: 'classification',
  }),
  computed: {
    ...mapGetters('classification', ['classifications']),
    ...mapGetters('family', ['families']),
    classificationsByIds() {
      return this.classifications.reduce((r, c) => {
        r[c.id] = c;
        return r;
      }, {});
    },
    familyByIds() {
      return this.families.reduce((r, c) => {
        r[c.id] = c;
        return r;
      }, {});
    },
    settings() {
      return this.$store.getters['settings/settings'];
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    filterOnlyCapsText(value, search, item) {
      return value != null
        && search != null
        && typeof value === 'string'
        && value.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    },
    getColor(count) {
      if (count === 0 || !count) return 'red';
      return 'green';
    },
    getRouteLink(e) {
      return {
        name: 'settings.classification.remoteCode',
        params: {
          remoteCode: e.remoteCode.replace('pim.c.', ''),
        },
      };
    },
    buildPath(pathArray) {
      if (!this.classificationsByIds) return [];
      return pathArray.reduce((r, s) => {
        // root id for classifications table
        if (s === 1) return r;
        if (typeof this.classificationsByIds[s] !== 'undefined') {
          r.push({
            name: this.classificationsByIds[s].name,
            remoteCode: this.classificationsByIds[s].remoteCode.replace('pim.c.', ''),
          });
        }
        return r;
      }, []);
    },
    buildFamily(familyId) {
      if (!this.familyByIds) return [];
      return ({
        name: this.familyByIds[familyId].name,
        remoteCode: this.familyByIds[familyId].remoteCode.replace('pim.f.', ''),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
div.title-search .v-text-field .v-label {
  font-classification: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 300 !important;
  line-height: 1.5em !important;
  font-size: 0.75rem !important;
  color: rgba(0, 0, 0, 0.6);
}
</style>
