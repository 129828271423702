// https://vuex.vuejs.org/en/state.html
/* eslint no-param-reassign:0 */
export default {
  hasLabel: state => (type) => {
    if (typeof state.labels[type] === 'undefined') {
      return false;
    }
    return true;
  },
  labels: state => (type) => {
    if (typeof state.labels[type] === 'undefined') {
      return {};
    }
    return state.labels[type];
  },
  options: state => (type) => {
    if (typeof state.options[type] === 'undefined') {
      return {};
    }
    return state.options[type];
  },
};
