import Vue from 'vue';
import Core from './core';
import VariantList from './variantlist';
import ProductStatus from './productstatus';

export default class Product extends Core {
  applyModel() {
    if (this.hasStatus()) {
      this.status = ProductStatus.create(this.status);
    }
    if (this.hasVariant()) {
      this.variant = VariantList.create(this.variant, this);
      this.image = this.getFirstImage();
    }
    return this;
  }

  hasVariant() {
    return (
      typeof this.variant !== 'undefined' && Array.isArray(this.variant) && this.variant.length > 0
    );
  }

  hasStatus() {
    return typeof this.status !== 'undefined';
  }

  getFirstImage() {
    return this.hasVariant() ? this.variant.getFirstImage() : false;
  }

  defineProperty() {
    this.setHookName();
  }

  setHookName() {
    let { name } = this;
    Object.defineProperty(this, 'name', {
      set(value) {
        name = value.toUpperCase();
        if (this.hasVariant()) {
          this.variant.setVariantsName();
        }
      },
      get() {
        return name;
      },
    });
  }

  /**
   * convert instance in plain object
   * @return {Object}
   */
  toObject() {
    try {
      const rootProduct = this.clone();
      rootProduct.parent = null;
      delete rootProduct.parent;
      rootProduct.image = null;
      if (rootProduct.image && typeof rootProduct.image.toObject === 'function') {
        rootProduct.image = rootProduct.image.toObject();
      }
      if (rootProduct.hasStatus()) {
        rootProduct.status = rootProduct.status.toObject();
      }
      if (rootProduct.hasVariant()) {
        rootProduct.variant = rootProduct.variant.map(variant => variant.toObject());
      }
      return JSON.parse(JSON.stringify(Object.seal(rootProduct)));
    } catch (e) {
      Vue.$log.error(e);
      return {};
    }
  }

  getChildren() {
    if (this.hasVariant()) {
      return {
        variant: this.variant,
        sku: this.variant.reduce((skus, variant) => {
          // eslint-disable-next-line no-param-reassign
          skus = skus.concat(variant.getChildren().sku);
          return skus;
        }, []),
      };
    }
    return { variant: [], sku: [] };
  }

  copy() {
    const product = { ...this };
    delete product.variant;
    delete product.status;
    delete product.image;
    delete product.errors;
    delete product.errorsOriginal;
    return product;
  }

  async getCompletude(dispatch) {
    if (this.percent && ['0', '0.00', '0,00', 0].indexOf(this.percent) === -1) {
      return this.percent;
    }
    const product = this.copy();
    return dispatch(
      'completude/calc',
      {
        endpoint: 'product',
        data: product,
      },
      { root: true },
    ).then((completude) => {
      Vue.set(this, 'percent', completude);
      return this;
    });
  }

  async setCompletude(dispatch) {
    const product = this.copy();
    return dispatch(
      'completude/update',
      {
        endpoint: 'product',
        data: product,
      },
      { root: true },
    ).then((completude) => {
      Vue.set(this, 'percent', completude);
      return this;
    });
  }
}
