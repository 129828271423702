const defaultData = {
  isValid: true,
  isClicked: false,
  openDialog: false,
};

export default {
  data: () => ({
    ...defaultData,
  }),
  watch: {
    dialog() {
      this.openDialog = this.dialog;
    },
    value() {
      this.item = this.value;
    },
  },
  methods: {
    save() {
      // click once
      if (!this.isClicked) {
        this.isClicked = true;
        this.$emit('input', this.item);
        this.$emit('onSave', () => {
          this.closeDialog();
        });
      }
    },
    close() {
      this.$emit('onClose', () => {
        this.closeDialog();
      });
    },
    closeDialog() {
      this.defaultValues();
    },

    defaultValues() {
      this.isValid = defaultData.isValid;
      this.isClicked = defaultData.isClicked;
      // https://vuejs.org/v2/guide/components-custom-events.html#sync-Modifier
      this.$emit('update:dialog', false);
    },
  },
};
