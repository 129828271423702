<template>
  <v-container fill-height fluid>
    <v-layout wrap>
      <v-flex md12>
        <material-card
          color="blue"
          title="Import list by collection"
          text="Here is a subtitle for this table"
        >
          <core-alert></core-alert>
          <v-data-table :headers="headers" :items="items" hide-default-footer>
            <template v-slot:item.status="{ item }">
              <v-chip :color="getColor(item.status)" dark>{{ item.status }}</v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" v-on:click.stop @click="gotoImport(item)">
                mdi-table-edit
              </v-icon>
              <v-icon small v-on:click.stop @click="deleteItem(item)">
                mdi-table-remove
              </v-icon>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ChipBaseMixins from '../../mixins/chip-base';

export default {
  mixins: [ChipBaseMixins],
  data: () => ({}),
  computed: {
    ...mapGetters('form/rules', { textRules: 'text' }),
    items() {
      return this.$store.getters['collection/collections'];
    },
    headers() {
      return this.$store.getters['settings/headers'].collection;
    },
  },
  // called when page is created before dom
  created() {
    this.list();
  },
  methods: {
    gotoImport(item) {
      this.$router.push({ path: `/pim/collection/${item.id}/products` });
    },
    ...mapActions('collection', ['list', 'save']),
    deleteItem(item) {
      this.loading = true;
      const index = this.items.indexOf(item);
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure you want to delete this item?')) {
        this.$store
          .dispatch('collection/delete', item.id)
          .then(() => this.items.splice(index, 1))
          .catch(error => this.throwError(error))
          .finally(() => {
            this.loading = false;
            this.close();
          });
      }
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
  },
};
</script>

<style></style>
