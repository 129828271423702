<template>
  <span>
    DashBoard
  </span>
</template>

<script>
export default {
  mounted() {
    this.$log.log('components.pim.dashboardview.mounted');
  },
};
</script>

<style></style>
