<template>
  <ta-span>{{ cleanValue(value) }}</ta-span>
</template>

<script>
import LangSubMixins from '@/mixins/config/lang-sub-base';

export default {
  name: 'FieldCountry',
  mixins: [LangSubMixins],
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    countries() {
      return this.$store.getters['config/countriesByKey'];
    },
  },
  methods: {
    cleanValue(value) {
      try {
        const country = this.countries[value.slugify()];
        if (country) {
          if (country.translations[this.currentLang]) {
            return country.translations[this.currentLang];
          }
          return country.translations.en;
        }
      } catch (e) {} // eslint-disable-line no-empty
      return '';
    },
  },
};
</script>
