<template>
  <v-card class="mx-auto">
    <v-card-title class="error">Errors messages ({{ errors.length }})</v-card-title>
    <v-card-text>Display errors messages received during import</v-card-text>
    <v-expand-transition>
      <v-list nav dense>
        <v-list-item-group active-class="pink--text" multiple>
          <template v-for="(item, index) in errors">
            <v-list-item :key="item.vueKey">
              <template v-slot:default="{ active }">
                <v-list-item-content>
                  <v-row align="center">
                    <v-col cols="12" lg="4">
                      <v-list-item-title v-text="`Nom commun: ${item.name}`"></v-list-item-title>
                      <v-list-item-subtitle
                        v-if="item.category"
                        class="text--primary"
                        v-text="`Catégorie de produit: ${item.category}`"
                      >
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="item.family"
                        class="text--primary"
                        v-text="`Famille de produit: ${item.family}`"
                      >
                      </v-list-item-subtitle>
                    </v-col>
                    <v-col cols="12" lg="2">
                      <v-list-item-subtitle v-if="item.color" v-text="`color: ${item.color}`">
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="item.size" v-text="`size: ${item.size}`">
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="item.ean" v-text="`ean: ${item.ean}`">
                      </v-list-item-subtitle>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <template v-for="(error, i) in item.list">
                        <v-list-item-subtitle :key="i" v-text="error"></v-list-item-subtitle>
                      </template>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-divider v-if="index < errors.length - 1" :key="index"></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </v-expand-transition>
  </v-card>
</template>

<script>
import ErrorBaseMixins from '../../mixins/error-base';

export default {
  name: 'ListErrors',
  mixins: [ErrorBaseMixins],
  props: {
    value: {
      type: [Array, Object], // String, Number, Boolean, Function, Object, Array
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    errors() {
      let value = null;
      if (Array.isArray(this.value)) {
        value = this.value;
      } else {
        value = [this.value];
      }
      return value.reduce((errors, level1) => {
        const isCsv = typeof level1[this.fields.FIELD_EAN] !== 'undefined';
        const isProduct = typeof level1.variant !== 'undefined';
        if (typeof level1.errors !== 'undefined' && Object.keys(level1.errors).length > 0) {
          errors.push(isCsv ? this.csv(level1) : this.productOrVariant(level1, isProduct));
          if (typeof level1.variant !== 'undefined') {
            level1.variant.forEach((variant) => {
              if (Object.keys(variant.errors).length > 0) {
                errors.push(this.variant(variant));
              }
              variant.skus.forEach((sku) => {
                if (Object.keys(sku.errors).length > 0) {
                  errors.push(this.sku(sku));
                }
              });
            });
          } else if (typeof level1.skus !== 'undefined') {
            level1.skus.forEach((sku) => {
              if (Object.keys(sku.errors).length > 0) {
                errors.push(this.sku(sku));
              }
            });
          }
        }
        return errors;
      }, []);
    },
    fields() {
      return this.$store.getters['api/attribute/fields'];
    },
  },
  methods: {
    /**
     * Get all messages error
     *s
     * @param  {Array} errors [description]
     * @return {[type]}        [description]
     *
     * @example
     *           barcode can have multiple errors inside
     *           errors[key]
     */
    getMessageErrors(errors) {
      return Object.keys(errors).reduce((list, key) => {
        if (!Array.isArray(errors[key])) {
          list.push(this.getMessage(key, errors[key]));
        } else if (errors[key].length > 1) {
          errors[key].forEach((error) => {
            list.push(this.getMessage(key, error));
          });
        } else {
          list.push(this.getMessage(key, errors[key][0]));
        }
        return list;
      }, []);
    },
    csv(datum) {
      this.$log.debug('components.pim.listerrors.csv.started');
      return {
        vueKey: datum.vueKey,
        type: 'csv',
        id: 0,
        name: datum[this.fields.FIELD_PRODUCTNAME],
        category: datum['Catégorie de produit'],
        color: datum[this.fields.FIELD_COLOR],
        ean: datum[this.fields.FIELD_EAN],
        size: datum['Taille (FR)'],
        list: this.getMessageErrors(datum.errors),
      };
    },
    productOrVariant(datum, isProduct = true) {
      return isProduct ? this.product(datum) : this.variant(datum);
    },
    product(datum) {
      this.$log.debug('components.pim.listerrors.product.started');
      return {
        vueKey: datum.vueKey,
        type: 'product',
        id: datum.id,
        name: datum.name,
        category: datum.classificationId,
        list: this.getMessageErrors(datum.errors),
      };
    },
    variant(datum) {
      this.$log.debug('components.pim.listerrors.variant.started');
      return {
        vueKey: datum.vueKey,
        type: 'variant',
        id: datum.id,
        name: datum.name,
        family: datum.familyId,
        color: datum.color,
        list: this.getMessageErrors(datum.errors),
      };
    },
    sku(datum) {
      this.$log.debug('components.pim.listerrors.sku.started');
      return {
        vueKey: datum.vueKey,
        type: 'datum',
        id: datum.id,
        name: datum.name,
        color: datum.color,
        size: datum.size,
        list: this.getMessageErrors(this.getErrors(datum.errors)),
      };
    },
  },
};
</script>

<style lang="css" scoped></style>
