import { version } from '../../../../package.json';

export default {
  drawer: null,
  color: 'success',
  logo: 'https://portal.theagent.com/assets/backoffice/img/the-agent-logo-white.png',
  image:
    'http://media1.theagent.com/sale/sale_7700/product_1361832/max_fullsize_1.jpg?ts=1571240428000',
  appVersion: version,
  restApi: {
    version: 'dev',
    versionCommit: null,
  },
  user: {},
  client: {},
  lang: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
};
