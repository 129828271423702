import Notification from './notification-base';
import Alert from './alert-base';
import Chip from './chip-base';
import Profile from './profile-base';
import Marketplace from './marketplace-base';
import Lang from './lang-base';
import Color from './color-base';
import Search from './search-base';
import Completudes from './completudes-base';
import Link from './link-base';


// https://freek.dev/823-using-global-mixins-in-vuejs
// for the moment we cant use global mixin
// export default {
//   $_notification_class: {
//     Notification,
//   },
// };
export default [
  Notification,
  Alert,
  Chip,
  Profile,
  Marketplace,
  Lang,
  Color,
  Search,
  Completudes,
  Link,
];
