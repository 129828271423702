/* eslint max-len:0 */
import Vue from 'vue';
import { Attribute } from '@/models';

const namespace = 'mixins.form-base';

export default {
  props: {
    force: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    attribute() {
      if (typeof this.customComponent !== 'undefined' && this.customComponent) {
        return this.customComponent;
      }
      const attr = this.$store.getters['api/attribute/attribute'](this.keyAttr || this.property);
      // attr is an array with name: default
      if (attr && !Array.isArray(attr)) {
        return Attribute(attr, {
          entityTypes: this.$store.getters['config/attribute.typeEntities'],
          currentLang: this.currentLang || 'fr',
        });
      }
      return Attribute(undefined);
    },
    valueAttribute: {
      get() {
        if (this.hasDataModel && this.keyAttr && typeof this.item.dataJson !== 'undefined') {
          return this.convertIfArrayNeeded(typeof this.item.dataJson[this.keyAttr] !== 'undefined'
            ? this.item.dataJson[this.keyAttr]
            : '');
        }
        if (this.keyAttr) {
          return this.convertIfArrayNeeded(typeof this.item[this.property][this.keyAttr] !== 'undefined'
            ? this.item[this.property][this.keyAttr]
            : '');
        }
        return this.convertIfArrayNeeded(this.item[this.property]);
      },
      set(value) {
        if (this.hasDataModel && this.keyAttr) {
          Vue.set(this.item.dataJson, this.keyAttr, value);
        } else if (this.keyAttr) {
          Vue.set(this.item[this.property], this.keyAttr, value);
        } else {
          Vue.set(this.item, this.property, value);
        }
      },
    },
    bindAttribute() {
      return this.keyAttr || this.property;
    },
    selectValues() {
      if (!this.itemsValue || this.itemsValue.length === 0) {
        return [];
      }
      const debug = this.$store.getters['settings/theagent'].sillyMode;
      // https://github.com/vuejs/eslint-plugin-vue/blob/master/docs/rules/no-side-effects-in-computed-properties.md
      // .slice makes a copy of the array, instead of mutating the orginal
      let itemsValue = [];
      try {
        itemsValue = this.itemsValue.slice(0);
      } catch (err) {
        this.$log.error(`${namespace}.selectvalues.value.itemsvalue.failed`);
        if (debug) this.$log.error(err);
      }
      if (!this.multipleSelection) {
        // add default value
        itemsValue.unshift({
          name: 'Empty value',
          id: '',
        });
      }
      if (!this.item[this.property]) {
        this.$log.debug(`${namespace}.if.value.for.${this.property}.is.empty.let.skipped.all.process`);
        return itemsValue.map(i => ({
          id: i.id,
          name: i.name.fra || i.name.fr || i.name,
        }));
      }

      if (this.attribute.isAttribute()) {
        if (debug && this.isEmpty(this.item[this.property][this.keyAttr])) {
          this.$log.error(`${namespace}.no.attribute.value.for.${this.keyAttr}`);
        }
        const valueFromItem = [];
        if (Array.isArray(this.item[this.property][this.keyAttr])) {
          if (!this.attribute.isMultiple() && this.item[this.property][this.keyAttr].length > 1) {
            this.$log.error(`${namespace}.attribute.value.is.not.multiple.but.too.many.value`);
          }
          if (this.force) {
            valueFromItem.push(...this.item[this.property][this.keyAttr]);
          }
        } else if (this.keyAttr && typeof this.item[this.property][this.keyAttr] !== 'undefined') {
          if (this.force) {
            // all attribute values from database
            valueFromItem.push(this.item[this.property][this.keyAttr]);
          }
        } else if (typeof this.item[this.property] !== 'undefined') {
          if (this.force) {
            // all direct values from database
            valueFromItem.push(this.item[this.property]);
          }
        } else {
          this.$log.error(`${namespace}.attribute.field.${this.keyAttr || this.property}.have.not.value`);
        }
        if (this.force) {
          valueFromItem.forEach((value) => {
            const index = itemsValue.findIndex(v => v.id.slugify() === (value.fr || value).slugify());
            if (index === -1) {
              itemsValue.push({
                name: value,
                id: value.fr || value,
              });
            }
          });
        }
      }
      return itemsValue.map(i => ({
        id: i.id,
        name: i.name.fra || i.name.fr || i.name,
      }));
    },
    radioValues() {
      if (typeof this.attribute.values === 'undefined') {
        return [];
      }
      if (Array.isArray(this.attribute.values) && typeof this.attribute.values[0] === 'object') {
        return this.attribute.values.map(value => ({
          value: value.fra || value.fr,
          label: value[this.currentLang] || value.fra || value.fr,
        }));
      }
      return this.attribute.values.map(value => ({
        value,
        label: value,
      }));
    },
    multipleSelection() {
      const debug = this.$store.getters['settings/theagent'].sillyMode;
      try {
        return this.attribute.isMultiple();
      } catch (err) {
        this.$log.error(`${namespace}.attribute.field.${this.keyAttr || this.property}.has.multiple.issue`);
        if (debug) this.$log.error(err);
      }
      return false;
    },
  },
  methods: {
    /**
     * convert string to array if multiSelection is setted in database
     *
     * @param  {Mixed} value String|Array
     * @return {Mixed}       [description]
     */
    convertIfArrayNeeded(value) {
      if (this.multipleSelection && !Array.isArray(value)) {
        if (typeof value === 'string' && value.indexOf(',') !== -1) {
          return value.split(',').map(v => v.trim());
        }
        return value ? [value] : [];
      }
      return value;
    },
    isEmpty(values) {
      if (typeof values === 'string' && !values) return true;
      if (Array.isArray(values) && values.length === 1 && typeof values[0] === 'string' && !values[0]) return true;
      if (Array.isArray(values) && values.length === 0) return true;
      return false;
    },
    bindLangAttribute(lang) {
      return `${this.bindAttribute}.${lang}`;
    },
  },
};
