const namespace = 'utils.router-link';

export default {
  methods: {
    getUrlByName: (name, value) => {
      if (typeof name !== 'string') {
        throw new TypeError(`${namespace}.name.is.invalid`);
      }
      if (!value || typeof value === 'object') {
        throw new TypeError(`${namespace}.value.is.invalid`);
      }
      return {
        name,
        params: { remoteCode: value },
      };
    },
  },
};
