<template>
  <div>
    {{ name(item[property]) }}
  </div>
</template>
<script>
import AttributeBaseMixins from '../../mixins/attribute-base';
import { Gender } from '../../models';

export default {
  name: 'attributeGender',
  mixins: [AttributeBaseMixins],
  methods: {
    name(id) {
      return Gender.fetchById(id);
    },
  },
};
</script>
