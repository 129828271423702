<template>
  <v-layout row wrap>
    <v-flex xs2 md2 v-for="(item, index) in items" :key="index"
      ><core-image :value="item">
        <template v-slot:default>
          <ul>
            <li class="info--text" v-if="item.order">order:{{ item.order }}</li>
            <li class="info--text" v-if="item.marketplace">marketplace:{{ item.marketplace }}</li>
            <template v-if="getErrors(item).length > 0">
              <template v-for="(error, index) in getErrors(item)">
                <li :key="index" :class="`${error.level}--text`">
                  {{ error.message }}
                </li>
              </template>
            </template>
            <template v-else>
              <li class="success--text">Everything is okay</li>
            </template>
          </ul>
        </template>
      </core-image>
    </v-flex>
  </v-layout>
</template>

<script>
import Mixins from '../../mixins';

export default {
  name: 'MediaGridView',
  mixins: Mixins,
  props: {
    items: {
      type: Array,
    },
  },
  methods: {
    /**
     * identify the sm grid for media list
     * @return {[type]} [description]
     */
    grid() {
      const imgNumber = Object.keys(this.items).reduce((raw, index) => {
        // eslint-disable-next-line max-len
        if (
          typeof this.items[index].original !== 'undefined'
          || typeof this.items[index].aliases !== 'undefined'
        ) {
          raw += 1; // eslint-disable-line no-param-reassign
        }
        return raw;
      }, 0);
      // to avoid to display picture with witdh 100%
      // we apply a arbitrary grid to 2
      if (imgNumber < 4 && !this.mini) {
        return 2;
      }
      // this option is only to column display
      if (this.mini) {
        return 5;
      }
      return Math.round(12 / imgNumber);
    },
    getErrors(item) {
      const errors = [];
      if (!item.marketplace) {
        errors.push({
          level: 'warning',
          message: 'Your marketplace has not filled, may be an issue',
        });
      }
      if (!item.id && item.reference) {
        errors.push({
          level: 'error',
          message: `Your reference "${item.reference}" have not image`,
        });
      } else if (!item.reference && item.id) {
        errors.push({
          level: 'error',
          message: `Your filename "${item.id}" have not reference`,
        });
      }
      if (!item.color && item.id) {
        errors.push({
          level: 'error',
          message: `Your filename "${item.id}" with the reference "${item.reference}" has not found`,
        });
      }
      if (!item.order && item.id) {
        errors.push({
          level: 'error',
          message: `Your filename "${item.id}" is not ordered`,
        });
      }
      if (!item.original && !item.alias) {
        errors.push({
          level: 'error',
          message: `Your image filename "${item.id}" doesnt exist`,
        });
      }
      if (!['jpg', 'jpeg', 'png', 'gif', 'webp'].some(f => item.original.toLowerCase().indexOf(f) !== -1)) {
        errors.push({
          level: 'error',
          message: `Your image filename "${item.id}" is invalid and not web valid`,
        });
      }
      return errors;
    },
  },
};
</script>

<style></style>
