<template>
  <v-breadcrumbs :items="breadCrumbs">
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
     <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.to"
        :href="item.href"
        class="text-subtitle-2 crumb-item"
        :disabled="item.disabled"
        exact
      >
        <v-toolbar-title>
          {{ item.text }}
        </v-toolbar-title>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: 'Breadcrumb',
  computed: {
    importMeta() {
      return this.$store.getters['import/importMeta'];
    },
    brands() {
      return this.$store.getters['brand/brands'];
    },
    product() {
      return this.$store.getters['product/product'];
    },
    breadCrumbs() {
      const breadcrumbs = [];
      breadcrumbs.push({
        to: 'name',
        text: 'Dashboard',
      });
      breadcrumbs.push(...this.$route.matched
        .slice(0, this.$route.matched.length - 1)
        .filter(m => !m.meta.noBreadcrumb)
        .map(m => ({
          to: m.path,
          text: m.meta.title || m.name,
        })));
      if (this.title) {
        breadcrumbs.push({
          text: this.title,
        });
      }
      return breadcrumbs;
    },
    title() {
      let title = this.$route.meta.title || this.$route.name;
      if (this.$route.meta.noBreadcrumb) {
        return null;
      }
      if (typeof this.$route.params.importId !== 'undefined') {
        const { brandId, name } = this.importMeta;
        title = `${title.replace(/(data|csv)/g, this.getBrandName(brandId))} ${name}`;
      }
      if (typeof this.$route.params.productId !== 'undefined') {
        title = `${title} ${this.product.name} (${this.getBrandName(this.product.brandId)})`;
      }
      if (typeof this.$route.params.attributeId !== 'undefined') {
        title = `Attribute (${this.$route.params.attributeId})`;
      }
      return title;
    },
  },
  methods: {
    getBrandName(brandId) {
      if (!this.brands) return '';
      const index = this.brands.findIndex(b => b.id === brandId);
      if (index !== -1) {
        return this.brands[index].name;
      }
      return '';
    },
  },
};
</script>
