import Vue from 'vue';
import Log from '@/models/utils/log';
import Core from './core';
import SkuList from './skulist';
import MediaList from './medialist';

export default class Variant extends Core {
  constructor(data, parent) {
    super(data, parent);
    this.attributeInitializer();
    // this is a virtual datatype
    // it s really important to send the color each time from the parent instance
    // @see theagent-pim:node_modules/theagent-pim/models/reference.js
    this.classificationId = Variant.getParentValue(parent, 'classificationId');
  }

  attributeInitializer() {
    Log.debug('models.pim.variant.attributeinitializer.started');
    this.attributes_temp = {};
    if (typeof this.attributes !== 'undefined' && this.attributes.length > 0) {
      for (let l = 0; l < this.attributes.length; l += 1) {
        const attribute = this.attributes[l];
        if (Object.keys(attribute).length > 0) {
          Object.keys(attribute.dataJson).forEach((property) => {
            if (typeof this.attributes_temp[property] === 'undefined') {
              this.attributes_temp[property] = attribute.dataJson[property];
            }
          });
        }
      }
    }
  }

  defineProperty() {
    this.setHookName();
    this.setHookColor();
  }

  attribute(property) {
    return this.attributes_temp[property] || false;
  }

  applyModel() {
    if (this.hasSkus()) {
      this.skus = SkuList.create(this.skus, this);
    }
    if (this.hasMedias()) {
      // some extreme case cant be found on this attribute this.medias
      // the first this.medias is a array
      // just after this.medias is MediaList instance
      this.medias.medias = MediaList.create(this.medias.medias, this);
      this.image = this.getFirstImage();
    }
    return this;
  }

  hasMedias() {
    if (
      typeof this.medias === 'undefined'
      || !this.medias
      || !this.medias.medias
      || Object.keys(this.medias.medias).length === 0
    ) {
      return false;
    }
    return (
      Array.isArray(this.medias.medias)
      && this.medias.medias.length > 0
      && this.medias.medias.some(m => m.original)
    );
  }

  hasSkus() {
    return typeof this.skus !== 'undefined' && Array.isArray(this.skus) && this.skus.length > 0;
  }

  getFirstImage() {
    Log.debug('models.pim.variant.getfirstimage.started');
    if (!this.hasMedias()) {
      return false;
    }
    const index = this.medias.medias.findIndex(m => m && m.original);
    if (index === -1) {
      return false;
    }
    return this.medias.medias[index];
  }

  /**
   * [setName description]
   * @param {String} name  product name
   * @param {String} color color
   *
   * @see theagent-pim-core:models/variant.js:Variant.getname()
   */
  static setName(name, color) {
    return `${name.toUpperCase()} ${color.toUpperCase()}`;
  }

  setHookColor() {
    let { color } = this;
    Object.defineProperty(this, 'color', {
      set(value) {
        try {
          color = value.toLowerCase();
        } catch (err) {
          throw new TypeError('models.pim.variant.sethookcolor.color.failed');
        }
        this.name = Variant.setName(this.parent.name, color);
        if (this.hasSkus()) {
          this.skus.fixDependencies();
        }
      },
      get() {
        return color;
      },
    });
  }

  setHookName() {
    let { name } = this;
    Object.defineProperty(this, 'name', {
      set(value) {
        try {
          name = value.toUpperCase();
        } catch (err) {
          throw new TypeError('models.pim.variant.sethookname.name.failed');
        }
        name = value.toUpperCase();
        if (this.hasSkus()) {
          this.skus.fixDependencies();
        }
      },
      get() {
        return name;
      },
    });
  }

  /**
   * convert instance in plain object
   * @return {Object}
   */
  toObject() {
    try {
      const rootVariant = this.clone();
      rootVariant.parent = null;
      delete rootVariant.parent;
      rootVariant.image = null;
      if (rootVariant.image && typeof rootVariant.image.toObject === 'function') {
        rootVariant.image = rootVariant.image.toObject();
      }
      if (rootVariant.hasMedias()) {
        rootVariant.medias.medias = rootVariant.medias.medias.map(media => (typeof media.toObject === 'function' ? media.toObject() : media));
      }

      if (rootVariant.hasSkus()) {
        rootVariant.skus = rootVariant.skus.map(sku => (typeof sku.toObject === 'function' ? sku.toObject() : sku));
      }
      return JSON.parse(JSON.stringify(Object.seal(rootVariant)));
    } catch (e) {
      Log.error(e);
      return {};
    }
  }

  getChildren() {
    return {
      sku: this.hasSkus() ? this.skus : [],
    };
  }

  reset() {
    this.id = null;
    this.legacyId = null;
    this.image = null;
    if (this.medias instanceof MediaList) {
      this.medias.reset();
    }
    if (this.skus.length > 0) {
      this.skus.forEach((sku) => {
        sku.reset();
      });
    }
    if (this.attributes.length > 0) {
      this.attributes.forEach((attribute) => {
        Vue.set(attribute, 'id', null);
        Vue.set(attribute, 'variantId', null);
      });
    }
  }

  generateRandomRemoteCode() {
    this.remoteCode = `temp.rc.${Math.floor(Math.random() * 99999999) + 1}`;
  }

  copy() {
    const variant = { ...this };
    delete variant.image;
    delete variant.medias;
    delete variant.skus;
    delete variant.parent;
    delete variant.parentError;
    delete variant.errors;
    delete variant.errorsOriginal;
    return variant;
  }

  async getCompletude(dispatch) {
    if (typeof this.completudes === 'object' && Object.keys(this.completudes).length > 0) {
      return this.completudes;
    }
    const variant = this.copy();
    return dispatch(
      'completude/calc',
      {
        endpoint: 'variant',
        data: variant,
      },
      { root: true },
    ).then((completude) => {
      Vue.set(this, 'completudes', completude);
      return this;
    });
  }

  async setCompletude(dispatch) {
    const variant = this.copy();
    return dispatch(
      'completude/update',
      {
        endpoint: 'variant',
        data: variant,
      },
      { root: true },
    ).then((completude) => {
      Vue.set(this, 'completudes', completude);
      return this;
    });
  }
}
