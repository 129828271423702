// https://vuex.vuejs.org/en/modules.html

const requireModule = require.context('.', true, /\.js$/);
const modules = {};
requireModule.keys().forEach((fileName) => {
  if (fileName === './index.js') return;
  // Replace ./ and .js
  const path = fileName.replace(/(\.\/|\.js)/g, '');
  const [moduleName, subModuleName, imported] = path.split('/');
  if (!modules[moduleName]) {
    modules[moduleName] = {
      namespaced: true,
      modules: {},
    };
  }
  const isSubModule = typeof imported !== 'undefined' && imported;

  if (isSubModule && !modules[moduleName].modules[subModuleName]) {
    modules[moduleName].modules[subModuleName] = {
      namespaced: true,
    };
  }

  if (isSubModule) {
    modules[moduleName].modules[subModuleName][imported] = requireModule(fileName).default;
  } else {
    modules[moduleName][subModuleName] = requireModule(fileName).default;
  }
});
export default modules;
