<template>
  <v-dialog v-model="dialog" persistent max-width="960px">
    <!-- https://vuetifyjs.com/en/components/menus/#activator-and-tooltip -->
    <template v-slot:activator="{ on: openDialog }">
      <v-tooltip left>
        <template v-slot:activator="{ on: tooltip, attrs }">
          <v-btn
            color="green"
            fab
            dark
            absolute
            right
            fixed
            class="mx-2"
            style="bottom: 160px;"
            v-bind="attrs"
            v-on="{ ...tooltip, ...openDialog }"
          >
            <v-icon dark>mdi-table-row-plus-after</v-icon>
          </v-btn>
        </template>
        <span>Create a new row</span>
      </v-tooltip>
    </template>
    <div>
      <v-btn color="grey darken-1" fab dark fixed top right  style="top: 80px;" @click="close">
        <v-icon dark>mdi-cancel</v-icon>
      </v-btn>
      <v-btn color="blue darken-1"
        fab
        dark
        fixed
        top
        right
        :disabled="!isValid || isClicked"
        @click="save">
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
      <v-btn dark
        fixed
        top
        right
        fab
        style="top: 160px;"
        @click="setMarketplace('default')">
        <material-percent
          :percent="getPercent('default')"
          :color="getCompletudeColor('default')"
          size="100"
          circular
          title-button="default"></material-percent>
      </v-btn>
      <v-btn dark
        fixed
        top
        right
        fab
        style="top: 240px;"
        @click="setMarketplace('zalando')">
        <material-percent
          :percent="getPercent('zalando')"
          :color="getCompletudeColor('zalando')"
          size="100"
          circular
          title-button="zalando"></material-percent>
      </v-btn>
      <v-btn style="top: 320px !important;"
        dark
        fixed
        large
        top
        right
        fab
        @click="setMarketplace('laRedoute')">
        <material-percent
          :percent="getPercent('laRedoute')"
          :color="getCompletudeColor('laRedoute')"
          size="100"
          circular
          title-button="laRedoute"></material-percent>
      </v-btn>
    </div>
    <v-card>
      <v-form v-model="isValid">
        <v-card-title>{{ $t("component.common.fields-required") }}:</v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-for="(row, key) in fieldsRequired" :key="key" cols="12" md="6">
              <v-card>
                <core-attribute :property="key" auto-grow :item="item" item-value="name" filled>
                </core-attribute>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-title>{{ $t("component.common.others-fields") }}:</v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-for="(row, key) in fields" :key="key" cols="12" :md="6">
              <v-card>
                <core-attribute :property="key" auto-grow :item="item" item-value="name" filled>
                </core-attribute>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import ChipBaseMixins from '@/mixins/chip-base';
import DialogEditBaseMixins from '@/mixins/dialog-edit-base';
import CompletudeBaseMixins from '@/mixins/completude-base';
import ConfigBaseMixins from '@/mixins/config-base';

export default {
  mixins: [ChipBaseMixins, DialogEditBaseMixins, CompletudeBaseMixins, ConfigBaseMixins],
  props: {
    value: {
      type: Object,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    item: {},
  }),
  beforeCreate() {
    this.$store.dispatch('api/attribute/list');
  },
  computed: {
    headers() {
      return this.$store.getters['settings/csvAttributes'];
    },
    fieldsRequired() {
      return this.headers
        .filter(h => h.required || false)
        .reduce((fields, h) => {
          if (typeof this.item[h.name] !== 'undefined') {
            // eslint-disable-next-line no-param-reassign
            fields[h.name] = this.item[h.name];
          }
          return fields;
        }, {});
    },
    /**
     * this method filter all header colmuns according to the selected headers
     * @return {[type]} [description]
     */
    fields() {
      return this.headers
        .filter(h => typeof h.required === 'undefined' || h.required === false)
        .reduce((fields, h) => {
          if (typeof this.item[h.name] !== 'undefined') {
            // eslint-disable-next-line no-param-reassign
            fields[h.name] = this.item[h.name];
          }
          return fields;
        }, {});
    },
    ...mapGetters('form/rules', {
      barcodeRules: 'barcode',
    }),
  },
};
</script>

<style></style>
