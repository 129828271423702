<template>
  <v-container>
    <v-autocomplete
      v-model="select"
      :loading="loading"
      :items="headers"
      item-text="labelName"
      item-value="name"
      filled
      chips
      :multiple="!onlyOne"
      hide-no-data
      hide-details
      solo-inverted
      :label="labelName"
    >
      <template v-slot:selection="{ attrs, select, selected, item }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          close
          @click="select"
          @click:close="remove(item)"
        >
          <div class="text-center ta-expand-icon">
            <v-btn fab x-small :class="item.template.class">{{ item.template.name }}</v-btn>
          </div>
          {{ item.labelName }}
        </v-chip>
      </template>
      <template v-slot:item="{ item }" :class="item.template.class">
        <v-list-item-avatar>
          <div class="text-center ta-expand-icon">
            <v-btn fab x-small :class="item.template.class">{{ item.template.name }}</v-btn>
          </div>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="item.labelName"></v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { EventBus } from '@/helpers/event-bus';
import Mixins from '@/mixins';

export default {
  name: 'AttributeProduct',
  mixins: Mixins,
  props: {
    value: {
      type: [Number, Boolean],
      default: () => false,
    },
    labelName: {
      type: String,
      default: 'Search with your header name to update?',
    },
    filter: {
      type: [Array],
      default: () => [],
    },
    onlyOne: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    loading: false,
    select: [],
    itemsPerPage: 10,
  }),
  computed: {
    isNotMultiple() {
      return this.onlyOne && typeof this.select === 'string';
    },

    headers() {
      let attribute = null;
      let headers = this.$store.getters['api/attribute/csv'];
      if (this.filter.length > 0) {
        headers = headers.filter(h => this.filter.includes(h.name));
      }
      let selected = this.select || [];
      if (this.isNotMultiple) {
        selected = [this.select];
      }
      if (typeof selected[0] !== 'undefined') {
        attribute = headers.find(a => selected[0] === a.name) || null;
      }
      return headers.reduce((result, h) => {
        // eslint-disable-next-line max-len
        if (
          (selected.length === 0)
          || (!attribute && selected.length > 0)
          || (selected.length > 0 && (attribute && attribute.entityType === h.entityType))
        ) {
          Vue.set(h, 'labelName', h.label[this.currentLang] || h.label.fr);
          Vue.set(h, 'template', this.getEntityType(h));
          result.push(h);
        }
        return result;
      }, []);
    },
    entityTypes() {
      return this.$store.getters['config/attribute.typeEntities'];
    },
  },
  beforeCreate() {
    this.$store.dispatch('api/attribute/list');
  },
  watch: {
    select() {
      let value = this.select;
      if (this.isNotMultiple) {
        value = [this.select];
      }
      EventBus.$emit('attribute.header:id', value);
      this.$emit('input', value);
    },
  },
  methods: {
    getEntityType(item) {
      if (this.entityTypes && item.entityType === this.entityTypes.R_TYPE) {
        return { name: 'R', class: 'ta-product' };
      }
      if (this.entityTypes && item.entityType === this.entityTypes.RC_TYPE) {
        return { name: 'RC', class: 'ta-variant' };
      }
      if (this.entityTypes && item.entityType === this.entityTypes.RCT_TYPE) {
        return { name: 'RCT', class: 'ta-sku' };
      }
      if (this.entityTypes && item.entityType === this.entityTypes.RCTA_TYPE) {
        return { name: 'RCTA', class: 'ta-sku-attribute' };
      }
      if (this.entityTypes && item.entityType === this.entityTypes.RA_TYPE) {
        return { name: 'RA', class: 'ta-product-attribute' };
      }
      if (this.entityTypes && item.entityType === this.entityTypes.RCA_TYPE) {
        return { name: 'RCA', class: 'ta-variant-attribute' };
      }
      return { name: 'ERROR', class: 'ta-error' };
    },
    remove(item) {
      if (this.isNotMultiple) {
        this.select = [];
      } else {
        const index = this.select.indexOf(item.name);
        if (index >= 0) this.select.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="css" scoped></style>
