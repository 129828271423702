<template>
  <v-container fluid>
    <v-data-table
      v-model="variantSelected"
      :headers="filterByColumnMedia()"
      class="elevation-0 ta-fix-column ta-variant"
      :item-class="checkError"
      :item-key="id"
      :items="items"
      hide-default-footer
      fixed-header
      :items-per-page="items.length"
      :search="search"
      :dense="settings.dense"
      :show-select="settings.showSelect"
      :custom-filter="defaultFilter"
    >
      <template v-slot:item.data-table-expand="{ expand, isExpanded }">
        <div class="text-center ta-expand-icon">
          <v-btn fab x-small class="ta-variant" @click="expand(!isExpanded)">RC</v-btn>
          <v-icon v-if="isExpanded" @click="expand(!isExpanded)">mdi-chevron-up</v-icon>
          <v-icon v-else @click="expand(!isExpanded)">mdi-chevron-down</v-icon>
        </div>
      </template>
      <template v-for="head in filteredFields()" v-slot:[`header.${head.value}`]="{ header }">
        <h6 :key="head.value" style="display: inline-block">
          {{ head.text }}
        </h6>
      </template>
      <template v-slot:item.id="{ item }">
        <dialog-noedit :item="item" property="id"> </dialog-noedit>
      </template>
      <template v-slot:item.color="{ item }">
        <dialog-select
          :item="item"
          property="color"
          labelName="Choose your color"
          :saveOnApi="saveOnApi"
          store="variant"
          no-dialog
        >
        </dialog-select>
      </template>
      <template v-slot:item.familyId="{ item }">
        <dialog-family
          :item="item"
          property="familyId"
          labelName="Choose your family"
          :saveOnApi="saveOnApi"
          no-dialog
        >
        </dialog-family>
      </template>
      <template v-slot:item.medias="{ item }">
        <common-media-list-view
          :hasMedia="item.hasMedias()"
          :variantMedia="item.medias"
          :mini="true"
          :variantId="item.id"
          style="max-width: 960px;"
        >
        </common-media-list-view>
      </template>
      <template v-slot:item.quable="{ item }">
        <v-chip :color="getColor(item.quable)" dark>
          {{ getMessage(item.quable) }}
        </v-chip>
        <v-chip :color="item.active ? 'green' : 'red'" dark>
          {{ item.active ? "acenabledtive" : "disabled" }}
        </v-chip>
        <v-chip :color="item.sealed ? 'green' : 'red'" dark>
          {{ item.active ? "sealed" : "unsealed" }}
        </v-chip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Mixins from '../../mixins';
import ItemsBaseMixins from '../../mixins/items-base';

export default {
  mixins: [ItemsBaseMixins, ...Mixins],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    headersAuthorized: [
      'id',
      'name',
      'supplierReference',
      'color',
      'pattern',
      'familyId',
      'medias',
      'quable',
    ],
  }),
  beforeCreate() {
    this.$store.dispatch('api/attribute/list');
  },
  computed: {
    // https://fr.vuejs.org/v2/guide/computed.html
    variantSelected: {
      get() {
        return this.value || [];
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    settings() {
      return this.$store.getters['settings/settings'];
    },
    headers() {
      return this.$store.getters['settings/headers'].variant;
    },
  },
  methods: {
    filterByColumnMedia() {
      return this.filterByColumn('variant').filter(h => this.headersAuthorized.includes(h.value));
    },
  },
};
</script>

<style></style>
