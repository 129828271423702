<template>
  <div></div>
</template>

<script>
export default {
  name: 'DashBoardView',

  data: () => ({}),
};
</script>

<style lang="css" scoped></style>
