<template>
  <div>
    <div v-if="hasRight('pimapp.v2:pim:classification:write')">
      <classification-link :key="classification.remoteCode"
        v-model="classification.remoteCode"
        :label="classification.name">
      </classification-link>
    </div>
    <div v-else-if="!hasRightActions || noDialog">{{ titleName }}</div>
    <v-edit-dialog :return-value="item[property]" large lazy v-else @save="saveInline">
      <div>{{ titleName }}</div>
      <core-error v-if="item.errors" :property="property" :errors="item.errors[property]" />
      <template v-slot:input>
        <v-select
          v-bind:value="item[property]"
          v-on:change="newObject = $event"
          :items="classifications"
          menu-props="auto"
          hide-details
          item-text="name"
          item-value="id"
          :label="labelName"
        ></v-select>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
import ClassificationLink from '@/components/link/classification';
import DialogBaseMixins from '@/mixins/dialog-base';
import ProfileMixins from '@/mixins/profile-base';
import ClassificationSubMixins from '@/mixins/config/classification-sub-base';

export default {
  mixins: [ClassificationSubMixins, DialogBaseMixins, ProfileMixins],
  data: () => ({}),
  components: {
    ClassificationLink,
  },
  computed: {
    itemsValue() {
      return null;
    },
    classification() {
      const classification = this.classifications.find(c => c.id === this.item[this.property]);
      if (!classification) return {};
      return classification;
    },
    titleName() {
      try {
        return this.classification.name;
      } catch (e) {}
      return '';
    },
  },
  methods: {},
};
</script>

<style></style>
