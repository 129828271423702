// https://vuex.vuejs.org/en/mutations.html

export default {
  set_medias(state, medias) {
    state.medias = medias;
  },
  set_media(state, media) {
    state.media = media;
  },
  set_marketplaces(state, marketplaces) {
    state.marketplaces = marketplaces;
  },
};
