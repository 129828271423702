import Vue from 'vue';
import Log from '@/models/utils/log';
import AttributeAbstract from '@/models/attribute/abstract';

const namespace = 'models.attribute.settings';

const DEFAULT_MARKETPLACE = {
  options: {},
  condition: {},
  value: null,
};

export default class AttributeValue extends AttributeAbstract {

  objectInitializer(data) {
    super.objectInitializer(data);
    this.remoteCode = data.remoteCode.replace('pim.av.', '');
  }

  getValue(marketplace, attributeCode = null) {
    if (!this.values[marketplace]) {
      Log.error(`${namespace}.${this.name}.marketplace.is.not.setted`);
      return 'No value';
    }
    try {
      if (attributeCode && typeof this.values[marketplace][attributeCode] === 'object') {
        // if empty string, can be return
        if (this.values[marketplace][attributeCode].value !== null) {
          return this.values[marketplace][attributeCode].value;
        }
      }
      if (typeof this.values[marketplace].default !== 'object') {
        Log.error(`${namespace}.${this.name}.not.value.found`);
        return 'No value';
      }
      if (this.values[marketplace].default.value !== null) {
        return this.values[marketplace].default.value;
      }
    } catch (e) {}
    return null;
  }

  getMarketplaces() {
    if (typeof this.values === 'undefined' || Object.keys(this.values).length === 0) {
      Log.error(`${namespace}.${this.name}.marketplaces.doesnt.exist`);
      return null;
    }
    return Object.keys(this.values).reduce((r, marketplace) => {
      try {
        if (typeof this.values[marketplace] === 'object'
          && Object.values(this.values[marketplace]).some(v => v.value)) {
          r.push(marketplace);
        }
      } catch (err) {}
      return r;
    }, (this.defaultValue ? ['default'] : []));
  }

  // getValueMarketplace(marketplace) {
  //   if (typeof this.values === 'undefined' || !this.values) {
  //     Vue.set(this, 'values', {});
  //   }
  //   if (typeof this.values[marketplace] === 'undefined' || !this.values[marketplace]) {
  //     Vue.set(this.values, marketplace, [DEFAULT_MARKETPLACE]);
  //   }
  //   return this.values[marketplace];
  // }
}