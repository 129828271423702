import LangSubMixins from './lang-sub-base';

export default {
  mixins: [LangSubMixins],
  computed: {
    distributors() {
      const distributors = this.$store.getters['distributor/distributors'];
      if (typeof this.user.preferences !== 'undefined' && this.user.preferences.brandId) {
        const brands = this.$store.getters['brand/brands'];
        const brandDistributors = brands
          .filter(b => this.user.preferences.brandId.includes(b.id))
          .reduce((r, d) => {
            r.push(...d.distributor.map(a => a.id));
            return r;
          }, []);
        return distributors.filter(b => brandDistributors.includes(b.id));
      }
      return distributors;
    },
  },
};
