<template>
  <v-dialog v-model="openDialog" persistent max-width="500px">
    <v-form v-model="isValid">
      <v-card>
        <v-card-text>
          <v-autocomplete
            :items="attributes"
            v-model="attributesSelected"
            item-text="name"
            item-value="id"
            persistent-hint
            chips
            small-chips
            dense
            multiple
          ></v-autocomplete>
        </v-card-text>
        <v-spacer />
        <v-card-actions>
          <v-btn color="grey darken-1" text outlined @click="close">Cancel</v-btn>
          <v-btn color="blue darken-1" dark :disabled="!isValid" @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'DialogListAttributes',
  props: {
    value: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isValid: true,
    openDialog: false,
  }),
  watch: {
    dialog() {
      this.openDialog = this.dialog;
    },
  },
  computed: {
    attributesSelected: {
      get() {
        return [...this.value];
      },
      set(value) {
        const attributesSelected = this.attributes
          .filter(a => value.includes(a.id))
          .map(a => ({
            id: a.id,
            label: a.label,
            remoteCode: a.remoteCode,
            name: a.name,
          }));
        Vue.set(this, 'value', attributesSelected);
      },
    },
    attributes() {
      return this.$store.getters['attribute/attributes'];
    },
  },
  methods: {
    save() {
      return this.$emit('onSave', this.value);
    },
    close() {
      this.openDialog = false;
      // https://vuejs.org/v2/guide/components-custom-events.html#sync-Modifier
      this.$emit('update:dialog', this.openDialog);
      return this.$emit('onClose');
    },
  },
};
</script>
