<template>
  <v-data-table
    :headers="headers"
    class="elevation-2 ta-fix-column ta-table-csv ta-attribute-value"
    :items="values"
    :loading="loading"
    :footer-props="{ 'items-per-page-options': [100, 200, 500, -1] }"
    loading-text="Loading... Please wait"
    :dense="settings.dense"
    fixed-header
    hide-default-footer
  >
    <template v-for="head in headersSelection" v-slot:[`header.${head.value}`]="{ header }">
      <span v-if="getErrors(head).length > 0" color="red-text darken-1" :key="head.value">
        <v-icon color="red darken-4">mdi-alert</v-icon>
        {{ head.text }}
      </span>
      <span v-else :key="head.value">{{ head.text }}</span>
    </template>
    <template v-for="head in headersSelection" v-slot:[`item.${head.value}`]="{ item }">
      <autocomplete-av
        :key="head.value"
        v-model="item[head.value].value"
        :items="getItems(head.value)"
        :readonly="item[head.value].readonly"
      >
     </autocomplete-av>
    </template>
    <template v-slot:item.typeEntity="{ item }">
      <attribute-typeentity v-model="item.typeEntity"></attribute-typeentity>
    </template>
    <template v-slot:item.typeHinting="{ item }">
      <attribute-typehinting v-model="item.typeHinting"></attribute-typehinting>
    </template>
    <template v-slot:item.marketplace="{ item }">
      <attribute-marketplaces v-model="item.marketplace"></attribute-marketplaces>
    </template>
    <template v-slot:item.active="{ item }">
      <v-switch
        v-model="item.active"
         @change="active(item)"
      ></v-switch>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-1"  @click="saveAttributeValue(item)">
        mdi-content-save
      </v-icon>
      <router-link :to="{ name: 'settings.attribute.values.remoteCode', params: { remoteCode: item.remoteCode, aRemoteCode: item.aRemoteCode }}">
        <v-icon small class="mr-1">
          mdi-pencil-circle
        </v-icon>
      </router-link>
      <v-icon small class="mr-1" @click="deleteAttribute(item)">
        mdi-delete-circle
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import AttributeTypeMixins from '@/mixins/attribute-type-base';
import AlertMixins from '@/mixins/alert-base';
import NotificationMixins from '@/mixins/notification-base';
import AutocompleteAv from '@/components/attributelist/autocomplete-av';

const namespace = 'settings.attributevaluelistview';

const getAttributeProp = name => `attribut.${name}`;
const getMarketplaceProp = name => `marketplace.${name}`;

export default {
  name: 'AttributeListView',
  mixins: [
    AttributeTypeMixins,
    NotificationMixins,
    AlertMixins,
  ],
  components: {
    AutocompleteAv,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    marketplace: {
      type: String,
      required: false,
      default: 'default',
    },
    associations: {
      type: [Array],
      required: false,
      default: () => [],
    },
  },
  data: () => ({
    headersDefault: [
      { text: 'Nom de la valeur Pim', value: 'label' },
      { text: 'Identifiant', value: 'name' },
      { text: 'Marketplaces', value: 'marketplace', sortable: false },
      {
        text: 'Active', value: 'active', width: 100, sortable: false,
      },
    ],
    headersMarketplace: [
      {
        text: 'Zalando', value: getMarketplaceProp('zalando'), values: [], width: 200,
      },
      {
        text: 'Sarenza', value: getMarketplaceProp('sarenza'), values: [], width: 200,
      },
      {
        text: 'La redoute', value: getMarketplaceProp('neutral'), values: [], width: 200,
      },
    ],
    loading: false,
    endpointType: 'attribute-values',
  }),

  computed: {
    headers() {
      const headers = [...this.headersDefault];
      if (this.headersMarketplace.length > 0) {
        headers.push(...this.headersMarketplace);
      }
      headers.forEach((h) => {
        if (Array.isArray(h.values)) {
          h.values = this.getMainValues(h.text.toLowerCase());
        }
      });
      if (Array.isArray(this.newHeaders)) {
        this.newHeaders.forEach((attrFromMarketplace) => {
          headers.push({
            text: this.getTitleHeader(attrFromMarketplace),
            value: getAttributeProp(attrFromMarketplace.getCode()),
            values: attrFromMarketplace.values.map(v => v.label),
            width: 200,
          });
        });
      }
      headers.push({
        text: 'Actions', value: 'actions', width: 100, sortable: false,
      });
      return headers;
    },
    headersSelection() {
      return this.headers.filter(f => Array.isArray(f.values));
    },
    marketplaces() {
      return this.$store.getters['config/marketplaces'];
    },
    attributesAfm() {
      return this.$store.getters['attribute-from-marketplace/attributes'];
    },
    newHeaders() {
      return this.attributesAfm.reduce((r, f) => {
        if (!this.associations) return r;
        const found = this.associations.find(a => f.remoteCode === a.remoteCode);
        if (found) {
          r.push(f);
        }
        return r;
      }, []);
    },
    settings() {
      return this.$store.getters['settings/settings'];
    },
    attribute() {
      return this.$store.getters['attribute/attribute'];
    },
    values() {
      return this.value.values.map((v) => {
        const value = {
          aRemoteCode: this.value.remoteCode,
          remoteCode: v.remoteCode,
          name: v.name,
          label: v.defaultValue,
          marketplace: v.getMarketplaces(),
          active: v.active,
        };
        if (this.headersMarketplace.length > 0) {
          this.headersMarketplace.forEach((m) => {
            if (m.value) {
              value[m.value] = {
                value: v.getValue(m.text.toLowerCase()),
                readonly: false,
              };
            }
          });
        }
        if (this.associations.length > 0) {
          this.associations.forEach((a) => {
            const remoteCode = a.getCode();
            const marketplace = this.marketplaces.find(m => a.marketplaceId === m.id);
            value[getAttributeProp(remoteCode)] = { value: '', readonly: false };
            if (remoteCode && marketplace) {
              const valTemp = v.getValue(marketplace.remoteCode, remoteCode);
              if (this.getItems(getAttributeProp(remoteCode)).includes(valTemp)) {
                value[getAttributeProp(remoteCode)].value = valTemp;
                if (value[getAttributeProp(remoteCode)].value !== null
                  && value[getMarketplaceProp(marketplace.remoteCode)].value !== null
                  && value[getMarketplaceProp(marketplace.remoteCode)].value === value[getAttributeProp(remoteCode)].value) {
                  value[getAttributeProp(remoteCode)].readonly = true;
                }
              }
            }
          });
        }
        return value;
      });
    },
  },
  methods: {
    getTitleHeader(a) {
      const marketplace = this.marketplaces.find(m => m.id === a.marketplaceId);
      if (!marketplace) return a.name;
      return `${marketplace.name} / ${a.name}`;
    },
    getItems(headerName) {
      const header = this.headersSelection.find(h => h.value === headerName);
      if (!header) return [];
      return header.values || [];
    },
    getMainValues(marketplaceName) {
      const marketplace = this.marketplaces.find(m => m.remoteCode === marketplaceName);
      if (!marketplace) return [];
      return (this.associations || []).filter(a => a.mainAttribute && a.marketplaceId === marketplace.id)
        .reduce((r, a) => {
          r.push(...a.values.map(v => v.label));
          return r;
        }, []);
    },
    getErrors(header) {
      const errors = [];
      if (header.values.length === 0) errors.push('No marketplace attribute associated');
      return errors;
    },
    saveAttributeValue(attributeValue) {
      this.loading = true;
      setTimeout(() => {
        const params = Object.keys(attributeValue).reduce((r, key) => {
          if (key.indexOf('marketplace.') === 0) {
            const marketplace = this.marketplaces.find(m => getMarketplaceProp(m.remoteCode) === key);
            if (!marketplace) return r;
            r.marketplaces.push({ marketplace: marketplace.remoteCode, value: attributeValue[key].value });
          } else if (key.indexOf('attribut.') === 0) {
            const afmObj = this.associations.find(a => getAttributeProp(a.getCode()) === key);
            if (!afmObj) return r;
            r.fromMarketplace.push({ attributeFromMarketplace: afmObj.remoteCode, value: attributeValue[key].value });
          } else {
            r[key] = attributeValue[key];
          }
          return r;
        }, { marketplaces: [], fromMarketplace: [] });
        return this.$store
          .dispatch('attribute-values/mapping', params)
          .catch(error => this.throwError(error))
          .finally(() => {
            this.loading = false;
          });
      }, 2000);
    },
  },
};
</script>
