/* eslint no-param-reassign:0 */
// https://vuex.vuejs.org/en/getters.html

const getters = {
  isLoaded: state => state.loaded && !!state.profiles && !!state.marketplaces,
  countries: state => state.countries,
  countriesByKey: state => state.countries_by_key,
  marketplaces: state => state.marketplaces,
  config: state => state.config,
};
getters['attribute.fields'] = state => state.fields;
getters['attribute.profiles'] = state => state.profiles;
getters['attribute.typeEntities'] = state => Object.values(state.type_entities)
  .map(t => Number(t));
getters['attribute.typeHinting'] = state => state.type_hinting;

export default getters;
