<template>
  <v-dialog v-model="openDialog" persistent max-width="960px">
    <v-card>
      <v-form v-model="isValid">
        <v-card-text>
          <pim-variant-edit v-model="item" :withSku="withSku"></pim-variant-edit>
        </v-card-text>
        <v-spacer />
        <v-card-actions>
          <v-btn color="grey darken-1" fab dark fixed top right  style="top: 80px;" @click="close">
            <v-icon dark>mdi-cancel</v-icon>
          </v-btn>
          <v-btn color="blue darken-1" fab dark fixed top right :disabled="!isValid || isClicked" @click="save">
            <v-icon dark>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { Variant } from '@/models';
import ChipBaseMixins from '@/mixins/chip-base';
import DialogEditBaseMixins from '@/mixins/dialog-edit-base';

export default {
  mixins: [ChipBaseMixins, DialogEditBaseMixins],
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    withSku: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Variant,
      default: () => Variant.create(),
    },
  },
};
</script>

<style></style>
