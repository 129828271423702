// https://vuex.vuejs.org/en/mutations.html

export default {
  set_variants(state, variants) {
    state.variants = variants;
  },
  set_variant(state, variant) {
    state.variant = variant;
  },
};
