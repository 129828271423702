/* eslint no-param-reassign:0 */
// https://vuex.vuejs.org/en/actions.html
import Vue from 'vue';
import axios from 'axios';

const BASE_URI = '/api/v1/auth';
const PUBIC_BASE_URI = '/api/public/auth';

export default {
  async status({ commit }) {
    Vue.$log.info('store.app.actions.status.started');
    return new Promise((resolve) => {
      axios.get(`${BASE_URI}/status`).then((response) => {
        const {
          user,
          client,
          version,
          versioncommit,
        } = response.data;
        commit('set_rest_api', {
          version,
          versionCommit: versioncommit,
        });
        commit('set_user', user);
        commit('set_lang', user.preferences.lang);
        commit('set_client', client);
        return resolve(response.data);
      });
    });
  },
  async clients() {
    Vue.$log.debug('store.app.actions.clients.started');
    return new Promise((resolve) => {
      axios.get(`${PUBIC_BASE_URI}/clients`).then((response) => {
        const { clients } = response.data;
        return resolve(clients);
      });
    });
  },
};
