<template>
  <v-progress-circular
    v-if="circular"
    rotate="360"
    :size="size"
    v-model="progressPercent"
    :color="color"
  >
    <p v-if="titleButton" >{{ titleButton }} <br />{{ getPercent() }}</p>
    <p v-else>{{ getPercent() }}</p>
  </v-progress-circular>
  <v-progress-linear
    v-else
    v-model="progressPercent"
    :color="color"
    :height="height"
    rounded
    buffer-value="0"
    stream
  >
    <template v-slot:default="{ value }">
      <strong>{{ getPercent() }}</strong>
    </template>
  </v-progress-linear>
</template>

<script>
export default {
  name: 'Percent',
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    percent: {
      type: [Number, String],
      default: 0,
    },
    color: {
      type: String,
      default: 'blue',
    },
    height: {
      type: Number,
      default: 15,
    },
    size: {
      type: [Number, String],
      default: 50,
    },
    circular: {
      type: Boolean,
      default: false,
    },
    linear: {
      type: Boolean,
      default: false,
    },
    titleButton: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    progressPercent() {
      return Number(this.value) || Number(this.percent) || 0;
    },
  },
  methods: {
    /**
     * [getPercent description]
     * @return {String} a number with a percent sign
     */
    getPercent() {
      let valuePercent = Number(this.progressPercent);
      if (valuePercent > 100) {
        this.$log.error(`components.material.percent.getpercent.${valuePercent}.exceeded.limit`);
        valuePercent = 0;
      }
      return `${Math.ceil(valuePercent)}%`;
    },
  },
};
</script>
