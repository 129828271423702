/* eslint no-unused-vars:0 */
// https://vuex.vuejs.org/en/actions.html
import Vue from 'vue';
import axios from 'axios';
import { errorHandler } from '@/store/modules/global_helpers';

const BASE_URI = '/api/v1/pim';
const namespace = 'store.modules.variantattribute.actions';

export default {
  list({ commit }) {
    Vue.$log.log(`${namespace}.list.started`);
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URI}/variant_attribute`)
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.list.no.response`);
          }
          return resolve(response.data.result);
        })
        .catch(errorHandler(reject));
    });
  },
  new({ commit }, attribute) {
    Vue.$log.log(`${namespace}.new.started`);
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URI}/variant_attribute`, attribute)
        .then((response) => {
          Vue.$log.log(`${namespace}.new.has.been.created`);
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  update({ commit }, attribute) {
    Vue.$log.log(`${namespace}.update.started`);
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI}/variant_attribute/${attribute.id}`, attribute)
        .then((response) => {
          Vue.$log.log(`${namespace}.update.${attribute.id}.has.been.updated`);
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  delete({ commit }, id) {
    Vue.$log.log(`${namespace}.delete.started`);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${BASE_URI}/variant_attribute/${id}`)
        .then((response) => {
          Vue.$log.log(`${namespace}.delete.${id}.has.been.deleted`);
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },

  active({ commit }, payload) {
    Vue.$log.log(`${namespace}.active.started`);
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI}/variant_attribute/${payload.id}/active`, {
          active: payload.active,
        })
        .then((response) => {
          Vue.$log.log(`${namespace}.active.${payload.id}.has.been.updated`);
          return resolve(response.data);
        })
        .catch((error) => {
          Vue.$log.error(`store.variant.actions.active.${payload.id}.error`, {
            error: error.message,
            stack: error.stack,
          });
          Vue.$log.error(error.message);
          return errorHandler(reject)(error);
        });
    });
  },
};
