<template>
  <v-container id="dashboard" fluid>
    <v-row>
      <v-col cols="12" lg="12">
        <chart-number-graph color="#4CAF50" period="month"> </chart-number-graph>
      </v-col>
      <v-col cols="12" lg="4">
        <chart-number-count color="green" period="full"> </chart-number-count>
      </v-col>
      <v-col cols="12" lg="4">
        <chart-number-count color="green" period="full" productType="variant"> </chart-number-count>
      </v-col>
      <v-col cols="12" lg="4">
        <chart-number-count color="green" period="full" productType="reference">
        </chart-number-count>
      </v-col>
      <v-col cols="12" lg="3">
        <chart-number-graph color="#E91E63" chartType="hours" graphType="Line" period="day">
        </chart-number-graph>
      </v-col>
      <v-col cols="12" lg="3">
        <chart-number-graph productType="variant" color="#E91E63" chartType="hours" period="day">
        </chart-number-graph>
      </v-col>
      <v-col cols="12" lg="3">
        <chart-number-graph productType="reference" color="#E91E63" chartType="hours" period="day">
        </chart-number-graph>
      </v-col>
      <v-col cols="12" lg="3">
        <chart-number-count color="green" period="day" productType="product"> </chart-number-count>
        <chart-number-count color="green" period="day" productType="variant">
        </chart-number-count> </v-col
      ><v-col cols="12" lg="3">
        <chart-number-graph color="#00BCD4" period="week"> </chart-number-graph>
      </v-col>
      <v-col cols="12" lg="3">
        <chart-number-graph productType="variant" color="#00BCD4" period="week">
        </chart-number-graph>
      </v-col>
      <v-col cols="12" lg="3">
        <chart-number-graph productType="reference" color="#00BCD4" period="week">
        </chart-number-graph>
      </v-col>
      <v-col cols="12" lg="3">
        <chart-number-count color="green" period="week" productType="product"> </chart-number-count>
        <chart-number-count color="green" period="week" productType="variant">
        </chart-number-count> </v-col
      ><v-col cols="12" lg="3">
        <chart-number-graph color="secondary" graphType="Line" period="month"> </chart-number-graph>
      </v-col>
      <v-col cols="12" lg="3">
        <chart-number-graph productType="variant" color="secondary" period="month">
        </chart-number-graph>
      </v-col>
      <v-col cols="12" lg="3">
        <chart-number-graph productType="reference" color="secondary" period="month">
        </chart-number-graph>
      </v-col>
      <v-col cols="12" lg="3">
        <chart-number-count color="green" period="month" productType="product">
        </chart-number-count>
        <chart-number-count color="green" period="month" productType="variant">
        </chart-number-count>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
