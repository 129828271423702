// https://vuex.vuejs.org/en/mutations.html
import stateDefault from './state';

const getClean = (state) => {
  state.isLoaded = stateDefault.isLoaded;
  state.families = stateDefault.families;
  state.family = stateDefault.family;
  state.state = stateDefault.state;
};

export default {
  set_families(state, payload) {
    state.families = payload;
  },
  set_family(state, payload) {
    if (!Array.isArray(state.families)) {
      getClean(state);
    }
    const index = state.families.findIndex(a => a.id === payload.id);
    if (typeof state.families[index] !== 'undefined') {
      state.families[index] = payload;
    } else {
      state.families.push(payload);
    }
    state.family = payload;
  },
  set_state(state, payload) {
    state.state = payload;
  },
  clear(state) {
    getClean(state);
  },
};
