<template>
  <div>
    <div v-if="!hasRightActions || noDialog">{{ titleName }}</div>
    <v-edit-dialog :return-value="item[property]" large persistent lazy v-else @save="saveInline">
      <div>{{ titleName }}</div>
      <core-error v-if="item.errors" :property="property" :errors="item.errors[property]" />
      <template v-slot:input>
        <v-text-field
          v-bind:value="item[property]"
          v-on:change="newObject = $event"
          :rules="colorRules"
          :label="labelName"
          @update:error="showError"
          :readonly="item.sealed"
          counter
          autofocus
        ></v-text-field>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DialogBaseMixins from '../../mixins/dialog-base';
import ProfileMixins from '../../mixins/profile-base';

export default {
  mixins: [DialogBaseMixins, ProfileMixins],
  computed: {
    ...mapGetters('form/rules', { colorRules: 'color' }),
    itemsKey: () => undefined,
  },
};
</script>

<style></style>
