// https://vuex.vuejs.org/en/actions.html
import Vue from 'vue';
import axios from 'axios';
import { workflow } from 'theagent-status';

import { errorHandler } from '@/store/modules/global_helpers';

const BASE_URI = '/api/v1/config';
const namespace = 'store.modules.config.actions';
const { DONE } = workflow;

export default {
  init({ commit, getters, rootGetters }, payload = {}) {
    const debug = rootGetters['settings/theagent'].sillyMode;
    if (debug) Vue.$log.debug(`${namespace}.started`);
    const { commiters = [] } = payload;
    if (getters.isLoaded) return Promise.resolve();
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URI)
        .then((response) => {
          const {
            marketplaces,
            countries,
            attributes,
            classifications,
            brands,
            families,
            distributors,
          } = response.data.result;
          commit('set_loaded', true);
          commit('set_countries', countries);
          commit('set_marketplaces', marketplaces);
          commit('family/set_state', DONE, { root: true });
          commit('family/set_families', families, { root: true });
          commit('classification/set_state', DONE, { root: true });
          commit('classification/set_classifications', classifications, { root: true });
          commit('brand/set_state', DONE, { root: true });
          commit('brand/set_brands', brands, { root: true });
          commit('distributor/set_state', DONE, { root: true });
          commit('distributor/set_distributors', distributors, { root: true });
          commit('set_attributes_fields', attributes.fields);
          commit('set_attributes_profiles', attributes.profiles);
          commit('set_attributes_type_entities', attributes.type_entities);
          commit('set_attributes_type_hinting', attributes.type_hinting);
          if (Array.isArray(commiters) && commiters.length) {
            commiters.forEach((c) => {
              if (c === 'set_countries_by_key') {
                commit(c, countries);
              }
            });
          }
          return resolve();
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          commit('set_loaded', false);
          return errorHandler(reject)(error);
        });
    });
  },
  getFromHash({ commit }, exportHash) {
    Vue.$log.info(`${namespace}.get.${exportHash}.started`);
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URI}/export-marketplace/${exportHash}`)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`.api.v1.pim.brand.${exportHash}.not.found`);
          }
          commit('set_config', response.data.result);
          return resolve(response.data.result);
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return errorHandler(reject)(error);
        });
    });
  },

};
