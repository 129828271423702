const errorsRef = {
  invalid: 'This field value "{{value}}" is invalid for "{{property}}:"',
  invalidCsv: 'This field "{{key}}" failed on value "{{value}}", please check',
  required: 'This field "{{property}}" is required.',
  integer: 'This "{{property}}" has to be a integer.',
  letter: 'This "{{property}}" has to be string with only alphabet.',
  string: 'This "{{property}}" has to be a string.',
  exists: 'This property "{{property}}"{{value}} exist',
  property: 'This property "{{property}}" is not correct',
  priceInvalid: 'This price "{{property}}" is invalid',
  priceIncorrect: 'This price "{{property}}" is too low',
  sizeInvalid: 'This size has not matching in the engine',
  notExist: 'This value "{{property}}" don\'t exist',
  conflict: 'This "{{property}}" has a conflict',
  limitations: 'Category limitation ({{value}}) reached set by Marketplace manager',
  longText: 'This "{{property}}" is too long.',
  imageFailed: 'This "{{property}}" not found.',
  remoteUrlNotExist: 'This url "{{property}}" is not found.',
  remotesUrlNotFound: 'Somes remotes urls "{{property}}" are not found.',
  multiplePresenceInFile: 'This value "{{property}}" has already present.',
  legacyExist: 'This value "{{property}}" has already present in the legacy database.',
  empty: 'This value "{{property}}" is empty',
  legacyPimExist: 'This value "{{property}}" has already present in the legacy database for pim',
  langNotSetted: 'This attribute "{{property}}" langs is not setted',
  internationalization: 'This attribute "{{property}}" is not in multiple languages',
  duplicated: 'This value "{{property}}" may have an duplicated error, check it please',
  errorProduct: 'This product has some errors',
  errorVariant: 'This variant has some errors',
  invalidColor: 'This color "{{value}}" is not a color valid("{{property}}:")',
  hasComposition: 'This sentence "{{value}}" has no composition translated',
  duplicateRefColor:
    'This main color "{{value}}" and specific color have already used with another supplier reference ({{clue}})',
  duplicateRefSize:
    'This size "{{value}}" has already used within this supplier reference ({{clue}})',
  duplicateEan: 'This ean "{{value}}" has already used within this supplier reference ({{clue}})',
  duplicateComposition: 'This composition should be the same like this product ({{clue}})',
};

export default {
  data: () => ({
    itemsErrors: [],
  }),
  computed: {
    errorHeaders() {
      const rows = this.items;
      const defaultValues = this.filteredFields().reduce((r, c) => {
        // eslint-disable-next-line no-param-reassign
        r[c.value] = {
          title: this.$te(c.i18n) ? this.$t(c.i18n) : c.text,
          identifier: c.value,
          count: 0,
          indexes: [],
          close: false,
        };
        return r;
      }, {});
      if (!rows) return defaultValues;
      return rows.reduce((customErrors, row, index) => {
        if (row.errors && Object.keys(row.errors).length > 0) {
          Object.keys(row.errors).forEach((head) => {
            if (typeof customErrors[head] !== 'undefined') {
              // eslint-disable-next-line no-param-reassign
              customErrors[head].count += 1;
              customErrors[head].indexes.push(index);
            }
          });
        }
        return customErrors;
      }, defaultValues);
    },
  },
  methods: {
    getMessage(property, error) {
      if (Array.isArray(error)) {
        throw new TypeError('mixins.error-base.getmessage.isarray.invalid');
      }
      if (typeof error === 'object' && typeof error.rule !== 'undefined') {
        const {
          value, clue, rule, key,
        } = error;
        if (typeof errorsRef[rule] !== 'undefined') {
          return errorsRef[rule]
            .replace('{{property}}', property)
            .replace('{{value}}', value)
            .replace('{{key}}', key)
            .replace('{{clue}}', clue);
        }
      }
      if (typeof error === 'string' && error.indexOf('-') !== -1) {
        throw new TypeError('mixins.error-base.getmessage.isstring.invalid');
      }
      if (typeof errorsRef[error] !== 'undefined') {
        return errorsRef[error].replace('{{property}}', property);
      }
      return '';
    },
    getClass(error) {
      if (typeof error === 'object' && typeof error.level !== 'undefined') {
        return error.level;
      }
      return 'error';
    },
    errorsSelected(headerIdentifier) {
      this.$log.debug(`mixins.error-base.errorsselected.${headerIdentifier}.started`);
      if (typeof this.resetItems === 'function') this.resetItems();
      Object.keys(this.errorHeaders).forEach((header) => {
        if (header === headerIdentifier) {
          this.errorHeaders[headerIdentifier].close = true;
          this.itemsErrors = this.errorHeaders[headerIdentifier].indexes.reduce((result, index) => {
            result.push(this.items[index]);
            return result;
          }, []);
        } else {
          this.errorHeaders[header].close = false;
        }
      });
    },
    resetErrorsSelected(headerIdentifier) {
      this.$log.debug('mixins.error-base.reseterrorsselected.started');
      this.errorHeaders[headerIdentifier].close = false;
      if (typeof this.resetItems === 'function') {
        this.resetItems();
      } else {
        this.resetErrors();
      }
    },
    resetErrors() {
      this.$log.debug('mixins.error-base.reseterrors.started');
      this.itemsErrors = [];
    },
    hasErrors(item) {
      return item.errors && Object.keys(item.errors).length > 0;
    },
    hasErrorOrWarning(item, property) {
      // too fast item seems not ready to be catch
      if (Object.keys(item).length === 0) {
        return false;
      }
      return item.errors && typeof item.errors[property] !== 'undefined';
    },
    checkError(row) {
      if (typeof row.alreadyExisted !== 'undefined') {
        return 'ta-alreadyexisted';
      }
      if (typeof row.errors === 'undefined' || Object.keys(row.errors).length === 0) {
        return '';
      }
      return 'ta-error';
    },
    getErrors(errors) {
      if (!Array.isArray(errors)) {
        this.$log.error('components.mixins.error-base.computed.errorscleaned.errors.invalid');
        return [];
      }
      return Array.uniq(errors).reduce((list, error) => {
        if (Array.isArray(error)) {
          if (error.length > 1) {
            // eslint-disable-next-line no-param-reassign
            list = [...list, ...error];
          } else {
            list.push(error[0]);
          }
        } else {
          list.push(error);
        }
        return list;
      }, []);
    },
  },
};
