// https://vuex.vuejs.org/en/mutations.html
import stateDefault from './state';

const getClean = (state) => {
  state.isLoaded = stateDefault.isLoaded;
  state.brands = stateDefault.brands;
  state.brand = stateDefault.brand;
  state.state = stateDefault.state;
};

export default {
  set_brands(state, payload) {
    state.brands = payload;
  },
  set_brand(state, payload) {
    if (!Array.isArray(state.brands)) {
      getClean(state);
    }
    const index = state.brands.findIndex(a => a.id === payload.id);
    if (typeof state.brands[index] !== 'undefined') {
      state.brands[index] = payload;
    } else {
      state.brands.push(payload);
    }
    state.brand = payload;
  },
  set_state(state, payload) {
    state.state = payload;
  },
  clear(state) {
    getClean(state);
  },
};
