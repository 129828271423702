<template>
  <v-card class="mx-auto">
    <v-card-subtitle class="blue">Files to download ({{ value.length }})</v-card-subtitle>
    <v-expand-transition>
        <v-card-text>
          <div v-for="file in value" :key="file">
            <v-btn x-small color="blue" text @click="goToLink(file)">
              <v-icon right>mdi-download</v-icon>
              {{ getTypeFile(file) }}
              <template v-if="showFiles">
                ({{ file }})
              </template>
            </v-btn>
          </div>
        </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: 'ListFiles',
  props: {
    value: {
      type: Array, // String, Number, Boolean, Function, Object, Array
      required: true,
      default: () => ({}),
    },
    showFiles: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    baseURL: process.env.VUE_APP_PIM_BASEURL || 'http://localhost:3128/',
  }),
  methods: {
    goToLink(filepath) {
      if (filepath.indexOf('tmp/storage') !== -1) {
        // eslint-disable-next-line no-param-reassign
        filepath = filepath.replace('tmp/storage', 'storage');
      }
      window.open(`${this.baseURL}/${filepath}`, '_blank');
    },
    getTypeFile(file) {
      if (file.indexOf('tmp/storage/csv/catalog-import') !== -1) {
        return 'Catalog original imported';
      }
      if (file.indexOf('catalog-import') !== -1) {
        return 'Catalog imported';
      }
      if (file.indexOf('images-') !== -1) {
        return 'Images loaded';
      }
      if (file.indexOf('shotlist-') !== -1) {
        return 'Shotlist loaded';
      }
      if (file.indexOf('catalog-export') !== -1) {
        return 'Catalog exported';
      }
      return file;
    },
  },
};
</script>

<style lang="css" scoped></style>
