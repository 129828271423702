<template>
  <v-menu
    bottom
    left
    :close-on-content-click="false"
    top
    min-width="400"
    max-height="90vh"
    offset-x
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn rounded dark icon v-bind="attrs" v-on="on" color="black">
        <v-icon>mdi-filter-variant</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-sm-center text-uppercase">>> Filters by</span>
      </v-card-title>
      <v-divider class="mt-3"></v-divider>
      <v-card-text>
        <v-row class="text-sm-center">
          <v-col cols="12">
            filter by :
          </v-col>
          <v-col cols="4">
            <v-btn rounded small color="indigo" text-color="white" @click="view = 0">
              product
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn rounded small color="orange" text-color="white" @click="view = 1">
              variant color
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn rounded small color="purple" text-color="white" @click="view = 2">
              variant size
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'MenuFilter',
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.view = this.value;
  },
  data: () => ({
    view: 0, // [0, 1, 2]
  }),
  watch: {
    view() {
      this.$emit('input', this.view);
    },
  },
};
</script>
