import Log from '@/models/utils/log';
import Core from './core';
import { MediaError } from './exception';

const baseURL = process.env.VUE_APP_PIM_BASEURL || 'http://localhost:3128/';

export default class Media extends Core {
  url(alias = false) {
    try {
      this.checkValidObject();
      if (this.alias === 0 || !this.alias) {
        throw new MediaError('models.pim.media.alias.nogenerated');
      }
      if (typeof this.aliases[alias] === 'undefined') {
        throw new MediaError(`models.pim.media.aliases.alias.${alias}.invalid`);
      }
    } catch (err) {
      Log.debug(err.message, this.original);
      return Media.buildUrl(this.original, this.hash);
    }
    return Media.$url(this.aliases[alias].pathalias, this.hash);
  }

  static buildUrl(imageUrl, hash = null) {
    const hasHash = !!hash;
    let query = '';
    if (hasHash) {
      query = `?hash=${hash.substring(0, 9)}`;
    } else {
      query = `?ts=${Date.now()}`;
    }
    return `${imageUrl}${query}`;
  }

  static $url(pathFile, hash = null) {
    if (!pathFile) {
      return '';
    }
    return Media.buildUrl(`${baseURL}/${pathFile.replace('tmp/storage/', 'storage/')}`, hash);
  }

  hasAlias() {
    return this.alias && Object.keys(this.data).length > 0;
  }

  checkValidObject() {
    const properties = ['metaData', 'original'];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < properties.length; i++) {
      if (typeof this[properties[i]] === 'undefined' || !this[properties[i]]) {
        throw new MediaError(`models.pim.media.checkvalidobject.${properties[i]}.invalid`);
      }
    }
    return true;
  }

  /**
   * convert instance in plain object
   * @return {Object}
   */
  toObject() {
    try {
      const rootMedia = this.clone();
      rootMedia.parent = null;
      delete rootMedia.parent;
      return JSON.parse(JSON.stringify(Object.seal(rootMedia)));
    } catch (e) {
      Log.error(e);
      return {};
    }
  }

  reset() {
    this.id = null;
    this.url = null;
    this.path = null;
    this.metaData = {};
    this.aliases = {};
  }
}
