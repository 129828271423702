<template>
  <v-select
    v-if="editing"
    v-model="value"
    :items="itemTypes"
    menu-props="auto"
    item-text="name"
    item-value="id"
    :label="label || 'Pick your stock type'"
    hint="Pick your stock type"
    persistent-hint
  >
    <template v-slot:selection="{ item, index }">
      <v-chip :color="getColor(item.id)" dark>{{ item.name }}</v-chip>
    </template>
  </v-select>
  <v-chip v-else :color="getColor(value)" dark>{{ getLabel(value) }}</v-chip>
</template>

<script>
export default {
  name: 'EntityType',
  props: {
    value: {
      type: Number,
      required: false,
    },
    editing: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    types: {
      0: ['null', '#f44336'],
      1: ['ref', '#673ab7'],
      2: ['ref color attr', '#607d8b'],
      3: ['ref color size', '#8bc34a'],
      4: ['ref color', '#ff5722'],
      5: ['ref color media', '#ff9800'],
      6: ['ref attr', '#3f51b5'],
      7: ['ref color size attr', '#e91e63'],
    },
  }),
  computed: {
    itemTypes() {
      const typeEntities = this.$store.getters['config/attribute.typeEntities'];
      return Object.keys(this.types).reduce((r, id) => {
        if (!Object.values(typeEntities).includes(Number(id))) {
          this.$log.error(`attribute.typeentities.${id}.color.doesnt.exist`);
        }
        r.push({ id: Number(id), name: this.types[id][0] });
        return r;
      }, []);
    },
  },
  methods: {
    getLabel(entityId) {
      if (this.types[entityId] && this.types[entityId][0]) return this.types[entityId][0];
      this.$log.error(`attribute.entitytype.${entityId}.label.doesnt.exist`);
      return '';
    },
    getColor(entityId) {
      if (this.types[entityId] && this.types[entityId][1]) return this.types[entityId][1];
      this.$log.error(`attribute.entitytype.${entityId}.color.doesnt.exist`);
      return '';
    },
  },
};
</script>
