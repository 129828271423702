import Log from '@/models/utils/log';
import AttributeAbstract from '@/models/attribute/abstract';
import Family from '@/models/attribute/family';
import ClassificationFromMarketplace from '@/models/attribute/classificationfrommarketplace';

const namespace = 'models.attribute.classification';

export default class Classification extends AttributeAbstract {
  objectInitializer(data) {
    // inside classificationbyid.vue it takes the data from the store
    if (data && Object.keys(data).length > 0) {
      super.objectInitializer(data);

      this.hideProperty('deletedAt', data.deletedAt);
      this.hideProperty('createdAt', data.createdAt);

      this.remoteCode = data.remoteCode.replace('pim.c.', '');
      if (data.family) {
        this.family = new Family(data.family);
      }
      if (Array.isArray(data.fromMarketplace)) {
        this.fromMarketplace = data.fromMarketplace.map(v => new ClassificationFromMarketplace(v));
      }
    }
  }

  getCode() {
    try {
      return this.options.remoteCode || this.options.code;
    } catch (e) {
      Log.error(`${namespace}.getCode.${this.name}.failed`);
      return null;
    }
  }
}
