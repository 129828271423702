import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('app', ['user']),
    currentLang: {
      get() {
        return this.$store.getters['app/currentLang'];
      },
      set(value) {
        this.$i18n.locale = value;
        this.$store.commit('app/set_lang', value);
      },
    },
  },
};
