// https://vuex.vuejs.org/en/state.html
import Vue from 'vue';

const lineSmooth = Vue.chartist.Interpolation.cardinal({
  tension: 0,
});
// current month
const numberDays = (() => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
})();

export default {
  labels: {
    week: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    month: Array.from({ length: numberDays }, (_, i) => i + 1),
    hours: ['3am', '6am', '9am', '12am', '3pm', '6pm', '9pm', '12pm'],
    hours1to24: [
      '1am',
      '2am',
      '3am',
      '4am',
      '5am',
      '6am',
      '7am',
      '8am',
      '9am',
      '10am',
      '11am',
      '12am',
      '1pm',
      '2pm',
      '3pm',
      '4pm',
      '5pm',
      '6pm',
      '7pm',
      '8pm',
      '9pm',
      '10pm',
      '11pm',
      '12pm',
    ],
    hours12to9: ['12am', '3pm', '6pm', '9pm', '12pm', '3am', '6am', '9am'],
    year: ['Ja', 'Fe', 'Ma', 'Ap', 'Mai', 'Ju', 'Jul', 'Au', 'Se', 'Oc', 'No', 'De'],
  },

  options: {
    default: {
      low: 0,
      high: 50,
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    month: {
      lineSmooth,
    },
    week: {
      lineSmooth,
    },
    hours: {
      lineSmooth,
    },
    hours1to24: {
      lineSmooth,
    },
    hours12to9: {
      lineSmooth,
    },
    year: {
      axisX: {
        showGrid: false,
      },
    },
  },
};
