<template>
  <v-container fill-height fluid>
    <v-layout>
      <material-card
        title="Export for marketplace"
        class="green--text text--lighten-1 font-weight-light col-lg-12 marketplace-view"
      >
        <v-form v-model="isValid" ref="form" lazy-validation>
          <v-text-field
            v-if="parameters.edi_file"
            :value="formatDate(parameters.edi_file.ctime)"
            hide-details="auto"
            label="EDI Publish: 1 Date Synced with PIM"
            readonly
          ></v-text-field>
          <v-text-field
            v-model="titleExport"
            hide-details="auto"
            label="Export title"
            hint="Help to identify the export file"
            clearable
          ></v-text-field>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-radio-group
                v-model="marketplaceChoice"
                :rules="marketplaceRules"
              >
                <template v-slot:label>
                  <div>Choose marketplaces for Export</div>
                </template>
                <v-radio v-if="hasRight('catalogapp.v1:export:marketplace:sync:write')" value="tradebytes" required>
                  <template v-slot:label>
                    <div>for TheAgent BO (<strong class="success--text">Tradebytes and others</strong>)</div>
                  </template>
                </v-radio>
                <v-radio value="neutral">
                  <template v-slot:label>
                    <div>for Mirakl <strong class="primary--text">La Redoute</strong></div>
                  </template>
                </v-radio>
                <v-radio value="galeries-lafayette">
                  <template v-slot:label>
                    <div>for Mirakl <strong class="primary--text">Galeries Lafayette</strong></div>
                  </template>
                </v-radio>
                <v-radio value="decathlon">
                  <template v-slot:label>
                    <div>for Mirakl <strong class="primary--text">Decathlon</strong></div>
                  </template>
                </v-radio>
              </v-radio-group>
              <v-subheader class="pl-0">
                Filter by Supplier color creation date
              </v-subheader>
              <v-slider
                v-model="yearCreatedAt"
                :tick-labels="yearLabels"
                min="2021"
                :max="new Date().getFullYear()"
              ></v-slider>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <p>Choose your filter condition :</p>
              <v-checkbox
                v-model="selectedCondition"
                label="Brand"
                value="hasBrand"
                :rules="conditionRules"
                required
              ></v-checkbox>
              <v-checkbox
                v-model="selectedCondition"
                label="Supplier Reference"
                value="hasSupplierRef"
                :rules="conditionRules"
                required
              ></v-checkbox>
              <v-checkbox
                v-model="selectedCondition"
                label="Ean (European Article Numbering)"
                value="hasEan"
                :rules="conditionRules"
                required
              ></v-checkbox>
              <v-checkbox
                v-model="selectedCondition"
                label="Sku (Stock Keeping Unit)"
                value="hasSku"
                :rules="conditionRules"
                required
              ></v-checkbox>
            </v-col>
          </v-row>


          <attribute-brand-active
            v-if="selectedCondition.includes('hasBrand')"
            v-model="brandId"
            :rules="brandRules"
            required
          ></attribute-brand-active>
          <v-textarea
            v-if="selectedCondition.includes('hasSupplierRef')"
            v-model="supplierRefs"
            label="Supplier Reference"
            :rules="supplierRefsRules"
            required
            auto-grow
            filled
            clearable
          ></v-textarea>
          <v-textarea
            v-if="selectedCondition.includes('hasEan')"
            v-model="eans"
            label="Ean"
            :rules="eansRules"
            required
            auto-grow
            filled
            clearable
          ></v-textarea>
          <v-textarea
            v-if="selectedCondition.includes('hasSku')"
            v-model="skus"
            label="Sku"
            :rules="skusRules"
            required
            auto-grow
            filled
            clearable
          ></v-textarea>
          <v-card-actions>
            <v-btn :disabled="!isValid || loading" color="green darken-1" @click="validate" class="mr-4">
              Export
              <v-icon>mdi-database-export</v-icon>
            </v-btn>
            <v-btn
              color="warning"
              @click="resetValidation"
            >
              Reset Validation
            </v-btn>
          </v-card-actions>
        </v-form>
        <table-files
          v-model="files"
          reload="fetchListFiles()"
          :loading="loading"
        >
        </table-files>
      </material-card>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import Mixins from '@/mixins';
import TableFiles from '@/components/attributelist/table-files';

const baseURL = process.env.VUE_APP_PIM_BASEURL || 'http://localhost:3128/';
const checkingThreshold = process.env.VUE_APP_CHECK_FILES_THREESHOLD || 5;
const checkingLimitFilesThreshold = 100;

export default {
  name: 'MarketplaceView',
  mixins: Mixins,
  components: {
    TableFiles,
  },
  data: () => ({
    yearLabels: Array.from(
      { length: (new Date().getFullYear() - 2021) + 1 },
      (value, index) => 2021 + index,
    ),
    yearCreatedAt: 2021,
    isValid: true,
    brandId: null,
    supplierRefs: null,
    eans: null,
    skus: null,
    // hasBrand, hasSupplierRef, hasEan, hasSku
    selectedCondition: [],
    marketplaceChoice: null,
    callIntervalFiles: null,
    titleExport: null,
    checkingThreshold,
    checkingLimitFilesThreshold,
    countChecking: 0,
    countFilesChecking: 0,
    total: 0,
    loading: false,
    baseURL,
    files: [],
    parameters: {
      edi_file: null,
    },
  }),
  created() {
    this.intervalFiles();
  },
  computed: {
    marketplaceRules() {
      return [
        !!this.marketplaceChoice || 'At least one marketplace should be selected',
      ];
    },
    conditionRules() {
      return [
        this.selectedCondition.length > 0 || 'At least one condition should be selected',
      ];
    },
    brandRules() {
      if (!this.selectedCondition.includes('hasBrand')) return [];
      return [v => !!v || 'Brand is required'];
    },
    supplierRefsRules() {
      if (!this.selectedCondition.includes('hasSupplierRef')) return [];
      return [v => !!v || 'A list of supplier refs is required'];
    },
    eansRules() {
      if (!this.selectedCondition.includes('hasEan')) return [];
      return [v => !!v || 'A list of eans is required'];
    },
    skusRules() {
      if (!this.selectedCondition.includes('hasSku')) return [];
      return [v => !!v || 'A list of skus is required'];
    },
    settings() {
      return this.$store.getters['settings/settings'];
    },
  },
  methods: {
    ...mapActions('export', {
      postMarketplace: 'marketplace',
      postCheck: 'check',
      fetchList: 'files',
    }),
    intervalFiles() {
      this.resetIntervalFiles();
      this.callIntervalFiles = setInterval(() => {
        this.fetchListFiles();
        this.countFilesChecking += 1;
        if (this.countFilesChecking >= this.checkingLimitFilesThreshold) {
          this.resetIntervalFiles();
        }
      }, 5000);
    },
    resetIntervalFiles() {
      clearInterval(this.callIntervalFiles);
    },
    async fetchListFiles() {
      const { result, parameters } = await this.fetchList()
        .catch((e) => { // eslint-disable-line no-unused-vars
          this.resetIntervalFiles();
          throw new Error('Stop retrieving files');
        });
      this.files = result;
      this.parameters = parameters;
    },
    async check(payload, withoutDone = true) {
      this.countChecking += 1;
      const { result, result_count: resultCount } = await this.postCheck(payload);
      this.total = resultCount;
      if (result.exists && ['done', 'wip'].includes(result.state) && withoutDone) {
        if (result.state === 'done') {
          const index = this.files.findIndex(f => result.filepathFound === f.filepath);
          if (index !== -1) {
            Vue.set(this.files[index], 'exists', true);
          }
          this.notificationAlfred({ message: 'The file for today already exist, I don\'t do anything' });
        } else if (result.state === 'wip') this.notificationAlfred({ message: 'A file is generating actually, I don\'t do anything' });
        throw new Error('Stop the processus');
      }
      if (this.checkingThreshold < this.countChecking) {
        this.notificationSystem({ message: 'Threshold reached' });
        throw new Error('Please await until the file is generated, threshold reached');
      } else {
        this.notificationAlfred({
          message: `Please wait while the file is being generated. Only ${resultCount} R remains to be generated.`,
          timeout: 10000,
        });
      }
      if (['none', 'wip'].includes(result.state) && resultCount > 0 && result.exists) return new Promise(resolve => setTimeout(() => resolve(this.check(payload, withoutDone)), 10000));
      return Promise.resolve();
    },
    validate() {
      this.loading = true;
      this.countChecking = 0;
      // clean files for exists property
      if (Array.isArray(this.files) && this.files.length > 0) {
        this.files = this.files.map((f) => {
          delete f.exists;
          return f;
        });
      }
      if (this.$refs.form.validate() && this.marketplaceChoice) {
        const payload = {
          marketplace: this.marketplaceChoice,
          title: this.titleExport,
          variantCreatedAt: this.yearCreatedAt,
        };
        if (this.selectedCondition.includes('hasBrand') && this.brandId) {
          payload.brandId = this.brandId;
        }
        if (this.selectedCondition.includes('hasSupplierRef') && this.supplierRefs) {
          payload.supplierRefs = this.supplierRefs.split('\n').filter(Boolean);
        }
        if (this.selectedCondition.includes('hasEan') && this.eans) {
          payload.eans = this.eans.split('\n').filter(Boolean);
        }
        if (this.selectedCondition.includes('hasSku') && this.skus) {
          payload.skus = this.skus.split('\n').filter(Boolean);
        }
        this.check(payload)
          .then(() => this.postMarketplace(payload)
            .then(() => this.check(payload, false))
            .then(() => this.notificationAlfred({
              message: 'Come back later, I\'m working on it',
              timeout: 10000,
            })))
          .catch(e => this.throwError(e))
          .finally(() => this.$nextTick(() => {
            this.loading = false;
            this.checkingThreshold = checkingThreshold;
            this.total = 0;
            this.intervalFiles();
          }));
      } else {
        this.$nextTick(() => {
          this.loading = false;
          this.total = 0;
          this.checkingThreshold = checkingThreshold;
        });
        this.notificationAlfred({ message: 'Please fill the form correctly' });
      }
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    formatDate(dateString) {
      try {
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', {
          weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric',
        });
      } catch (e) {
        return '';
      }
    },
  },
};
</script>

<style lang="scss">
  .marketplace-view {
    & .v-input__slot {
      margin-bottom: 0 !important;
    }
    & .v-input--selection-controls {
      margin-top: 0;
    }

    & .v-messages:has(> .v-messages__wrapper:empty) {
      display: none;
    }
  }


</style>
