<template>
  <material-card color="blue" :title="title" class="col-lg-12">
    <template v-slot:dialog>
      <dialog-attribute-base
        :attribute="editedItem"
        :dialog="dialog"
        v-on:update:dialog="dialog = $event"
        @onSave="saveAttribute"
        @onClose="closeItem"
      >
      </dialog-attribute-base>
    </template>
    <v-text-field v-model="search" label="Search" single-line hide-details> </v-text-field>
    <v-spacer></v-spacer>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :footer-props="{ 'items-per-page-options': [15, 30, 50, 100, -1] }"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" v-on:click.stop @click="editAttribute(item)">
          mdi-pencil-circle
        </v-icon>
        <v-icon small v-on:click.stop @click="deleteAttribute(item)">
          mdi-delete-circle
        </v-icon>
      </template>
    </v-data-table>
  </material-card>
</template>

<script>
import EditMixins from '../../mixins/edit-base';
import AttributeEditMixins from '../../mixins/attribute-edit-base';
import Mixins from '../../mixins';

export default {
  name: 'AttributeList',
  mixins: [EditMixins, AttributeEditMixins, ...Mixins],
  computed: {
    title() {
      return `Property: ${this.mainAttribute}`;
    },
  },
  data: () => ({
    search: '',
    headers: [
      {
        sortable: false,
        text: 'Id',
        value: 'id',
      },
      {
        sortable: true,
        text: 'Name',
        value: 'name',
      },
      {
        sortable: true,
        text: 'Remote Code',
        value: 'remoteCode',
      },
      {
        sortable: false,
        text: 'Actions',
        value: 'actions',
      },
    ],
    defaultItem: {},
  }),
  methods: {},
};
</script>
