<template>
  <h6 style="display: inline-block">
    {{ header.title }}
    <v-btn
      fab
      x-small
      v-if="isFiltered"
      color="red"
      v-on:click.stop="headerFiltered(header.identifier)"
      >{{ header.count }}</v-btn
    >
    <v-btn
      fab
      x-small
      v-if="value"
      color="blue"
      v-on:click.stop="headerUnfiltered(header.identifier)"
      ><v-icon>mdi-close</v-icon></v-btn
    >
  </h6>
</template>

<script>
import ItemsBaseMixins from '../../mixins/items-base';

export default {
  name: 'TableHeaderTitle',
  mixins: [ItemsBaseMixins],
  props: {
    value: {
      type: Boolean, // String, Number, Boolean, Function, Object, Array
      required: false,
    },
    header: {
      type: Object, // String, Number, Boolean, Function, Object, Array
      required: true,
      validator(value) {
        return (
          typeof value.title !== 'undefined'
          && typeof value.count !== 'undefined'
          && typeof value.identifier !== 'undefined'
        );
      },
    },
  },
  computed: {
    isFiltered() {
      return !this.value && !!this.header.count;
    },
  },
  methods: {
    headerFiltered(headerIdentifier) {
      this.$emit('header:filtered', headerIdentifier);
    },
    headerUnfiltered(headerIdentifier) {
      this.$emit('header:unfiltered', headerIdentifier);
    },
  },
};
</script>
