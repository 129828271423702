<template>
  <v-lazy
    v-model="isActive"
    :options="{
      threshold: 0.5
    }"
    min-height="80"
    transition="fade-transition"
  >
    <v-avatar class="mx-auto" width="100%" :height="`undefined`" max-width="100" tile>
      <img v-if="image" alt="Avatar" :src="image" />
      <v-icon v-else large color="gray darken-2">mdi-camera-plus</v-icon>
    </v-avatar>
  </v-lazy>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    size: {
      type: String,
      default: '64px',
    },
    customSize: {
      type: [Boolean, String],
      default: false,
    },
    image: {
      type: [Boolean, String],
      required: true,
      default: '',
    },
  },
  data: () => ({
    isActive: false,
  }),
  computed: {
    settings() {
      return this.$store.getters['settings/settings'];
    },
    sizeImage() {
      if (this.settings.dense && !this.customSize) {
        return '36px';
      }
      if (this.customSize) {
        return this.customSize;
      }
      return this.size;
    },
  },
};
</script>

<style>
.v-avatar.mx-auto.v-avatar--tile {
  width: auto !important;
}
</style>
