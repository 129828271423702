<template>
  <v-container fill-height fluid>
    <v-row dense align="center" style="background-color: white;">
      <v-col cols="1">
        Edition
      </v-col>
      <v-col cols="11">
        <dialog-search-translate v-model="search" @onSave="saveAndSearch">
        </dialog-search-translate>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <core-alert></core-alert>
      </v-col>
    </v-row>
    <v-data-table
      :headers="filterByColumn('translate')"
      class="elevation-2 ta-fix-column ta-translate"
      :items="items"
      :server-items-length="itemsCount"
      :options.sync="itemsOptions"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [100, 200, 500, -1] }"
      loading-text="Loading... Please wait"
      :show-expand="settings.showExpand"
      :dense="settings.dense"
      fixed-header
    >
      <template v-slot:item.data-table-expand>
        <div class="text-center ta-expand-icon">
          <v-btn fab x-small class="ta-translate">T</v-btn>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" v-on:click.stop @click="editItem(item)">
          mdi-pencil-circle
        </v-icon>
        <v-icon small class="mr-2" @click="deleteItem(item)">
          mdi-delete-circle
        </v-icon>
      </template>
    </v-data-table>
    <div>
      <dialog-translate
        v-if="hasRightActions"
        :item="editedItem"
        :dialog="dialog"
        v-on:update:dialog="dialog = $event"
        @onSave="saveItemTranslate"
        @onClose="closeItem"
      >
      </dialog-translate>
      <dialog-settings :dialog="dialog"> </dialog-settings>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import { marketplace } from 'theagent-status';
import Mixins from '../../mixins';
import ItemsBaseMixins from '../../mixins/items-base';
import EditMixins from '../../mixins/edit-base';
import ProfileMixins from '../../mixins/profile-base';

export default {
  mixins: [ItemsBaseMixins, EditMixins, ProfileMixins, ...Mixins],
  data: () => ({
    itemsCount: 0,
    itemsOptions: {
      sortBy: [],
      sortDesc: [],
      itemsPerPage: 50,
    },
    items: [{}],
    search: {}, // data come from dialog-search via a two way binding
    loading: false,
    mode: 0,
    doneStatus: marketplace.DONE,
  }),
  created() {
    this.itemsOptions = this.itemsOptionsDefault();
    this.editedItem = { ...this.defaultItem };
    this.$on('delete:item', item => this.deleteTranslate(item));
  },
  watch: {
    itemsOptions: {
      handler() {
        this.searchTranslations().then(({ itemsCount, itemsPerPage }) => {
          this.itemsCount = itemsCount;
          this.itemsPerPage = itemsPerPage;
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.$log.log('components.settings.translatelistview.mounted');
    if (Object.keys(this.$route.query).length > 0) {
      const {
        limit = this.itemsOptions.itemsPerPage,
        page = 1,
        search = '',
        filters = {},
      } = this.$route.query;
      this.search = {
        text: search,
        filters: JSON.parse(filters),
      };
      this.itemsOptions.itemsPerPage = limit;
      this.itemsOptions.page = page;
    }
  },
  computed: {
    settings() {
      return this.$store.getters['settings/settings'];
    },
    headers() {
      return this.$store.getters['settings/headers'].translate;
    },
    defaultItem: function defaultItem() {
      return this.$store.getters['settings/headers'].translate.reduce((item, header) => {
        // eslint-disable-next-line no-param-reassign
        item[header.value] = '';
        return item;
      }, {});
    },
  },
  methods: {
    ...mapActions('translate', {
      apiTranslateSearch: 'search',
    }),
    searchTranslations() {
      this.$log.log('components.settings.translatelistview.searchproducts.started');
      this.$log.debug('components.settings.translatelistview.searchproducts.filters', this.search);
      this.loading = true;
      const { page, itemsPerPage } = this.itemsOptions;
      let filters = {};
      if (Object.keys(this.search).length > 0) {
        // eslint-disable-next-line prefer-destructuring
        filters = this.search.filters;
      }
      const params = {
        params: {
          search: this.search.text ? this.search.text.trim() : '',
          limit: parseInt(itemsPerPage, 10),
          page,
          filters,
        },
      };
      this.$router
        .push({
          name: 'settings.translations',
          query: {
            search: this.search.text,
            limit: params.params.limit,
            page: params.params.page,
            filters: JSON.stringify(filters),
          },
        })
        .catch(() => {}); // NavigationDuplicated error quiet

      return this.apiTranslateSearch(params)
        .then((data) => {
          const { items } = this.options(data.result, this.itemsCount, this.itemsOptions);
          this.items = items;
          this.$log.debug('components.settings.translatelistview.searchproducts.result', {
            itemsCount: data.parameters.count,
            itemsPerPage,
            itemsOptions: this.itemsOptions,
          });
          return {
            itemsCount: data.parameters.count,
            itemsPerPage,
          };
        })
        .catch(error => this.throwError(error))
        .finally(() => {
          this.loading = false;
        });
    },
    deleteTranslate(item) {
      this.$log.info('components.settings.translatelistview.deletetranslate.started');
      this.$store
        .dispatch('translate/delete', item.id)
        .catch(error => this.throwError(error))
        .finally(() => this.closeItem());
    },
    saveItem() {
      this.$log.info('components.settings.translatelistview.saveitem.started', this.editedItem);
      let endpoint = 'translate/new';
      const isOld = this.editedIndex > -1;
      if (isOld) {
        endpoint = 'translate/update';
      }
      this.$store
        .dispatch(endpoint, this.editedItem)
        .then((response) => {
          if (this.editedIndex === -1) {
            this.items.unshift(response);
          } else {
            Vue.set(this.items, this.editedIndex, response);
          }
        })
        .catch(error => this.throwError(error))
        .finally(() => this.closeItem());
    },
    saveAndSearch(filters) {
      this.$log.info('components.settings.translatelistview.saveandsearch.started');
      return this.searchTranslations(filters).then(({ itemsCount, itemsPerPage }) => {
        this.itemsCount = itemsCount;
        this.itemsPerPage = itemsPerPage;
      });
    },
    saveItemTranslate(save = true) {
      this.$log.debug('components.pim.importcsvedit.saveitemcdv.started');
      // to save new item
      if (save) {
        this.saveItem();
      }
      return this.closeItem();
    },
  },
};
</script>

<style></style>
