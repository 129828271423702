// https://vuex.vuejs.org/en/mutations.html

export default {
  set_products(state, products) {
    state.products = products;
  },
  set_product(state, product) {
    state.product = product;
  },
};
