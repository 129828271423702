// https://vuex.vuejs.org/en/mutations.html

export default {
  set_classifications(state, payload) {
    state.classifications = payload;
  },
  set_classification(state, payload) {
    state.classification = payload;
  },
  set_state(state, payload) {
    state.state = payload;
  },
};
