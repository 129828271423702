import Vue from 'vue';

import './axios';
import './flag';
import './logger';
import './notification';
import './chartist';
import './i18n';

import TheAgent from './theagent';
import Pim from './pim';

// The plugin is loaded here.
Vue.use(Pim, {
  baseURL: process.env.VUE_APP_PIM_BASEURL || 'http://localhost:3128/',
});
Vue.use(TheAgent, {});
