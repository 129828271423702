<template>
  <v-chip
    dark
    class="ma-2 ta-error"
    :style="{ backgroundColor: '#f44336' }"
    small
  >
    <v-avatar
      left
      class="red darken-4"
    >
      C
    </v-avatar>
    No association
  </v-chip>
</template>

<script>
export default {
  name: 'LinkError',
};
</script>

<style lang="css">
</style>
