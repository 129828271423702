<template>
  <v-row align="center" no-gutters dense>
    <v-col cols="11">
      <v-row align="center" no-gutters dense>
        <v-col cols="12" no-gutters>
          <chips-text
            v-if="searchParameters.text && searchParameters.text.length < 40"
            v-model="searchParameters.text"
            color="red"
            @onClose="save"
          />
          <chips-values-object
            v-if="searchParameters.filters.brandId"
            :items="brands"
            name="brand"
            v-model="searchParameters.filters.brandId"
            color="primary"
            @onClose="save"
          />
          <chips-values-object
            v-if="searchParameters.filters.classificationId"
            :items="classifications"
            name="classification"
            v-model="searchParameters.filters.classificationId"
            :keys="['id', 'nameI18n']"
            color="secondary"
            @onClose="save"
          />
          <chips-values-object
            v-if="searchParameters.filters.distributorIds"
            :items="distributors"
            name="supplier"
            v-model="searchParameters.filters.distributorIds"
            @onClose="save"
          />
          <chips-values-object
            v-if="searchParameters.filters.season"
            :items="collections"
            name="season"
            v-model="searchParameters.filters.season"
            color="pink"
            @onClose="save"
          />
          <chips-values-object
            v-if="searchParameters.filters.from"
            :items="states"
            name="country"
            v-model="searchParameters.filters.from"
            :keys="['key', 'name']"
            color="yellow"
            @onClose="save"
          />
          <chips-text
            v-if="searchParameters.filters.searchType"
            v-model="searchParameters.filters.searchType"
            color="green"
            type="search-type"
            @onClose="save"
          />
          <chips-date
            v-if="searchParameters.filters.createdAt"
            v-model="searchParameters.filters.createdAt"
            name="dateStart"
            color="blue"
            @onClose="save"
          />
        </v-col>
        <v-col cols="11">
          <v-text-field
            v-model="searchParameters.text"
            label="Search your data"
            v-on:keyup.enter="save"
            append-icon="mdi-magnify"
            :hint="hintText"
          >
          </v-text-field>
        </v-col>
        <v-col cols="1">
          <material-markdown src="search.md"></material-markdown>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="1">
      <v-dialog
        v-model="searchParameters.menu"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
        <template v-slot:activator="{ on }">
          <v-btn color="black darken-1" icon dark v-on="on">
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
        </template>
        <v-card tile>
          <v-toolbar flat dark color="blue">
            <v-btn icon dark @click="searchParameters.menu = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Advanced search</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn dark text @click="reset">
              <v-icon>mdi-lock-reset</v-icon>
            </v-btn>
            <v-btn dark text @click="save">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-toolbar>
          <v-list three-line subheader>
            <v-subheader>General</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  Set the search text <material-markdown src="search.md"></material-markdown>
                </v-list-item-subtitle>
                <v-row>
                  <v-col cols="9">
                    <v-textarea
                      v-model="searchParameters.text"
                      label="Search"
                      hide-details
                    ></v-textarea>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      v-model="searchParameters.filters.searchType"
                      column
                    >
                      <v-radio
                        label="Classic search"
                        color="red"
                        value="text"
                      ></v-radio>
                      <v-radio
                        label="Multiple search for product name (product)"
                        color="green"
                        value="productName"
                      ></v-radio>
                      <v-radio
                        label="Multiple search for supplier reference (variant color)"
                        color="orange"
                        value="supplierReference"
                      ></v-radio>
                      <v-radio
                        label="Multiple search for ean (variant size)"
                        color="blue darken-3"
                        value="ean"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list subheader>
            <v-subheader>Filters</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <attribute-date
                  :item="searchParameters.filters"
                  property="createdAtStart"
                  label="Choose your date start"
                ></attribute-date>
              </v-list-item-content>
              <v-spacer></v-spacer>
              <v-list-item-content>
                <attribute-date
                  :item="searchParameters.filters"
                  property="createdAtEnd"
                  label="Choose your date end"
                ></attribute-date>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <attribute-brand
                  ref="brand"
                  :item="searchParameters.filters"
                  property="brandId"
                  label="Pick your brand"
                  multiple
                  chips
                />
              </v-list-item-content>
              <v-divider vertical></v-divider>
              <v-list-item-content>
                <attribute-classification
                  ref="brand"
                  :item="searchParameters.filters"
                  property="classificationId"
                  label="Pick your classification"
                  multiple
                  chips
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <attribute-country
                  ref="country"
                  :item="searchParameters.filters"
                  property="from"
                  label="Choose your country"
                  multiple
                  chips
                />
              </v-list-item-content>
              <v-divider vertical></v-divider>
              <v-list-item-content>
                <attribute-fournisseurs
                  ref="fournisseurs"
                  :item="searchParameters.filters"
                  property="distributorIds"
                  label="Pick your supplier"
                  multiple
                  chips
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <attribute-collection
                  ref="season"
                  :item="searchParameters.filters"
                  property="season"
                  label="Select your season"
                  chips
                />
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list subheader>
            <v-subheader>Additionnal parameters</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-switch
                  v-model="searchParameters.filters.marketplace"
                  class="mt-2"
                  label="Marketplace"
                ></v-switch>
              </v-list-item-content>
              <v-divider vertical></v-divider>
              <v-list-item-content>
                <v-switch
                  v-model="searchParameters.filters.active"
                  class="mt-2"
                  label="Active"
                ></v-switch>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import ConfigBaseMixins from '../../mixins/config-base';

export default {
  name: 'search',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [ConfigBaseMixins],
  data: () => ({
    hintText: null,
    searchType: 'text',
    searchParameters: {
      menu: false,
      text: '',
      filters: {},
    },
    defaultFilters: {
      brandId: [],
      classificationId: [],
      marketplace: 0,
      from: null,
      season: null,
      createdAtStart: null,
      createdAtEnd: null,
      searchType: 'text',
    },
  }),
  computed: {
    ...mapGetters('form/rules', { textRules: 'text' }),
    settings() {
      return this.$store.getters['settings/settings'];
    },
    headers() {
      return this.$store.getters['settings/headers'].product;
    },
    collections() {
      return this.$store.getters['collection/collections'];
    },
  },
  watch: {
    value() {
      this.searchParameters = this.value;
    },
    'searchParameters.text': {
      handler(text) {
        [
          ...['brand', 'brands', 'marque', 'marques'],
          ...['classification', 'classifications', 'category', 'categorie', 'categories', 'cat'],
          ...['distributor', 'supplier', 'fournisseur', 'distributors', 'suppliers'],
          ...['collection', 'collections', 'saison', 'season'],
          ...['pays', 'country', 'countries'],
          ...['ean', 'barcode'],
          ...['sku'],
        ].forEach((key) => {
          if (['brand', 'brands', 'marque', 'marques'].includes(key)) {
            this.tokenizer(text, key, this.brands, 'brandId');
          } else if (
            [
              'classification',
              'classifications',
              'category',
              'categorie',
              'categories',
              'cat',
            ].includes(key)
          ) {
            this.tokenizer(text, key, this.classifications, 'classificationId');
          } else if (
            ['distributor', 'supplier', 'fournisseur', 'distributors', 'suppliers'].includes(key)
          ) {
            this.tokenizer(text, key, this.distributors, 'distributorIds');
          } else if (['collection', 'collections', 'saison', 'season'].includes(key)) {
            this.tokenizer(text, key, this.collections, 'season');
          } else if (['pays', 'country', 'countries'].includes(key)) {
            this.tokenizer(text, key, this.states, 'from');
          } else if (['ean', 'barcode'].includes(key)) {
            this.tokenizer(text, key, null, 'ean');
          } else if (['sku'].includes(key)) {
            this.tokenizer(text, key, null, 'sku');
          }
        });
      },
      deep: true,
    },
  },
  mounted() {
    if (Object.keys(this.value).length > 0) {
      this.searchParameters = {
        menu: false,
        text: this.value.text || '',
        filters: this.value.filters || {},
      };
    }
    this.apiCollection();
  },
  methods: {
    ...mapActions('collection', { apiCollection: 'list' }),
    setUndefinedIfNeeded(value = null) {
      return !!(value === null || value === '' || typeof value === 'undefined');
    },
    reset() {
      this.searchParameters.filters = { ...this.defaultFilters };
      this.$emit('input', this.filtered);
      this.$emit('onSave');
      this.close();
    },
    renderFilter(data) {
      this.$log.log('components.dialog.search.renderfilter.started');
      const dataFiltered = { ...data };
      let filtered = { ...data.filters };
      if (filtered.createdAtStart || filtered.createdAtEnd) {
        if (filtered.createdAtStart && filtered.createdAtEnd) {
          filtered.createdAt = {
            type: 'between',
            value: [filtered.createdAtStart, filtered.createdAtEnd],
          };
        } else if (filtered.createdAtStart) {
          filtered.createdAt = {
            type: 'gte',
            value: filtered.createdAtStart,
          };
        } else if (filtered.createdAtEnd) {
          filtered.createdAt = {
            type: 'lte',
            value: filtered.createdAtEnd,
          };
        }
      }
      filtered = Object.keys(filtered).reduce((result, filter) => {
        if (this.setUndefinedIfNeeded(filtered[filter]) === false) {
          Vue.set(result, filter, filtered[filter]);
        }
        return result;
      }, {});
      delete filtered.createdAtStart;
      delete filtered.createdAtEnd;
      delete dataFiltered.menu;
      dataFiltered.filters = filtered;
      if (this.setUndefinedIfNeeded(dataFiltered.text) === true) {
        delete dataFiltered.text;
      }
      this.$log.debug('components.dialog.search.renderfilter.data', dataFiltered);
      return dataFiltered;
    },
    tokenizer(text, key, values = [], parameterKey = false) {
      if (text && text.includes(`${key}:`)) {
        const regex = new RegExp(`${key}:([a-z0-9$])*`, 'igm');
        const [bind] = regex.exec(text);
        if (values.some(b => b.name.slugify().includes(bind.split(':')[1].slugify()))) {
          const valuesFound = values.filter(b => b.name.slugify().includes(bind.split(':')[1].slugify()));
          if (valuesFound.length > 1 && valuesFound.length < 4 && text.indexOf('$') === -1) {
            this.hintText = valuesFound.map(b => b.nameI18n || b.name).join(', ');
          } else if (valuesFound.length === 1 || text.indexOf('$') !== -1) {
            this.hintText = null;
            if (typeof this.searchParameters.filters[parameterKey] === 'undefined') {
              Vue.set(this.searchParameters.filters, parameterKey, []);
            }
            this.searchParameters.filters[parameterKey].push(valuesFound[0].id);
            Vue.set(this.searchParameters, 'text', text.replace(bind, ''));
          }
        } else if (values === null) {
          this.hintText = null;
          if (typeof this.searchParameters.filters.searchType === 'undefined') {
            Vue.set(this.searchParameters.filters, 'searchType', 'text');
          }
          this.searchParameters.filters.searchType = parameterKey;
          Vue.set(this.searchParameters, 'text', text.replace(bind, ''));
        }
      }
    },
    save() {
      this.$log.debug('components.dialog.search.save.data', this.searchParameters);
      const filtered = this.renderFilter(this.searchParameters);
      this.$emit('input', filtered);
      this.$emit('onSave', filtered);
      this.close();
    },
    close() {
      // https://vuejs.org/v2/guide/components-custom-events.html#sync-Modifier
      this.searchParameters.menu = false;
    },
  },
};
</script>

<style lang="css">
.v-toolbar__title {
  color: white;
}

.theme--light .v-picker td .v-btn {
  /*color: rgba(0, 0, 0, 0.87) !important;*/
}
</style>
