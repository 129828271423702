<template>
  <div>
    <!-- @todo this block can be factorized -->
    <template v-if="!hasRightActions">
      <div v-for="(langPim, lang) in langs" :key="lang">
        <template v-if="hasLang(langPim) && item[property][keyAttr][langPim]">
          <flag :iso="lang" />{{ item[property][keyAttr][langPim] }}
        </template>
      </div>
    </template>
    <v-edit-dialog :return-value="item[property][keyAttr]" large lazy v-else @save="saveInline">
      <div v-for="(langPim, lang) in langs" :key="lang">
        <template v-if="hasLang(langPim) && item[property][keyAttr][langPim]">
          <flag :iso="lang" />{{ item[property][keyAttr][langPim] }}
        </template>
      </div>
      <core-error
        v-if="
          typeof item[property].errors !== 'undefined' &&
            typeof item[property].errors[keyAttr] !== 'undefined'
        "
        :property="property"
        :errors="item[property].errors[keyAttr]"
      />
      <template v-slot:input>
        <v-card>
          <v-tabs
            background-color="cyan"
            dark
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
          >
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab v-for="(langPim, lang) in langs" :key="lang">
              <flag :iso="lang" />
            </v-tab>
            <v-tab-item v-for="(langPim, lang) in langs" :key="lang">
              <v-textarea
                v-if="hasLang(langPim)"
                v-model="item[property][keyAttr][langPim]"
                filled
                :value="item[property][keyAttr][langPim]"
                class="vuetify-editor"
                :label="langPim"
                auto-grow
              ></v-textarea>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
/* eslint no-param-reassign:0 */
/* eslint max-len:0 */
import { mapGetters } from 'vuex';
import DialogBaseMixins from '../../mixins/dialog-base';
import ProfileBaseMixins from '../../mixins/profile-base';
import LangBaseMixins from '../../mixins/lang-base';

export default {
  mixins: [DialogBaseMixins, LangBaseMixins, ProfileBaseMixins],
  props: {
    /**
     * this is the key from quable
     *
     * @type {String}
     * @example
     *   - attr_descrption_r
     */
    keyAttr: {
      type: String,
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters('form/rules', { textRules: 'text' }),
    itemsKey: () => undefined,
    titleName: () => undefined,
    itemsValue: () => undefined,
  },
  created() {
    this.initLongTextLang();
    if (typeof this.item[this.property][this.keyAttr] === 'string') {
      const valueString = this.item[this.property][this.keyAttr];
      this.item[this.property][this.keyAttr] = {
        fr: valueString,
      };
    }
  },
  methods: {
    hasLang(lang) {
      if (!this.item[this.property]) {
        return false;
      }
      return (
        !!this.item[this.property][this.keyAttr]
        && typeof this.item[this.property][this.keyAttr][lang] !== 'undefined'
      );
    },
  },
};
</script>

<style></style>
