<template>
  <v-autocomplete
    :chips="chips"
    :filled="filled"
    :item-value="itemValue"
    :items="itemsValue"
    :label="label || 'Pick your classification'"
    :messages="getDefaultValues()"
    :loading="isCompletudeRequired(bindAttribute, currentIssues)"
    :multiple="multiple"
    hint="Pick your classification"
    item-text="name"
    menu-props="auto"
    persistent-hint
    v-model="valueAttribute"
  >
  </v-autocomplete>
</template>
<script>
import AttributeBaseMixins from '@/mixins/attribute-base';
import CompletudeBaseMixins from '@/mixins/completude-base';

export default {
  mixins: [AttributeBaseMixins, CompletudeBaseMixins],
  name: 'attributeClassification',
  data: () => ({}),
  computed: {
    itemsValue() {
      return this.$store.getters['classification/classifications'];
    },
  },
  methods: {},
};
</script>
