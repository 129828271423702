<template>
  <v-autocomplete
    chips
    :items="itemsValue"
    :label="'Pick your brand (required)'"
    hint="Pick your brand"
    item-text="name"
    item-value="id"
    menu-props="auto"
    persistent-hint
    v-model="brandId"
    v-bind="$attrs"
  >
  </v-autocomplete>
</template>
<script>

export default {
  name: 'AttributeBrandNative',
  props: {
    value: {
      type: [Number, Object],
      default: () => false,
    },
  },
  data: () => ({
    brandId: null,
  }),
  mounted() {
    this.brandId = this.value;
  },
  computed: {
    itemsValue() {
      return this.$store.getters['brand/brands'];
    },
  },
  watch: {
    brandId() {
      this.$emit('input', this.brandId);
    },
  },
};
</script>
