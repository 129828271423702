<template>
  <v-menu open-on-hover class="text-end" left offset-x nudge-left="20" close-delay="100">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon color="grey lighten-1">mdi-help-circle</v-icon>
      </v-btn>
    </template>
    <material-card>
      <markdown-it-vue class="md-body" :content="content" :options="options"></markdown-it-vue>
    </material-card>
  </v-menu>
</template>

<script>
import MarkdownItVue from 'markdown-it-vue';
import axios from 'axios';

export default {
  name: 'MaterialMarkdown',
  components: {
    MarkdownItVue,
  },
  props: {
    src: {
      type: String,
      required: true,
      default: '',
    },
  },
  data: () => ({
    content: '',
    options: {
      markdownIt: {
        linkify: true,
      },
      linkAttributes: {
        attrs: {
          target: '_blank',
          rel: 'noopener',
        },
      },
    },
  }),
  mounted() {
    this.updatedContent();
  },
  watch: {
    src() {
      this.updatedContent();
    },
  },
  methods: {
    updatedContent() {
      const baseUrl = process.env.VUE_APP_BASEURL || 'http://localhost:8080/';
      if (this.src) {
        axios.get(`${baseUrl}/assets/md/${this.src}`).then((response) => {
          this.content = response.data;
        });
      }
    },
  },
};
</script>

<style type="text/css">
.md-body {
  text-align: start !important;
}

.md-body table {
  width: 100% !important;
}
</style>
