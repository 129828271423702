/* eslint no-empty:0 */

import Profile, { getProfile } from '../helpers/profile';

export const settingsDefault = {
  singleExpand: false,
  expandAll: false,
  showSelect: false,
  showHeaders: false,
  showExpand: true,
  dense: false,
  headers: {},
  profile: getProfile(Profile.DEFAULT),
};

export default {
  set: function set(settings) {
    localStorage.setItem('settings', JSON.stringify(settings));
  },

  get: function get() {
    try {
      const settings = JSON.parse(localStorage.getItem('settings'));
      if (Object.keys(settings).length === 0) {
        throw new TypeError('services.settingsstorage.settings.empty');
      }
      return settings;
    } catch (err) {}
    return Object.assign({}, settingsDefault);
  },

  setItem: function setItem(property, value) {
    let settings = Object.assign({}, settingsDefault);
    try {
      settings = JSON.parse(localStorage.getItem('settings'));
      if (typeof settings[property] !== 'undefined') {
        settings[property] = value;
      }
    } catch (err) {}
    localStorage.setItem('settings', JSON.stringify(settings));
  },

  getItem: function getItem(property) {
    let settings = Object.assign({}, settingsDefault);
    try {
      settings = JSON.parse(localStorage.getItem('settings'));
      if (typeof settings[property] !== 'undefined') {
        return settings[property];
      }
    } catch (err) {}
    return settings;
  },

  clear: function clear() {
    localStorage.removeItem('settings');
  },
};
