export default [
  {
    name: 'fr_FR',
    legacyName: 'fr',
    icon: 'fr',
    iso3: 'fra',
    label: 'français',
    required: true,
    interface: true,
  },
  {
    name: 'en_GB',
    legacyName: 'en',
    icon: 'gb',
    iso3: 'gbr',
    label: 'anglais',
    required: true,
    interface: true,
  },
  {
    name: 'es_ES',
    legacyName: 'es',
    icon: 'es',
    iso3: 'esp',
    label: 'espagnol',
    interface: true,
  },
  {
    name: 'it_IT',
    legacyName: 'it',
    icon: 'it',
    iso3: 'ita',
    label: 'italien',
  },
  {
    name: 'de_DE',
    legacyName: 'de',
    icon: 'de',
    iso3: 'deu',
    label: 'allemand',
  },
  // {
  //   name: 'zh_CN',
  //   legacyName: 'zh',
  //   icon: 'zh',
  //   iso3: null,
  //   label: 'chinois',
  // },
  // {
  //   name: 'en_US',
  //   legacyName: 'us',
  //   icon: 'us',
  //   iso3: 'usa',
  //   label: 'américain',
  // },
  {
    name: 'pt_PT',
    legacyName: 'pt',
    icon: 'pt',
    iso3: 'prt',
    label: 'portugais',
  },
  {
    name: 'nl_NL',
    legacyName: 'nl',
    icon: 'nl',
    iso3: 'nld',
    label: 'néerlandais',
  },
];
