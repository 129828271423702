import { set } from '@/utils/vuex';

export default {
  set_image: set('image'),
  set_color: set('color'),
  set_drawer: set('drawer'),
  set_rest_api: set('restApi'),
  set_user: set('user'),
  set_client: set('client'),
  set_lang: set('lang'),
};
