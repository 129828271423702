/* eslint no-param-reassign:0 */
export default {
  methods: {
    /**
     * This hook step in when we sync all ids from import/generate endpoint
     * @param  {[type]} items [description]
     * @return {[type]}       [description]
     */
    hookSyncIds(items) {
      items.map((product) => {
        product.infos.att_theagent_r = product.id;
        // eslint-disable-next-line array-callback-return
        return product.variant.map(variant => variant.attributes.map((attribute) => {
          if (typeof attribute.dataJson.att_theagent_rc !== 'undefined' && variant.id) {
            attribute.dataJson.att_theagent_rc = variant.id;
          }
        }));
      });
    },
  },
};
