<template>
  <v-row>
    <v-col cols="12">
      <v-container fluid>
        <v-row>
          <v-col
            v-for="(item, index) in items"
            v-bind:key="`${index}-${item.id}`"
            class="d-flex child-flex"
            cols="2"
          >
            <!-- arbitrary property value to avoid error-->
            <attribute-media v-if="item" :value="item" property="media" alias="w237">
              <template v-slot:placeholder v-if="!mini">
                <v-chip
                  v-if="item.editing"
                  class="pa-2 mt-auto"
                  color="blue"
                  label
                  text-color="white"
                >
                  <v-icon left>mdi-camera-image</v-icon>
                  edited
                </v-chip>
                <v-chip
                  v-if="item.alias"
                  class="pa-2 mt-auto"
                  color="green"
                  label
                  text-color="white"
                >
                  <v-icon left>mdi-camera-burst</v-icon>
                  alias
                </v-chip>
              </template>
              <template v-slot:actions>
                <dialog-media-carousel :item="item"></dialog-media-carousel>

                <dialog-media :item="item" v-on:update:item="searchMedia"></dialog-media>
                <v-btn fab small dark class="mx-2" color="danger" @click="deleteItem(item)">
                  <v-icon dark>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
            </attribute-media>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="itemsOptions.page"
                class="my-4"
                :length="totalPage"
                :total-visible="10"
              ></v-pagination>
            </v-container>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="itemsOptions.itemsPerPage"
              class="mx-4"
              :items="itemsPage"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import ItemsBaseMixins from '../../mixins/items-base';
import EditMixins from '../../mixins/edit-base';
import Mixins from '../../mixins';

export default {
  mixins: [ItemsBaseMixins, EditMixins, ...Mixins],
  name: 'MediaListView',
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [],
    itemsCount: 0,
    itemsOptions: {
      itemsPerPage: 30,
      page: 1,
      sortBy: [],
      sortDesc: [],
    },
    loading: false,
    defaultItem: {},
    totalPage: 0,
    itemsPage: [{ text: 18 }, { text: 30 }, { text: 48 }, { text: 60 }, { text: 90 }],
  }),
  created() {
    this.searchMedia();
  },
  watch: {
    itemsOptions: {
      handler() {
        this.searchMedia();
      },
      deep: true,
    },
  },
  mounted() {
    // Listen for the delete:item event, from this component
    this.$on('delete:item', item => this.deleteMedia(item));
  },
  computed: {
    settings() {
      return this.$store.getters['settings/settings'];
    },
    headers() {
      return this.$store.getters['settings/headers'].product;
    },
  },
  methods: {
    ...mapActions('media', {
      apiList: 'list',
    }),
    searchMedia(searchFilters = {}) {
      this.loading = true;
      const { itemsPerPage, page } = this.itemsOptions;
      let filters = {};
      if (Object.keys(searchFilters).length > 0) {
        filters = JSON.stringify(searchFilters.filters);
      }
      const params = {
        params: {
          search: searchFilters.text,
          limit: parseInt(itemsPerPage, 10),
          page,
          filters,
        },
      };
      this.$router
        .push({
          path: '/dam/medias',
          query: {
            limit: params.params.limit,
            page: params.params.page,
            filters: params.params.filters,
          },
        })
        .catch(() => {}); // NavigationDuplicated error quiet

      return this.apiList(params)
        .then((data) => {
          const { items, itemsCount } = this.options(
            data.result,
            data.parameters.count,
            this.itemsOptions,
          );
          this.loading = false;
          return {
            items,
            itemsCount,
            itemsPerPage,
          };
        })
        .then(({ items, itemsCount }) => {
          this.items = items;
          this.itemsCount = itemsCount;
          this.itemsPerPage = itemsPerPage;
          this.totalPage = Math.round(itemsCount / itemsPerPage);
        })
        .catch(error => this.notificationError({ message: error.message }));
    },
    deleteMedia(item) {
      this.$log.debug('dam.medialistview.deleteimage.started', item);
      const endpoint = 'media/delete';
      this.$store
        .dispatch(endpoint, item.id)
        .then(() => this.searchMedia())
        .then(() => this.closeItem())
        .catch(error => this.notificationError({ message: error.message }));
    },
  },
};
</script>

<style type="text/css">
.v-card-reveal {
  display: block !important;
}

.v-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.2s ease;
}
.v-card-reveal:hover .v-card-overlay {
  display: block;
  background: rgba(0, 0, 0, 0.3);
}

.v-card-reveal:hover .button {
  opacity: 1;
}
.v-card-hide {
  display: none !important;
}
</style>
