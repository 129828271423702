<template>
  <v-menu
    v-model="dialog"
    :close-on-content-click="false"
    top
    left
    min-width="900"
    max-width="100vh"
    max-height="90vh"
    offset-x
    transition="slide-y-transition"
  >
    <!-- https://vuetifyjs.com/en/components/menus/#activator-and-tooltip -->
    <template v-slot:activator="{ on: menu }">
      <v-tooltip left>
        <template v-slot:activator="{ on: tooltip, attrs }">
          <v-btn
            color="gray"
            fab
            dark
            right
            fixed
            bottom
            class="mx-2"
            style="bottom: 20px;"
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
          >
            <v-icon dark>mdi-cogs</v-icon>
          </v-btn>
        </template>
        <span>Open dialog settings</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-container>
        <div class="text-sm-center text-uppercase">Settings</div>
        <v-divider class="mt-3" />
      </v-container>
      <v-container grid-list-xl>
        <v-layout wrap>
          <v-flex xs6>
            <v-switch v-model="settings.dense" class="mt-2" label="Dense table"></v-switch>
            <v-switch v-model="settings.expandAll" class="mt-2" label="Expand all rows"></v-switch>
            <v-switch
              v-model="settings.singleExpand"
              class="mt-2"
              label="Expand only one row"
            ></v-switch>
          </v-flex>
          <v-flex xs6>
            <v-switch
              v-model="settings.showExpand"
              class="mt-2"
              label="Show expand icon"
            ></v-switch>
            <v-switch
              v-model="settings.showSelect"
              class="mt-2"
              label="Show checkbox to select Row"
            ></v-switch>
            <v-switch
              v-if="hasRight('dashboardapp.v1:settings:header:read')"
              v-model="settings.showHeaders"
              class="mt-2"
              label="💪🏾 Show select headers"
            ></v-switch>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-container v-if="!settings.showHeaders">
            <div class="text-sm-center text-uppercase">Profiles</div>
            <v-divider class="mt-3" />
          </v-container>
          <v-flex xs12 v-if="!settings.showHeaders">
            <div class="text-sm-center">Profile list:</div>
            <v-row>
              <v-col cols="12">
                <v-combobox
                  class="attribute-profile"
                  v-model="settings.profile"
                  :items="profiles"
                  item-text="name"
                  item-value="key"
                  label="Choose your profile"
                  chips
                >
                </v-combobox>
              </v-col>
            </v-row>
            <v-divider class="mt-3" />
          </v-flex>
          <v-container v-if="settings.showHeaders">
            <div class="text-sm-center text-uppercase">Headers table</div>
            <v-divider class="mt-3" />
          </v-container>
          <v-flex xs12 v-if="settings.showHeaders">
            <div class="text-sm-center">CSV (step 1- 4)</div>
            <v-row>
              <v-col cols="12">
                <v-combobox
                  v-model="settings.headers.csv"
                  :items="headers.csv"
                  item-text="text"
                  item-value="value"
                  label="Select your columns for CSV "
                  multiple
                  chips
                >
                </v-combobox>
              </v-col>
            </v-row>
            <v-divider class="mt-3" />
          </v-flex>
          <v-flex xs12 v-if="settings.showHeaders">
            <div class="text-sm-center">Product (steps 5 - 8)</div>
            <v-row>
              <v-col cols="12">
                <v-combobox
                  v-model="settings.headers.product"
                  :items="headers.product"
                  item-text="text"
                  item-value="value"
                  label="Select your columns for Product "
                  multiple
                  chips
                >
                </v-combobox>
              </v-col>
            </v-row>
            <v-divider class="mt-3" />
          </v-flex>
          <v-flex xs12 v-if="settings.showHeaders">
            <div class="text-sm-center">Variant (steps 5 - 8)</div>
            <v-row>
              <v-col cols="12">
                <v-combobox
                  v-model="settings.headers.variant"
                  :items="headers.variant"
                  item-text="text"
                  item-value="value"
                  label="Select your columns for Variant"
                  multiple
                  chips
                >
                </v-combobox>
              </v-col>
            </v-row>
            <v-divider class="mt-3" />
          </v-flex>
        </v-layout>
        <v-flex xs12 v-if="settings.showHeaders">
          <div class="text-sm-center">Skus (steps 5 - 9)</div>
          <v-row>
            <v-col cols="12">
              <v-combobox
                v-model="settings.headers.sku"
                :items="headers.sku"
                item-text="text"
                item-value="value"
                label="Select your columns for Sku "
                multiple
                chips
              >
              </v-combobox>
            </v-col>
          </v-row>
          <v-divider class="mt-3" />
        </v-flex>
        <v-flex xs12 v-if="settings.showHeaders && options.showHiddenPages">
          <div class="text-sm-center">Catalog</div>
          <v-row>
            <v-col cols="12">
              <v-combobox
                v-model="settings.headers.catalog"
                :items="headers.catalog"
                item-text="text"
                item-value="value"
                label="Select your columns for Catalog "
                multiple
                chips
              >
              </v-combobox>
            </v-col>
          </v-row>
          <v-divider class="mt-3" />
        </v-flex>
        <v-flex xs12 v-if="settings.showHeaders && options.showHiddenPages">
          <div class="text-sm-center">Translate</div>
          <v-row>
            <v-col cols="12">
              <v-combobox
                v-model="settings.headers.translate"
                :items="headers.translate"
                item-text="text"
                item-value="value"
                label="Select your columns for Translations "
                multiple
                chips
              >
              </v-combobox>
            </v-col>
          </v-row>
          <v-divider class="mt-3" />
        </v-flex>
      </v-container>
      <div class="text-center">
        <v-btn color="green darken-1" dark @click="restore">Restore default</v-btn>
        <v-btn color="grey darken-1" outlined text @click="close">Cancel</v-btn>
        <v-btn color="blue darken-1" dark @click="save">Save</v-btn>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import ChipBaseMixins from '@/mixins/chip-base';
import ProfileBaseMixins from '@/mixins/profile-base';

export default {
  mixins: [ProfileBaseMixins, ChipBaseMixins],
  data: () => ({
    items: ['Vuetify', 'Programming', 'Design', 'Vue'],
    selectProduct: [],
    dialog: false,
    expandAll: false,
    singleExpand: false,
  }),
  computed: {
    settings() {
      return {
        ...this.$store.getters['settings/settings'],
        headers: { ...this.$store.getters['settings/settings'].headers },
      };
    },
    profiles() {
      const profiles = this.$store.getters['api/attribute/profiles'];
      return this.$store.getters['settings/profiles'].reduce((result, profile) => {
        if (
          profile.key !== profiles.ADMIN.key
          || (profile.key === profiles.ADMIN.key && this.isSuperAdmin)
        ) {
          result.push(profile);
        }
        return result;
      }, []);
    },
    options() {
      return this.$store.getters['settings/theagent'];
    },
    headers() {
      return this.$store.getters['settings/headers'];
    },
  },
  methods: {
    async save() {
      this.$log.debug('component.dialog.settings.save.started');
      const profiles = this.$store.getters['settings/profiles'];
      const settings = { ...this.settings };
      if (
        typeof this.settings.profile !== 'undefined'
        && profiles.map(p => p.key).includes(settings.profile.key)
      ) {
        this.$log.debug(`component.dialog.settings.save.profilename.${settings.profile.name}`);
        await this.$store.commit('settings/set_profile', settings.profile);
      }
      return this.$store
        .dispatch('settings/save', settings)
        .catch(error => this.throwError(error))
        .finally(() => {
          this.close();
        });
    },
    restore() {
      this.$log.debug('component.dialog.settings.restore.started');
      return this.$store
        .dispatch('settings/clear')
        .catch(error => this.throwError(error))
        .finally(() => {
          this.close();
        });
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style></style>
