<template>
  <v-container fill-height fluid>
    <v-layout wrap>
      <material-card
        color="green"
        title="Load your csv or xls file to launch automatical downloading"
        class="col-lg-12"
      >
        <attribute-brand-native v-model="brandId"></attribute-brand-native>
        <common-sheets-upload
          v-if="brandId"
            label="Upload your size files (csv, xls, xslx)"
            :post-action="`${baseURL}/api/v1/export/media/download/${brandId}`"
            v-on:update:upload="downloadZip"
            v-on:upload="loader"
          >
        </common-sheets-upload>
        <v-overlay :value="loading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </material-card>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue';

const baseURL = process.env.VUE_APP_PIM_BASEURL || 'http://localhost:3128/';

export default {
  name: 'CheckSizeView',
  data: () => ({
    brandId: null,
    baseURL,
    loading: false,
  }),
  methods: {
    downloadZip(files) {
      if (Array.isArray(files) && files.length > 0) {
        files.forEach((filepath, index) => {
          setTimeout(() => {
            Vue.downloadUrl(`${this.baseURL}/${filepath}`);
          }, 500 * index);
        });
      } else {
        this.$log.error('components.dam.exportview.exportzipmedias.started');
      }
    },
    loader(value) {
      this.loading = value;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
