import { quable, marketplace, collection } from 'theagent-status';
import { STEPPER_START, STEPPER_CONVERT_PIM, STEPPER_VALID_IMPORT } from '../helpers/steps';

const pimStatus = {};
pimStatus[quable.NO_STATUS] = { color: 'gray', text: 'no status', i18n: 'status.no-status' };
pimStatus[quable.DONE] = { color: 'green', text: 'sync with pim', i18n: 'status.done' };
pimStatus[quable.IN_WORKING] = { color: 'blue', text: 'wip', i18n: 'status.in_working' };
pimStatus[quable.STANDBY] = { color: 'orange', text: 'standby', i18n: 'status.standby' };
pimStatus[quable.ERROR] = { color: 'red', text: 'error', i18n: 'status.error' };

const marketplaceStatus = {};
marketplaceStatus[marketplace.NO_STATUS] = {
  color: 'gray',
  text: 'no status',
  i18n: 'status.no-status',
};
marketplaceStatus[marketplace.DONE] = { color: 'green', text: 'sync with mp', i18n: 'status.done' };
marketplaceStatus[marketplace.IN_WORKING] = {
  color: 'blue',
  text: 'wip',
  i18n: 'status.in_working',
};
marketplaceStatus[marketplace.STANDBY] = {
  color: 'orange',
  text: 'standby',
  i18n: 'status.standby',
};
marketplaceStatus[marketplace.ERROR] = { color: 'red', text: 'error', i18n: 'status.error' };

const importStatus = {};
importStatus[collection.NO_STATUS] = { color: 'gray', text: 'no status', i18n: 'status.no-status' };
importStatus[collection.DONE] = { color: 'green', text: 'ouvert', i18n: 'status.done' };
importStatus[collection.CLOSED] = { color: 'orange', text: 'closed', i18n: 'status.closed' };
importStatus[collection.ERROR] = { color: 'red', text: 'error', i18n: 'status.error' };

export default {
  methods: {
    getColor(status, type = 'pim') {
      const statusList = eval(`${type}Status`); // eslint-disable-line no-eval
      return typeof statusList[status] !== 'undefined' ? statusList[status].color : 'yellow';
    },
    getMessage(status, type = 'pim') {
      const statusList = eval(`${type}Status`); // eslint-disable-line no-eval
      if (typeof statusList[status] !== 'undefined' && this.$te(statusList[status].i18n)) {
        return `${type}:${this.$t(statusList[status].i18n).toLowerCase()}`;
      }
      return `${type}:no message`;
    },
    getColorError(number) {
      if (number > 0 && number < 6) return 'blue';
      if (number >= 6) return 'red';
      return 'green';
    },
    getStepColor(number = 0) {
      if (number > STEPPER_START && number < STEPPER_CONVERT_PIM) return 'orange';
      if (number >= STEPPER_CONVERT_PIM && number < STEPPER_VALID_IMPORT) return 'blue';
      if (parseInt(number, 10) === STEPPER_VALID_IMPORT) return 'green';
      return 'red';
    },
    getEnableDisable(value) {
      if (value === 1) return 'green';
      return 'red';
    },
    getEnableDisableMessage(value) {
      if (value === 1) return 'enabled';
      return 'disabled';
    },
  },
};
