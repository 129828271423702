<template>
  <v-dialog v-model="dialog" persistent max-width="240px">
    <template v-slot:activator="{ on }">
      <v-btn
        color="green"
        fab
        dark
        absolute
        right
        fixed
        class="mx-2"
        style="bottom: 280px !important;"
        v-on="on"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-form v-model="isValid">
        <v-card-title class="headline">Sizes:</v-card-title>
        <v-card-text>
          <v-row>
            <flag iso="fr" />
            <v-spacer></v-spacer>
            <v-text-field v-model="attribute.fra" :rules="textRules" label="French" />
          </v-row>
          <v-row>
            <flag iso="gb" />
            <v-spacer></v-spacer>
            <v-text-field v-model="attribute.gbr" :rules="textRules" label="English" />
          </v-row>
          <v-row>
            <flag iso="de" />
            <v-spacer></v-spacer>
            <v-text-field v-model="attribute.deu" :rules="textRules" label="Deutch" />
          </v-row>
          <v-row>
            <flag iso="it" />
            <v-spacer></v-spacer>
            <v-text-field v-model="attribute.ita" :rules="textRules" label="Italian" />
          </v-row>
          <v-row>
            <flag iso="es" />
            <v-spacer></v-spacer>
            <v-text-field v-model="attribute.esp" :rules="textRules" label="Spanish" />
          </v-row>
          <v-select
            v-model="attribute.gender"
            :items="genders"
            menu-props="auto"
            hide-details
            item-text="name"
            item-value="id"
            label="Select gender"
          ></v-select>
          <v-select
            v-model="attribute.classificationId"
            :items="classifications"
            menu-props="auto"
            hide-details
            item-text="nameI18n"
            item-value="id"
            label="Select your classification"
          ></v-select>
          <v-select
            v-model="attribute.brandId"
            :items="brands"
            menu-props="auto"
            hide-details
            item-text="name"
            item-value="id"
            label="Select your brand"
          ></v-select>
        </v-card-text>
        <v-spacer />
        <v-card-actions>
          <v-btn color="grey darken-1" text outlined @click="close">Cancel</v-btn>
          <v-btn color="blue darken-1" dark :disabled="!isValid" @click="save">Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { Gender } from '../../models';

export default {
  name: 'attribute-base',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    attribute: {
      type: Object,
    },
  },
  data: () => ({
    isValid: true,
    saveOnApi: false,
  }),
  computed: {
    ...mapGetters('form/rules', { textRules: 'text' }),
    genders() {
      const genderIds = Gender.fetchListById();
      return Object.keys(genderIds).reduce((t, id) => {
        t.push({
          id: genderIds[id].legacyId,
          name: genderIds[id].name,
        });
        return t;
      }, []);
    },
    classifications() {
      return this.$store.getters['classification/classifications'];
    },
    brands() {
      return this.$store.getters['brand/brands'];
    },
  },
  methods: {
    save() {
      return this.$emit('onSave');
    },
    close() {
      // https://vuejs.org/v2/guide/components-custom-events.html#sync-Modifier
      this.$emit('update:dialog', false);
      return this.$emit('onClose');
    },
  },
};
</script>
