<template>
  <div class="v-card--material-numbercard d-flex grow flex-wrap" v-bind="$attrs">
    <v-card slot="offset" :color="`${color} darken-4`" class="pa-4">
      <v-icon color="white">{{ icon }}</v-icon>
    </v-card>
    <div class="ml-3 text-right">
      <p class="category grey--text font-weight-light">
        {{ title }}
      </p>
      <h3 class="title font-weight-light">
        {{ number }}
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumberCard',
  props: {
    title: {
      type: String,
      required: true,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: 'mdi-package',
    },
    color: {
      type: String,
      required: false,
      default: 'gray',
    },
    number: {
      type: [String, Number],
      required: false,
      default: 0,
    },
  },
  data: () => ({}),
};
</script>

<style lang="scss">
.v-card--material-numbercard {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  .v-card {
    border-radius: 4px;
    flex: 0 1 auto;
  }
}
</style>
