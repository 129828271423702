import Vue from 'vue';

const isDebugMode = process.env.VUE_APP_LOG_MODEL === 'true' || process.env.VUE_APP_LOG_MODEL === '1';

class Log {
  constructor() {
    this.info = Vue.$log.info;
    this.log = isDebugMode ? Vue.$log.log : () => {};
    this.debug = isDebugMode ? Vue.$log.debug : () => {};
    this.error = isDebugMode ? Vue.$log.error : () => {};
    this.warn = isDebugMode ? Vue.$log.warn : () => {};
  }
}

export default new Log();
