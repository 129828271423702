import Vue from 'vue';

export default {
  created() {
    if (!this.endpointType) {
      this.notificationSystem({ message: 'variable endpointType is missing' });
    }
  },
  methods: {
    active(item) {
      Vue.$log.debug('mixin.attribute-type.enabled.started', item);
      this.loading = true;
      if (this.endpointType === 'attribute') {
        item.active = item.isReady;
      }
      this.$store
        .dispatch(`${this.endpointType}/active`, {
          remoteCode: item.remoteCode,
          active: item.active,
        })
        .catch((error) => {
          if (this.endpointType === 'attribute') {
            item.isReady = !item.isReady;
          } else {
            item.active = !item.active;
          }
          this.notificationError({ message: error.message });
        })
        .finally(() => this.loading = false);
    },
  },
};
