import Vue from 'vue';

/**
 * Determines the appropriate template type based on the properties of the provided value object. The function
 * dynamically selects a template string by evaluating various conditions such as the presence of language,
 * text content, length, selection options, custom template, and disabled state.
 *
 * @param {Object, Header, Attribute} value - The object containing properties that influence the template selection. Expected to have
 * properties like name, lang, text, long, select, customTemplate, and disabled.
 * @returns {string|null} The template type as a string based on the provided value's properties. Returns null if
 * the name property is not present in the value object.
 *
 * Inline explanations:
 * - The template is initially derived from the value's name, with certain patterns removed for standardization.
 * - The function then checks for the existence and truthiness of various properties (lang, text, etc.) to determine
 *   the specific template string.
 * - If `customTemplate` is provided and truthy, it overrides any other template logic, setting the template directly.
 * - The selection of templates involves checking combinations of properties (e.g., `isSelection` and `isLang`) to
 *   assign more specific template strings.
 * - Debug logging is performed if the debug flag is set, providing insight into the decision-making process.
 */
const getTemplate = (value, options = {}) => {
  const { debug } = options;
  if (!value || !value.name) {
    return null;
  }
  const isLang = typeof value.lang !== 'undefined' && value.lang;
  const isText = typeof value.text !== 'undefined' && value.text;
  const isLong = typeof value.long !== 'undefined' && value.long;
  const isSelection = typeof value.select !== 'undefined' && value.select;
  const customTemplate = typeof value.customTemplate !== 'undefined' && value.customTemplate;
  const isDisabled = typeof value.disabled !== 'undefined' && value.disabled;
  let template = value.name.replace(/(att_|_rc|_r)/gi, '');
  if (customTemplate) {
    template = customTemplate;
  } else if (isSelection && isLang) {
    template = 'select_lang';
  } else if (isSelection) {
    template = 'select';
  } else if (isLang && isLong) {
    template = 'textarea';
  } else if (isLong) {
    template = 'textarea_nolang';
  } else if (isText) {
    template = 'string_nolang';
  } else if (isLang) {
    template = 'string';
  } else if (isDisabled) {
    template = 'disabled';
  }
  if (debug) {
    Vue.$log.debug(`components.core.attribute.template.${value.name}.started`, {
      template,
      value,
    });
  }
  return template;
};


export default {
  getTemplate,
};
