<template>
  <div>
    <div>{{ titleName }}</div>
    <slot></slot>
    <core-error v-if="item.errors" :property="property" :errors="item.errors[property]" />
  </div>
</template>

<script>
import DialogBaseMixins from '../../mixins/dialog-base';

export default {
  mixins: [DialogBaseMixins],
  computed: {
    itemsKey: () => undefined,
  },
};
</script>

<style></style>
