// https://vuex.vuejs.org/en/mutations.html
import stateDefault from './state';

const getClean = (state) => {
  state.isLoaded = stateDefault.isLoaded;
  state.attributes = stateDefault.attributes;
  state.attribute = stateDefault.attribute;
  state.state = stateDefault.state;
};

export default {
  set_attributes(state, payload) {
    state.attributes = payload;
  },
  set_attribute(state, payload) {
    if (!Array.isArray(state.attributes)) {
      getClean(state);
    }
    const index = state.attributes.findIndex(a => a.id === payload.id);
    if (typeof state.attributes[index] !== 'undefined') {
      state.attributes[index] = payload;
    } else {
      state.attributes.push(payload);
    }
    state.attribute = payload;
  },
  set_state(state, payload) {
    state.state = payload;
  },
  clear(state) {
    getClean(state);
  },
};
