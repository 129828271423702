// @see theagent-node-pim app/config/dashboard.js

const optionsDefault = {
  /**
   * This options allow to manage log level in browser console
   * @type {String}
   * @see ta-log-level header
   */
  logLevel: 'info',
  /**
   * This options display some hidden pages
   * @type {Boolean}
   * @see ta-show-hidden-pages header
   */
  showHiddenPages: false,
  /**
   * Authorize admin profile
   * @type {Boolean}
   * @see ta-profile-admin header
   * this action should be deprecated
   *   @see LoginHome to manager admin client
   */
  profileAdmin: false,
  /**
   * Enabled some debug log all over the code
   * @type {Boolean}
   * @see ta-silly-mode header
   */
  sillyMode: !(process.env.VUE_APP_LOGGER_SILLY === 'false') || false,
  /**
   * Force the way to make actions
   * @type {Boolean}
   * @see ta-force-way header
   */
  canForceWay: false,
};

export default {
  set: function set(settings) {
    Object.keys(settings).forEach((key) => {
      if (settings[key] === '0' || settings[key] === '1') {
        // eslint-disable-next-line no-param-reassign
        settings[key] = settings[key] === '1';
      }
      if (settings[key] === 'false' || settings[key] === 'true') {
        // eslint-disable-next-line no-param-reassign
        settings[key] = settings[key] === 'true';
      }
    });
    localStorage.setItem(
      'theagent',
      JSON.stringify({
        ...optionsDefault,
        ...settings,
      }),
    );
  },

  get: function get() {
    try {
      return JSON.parse(localStorage.getItem('theagent'));
    } catch (err) {} // eslint-disable-line no-empty
    return optionsDefault;
  },

  clear: function clear() {
    localStorage.removeItem('theagent');
  },
};
