export default {
  loaded: false,
  marketplaces: [],
  countries: [],
  countries_by_key: {},
  fields: {},
  profiles: {},
  type_entities: {},
  type_hinting: [],
  config: null,
};
