<template>
  <v-container fill-height fluid>
    <v-layout wrap>
      <material-card
        color="pink"
        title="Import des medias"
        text="Select your brand"
        class="col-lg-12"
      >
        <attribute-brand-native v-model="brandId"></attribute-brand-native>
        <common-zip-media-upload v-if="brandId" displayOverride :brandId="brandId">
        </common-zip-media-upload>
        <v-subheader>Note : L'ordre des images se font de 0 à XX.</v-subheader>
      </material-card>
      <div v-for="(variantMedia, variantId) in medias" :key="variantId">
        <v-row>
          <v-col cols="3">
            <v-card class="mx-auto my-12" max-width="374">
              <v-card-title>{{ variantMedia.medias[0].reference }}</v-card-title>
              <v-card-text v-if="variantMedia.medias[0].color">
                <v-chip-group active-class="deep-purple accent-4 white--text" column>
                  <v-chip :style="`background-color:${convertBackgroundColor(variantMedia.medias[0].color)}`"
                    >color: {{ variantMedia.medias[0].color }}</v-chip
                  >
                  <v-chip v-if="variantMedia.medias[0].variantId"
                    >variantId: {{ variantMedia.medias[0].variantId }}</v-chip
                  >
                </v-chip-group>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  text
                  color="blue"
                  v-if="variantMedia.medias[0].productId"
                  @click="gotoProduct(variantMedia.medias[0].productId)"
                >
                  Full edition >>
                </v-btn>
                <v-chip
                  text
                  color="green"
                  v-else-if="getVariantId(variantMedia.medias[0])"
                >
                  Synced with Pim RC found
                </v-chip>
                <v-chip
                  text
                  color="red"
                  v-else
                >
                  No Synced
                </v-chip>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="9">
            <common-media-list-view
              :variantMedia="variantMedia"
              :variantId="Number(variantId)"
              :hasMedia="Object.keys(variantMedia.medias).length > 0"
              noHover
            >
            </common-media-list-view>
          </v-col>
        </v-row>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
/* eslint no-param-reassign:0 */
import Mixins from '../../mixins';

export default {
  name: 'MediaImport',
  mixins: Mixins,
  data: () => ({
    brandId: null,
    hasMedia: true,
  }),
  computed: {
    mediasImported() {
      return this.$store.getters['import/mediasImported'];
    },
    medias() {
      const medias = this.$store.getters['import/mediasImported'];
      return medias.reduce((result, media) => {
        media.variantId = Number(media.variantId);
        if (typeof result[media.variantId] === 'undefined') {
          result[media.variantId] = { medias: {} };
          result[media.variantId].medias = [media];
        } else {
          result[media.variantId].medias.push(media);
        }
        return result;
      }, {});
    },
    // come from the previous data_structured
    importVariantsKey() {
      return this.$store.getters['import/variantsKey'];
    },
  },
  methods: {
    gotoProduct(productId) {
      const routeData = this.$router.resolve({ path: `/pim/product/${productId}/edit` });
      window.open(routeData.href, '_blank');
    },
    getVariantId(media) {
      if (Object.keys(this.importVariantsKey).length === 0) {
        return false;
      }
      const methodspace = 'common.zipupload.getvariantid';
      const { reference } = media;
      const { ean, supplierReference } = this.importVariantsKey;
      const isEan = String.isNumeric() && reference.length === 13;
      if (isEan && typeof ean[reference] !== 'undefined') {
        return ean[reference];
      }
      if (typeof supplierReference[reference] !== 'undefined') {
        return supplierReference[reference];
      }
      this.$log.debug(`${methodspace}.${media.reference}.not.found`);
      return false;
    },
  },
};
</script>

<style lang="css" scoped></style>
