<template>
  <v-autocomplete
    v-model="item[property]"
    :items="itemsValue"
    menu-props="auto"
    item-text="name"
    item-value="id"
    :label="label || 'Select your collection'"
    :messages="getDefaultValues()"
    :loading="isCompletudeRequired(bindAttribute, currentIssues)"
    hint="Select your collection"
    persistent-hint
    chips
    multiple
  ></v-autocomplete>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import AttributeBaseMixins from '../../mixins/attribute-base';
import CompletudeBaseMixins from '../../mixins/completude-base';

export default {
  mixins: [AttributeBaseMixins, CompletudeBaseMixins],
  name: 'attributeCanal',
  created() {
    this.apiCollection();
  },
  computed: {
    ...mapGetters('collection', ['collections']),
    itemsValue() {
      return this.collections;
    },
  },
  methods: {
    ...mapActions('collection', { apiCollection: 'list' }),
  },
};
</script>
