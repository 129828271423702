<template>
  <v-layout wrap>
    <v-flex md12>
      <v-toolbar flat>
        <v-toolbar-title>
          Export
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <dialog-search v-model="search" @onSave="saveAndSearch"> </dialog-search>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn color="green darken-1" @click="exportMedias">
          <v-icon>mdi-database-export</v-icon>
        </v-btn>
        <v-btn color="blue darken-1" @click="exportZipMedias">
          <v-icon>mdi-cloud-download</v-icon>
        </v-btn>
      </v-toolbar>
      <core-alert></core-alert>
    </v-flex>
    <dam-variant-view
      v-model="variantsSelected"
      :items="variants"
      v-if="variants"
      endpointType="variant"
      no-edition
    ></dam-variant-view>
    <dialog-settings :dialog="dialog"> </dialog-settings>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-layout>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import { marketplace } from 'theagent-status';
import { Product, ProductList } from '@/models';
import Mixins from '../../mixins';
import ItemsBaseMixins from '../../mixins/items-base';
import ProductBaseMixins from '../../mixins/product-base';
import EditMixins from '../../mixins/edit-base';
import ProfileMixins from '../../mixins/profile-base';

const baseURL = process.env.VUE_APP_PIM_BASEURL || 'http://localhost:3128/';

export default {
  mixins: [ItemsBaseMixins, EditMixins, ProfileMixins, ProductBaseMixins, ...Mixins],
  data: () => ({
    baseURL,
    variantsSelected: [],
    items: [],
    search: {}, // data come from dialog-search via a two way binding
    loading: false,
    mode: 0,
    doneStatus: marketplace.DONE,
  }),
  created() {
    this.editedItem = this.defaultItem.clone();
    this.searchMedias({ itemsPerPage: 10 });
  },
  mounted() {
    this.$log.log('components.dam.exportview.mounted');
    if (Object.keys(this.$route.query).length > 0) {
      const { search = '', filters = {} } = this.$route.query;
      this.search = {
        text: search,
        filters: JSON.parse(filters),
      };
    }
  },
  computed: {
    settings() {
      return this.$store.getters['settings/settings'];
    },
    headers() {
      return this.$store.getters['settings/headers'].csv;
    },
    variants() {
      if (this.items.length === 0) {
        return [];
      }
      return this.items.reduce((variants, product) => {
        variants.push(...product.variant);
        return variants;
      }, []);
    },
    defaultItem: function defaultItem() {
      const defaultValues = this.$store.getters['settings/headers'].product.reduce(
        (item, header) => {
          // eslint-disable-next-line no-param-reassign
          item[header.value] = '';
          return item;
        },
        {},
      );
      return Product.create(defaultValues);
    },
  },
  methods: {
    ...mapActions('export', {
      apiExportSearch: 'searchMedias',
      apiExportSearchShootlist: 'searchShootListMedias',
      apiExportSearchZip: 'searchZipMedias',
    }),
    searchMedias({ itemsPerPage = undefined }) {
      this.$log.log('components.dam.exportview.searchmedias.started');
      this.$log.debug('components.dam.exportview.searchmedias.filters', this.search);
      this.loading = true;
      let filters = {};
      if (Object.keys(this.search).length > 0) {
        // eslint-disable-next-line prefer-destructuring
        filters = this.search.filters;
      }
      const params = {
        params: {
          search: this.search.text ? this.search.text.trim() : '',
          limit: itemsPerPage || 100,
          filters,
        },
      };
      // this is security to avoid to load a lot of data
      if (!params.params.search && Object.keys(params.params.filters).length === 0) {
        params.params.limit = 10;
      }
      this.$router
        .push({
          name: 'dam.export',
          query: {
            search: this.search.text,
            filters: JSON.stringify(filters),
          },
        })
        .catch(() => {}); // NavigationDuplicated error quiet

      return this.apiExportSearch(params)
        .then((data) => {
          this.items = ProductList.create(data.result);

          this.$log.debug('components.dam.exportview.searchmedias.result');
        })
        .catch(error => this.throwError(error))
        .finally(() => {
          this.loading = false;
        });
    },
    saveAndSearch(filters) {
      this.$log.info('components.dam.exportview.saveandsearch.started');
      return this.searchMedias(filters);
    },
    exportMedias() {
      this.$log.log('components.dam.exportview.exportmedias.started');
      this.loading = true;
      let filters = {};
      if (Object.keys(this.search).length > 0) {
        // eslint-disable-next-line prefer-destructuring
        filters = this.search.filters;
      }
      const params = {
        search: this.search.text ? this.search.text.trim() : '',
        filters,
      };
      if (this.variantsSelected.length > 0) {
        params.variantId = this.variantsSelected.map(v => v.id);
      }
      return this.apiExportSearchShootlist(params)
        .then(([filepath]) => {
          if (filepath) {
            Vue.downloadUrl(`${this.baseURL}/${filepath}`);
          } else {
            this.$log.error('components.dam.importvalidationview.exportmedias.filepath.not.okay');
          }
        })
        .catch(error => this.throwError(error))
        .finally(() => {
          this.loading = false;
        });
    },
    exportZipMedias() {
      this.$log.log('components.dam.exportview.exportzipmedias.started');
      this.loading = true;
      let filters = {};
      if (Object.keys(this.search).length > 0) {
        // eslint-disable-next-line prefer-destructuring
        filters = this.search.filters;
      }
      const params = {
        search: this.search.text ? this.search.text.trim() : '',
        filters,
      };
      if (this.variantsSelected.length > 0) {
        params.variantId = this.variantsSelected.map(v => v.id);
      }
      return this.apiExportSearchZip(params)
        .then((files) => {
          if (Array.isArray(files) && files.length > 0) {
            files.forEach((filepath, index) => {
              setTimeout(() => {
                Vue.downloadUrl(`${this.baseURL}/${filepath}`);
              }, 500 * index);
            });
          } else {
            this.$log.error('components.dam.exportview.exportzipmedias.started');
          }
        })
        .catch(error => this.throwError(error))
        .finally(() => {
          this.notificationSuccess({ message: 'Export zip media' });
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
