<template>
  <v-chip class="ma-2" close @click:close="removeValue" :color="color">
    {{ format(value) }}
  </v-chip>
</template>

<script>
import ChipsBaseMixins from '../../mixins/chip-base';

export default {
  name: 'ValuesObjectChips',
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    name: {
      type: String,
      required: true,
      default: 'example',
    },
    value: {
      type: [Array, String],
      required: true,
    },
    color: {
      type: String,
      default: 'primary',
    },
    keys: {
      type: [Array, Boolean],
      default: () => ['id', 'name'],
    },
  },
  mixins: [ChipsBaseMixins],
  data: () => ({}),
  methods: {
    getIndexValue(value) {
      const isString = typeof this.keys === 'boolean';
      return !isString ? value[this.keys[0]] : value;
    },
    getReturnValue(value) {
      try {
        const isString = typeof this.keys === 'boolean';
        return !isString ? value[this.keys[1]] : value;
      } catch (err) {
        this.$log.error('components.chips.values-object.getreturnvalue.failed');
      }
      return typeof value === 'object' ? '' : value;
    },
    format() {
      this.$log.debug('components.chips.values-object.format.started');
      let data = this.value;
      let plural = '';
      if (typeof this.value === 'string' || typeof this.value === 'number') {
        data = [this.value];
      }
      const list = data.map((row) => {
        const index = this.items.findIndex(item => this.getIndexValue(item) === row);
        return this.getReturnValue(this.items[index]);
      });

      if (list.length > 1) {
        plural = 's';
      }
      return `${this.name}${plural}:${list.join(',')}`;
    },
    removeValue() {
      this.$log.debug('components.chips.values-object.removevalue.started');
      this.$emit('input', null);
      this.$emit('onClose');
    },
  },
};
</script>

<style lang="css" scoped></style>
