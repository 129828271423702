<template>
  <v-container fluid>
    <material-card color="blue" title="Fichier d'import" offset="10">
      <v-form v-model="isValid" ref="form">
        <v-card-text>
          <v-text-field
            ref="name"
            v-model="editedItem.name"
            :rules="titleRules"
            counter
            maxlength="255"
            label="Collection name (required)"
          />
          <attribute-brand
            ref="brand"
            :item="additionalValues"
            property="brandId"
            label="Pick your brand (required)"
            chips
          ></attribute-brand>
          <attribute-fournisseurs
            ref="fournisseurs"
            :item="additionalValues"
            property="distributorId"
            label="Pick your supplier (required)"
          />

          <div class="text-center">
            <v-chip
              class="ma-2"
              v-if="editedItem.status"
              :color="getColor(editedItem.status, 'import')"
              dark
            >
              <v-icon left>mdi-factory</v-icon>
              {{ getMessage(editedItem.status, "import") }}
            </v-chip>
            <v-chip
              :color="getStepColor(editedItem.metaData.step)"
              v-if="editedItem.metaData.step"
              dark
            >
              <v-icon left>mdi-stairs-up</v-icon>
              Step:{{ editedItem.metaData.step || 0 }}
            </v-chip>
          </div>
        </v-card-text>
        <v-spacer />
        <v-card-actions>
          <v-btn color="blue darken-1" dark :disabled="!isValid || isClicked" @click="save">
            {{ $t("component.button.save") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text outlined @click="reset">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            outlined
            :disabled="!isImported || (!isValid || isClicked)"
            @click="gotoImport"
            >Go to import edition</v-btn
          >
        </v-card-actions>
      </v-form>
    </material-card>
    <pim-list-files v-if="importedfiles.length > 0"
      v-model="importedfiles"
      showFiles>
    </pim-list-files>
  </v-container>
</template>

<script>
/* eslint no-param-reassign: 0 */
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import Mixins from '../../mixins';

export default {
  name: 'ImportEdit',
  mixins: Mixins,
  data: () => ({
    inputMode: false,
    // src/mixins/dialog-edit-base.js
    isClicked: false,
    isValid: true,
    defaultItem: {
      brandId: null,
      distributorId: null,
    },
  }),
  created() {
    this.$store.commit('import/set_files', []);
    this.apiBrand();
  },
  computed: {
    ...mapGetters('import', { status: 'statusKey' }),
    ...mapGetters('app', ['user']),
    ...mapGetters('form/rules', { titleRules: 'title' }),
    editedItem: function editedItem() {
      this.defaultValues();
      return this.$store.getters['import/editedItem'];
    },
    additionalValues: {
      get: function get() {
        if (typeof this.editedItem.metaData === 'undefined') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.editedItem.metaData = {};
        }
        if (typeof this.editedItem.metaData.item === 'undefined') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.editedItem.metaData.item = this.defaultItem;
        }
        return this.editedItem.metaData.item;
      },
      set: function set(values) {
        this.editedItem.metaData.item = values;
      },
    },
    isImported() {
      return typeof this.editedItem.id !== 'undefined' && this.editedItem.id > 0;
    },
    brands() {
      return this.$store.getters['api/list']('brand');
    },
    importedfiles() {
      return this.$store.getters['import/files'];
    },
  },
  watch: {
    'additionalValues.brandId': {
      handler(brandId, oldBrandId) {
        // apply only if brandId have changed
        if (this.hasBrandIdChanged(brandId, oldBrandId)) {
          const index = this.brands.findIndex(b => b.id === brandId);
          if (index !== -1) {
            Vue.set(
              this.editedItem.metaData.item,
              'distributorId',
              this.brands[index].distributor.map(d => d.id),
            );
          }
        }
      },
    },
  },
  methods: {
    ...mapActions('api', {
      apiBrand: 'brand',
    }),
    hasBrandIdChanged(brandId, oldBrandId) {
      if (!brandId) {
        return false;
      }
      if (brandId === oldBrandId) {
        return false;
      }
      if (
        typeof this.editedItem.metaData.item.brandId !== 'undefined'
        && this.editedItem.metaData.item.brandId === oldBrandId
      ) {
        return false;
      }
      return true;
    },
    save() {
      Vue.$log.debug('src.components.common.importedit.save.started');
      const endpoint = 'import/update';
      this.editedItem.status = this.status.WAITING;
      // click once
      if (!this.isClicked && this.isImported) {
        this.isClicked = true;

        this.$store
          .dispatch(endpoint, this.editedItem)
          .then(response => this.$store
            .dispatch('import/reset')
            .then(() => this.$store.dispatch('import/list'))
            .then(() => {
              this.editedIndex = response;
              this.defaultValues();
            }))
          .catch(error => this.throwError(error));
      } else if (!this.isImported) {
        this.notificationWarning({ message: 'Make sure to import a catalog file before' });
      }
    },
    reset() {
      this.additionalValues = {};
      this.defaultValues();
      this.$store.commit('import/set_edit_reset');
    },
    defaultValues() {
      this.isValid = true;
      this.isClicked = false;
    },
    gotoImport() {
      this.$router.push({ path: `/pim/import/${this.editedItem.id}/csv` });
    },
  },
};
</script>

<style></style>
