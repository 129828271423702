<template>
  <v-text-field
    v-model="valueAttribute"
    :rules="barcodeRules"
    :counter="13"
    class="vuetify-editor"
    auto-grow
    filled
    :messages="getDefaultValues()"
    :loading="isCompletudeRequired(bindAttribute, currentIssues)"
    hint="Enter your barcode"
    persistent-hint
    :label="label || 'Bar code (EAN 13)'"
  >
  </v-text-field>
</template>

<script>
import { mapGetters } from 'vuex';
import AttributeBaseMixins from '../../mixins/attribute-base';
import CompletudeBaseMixins from '../../mixins/completude-base';

export default {
  name: 'Barcode',
  mixins: [AttributeBaseMixins, CompletudeBaseMixins],
  computed: {
    ...mapGetters('form/rules', {
      barcodeRules: 'barcode',
    }),
  },
  data: () => ({}),
};
</script>
