import { hasRight } from '@/services/scope';
import Profile from '@/helpers/profile';

export default {
  computed: {
    hasRightActions() {
      return this.$store.getters['settings/profile'].key !== Profile.DEFAULT;
    },
    isSuperAdmin() {
      const right = hasRight('dashboardapp.v1:settings:profileadmin:read');
      if (right) return right;
      // this action should be deprecated
      const { profileAdmin } = this.$store.getters['settings/theagent'];
      return profileAdmin;
    },
  },
  methods: {
    hasRight,
  },
};
