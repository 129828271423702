import CoreList from './corelist';
import Media from './media';

export default class MediaList extends CoreList {
  applyModel(parent) {
    if (this.length > 0) {
      this.forEach((row, index) => {
        if (typeof row === 'object' && row && Object.keys(row).length > 0) {
          this.add(Media.create(row, parent), index);
        }
      });
    }
    return this;
  }
}
