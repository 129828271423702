import Log from '@/models/utils/log';
import AttributeAbstract from '@/models/attribute/abstract';

const namespace = 'models.attribute.frommarketplace';

export default class AttributeFromMarketplace extends AttributeAbstract {
  objectInitializer(data) {
    super.objectInitializer(data);
    this.remoteCode = data.remoteCode.replace('pim.afm.', '');
  }

  getCode() {
    try {
      return this.options.remoteCode || this.options.code;
    } catch (e) {
      Log.error(`${namespace}.getCode.${this.name}.failed`);
      return null;
    }
  };
}
