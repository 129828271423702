<template>
  <v-dialog v-model="dialog" max-width="600px" @keydown.esc="dialog = false">
    <!-- https://vuetifyjs.com/en/components/menus/#activator-and-tooltip -->
    <template v-slot:activator="{ on: openDialog }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip, attrs }">
          <v-btn
            fab
            small
            dark
            class="mx-2"
            color="secondary"
            v-bind="attrs"
            v-on="{ ...tooltip, ...openDialog }"
          >
            <v-icon dark>
              mdi-magnify
            </v-icon>
          </v-btn>
        </template>
        <span>Open carousel</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline"></span>
      </v-card-title>
      <v-card-text>
        <v-carousel
          v-if="media.hasAlias()"
          hide-delimiter-background
          show-arrows-on-hover
        >
          <v-carousel-item
            v-for="(item, i) in aliases"
            :key="i"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
            <v-row>
              <v-col cols="12">
                <v-row align="center" justify="center">
                  {{ item.title }}
                </v-row>
                <v-row align="center" justify="center">
                  <img :src="item.src" />
                </v-row>
              </v-col>
            </v-row>
          </v-carousel-item>
        </v-carousel>
        <v-row v-else>
          <v-col cols="12">
            <v-row align="center" justify="center">
              <img width="600px" :src="item.original" />
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { Media } from '../../models';

export default {
  name: 'DialogMedia',
  props: {
    item: {
      type: [Object, Media],
      required: true,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    media() {
      if (this.item instanceof Media) {
        return this.item;
      }
      return Media.create(this.item);
    },
    aliases() {
      if (typeof this.item.data === 'undefined' || this.item.data.length === 0) {
        return [];
      }
      return Object.keys(this.item.data).reduce((list, alias) => {
        list.push({
          src: this.item.url(alias),
          title: alias,
        });
        return list;
      }, []);
    },
  },
};
</script>
