/* eslint no-param-reassign:0 */
// https://vuex.vuejs.org/en/actions.html
import Vue from 'vue';
import axios from 'axios';

const BASE_URI = '/api/v1/pim/chart';

const ceil10 = number => 10 * Math.floor(Number(number) / 10);

const getOptions = (getters, type, { high }) => {
  const options = getters.options(type);
  // https://github.com/gionkunz/chartist-js/blame/6891c5644d4b4aa8818dca88897829734e306702/src/scripts/charts/bar.js
  // some issue on highLow data for Bar Type when low and high equals 0
  // unexpected Nan occured on y2 and y1
  // @todo make a PR
  Vue.set(options, 'high', ceil10(high) || getters.options('default').high);
  return {
    ...getters.options('default'),
    ...options,
  };
};

const action = (getters, payload) => {
  Vue.$log.info('store.chart.actions.action.started');
  if (!getters.hasLabel(payload.type)) {
    throw new TypeError(`store.modules.chart.actions.action.payload.type.${payload.type}.invalid`);
  }
  if (
    typeof payload.values === 'undefined'
    || payload.values.length === 0
    || payload.values[0].length === 0
  ) {
    throw new TypeError('store.modules.chart.actions.action.payload.values.invalid');
  }
  return {
    data: {
      labels: getters.labels(payload.type),
      series: payload.values,
    },
    options: getOptions(getters, payload.type, {
      high: Math.max(...payload.values[0]),
    }),
  };
};

const getValues = (type, values, getters) => {
  if (type === 'hours1to24' || type === 'month') {
    return [
      Object.keys(getters.labels(type)).reduce((r, i) => {
        const index = values.findIndex(d => d.groupLabel === Number(i) + 1);
        r.push(index === -1 ? 0 : values[index].groupValue);
        return r;
      }, []),
    ];
  }
  if (type === 'week') {
    return [
      Object.keys(getters.labels(type)).reduce((r, i) => {
        const index = values.findIndex(d => d.groupLabel === Number(i));
        r.push(index === -1 ? 0 : values[index].groupValue);
        return r;
      }, []),
    ];
  }
  if (type === 'hours') {
    return [
      Object.keys(getters.labels(type)).reduce((r, i) => {
        r[i] = values.reduce((s, d) => {
          if (Number(i) * 3 < d.groupLabel && d.groupLabel <= (Number(i) + 1) * 3) {
            s += d.groupValue;
          }
          return s;
        }, 0);
        return r;
      }, []),
    ];
  }
  return [];
};

const productTypes = ['product', 'variant', 'reference'];

export default {
  async numberBy({ getters }, { productType, chartType, period = null }) {
    Vue.$log.info('store.chart.actions.numberby.started');
    if (productTypes.indexOf(productType) === -1) {
      throw new TypeError(`store.chart.actions.numberby.${productType}.unknown`);
    }
    if (!getters.hasLabel(chartType)) {
      throw new TypeError(`store.chart.actions.numberby.${productType}.type.${chartType}.unknown`);
    }
    if (!period) {
      period = chartType;
    }
    return new Promise((resolve) => {
      axios.get(`${BASE_URI}/numberby${productType}/${period}`).then(response => resolve(
        action(getters, {
          type: chartType,
          values: getValues(chartType, response.data.result, getters),
        }),
      ));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  async count({}, { productType, chartType, period = null }) {
    Vue.$log.info('store.chart.actions.count.started');
    if (productTypes.indexOf(productType) === -1) {
      throw new TypeError(`store.chart.actions.count.${productType}.unknown`);
    }
    if (!period) {
      period = chartType;
    }
    return new Promise((resolve) => {
      axios
        .get(`${BASE_URI}/count${productType}/${period}`)
        .then(response => resolve(response.data.result[0].totalValue));
    });
  },
};
