// https://vuex.vuejs.org/en/mutations.html

export default {
  set_current(state, variantId) {
    state.currentVariantId = variantId;
  },
  set_families(state, families) {
    state.families = families;
  },
  set_marketplace(state, marketplaceName) {
    if (state.currentMarketplace === marketplaceName) {
      state.currentMarketplace = null;
    } else {
      state.currentMarketplace = marketplaceName;
    }
  },
  set_completudes(state, payload) {
    state.completudes = payload;
  },
  set_familyCompletude(state, familyCompletude) {
    state.familyCompletude = familyCompletude;
  },
  set_reset(state) {
    state.currentMarketplace = null;
    state.completudes = {};
    state.familyCompletyde = [];
  },
};
