<template>
  <v-card>
    <v-tabs
      background-color="green"
      dark
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
    >
      <v-tabs-slider color="yellow"></v-tabs-slider>
      <v-tab v-for="(langPim, lang) in langs" :key="lang">
        <flag :iso="lang"></flag>
      </v-tab>
      <v-tab-item v-for="(langPim, lang) in langs" :key="lang">
        <v-text-field
          v-model="item[property][keyAttr][langPim]"
          :label="label || keyAttr"
          :messages="defaultValueTextAre(langPim)"
          :hint="attribute.getHelp()"
          :loading="isCompletudeRequired(bindLangAttribute(langPim))"
        />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
<script>
import AttributeBaseMixins from '../../mixins/attribute-base';
import CompletudeBaseMixins from '../../mixins/completude-base';
import LangBaseMixins from '../../mixins/lang-base';

export default {
  mixins: [AttributeBaseMixins, LangBaseMixins, CompletudeBaseMixins],
  name: 'attributeString',
  data: () => ({
    tab: null,
  }),
  created() {
    this.initTextLang();
  },
  methods: {
    defaultValueTextAre(lang) {
      const defaultValues = this.getDefaultValues();
      if (!defaultValues) return '';
      return `${this.titleDefault} ${defaultValues[lang]}`;
    },
  },
};
</script>
