<template>
  <v-card max-width="434" tile>
    <v-carousel
      vertical
      vertical-delimiters
      show-arrows>
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.original"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <v-chip v-if="item.marketplace" class="pa-2 mt-auto" color="pink" label text-color="white">
          <v-icon left>mdi-label</v-icon>
          {{ item.marketplace }}:{{ item.order }}
        </v-chip>
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>

<script>
import Mixins from '../../mixins';

export default {
  name: 'ProductMediaView',
  mixins: Mixins,
  props: {
    value: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data: () => ({}),
  computed: {
    items() {
      if (!this.value || this.value.length === 0) {
        return [
          {
            original: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
          },
        ];
      }
      return this.value;
    },
  },
};
</script>

<style lang="css" scoped></style>
