<template>
  <v-autocomplete
    class="attribute-translate-type"
    v-model="item[property]"
    :items="itemsValue"
    menu-props="auto"
    :label="label || 'Pick your translate type'"
    hint="Pick your translate type"
    persistent-hint
    chips
    :messages="getDefaultValues()"
  >
  </v-autocomplete>
</template>

<script>
import { mapActions } from 'vuex';
import AttributeBaseMixins from '../../mixins/attribute-base';

export default {
  mixins: [AttributeBaseMixins],
  name: 'attributeTypes',
  data: () => ({
    type: 'translateTypes',
  }),
  created() {
    this.apiTranslateTypes();
  },
  computed: {
    itemsValue() {
      return this.$store.getters['translate/types'];
    },
  },
  methods: {
    ...mapActions('translate', {
      apiTranslateTypes: 'types',
    }),
  },
};
</script>
