import Log from '@/models/utils/log';
import AttributeAbstract from '@/models/attribute/abstract';

const namespace = 'models.attribute.family';

export default class Family extends AttributeAbstract {
  objectInitializer(data) {
    super.objectInitializer(data);
    this.remoteCode = data.remoteCode.replace('pim.f.', '');
  }
}
