<template>
  <ul class="size-legend">
    <li class="orange">Size default</li>
    <li class="blue">
      Size default for this gender <b>{{ gender }}</b>
    </li>
    <li class="green">
      Size for this gender <b>{{ gender }}</b> and the classification
      <b>{{ classification }}</b>
    </li>
    <li class="red">Size errors with no classification</li>
  </ul>
</template>

<script>
export default {
  name: 'SizeLegend',
  props: {
    gender: {
      type: String,
      required: false,
      default: null,
    },
    classification: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>
