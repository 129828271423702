import Media from '../pim/media';
import { DefaultError } from '../pim/exception';

export default class MediaManager {
  static newObject(media) {
    if (!(media instanceof Media)) {
      throw new DefaultError('models.pim.mediadefault.newobject.media.invalid');
    }
    const mediaData = media.toObject();
    // toOBject remove parent properties
    const defaultItem = Media.create(mediaData);
    defaultItem.reset();
    return defaultItem;
  }
}
