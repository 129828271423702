// @todo create a function to generate automatically theses properties
// authorized lets you know if the token is true or not
import { Classification } from '@/models';
import i18n from '@/plugins/i18n';

export default {
  classifications: (state) => {
    try {
      return state.classifications.map((row) => {
        // http://kazupon.github.io/vue-i18n/api/#vue-injected-methods
        if (i18n.te(`classification.${row.remoteCode}`)) {
          row.nameI18n = `${i18n.t(`classification.${row.remoteCode}`)}`;
        } else {
          row.nameI18n = row.name;
        }
        return new Classification(row);
      });
    } catch (err) {
      return Promise.reject(new Error('store.modules.api.helpers.translation.failed'));
    }
  },
  classification: state => new Classification(state.classification) || null,
};
