/**
 *  External Modules
 *  @todo : to add in readme
 *
 * npm i --save vuex-persistedstate js-cookie
 * files for plugin :
 *     src/App.vue
 *     src/main.js
 *     src/plugins/axios.js
 *     src/router/index.js
 *     src/router/paths.js
 *     src/views/LoginHome.vue
 *     src/plugins/auth.js
 *     src/services/cookiestorage.js
 *     src/services/watchguard.js
 */

import Vue from 'vue';
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';
import CookieService from '@/services/cookiestorage';

const configDefaults = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

/**
 *  Vue.js Instance Definition
 */

let instance;

export const getInstance = () => instance;

const states = () => ({
  authStatus: null,
  token: null,
  user: null,
});

const mutations = {
  auth_request(state) {
    state.authStatus = 'loading';
    state.token = null;
  },
  auth_success(state, { token }) {
    state.authStatus = 'success';
    state.token = token.access_token;
    state.user = token;
  },
  auth_error(state) {
    state.authStatus = 'error';
    state.token = null;
    state.user = null;
  },
  auth_reset_token(state) {
    state.token = null;
  },
  auth_waiting(state) {
    state.authStatus = 'waiting';
  },
  logout(state) {
    state.authStatus = null;
    state.token = null;
    state.user = null;
  },
};

const getters = {
  isAuthenticated: state => !!state.token,
  token: state => state.token,
  isLoading: state => !state.token && state.authStatus === 'loading',
  authstatus: state => state.authStatus,
  user: state => state.user,
};

/**
 *  Vue.js Instance Initialization
 */
const theAgentAuthorization = (pluginOptions) => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        token: null,
        error: null,
        clientId: null,
        accessTokenLifetime: null,
        store: pluginOptions.store || { states: () => ({}), mutations: {}, getters: {} },
      };
    },
    computed: {
      isAuthenticated() {
        return this.store.getters.isAuthenticated;
      },
      accessToken() {
        if (!this.store.getters.user) {
          return null;
        }
        return this.store.getters.user.access_token || null;
      },
      refreshToken() {
        if (!this.store.getters.user) {
          return null;
        }
        return this.store.getters.user.refresh_token || null;
      },
      clientIdToken() {
        if (!this.store.getters.user) {
          return null;
        }
        return this.store.getters.user.clientId || null;
      },
      isLoading() {
        return this.store.getters.isLoading;
      },
      user() {
        return this.store.getters.user || {};
      },
      scopes() {
        if (!this.store.getters.user || !this.store.getters.user.scope) {
          return [];
        }
        if (typeof this.store.getters.user.scope === 'string') {
          return [this.store.getters.user.scope];
        }
        return this.store.getters.user.scope;
      },
    },
    methods: {
      loginWithRedirect(userData) {
        Vue.$log.log('plugins.auth.loginwithredirect.started', {
          username: userData.username,
          password: userData.password,
          grant_type: 'password',
          clientId: userData.clientId || this.clientId,
        });
        this.store.commit('auth_request');
        return new Promise((resolve, reject) => {
          const params = new URLSearchParams();
          params.append('username', userData.username);
          params.append('password', userData.password);
          params.append('grant_type', 'password');
          params.append('client_id', userData.clientId || this.clientId);
          axios
            .post('/login', params, configDefaults)
            .then((response) => {
              if (typeof response.data === 'undefined') {
                throw new Error("Server don't send a data response");
              }
              const token = {
                ...response.data,
                clientId: userData.clientId || this.clientId,
              };
              axios.defaults.headers.common.Authorization = `Bearer ${token.access_token}`;
              this.store.commit('auth_success', { token });
              // mutation to change state properties to the values passed along
              resolve(token);
            })
            .catch((err) => {
              Vue.$log.error('plugins.auth.error');
              this.store.commit('auth_error');
              reject(err);
            });
        });
      },

      async getRefreshToken() {
        Vue.$log.log('plugins.auth.refreshtoken.started', {
          refresh_token: this.refreshToken,
          grant_type: 'refresh_token',
          clientId: this.clientIdToken,
        });
        this.store.commit('auth_request');
        if (!this.clientIdToken || !this.refreshToken) {
          throw new TypeError('plugins.auth.refreshtoken.or.clientidtoken.empty');
        }
        return new Promise((resolve, reject) => {
          const params = new URLSearchParams();
          params.append('refresh_token', this.refreshToken);
          params.append('grant_type', 'refresh_token');
          params.append('client_id', this.clientIdToken);
          axios
            .post('/token', params, configDefaults)
            .then((response) => {
              if (typeof response.data === 'undefined') {
                throw new Error("Server don't send a data response");
              }
              const token = {
                ...response.data,
                scope: this.scopes,
                clientId: this.clientIdToken || this.clientId,
              };
              axios.defaults.headers.common.Authorization = `Bearer ${token.access_token}`;
              // mutation to change state properties to the values passed along
              this.store.commit('auth_success', { token });
              return resolve(response);
            })
            .catch((error) => {
              Vue.$log.error('plugins.auth.refreshtoken.error');
              this.store.commit('auth_error');
              return reject(error);
            });
        });
      },
      logout() {
        Vue.$log.log('plugins.auth.logout.started');
        this.store.commit('logout');
        delete axios.defaults.headers.common.Authorization;
      },
    },

    async created() {
      this.clientId = pluginOptions.clientId;
    },
  });

  return instance;
};

/** $
 *  Vue.js Plugin Definition
 */
export const TaAuthPlugin = {
  // eslint-disable-next-line no-shadow
  install(Vue, options) {
    if (!options.store) {
      throw new Error('Please provide vuex store.');
    }
    // https://vuex.vuejs.org/guide/modules.html#dynamic-module-registration
    // register your own vuex module
    options.store.registerModule('auth', { state: states, mutations, getters });
    createPersistedState({
      paths: ['auth.token', 'auth.user'],
      storage: CookieService,
    })(options.store);
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$auth = theAgentAuthorization(options);
  },
};
