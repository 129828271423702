// https://vuex.vuejs.org/en/mutations.html

export default {
  set_attributes(state, payload) {
    state.attributes = payload;
  },
  set_entity_types(state, payload) {
    state.entityTypes = payload;
  },
  set_fields(state, payload) {
    state.fields = payload;
  },
  set_profiles(state, payload) {
    state.profiles = Object.values(payload).reduce((r, p) => {
      const [key, label, keyLabel] = p;
      // eslint-disable-next-line no-param-reassign
      r[keyLabel] = {
        name: label,
        key,
        i18n: `profile.${keyLabel.slugify()}`,
      };
      return r;
    }, {});
  },
  set_state(state, payload) {
    state.state = payload;
  },
};
