<template>
  <v-text-field
    v-model="newValue"
    append-icon="mdi-close-circle"
    append-outer-icon="mdi-send"
    filled
    label="Group name"
    type="text"
    @click:append-outer="sendMessage"
    @click:append="resetValue"
  >
  </v-text-field>
</template>

<script>
export default {
  name: 'TextFieldFamilyattributeName',
  props: {
    value: {
      type: String,
      required: true,
    },
    apiRemoteCode: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    newValue: null,
  }),
  mounted() {
    this.resetValue();
  },
  methods: {
    sendMessage() {
      this.$emit('loading', true);
      if (!this.apiRemoteCode) {
        this.$emit('input', this.newValue);
        this.$emit('loading', false);
      } else {
        setTimeout(() => this.$store
          .dispatch('family-attribute/updateName', {
            remoteCode: this.apiRemoteCode,
            name: this.newValue,
          })
          .then(() => {
            this.$emit('input', this.newValue);
          }).catch((error) => {
            this.resetValue();
            return this.throwError(error);
          })
          .finally(() => {
            this.$emit('loading', false);
          }),
        2000);
      }
    },
    resetValue() {
      this.newValue = this.value.slice();
    },
  },
};
</script>
