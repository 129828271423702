<template>
  <v-chip class="ma-2" close @click:close="removeValue" :color="color">
    {{ format(value) }}
  </v-chip>
</template>

<script>
import ChipsBaseMixins from '../../mixins/chip-base';

export default {
  name: 'TextChips',
  props: {
    value: {
      type: [Array, String],
      required: true,
    },
    color: {
      type: String,
      default: 'primary',
    },
    type: {
      type: String,
      default: 'search-text',
    },
  },
  mixins: [ChipsBaseMixins],
  data: () => ({}),
  methods: {
    format() {
      this.$log.debug('components.chips.text.format.started', this.value);
      return `${this.type}:${this.value}`;
    },
    removeValue() {
      this.$log.debug('components.chips.text.removevalue.started');
      this.$emit('input', null);
      this.$emit('onClose');
    },
  },
};
</script>

<style lang="css" scoped></style>
