<template>
  <v-card>
    <v-text-field
      v-model="valueAttribute"
      filled
      :value="valueAttribute"
      :label="label || property"
      class="vuetify-editor"
      auto-grow
      :messages="getDefaultValues()"
      :loading="isCompletudeRequired(bindAttribute, currentIssues)"
      :hint="attribute.getHelp()"
    >
      <template v-slot:label>
        <slot name="label"></slot>
      </template>
    </v-text-field>
  </v-card>
</template>
<script>
import AttributeBaseMixins from '../../mixins/attribute-base';
import CompletudeBaseMixins from '../../mixins/completude-base';

export default {
  mixins: [AttributeBaseMixins, CompletudeBaseMixins],
  name: 'attributeStringNoLang',
  data: () => ({}),
};
</script>
