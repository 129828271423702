// https://vuex.vuejs.org/en/state.html


const STATE_NONE = 0;
const STATE_DONE = 1;
const STATE_WIP = 2;
const STATE_UNKNOWN = 126;
const STATE_ERROR = 127;

export default {
  rows: [],
  constants: {
    STATE_NONE,
    STATE_DONE,
    STATE_WIP,
    STATE_UNKNOWN,
    STATE_ERROR,
  },
};
