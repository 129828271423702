<template>
  <div>
    <div v-if="!hasRightActions || noDialog">{{ titleName }}</div>
    <v-edit-dialog :return-value="item[property]" large lazy v-else @save="saveInline">
      <div>{{ titleName }}</div>
      <core-error v-if="item.errors" :property="property" :errors="item.errors[property]" />
      <template v-slot:input>
        <v-text-field
          v-bind:value="item[property]"
          v-on:change="callApi"
          :rules="textRules"
          :label="labelName"
          counter
          autofocus
          :suffix="suffix()"
        >
        </v-text-field>
        <helper-size-legend
          :gender="item.gender"
          :classification="classificationFormat(item.classificationId)"
        >
        </helper-size-legend>
        <v-simple-table :dense="settings.dense" fixed-header height="200">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left"><flag iso="fr" /></th>
                <th class="text-left"><flag iso="gb" /></th>
                <th class="text-left"><flag iso="de" /></th>
                <th class="text-left"><flag iso="it" /></th>
                <th class="text-left"><flag iso="es" /></th>
                <th class="text-left"><flag iso="us" /></th>
                <th class="text-left">gender</th>
                <th class="text-left">classification</th>
                <th class="text-left">brand</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="sizes.length > 0">
                <tr v-for="size in sizes" :key="size.fr">
                  <template>
                    <td>{{ size.fr }}</td>
                    <td>{{ size.en }}</td>
                    <td>{{ size.de }}</td>
                    <td>{{ size.it }}</td>
                    <td>{{ size.es }}</td>
                    <td>{{ size.us }}</td>
                    <td>{{ genderFormat(size.gender) }}</td>
                    <td>{{ classificationFormat(size.classificationId) }}</td>
                    <td>{{ brandFormat(size.brandId) }}</td>
                  </template>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="7">No values</td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DialogBaseMixins from '../../mixins/dialog-base';
import ProfileMixins from '../../mixins/profile-base';
import SizeMixins from '../../mixins/size-base';
import { Gender } from '../../models';

export default {
  mixins: [DialogBaseMixins, ProfileMixins, SizeMixins],
  data: () => ({
    type: 'size',
    sizes: [],
  }),
  created() {
    this.apiSize();
    if (typeof this.item[this.property] !== 'undefined' && this.item[this.property]) {
      this.callApi(this.item[this.property].trim());
    }
  },
  computed: {
    ...mapGetters('form/rules', { textRules: 'text' }),
    titleName() {
      return this.item[this.property];
    },
    settings() {
      return this.$store.getters['settings/settings'];
    },
    currentGenderId() {
      return Gender.fetchIdByname(this.item.gender);
    },
    brandId() {
      const { metaData } = this.$store.getters['import/import'];
      return metaData ? metaData.item : null;
    },
  },
  methods: {
    ...mapActions('api', {
      apiSize: 'size',
    }),
    callApi(value) {
      if (typeof value !== 'string') {
        throw new TypeError(`components.dialogsizeautocomplete.callapi.value.${value}.invalid`);
      }
      if (!value) {
        const exception = new TypeError('components.dialogsizeautocomplete.callapi.value.null');
        return Promise.reject(exception);
      }
      // see dialogbase mixins
      this.newObject = value.trim();
      return this.$store
        .dispatch('size/search', {
          search: value,
          classificationId: this.item.classificationId || null,
          brandId: this.brandId,
          gender: this.currentGenderId || null,
        })
        .then((response) => {
          this.sizes = response;
          return response;
        });
    },
    suffix() {
      const { gender, classificationId } = this.item;
      return `(sizes: ${
        this.sizes.length
      }, gender: ${gender}, classification: ${this.classificationFormat(
        classificationId,
      )}, brand: ${this.brandFormat(this.brandId)})`;
    },
  },
};
</script>

<style>
ul.size-legend {
  list-style-type: none;
  font-weight: bolder;
  font-size: 10px;
}
</style>
