export default {};

// https://github.com/vuejs/vue/issues/9259
// https://vuejsdevelopers.com/2017/06/11/vue-js-extending-components/
// https://github.com/vuejs/vue/issues/6943

export function makeReactive(obj) {
  //
  // eslint-disable-next-line no-proto
  const proto = Object.getPrototypeOf(
    Object.getPrototypeOf(Object.getPrototypeOf(obj).constructor),
  );

  Object.defineProperty(obj, '__proto__', {
    get() {
      return proto;
    },
    // eslint-disable-next-line no-proto
    set(newValue) {
      proto.__proto__ = newValue;
    },
  });
}
