<template>
  <v-container fluid>
    <v-row dense align="center" style="background-color: white;">
      <v-col cols="1">
        Edition
      </v-col>
      <v-col cols="9">
        <dialog-search v-model="search" @onSave="saveAndSearch"> </dialog-search>
      </v-col>
      <v-col cols="2">
        <helper-switchview v-model="mode"> </helper-switchview>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-divider></v-divider>
        <core-alert></core-alert>
      </v-col>
    </v-row>
      <template v-if="mode === 0">
        <v-data-table
          :headers="filterByColumn()"
          class="elevation-2 ta-fix-column ta-product"
          :items="items"
          :server-items-length="itemsCount"
          :options.sync="itemsOptions"
          :loading="loading"
          :footer-props="{ 'items-per-page-options': [100, 200, 500, -1] }"
          loading-text="Loading... Please wait"
          :show-expand="settings.showExpand"
          :single-expand="settings.singleExpand"
          :dense="settings.dense"
          fixed-header
        >
          <template v-slot:item.data-table-expand="{ expand, isExpanded }">
            <div class="text-center ta-expand-icon">
              <v-btn fab x-small class="ta-product" @click="expand(!isExpanded)">R</v-btn>
              <v-icon v-if="isExpanded" @click="expand(!isExpanded)">mdi-chevron-up</v-icon>
              <v-icon v-else @click="expand(!isExpanded)">mdi-chevron-down</v-icon>
            </div>
          </template>
          <template v-slot:item.image="{ item }">
            <core-avatar
              v-if="typeof item.image !== 'undefined' || item.image !== null"
              :image="$pimUrl(item.image, 'sq60')"
            />
          </template>
          <template v-slot:item.name="{ item }">
            <dialog-name
              :item="item"
              property="name"
              labelName="Choose your name"
              :updateChildren="true"
            >
            </dialog-name>
          </template>
          <template v-slot:item.infos.att_titre_produit_r="{ item }">
            <dialog-textarea
              :item="item"
              property="infos"
              keyAttr="att_titre_produit_r"
              labelName="Write a product title"
            >
            </dialog-textarea>
          </template>
          <template v-slot:item.infos.att_sous_categorie_produit_zalando_r="{ item }">
            <dialog-select
              :item="item"
              property="infos"
              keyAttr="att_sous_categorie_produit_zalando_r"
              labelName="Select a sub category"
              noDialog
            >
            </dialog-select>
          </template>
          <template v-slot:item.infos.att_commercial_name_r="{ item }">
            <dialog-name
              :item="item"
              property="infos"
              keyAttr="att_commercial_name_r"
              labelName="Commercial name"
              noDialog
            >
            </dialog-name>
          </template>
          <template v-slot:item.from="{ item }">
            <dialog-country :item="item" property="from" labelName="Choose your country">
            </dialog-country>
          </template>
          <template v-slot:item.brandId="{ item }">
            <dialog-brand :item="item" property="brandId" labelName="Choose your brand" noDialog>
            </dialog-brand>
          </template>
          <template v-slot:item.distributorIds="{ item }">
            <dialog-distributor
              :item="item"
              property="distributorIds"
              labelName="Choose your supplier"
            >
            </dialog-distributor>
          </template>
          <template v-slot:item.marketplace="{ item }">
            <v-chip :color="getColor(item.marketplace, 'marketplace')" dark>
              {{ getMessage(item.marketplace, "marketplace") }}
              <template v-if="item.marketplace === doneStatus && item.syncUpdatedAt">
                <br />({{ item.syncUpdatedAt }})
              </template>
            </v-chip>
          </template>
          <template v-slot:item.quable="{ item }">
            <v-chip :color="getColor(item.quable)" dark>
              {{ getMessage(item.quable) }}
            </v-chip>
          </template>
          <template v-slot:item.classificationId="{ item }">
            <dialog-classification
              :item="item"
              property="classificationId"
              labelName="Choose your classification"
              noDialog
            >
            </dialog-classification>
          </template>
          <template v-slot:item.active="{ item }">
            <v-switch v-model="item.active" @change="active(item)"></v-switch>
          </template>
          <template v-slot:item.percent="{ item }">
            <material-percent :percent="item.percent" color="red"></material-percent>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <pim-variant-view
                :items="item.variant"
                v-if="item.variant"
                endpointType="variant"
                no-edition
              ></pim-variant-view>
            </td>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-1" v-on:click.stop @click="gotoProduct(item)">
              mdi-pencil-circle
            </v-icon>
            <v-icon small class="mr-1" @click="deleteItem(item)">
              mdi-delete-circle
            </v-icon>
            <v-icon small class="mr-1" @click="syncItem(item)">
              mdi-sync-circle
            </v-icon>
            <v-icon small v-if="listOptions.length > 1" @click="openItemOptions(item)">
              mdi-dots-vertical-circle
            </v-icon>
          </template>
        </v-data-table>
        <dialog-list-options
          v-if="listOptions.length > 1"
          v-model="dialogListOption"
          :list-options="listOptions"
          :current="editedItem.copy()"
          x-large
          hide-button
        >
          <template v-slot:title>
            {{ editedItem.name }}<span v-if="editedItem.sealed"> (data sealed)</span>
          </template>
        </dialog-list-options>
      </template>
      <template v-else-if="mode === 1">
        <pim-variant-view
          :items="itemsVariant"
          v-if="itemsVariant"
          :saveOnApi="saveOnApi"
          endpointType="variant"
          :show-select="settings.showSelect"
          no-edition
        ></pim-variant-view>
      </template>
      <template v-else-if="mode === 2">
        <pim-skus-view
          :items="itemsSku"
          v-if="itemsSku"
          :saveOnApi="saveOnApi"
          :show-select="settings.showSelect"
          endpointType="sku"
          no-edition
        ></pim-skus-view>
      </template>
      <div>
        <dialog-settings :dialog="dialog"> </dialog-settings>
      </div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { marketplace } from 'theagent-status';
import { EventBus } from '@/helpers/event-bus';
import { Product } from '../../models';
import Mixins from '../../mixins';
import ItemsBaseMixins from '../../mixins/items-base';
import ProductBaseMixins from '../../mixins/product-base';
import EditMixins from '../../mixins/edit-base';
import ProfileMixins from '../../mixins/profile-base';

export default {
  mixins: [ItemsBaseMixins, EditMixins, ProfileMixins, ProductBaseMixins, ...Mixins],
  data: () => ({
    itemsCount: 0,
    itemsOptions: {
      sortBy: [],
      sortDesc: [],
      itemsPerPage: 50,
    },
    search: {}, // data come from dialog-search via a two way binding
    loading: false,
    mode: 0,
    doneStatus: marketplace.DONE,
    saveOnApi: true,
    dialogListOption: false,
    listOptionsDefault: [
      {
        name: 'Default',
      },
      {
        name: 'Duplicate this product',
        fields: ['id', 'name'],
        store: 'product/duplicate',
        scope: 'pimapp.v2:pim:product:duplicate:write',
        payload: {
          children: true,
        },
      },
      {
        name: 'Rename this product',
        fields: ['id', 'name'],
        store: 'product/rename',
        scope: 'pimapp.v2:pim:product:rename:write',
        payload: {
          children: true,
        },
      },
    ],
  }),
  created() {
    this.itemsOptions = this.itemsOptionsDefault();
    this.editedItem = this.defaultItem.clone();
  },
  watch: {
    itemsOptions: {
      handler() {
        this.searchProducts().then(({ itemsCount, itemsPerPage }) => {
          this.itemsCount = itemsCount;
          this.itemsPerPage = itemsPerPage;
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.$log.log('components.pim.productlistview.mounted');
    if (Object.keys(this.$route.query).length > 0) {
      const {
        limit = this.itemsOptions.itemsPerPage,
        page = 1,
        search = '',
        filters = {},
      } = this.$route.query;
      this.search = {
        text: search,
        filters: JSON.parse(filters),
      };
      this.itemsOptions.itemsPerPage = limit;
      this.itemsOptions.page = page;
    }
    EventBus.$on('pim.products:reload', () => {
      this.searchProducts().then(({ itemsCount, itemsPerPage }) => {
        this.itemsCount = itemsCount;
        this.itemsPerPage = itemsPerPage;
      });
    });
  },
  beforeCreate() {
    this.$store.dispatch('api/attribute/list');
  },
  computed: {
    settings() {
      return this.$store.getters['settings/settings'];
    },
    headers() {
      return this.$store.getters['settings/headers'].product;
    },
    items() {
      const { items } = this.options(
        this.$store.getters['product/products'],
        this.itemsCount,
        this.itemsOptions,
      );
      return items;
    },
    defaultItem: function defaultItem() {
      const defaultValues = this.$store.getters['settings/headers'].product.reduce(
        (item, header) => {
          // eslint-disable-next-line no-param-reassign
          item[header.value] = '';
          return item;
        },
        {},
      );
      return Product.create(defaultValues);
    },
    listOptions() {
      return this.listOptionsDefault.filter(o => this.hasRight(o.scope || null));
    },
  },
  methods: {
    ...mapActions('product', {
      apiProductSearch: 'search',
    }),
    searchProducts() {
      this.$log.log('components.pim.productlistview.searchproducts.started');
      this.$log.debug('components.pim.productlistview.searchproducts.filters', this.search);
      this.loading = true;
      return new Promise((resolve) => {
        const { page, itemsPerPage } = this.itemsOptions;
        let filters = {};
        if (Object.keys(this.search).length > 0) {
          // eslint-disable-next-line prefer-destructuring
          filters = this.search.filters;
        }
        const params = {
          params: {
            search: this.search.text ? this.search.text.trim() : '',
            limit: parseInt(itemsPerPage, 10),
            page,
            filters,
          },
        };
        this.$router
          .push({
            path: '/pim/product',
            query: {
              search: this.search.text,
              limit: params.params.limit,
              page: params.params.page,
              filters: JSON.stringify(filters),
            },
          })
          .catch(() => {}); // NavigationDuplicated error quiet

        return this.apiProductSearch(params).then((data) => {
          this.loading = false;
          this.$log.debug('components.pim.productlistview.searchproducts.result', {
            itemsCount: data.parameters.count,
            itemsPerPage,
            itemsOptions: this.itemsOptions,
          });
          resolve({
            itemsCount: data.parameters.count,
            itemsPerPage,
          });
        });
      });
    },
    saveItem() {
      this.$log.info('components.pim.productlistview.saveitem.started', this.editedItem);
      let endpoint = 'product/new';
      const isOld = this.editedIndex > -1;
      if (isOld) {
        endpoint = 'product/update';
      }
      this.$store
        .dispatch(endpoint, this.editedItem.toObject())
        .then((response) => {
          this.items[this.editedIndex].update(response);
          return this.items[this.editedIndex].setCompletude(this.$store.dispatch);
        })
        .catch(error => this.throwError(error))
        .finally(() => this.closeItem());
    },
    saveAndSearch(filters) {
      this.$log.info('components.pim.productlistview.saveandsearch.started');
      return this.searchProducts(filters).then(({ itemsCount, itemsPerPage }) => {
        this.itemsCount = itemsCount;
        this.itemsPerPage = itemsPerPage;
      });
    },
    gotoProduct(item) {
      const routeData = this.$router.resolve({ path: `/pim/product/${item.id}/edit` });
      window.open(routeData.href, '_blank');
    },
    openItemOptions(item) {
      this.dialogListOption = true;
      this.editedItem = item;
    },
  },
};
</script>

<style></style>
