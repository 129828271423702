<template>
  <v-container fill-height fluid>
    <v-layout wrap>
      <material-card
        color="green"
        title="Check if your size value is enabled for your classification"
        text="Select your classification"
        class="col-lg-12"
      >
        <attribute-classification-native v-model="classificationId"></attribute-classification-native>
        <attribute-brand-native v-if="classificationId" v-model="brandId"></attribute-brand-native>
        <common-sheets-upload
          v-if="classificationId"
            label="Upload your size files (csv, xls, xslx)"
            :post-action="getPostUrl(classificationId, brandId)"
            v-on:update:upload="getResult"
            v-on:upload="loader"
          >
        </common-sheets-upload>
      </material-card>
      <v-flex md12 style="min-height: 658px">
        <v-data-table
          :items="items"
          :headers="headers"
          class="elevation-1 ta-fix-column ta-table-csv ta-csv"
          loading-text="Loading... Please wait"
          :loading="loading"
          :dense="settings.dense"
          items-per-page="100"
          fixed-header
          :footer-props="{ 'items-per-page-options': [100, 200, 400, 500, -1] }"
        >
          <template v-for="head in headers" v-slot:[`item.${head.value}`]="{ item }">
            <div :key="head.value">
              <template v-if="typeof item[head.value] === 'string'">
                {{ item[head.value] }}
              </template>
              <v-btn
                v-else
                class="ma-2"
                text
                icon
                :color="`${item[head.value] ? 'green' : 'red'} lighten-2`"
              >
                <v-icon>mdi-thumb-{{ item[head.value] ? 'up' : 'down'}}</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import { Gender } from '@/models';
import LangBaseMixins from '@/mixins/lang-base';

const baseURL = process.env.VUE_APP_PIM_BASEURL || 'http://localhost:3128/';

export default {
  name: 'CheckSizeView',
  mixins: [LangBaseMixins],
  data: () => ({
    classificationId: null,
    brandId: null,
    items: [],
    loading: false,
  }),
  computed: {
    genders() {
      return [...new Set(Object.values(Gender.fetchList()))];
    },
    currentLang() {
      return this.$store.getters['app/currentLang'];
    },
    headers() {
      let headers = [{
        text: 'Size value',
        value: 'size',
        sortable: false,
        filterable: false,
      }];
      headers = this.genders.reduce((r, g) => {
        r.push({
          text: g.langs[this.currentLang],
          value: g.name,
          sortable: false,
          filterable: false,
        });
        return r;
      }, headers);
      headers.push({
        text: 'Action (coming soon)',
        value: 'action',
        sortable: false,
        filterable: false,
      });
      return headers;
    },

    settings() {
      return this.$store.getters['settings/settings'];
    },
  },
  methods: {
    getPostUrl(classificationId, brandId = null) {
      if (brandId) {
        return `${baseURL}/api/v1/pim/size/checking.by.classification/${classificationId}/${brandId}`;
      }
      return `${baseURL}/api/v1/pim/size/checking.by.classification/${classificationId}`;
    },
    getResult(rows) {
      this.items = rows.map((r) => {
        const gendersValue = this.genders.reduce((r2, g) => {
          r2[g.name] = r[g.legacyId];
          return r2;
        }, {});
        const action = Object.values(gendersValue).every(Boolean);
        return {
          size: r.originalSize,
          ...gendersValue,
          action,
        };
      });
    },
    loader(value) {
      this.loading = value;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
