import ServiceTemplate from '@/services/template';

const DEFAULT_PROFILE = 0;

const setScope = (header, profiles) => {
  // by default,  hide everything for everyone
  const defaultScope = [profiles.ADMIN.key];
  if (Array.isArray(header.scopes) && header.scopes.length > 0) {
    return header.scopes;
  }
  return defaultScope;
};

const setHeadersOptions = (
  headers,
  currentLang = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  profiles,
  bindKey = 'name',
) => headers.filter(h => h.display).map(h => ({
  sortable: false,
  scopes: setScope(h, profiles),
  text: h.label[currentLang],
  i18n: h.i18n,
  order: h.order,
  width: h.width || undefined,
  value: h[bindKey],
  template: ServiceTemplate.getTemplate(h),
  filterable: h.templateOptions.filterable || false,
}));

const getScope = (header, profiles) => {
  const hasProfiles = Object.keys(profiles).length > 0;
  if (hasProfiles && typeof header.isAdmin !== 'undefined' && header.isAdmin) {
    return [
      profiles.ADMIN.key,
    ];
  } if (hasProfiles && typeof header.isForAll !== 'undefined' && header.isForAll) {
    return [
      profiles.DEFAULT.key,
      profiles.BUSINESS.key,
      profiles.ADMIN.key,
      profiles.INTEGRATOR.key,
      profiles.MARKETPLACE.key,
    ];
  } if (hasProfiles && typeof header.isNotDefaultOnly !== 'undefined' && header.isNotDefaultOnly) {
    return [
      profiles.ADMIN.key,
      profiles.BUSINESS.key,
      profiles.INTEGRATOR.key,
    ];
  } if (hasProfiles && typeof header.isBusinessOnly !== 'undefined' && header.isBusinessOnly) {
    return [
      profiles.ADMIN.key,
      profiles.BUSINESS.key,
    ];
  }
  return [DEFAULT_PROFILE];
};

const setInternalHeadersOptions = (
  headers,
  profiles = [],
) => headers.map(h => ({
  ...h,
  template: ServiceTemplate.getTemplate(h),
  scopes: getScope(h, profiles),
}));

const setProfileHeaders = (headers, currentProfile) => headers.reduce((r, h) => {
  if (h.scopes.includes(currentProfile.key)) {
    r.push(h);
  }
  return r;
}, []);

export default {
  item: state => property => (typeof state.settings[property] !== 'undefined' ? state.settings[property] : false),
  settings: state => state.settings,
  theagent: state => state.theagent,
  settingsDefault: state => state.settingsDefault,
  // this getter method should be inside api/attribute/getters
  headers: (state, getters, actions, rootGetters) => {
    const currentLang = rootGetters['app/currentLang'];
    const profiles = rootGetters['api/attribute/profiles'];
    const currentProfile = state.profile;
    return {
      csv: setProfileHeaders([
        ...setInternalHeadersOptions(state.headers.csv, profiles),
        ...setHeadersOptions(rootGetters['api/attribute/csv'] || [], currentLang, profiles),
      ], currentProfile).sort((a, b) => a.order - b.order),
      product: setProfileHeaders([
        ...setInternalHeadersOptions(state.headers.product, profiles),
        ...setHeadersOptions(rootGetters['api/attribute/product'] || [], currentLang, profiles, 'bind'),
        ...setHeadersOptions(rootGetters['api/attribute/productAttribute'] || [], currentLang, profiles, 'bind'),
      ], currentProfile).sort((a, b) => a.order - b.order),
      variant: setProfileHeaders([
        ...setInternalHeadersOptions(state.headers.variant, profiles),
        ...setHeadersOptions(rootGetters['api/attribute/variant'] || [], currentLang, profiles, 'bind'),
        ...setHeadersOptions(rootGetters['api/attribute/variantAttribute'] || [], currentLang, profiles, 'bind'),
      ], currentProfile).sort((a, b) => a.order - b.order),
      sku: setProfileHeaders([
        ...setInternalHeadersOptions(state.headers.sku, profiles),
        ...setHeadersOptions(rootGetters['api/attribute/sku'] || [], currentLang, profiles, 'bind'),
        ...setHeadersOptions(rootGetters['api/attribute/skuAttribute'] || [], currentLang, profiles, 'bind'),
      ], currentProfile).sort((a, b) => a.order - b.order),
      catalog: setInternalHeadersOptions(state.headers.catalog, profiles),
      default: state.headers.default,
      import: state.headers.import,
      translate: setInternalHeadersOptions(state.headers.translate, profiles),
    };
  },
  csvAttributes: (state, getters, actions, rootGetters) => setProfileHeaders(rootGetters['api/attribute/csv'] || [], state.profile),
  profile: state => state.profile,
  profiles: (state, getters, actions, rootGetters) => Object.values(rootGetters['api/attribute/profiles']),
  catalog: state => state.catalog,
};
