<template>
  <v-row align="center" no-gutters dense>
    <v-col cols="11">
      <v-row align="center" no-gutters dense>
        <v-col cols="12" no-gutters>
          <chips-text
            v-if="searchParameters.text"
            v-model="searchParameters.text"
            color="red"
            @onClose="save"
          />
          <chips-values-object
            v-if="searchParameters.filters.type"
            :items="translateTypes"
            name="type"
            v-model="searchParameters.filters.type"
            color="primary"
            :keys="false"
            @onClose="save"
          />
          <chips-date
            v-if="searchParameters.filters.createdAt"
            v-model="searchParameters.filters.createdAt"
            name="dateStart"
            color="blue"
            @onClose="save"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="searchParameters.text"
            label="Search your data"
            v-on:keyup.enter="save"
            append-icon="mdi-magnify"
            :hint="hintText"
          >
          </v-text-field>
          <slot></slot>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="1">
      <v-dialog
        v-model="searchParameters.menu"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
        <template v-slot:activator="{ on }">
          <v-btn color="black darken-1" icon dark v-on="on">
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
        </template>
        <v-card tile>
          <v-toolbar flat dark color="blue">
            <v-btn icon dark @click="searchParameters.menu = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Advanced search</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn dark text @click="reset">
              <v-icon>mdi-lock-reset</v-icon>
            </v-btn>
            <v-btn dark text @click="save">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-toolbar>
          <v-list three-line subheader>
            <v-subheader>General</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  Set the search text
                </v-list-item-subtitle>
                <v-text-field
                  v-model="searchParameters.text"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list subheader>
            <v-subheader>Filters</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <attribute-translate-type
                  ref="type"
                  :item="searchParameters.filters"
                  property="type"
                  multiple
                  chips
                />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SearchTranslate',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    hintText: null,
    searchParameters: {
      menu: false,
      text: '',
      filters: {},
    },
    defaultFilters: {
      type: '',
      createdAtStart: null,
      createdAtEnd: null,
    },
  }),
  created() {
    this.apiTranslateTypes();
  },
  computed: {
    ...mapGetters('form/rules', { textRules: 'text' }),
    settings() {
      return this.$store.getters['settings/settings'];
    },
    headers() {
      return this.$store.getters['settings/headers'].product;
    },
    translateTypes() {
      return this.$store.getters['translate/types'];
    },
  },
  watch: {
    value() {
      this.searchParameters = this.value;
    },
    'searchParameters.text': {
      handler(text) {
        ['type', 'types'].forEach((key) => {
          this.tokenizer(text, key, this.translateTypes, 'type');
        });
      },
      deep: true,
    },
  },
  mounted() {
    if (Object.keys(this.value).length > 0) {
      this.searchParameters = {
        menu: false,
        text: this.value.text || '',
        filters: this.value.filters || {},
      };
    }
  },
  methods: {
    ...mapActions('translate', {
      apiTranslateTypes: 'types',
    }),
    setUndefinedIfNeeded(value = null) {
      return !!(value === null || value === '' || typeof value === 'undefined');
    },
    reset() {
      this.searchParameters.filters = { ...this.defaultFilters };
      this.$emit('input', this.filtered);
      this.$emit('onSave');
      this.close();
    },
    renderFilter(data) {
      this.$log.log('components.dialog.search.renderfilter.started');
      const dataFiltered = { ...data };
      let filtered = { ...data.filters };
      if (filtered.createdAtStart || filtered.createdAtEnd) {
        if (filtered.createdAtStart && filtered.createdAtEnd) {
          filtered.createdAt = {
            type: 'between',
            value: [filtered.createdAtStart, filtered.createdAtEnd],
          };
        } else if (filtered.createdAtStart) {
          filtered.createdAt = {
            type: 'gte',
            value: filtered.createdAtStart,
          };
        } else if (filtered.createdAtEnd) {
          filtered.createdAt = {
            type: 'lte',
            value: filtered.createdAtEnd,
          };
        }
      }
      filtered = Object.keys(filtered).reduce((result, filter) => {
        if (this.setUndefinedIfNeeded(filtered[filter]) === false) {
          Vue.set(result, filter, filtered[filter]);
        }
        return result;
      }, {});
      delete filtered.createdAtStart;
      delete filtered.createdAtEnd;
      delete dataFiltered.menu;
      dataFiltered.filters = filtered;
      if (this.setUndefinedIfNeeded(dataFiltered.text) === true) {
        delete dataFiltered.text;
      }
      this.$log.debug('components.dialog.search.renderfilter.data', dataFiltered);
      return dataFiltered;
    },
    tokenizer(text, key, values = [], parameterKey = false) {
      if (text && text.includes(`${key}:`)) {
        const regex = new RegExp(`${key}:([a-z0-9$])*`, 'igm');
        const [bind] = regex.exec(text);
        if (values.some(b => (b.name || b).slugify().includes(bind.split(':')[1].slugify()))) {
          const valuesFound = values.filter(b => (b.name || b).slugify().includes(bind.split(':')[1].slugify()));
          if (valuesFound.length > 1 && valuesFound.length < 4 && text.indexOf('$') === -1) {
            this.hintText = valuesFound.map(b => b.nameI18n || b.name || b).join(', ');
          } else if (valuesFound.length === 1 || text.indexOf('$') !== -1) {
            this.hintText = null;
            if (typeof this.searchParameters.filters[parameterKey] === 'undefined') {
              Vue.set(this.searchParameters.filters, parameterKey, []);
            }
            this.searchParameters.filters[parameterKey].push(valuesFound[0].id || valuesFound[0]);
            Vue.set(this.searchParameters, 'text', text.replace(bind, ''));
          }
        }
      }
    },
    save() {
      this.$log.debug('components.dialog.search.save.data', this.searchParameters);
      const filtered = this.renderFilter(this.searchParameters);
      this.$emit('input', filtered);
      this.$emit('onSave', filtered);
      this.close();
    },
    close() {
      // https://vuejs.org/v2/guide/components-custom-events.html#sync-Modifier
      this.searchParameters.menu = false;
    },
  },
};
</script>

<style lang="css">
.v-toolbar__title {
  color: white;
}

.theme--light .v-picker td .v-btn {
  /*color: rgba(0, 0, 0, 0.87) !important;*/
}
</style>
