import Vue from 'vue';
import axios from 'axios';

// configuration
Vue.prototype.$http = axios;
// an instruction to Axios to send all requests with credentials such as
// authorization headers, TLS client certificates, or cookies
axios.defaults.withCredentials = true;
// Sets the default url used by all of this axios instance's requests
axios.defaults.baseURL = process.env.VUE_APP_PIM_BASEURL || 'http://localhost:3128/';

axios.defaults.headers.get = {};
axios.defaults.headers.get.Accept = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';
// axios.defaults.headers.post = {};/
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.common.Retry = false;
axios.defaults.timeout = 60000;
