<template>
  <v-card class="m-auto" color="#26c6da" dark>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-h5">
          Marketplace: {{ marketplace }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-form v-model="valid">
      <v-text-field
        v-model="value.values[marketplace].value"
        label="Valeur"
        required
      ></v-text-field>
      <v-combobox
        v-model="limitations"
        :filter="filter"
        :hide-no-data="!search"
        :items="items"
        :search-input.sync="search"
        hide-selected
        label="Limitations"
        multiple
        small-chips
        solo
      >
        <template v-slot:no-data>
          <v-list-item>
            <span class="subheading">Create</span>
            <v-chip
              :color="`${colors[nonce - 1]} lighten-3`"
              label
              small
            >
              {{ search }}
            </v-chip>
          </v-list-item>
        </template>
        <template v-slot:selection="{ attrs, item, parent, selected }">

          <v-chip
            v-if="item === Object(item)"
            v-bind="attrs"
            :color="`${item.color} lighten-3`"
            :input-value="selected"
            label
            small
          >
            <span class="pr-2">
              {{ item }}
            </span>
            <v-icon
              small
              @click="parent.selectItem(item)"
            >
              $delete
            </v-icon>
          </v-chip>
        </template>
        <template v-slot:item="{ index, item }">
          <v-text-field
            v-if="editing === item"
            v-model="editing.text"
            autofocus
            flat
            background-color="transparent"
            hide-details
            solo
            @keyup.enter="edit(index, item)"
          ></v-text-field>
          <v-chip
            v-else
            :color="`${item.color} lighten-3`"
            dark
            label
            small
          >
            {{ item }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-list-item-action @click.stop>
            <v-btn
              icon
              @click.stop.prevent="edit(index, item)"
            >
              <v-icon>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </template>
      </v-combobox>
      <v-divider></v-divider>
        <v-btn
          :disabled="!valid"
          color="success"
          @click="validate"
        >
          dany dany
        </v-btn>

        <v-btn
          color="error"
          @click="reset"
        >
          Reset Form
        </v-btn>

    </v-form>
  </v-card>
</template>

<script>

import Vue from 'vue';
import { AttributeValue } from '@/models';

export default {
  name: 'FormMarketplace',
  props: {
    value: {
      type: AttributeValue,
      required: true,
    },
    marketplace: {
      type: String,
      required: false,
      default: 'default',
    },
  },
  data: () => ({
    backup: null,
    colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
    search: null,
    editing: null,
    editingIndex: -1,
    nonce: 1,
    menu: false,
    limitations: [],
  }),
  mounted() {
    this.backup = new AttributeValue(this.value.toObject());
    try {
      this.limitations = this.value.values[this.marketplace].condition.limitations;
    } catch (e) {
      this.limitations = [];
    }
  },
  watch: {
    limitations(val, prev) {
      if (val.length === prev.length) return;
      this.limitations = val.map((v) => {
        if (typeof v === 'string') {
          v = {
            text: v,
            color: this.colors[this.nonce - 1],
          };
          this.items.push(v);
          this.nonce++;
        }
        return v;
      });
    },
  },
  methods: {
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = val => (val != null ? val : '');

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1;
    },
    validate() {
      this.$emit('input', this.value);
    },
    reset() {
      Vue.set(this, 'value', this.backup);
    },
  },
};
</script>

<style lang="css" scoped>
</style>
