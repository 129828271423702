/* eslint no-console:0 */

import Vue from 'vue';
import VueLogger from 'vuejs-logger';

const options = {
  isEnabled: true,
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showArgumentInTable: false,
};

const logger = {
  info: console.info,
  log: console.log,
  debug: console.debug,
  error: console.error,
  warn: console.warn,
};

Vue.$log = logger;
Vue.prototype.$log = logger;
Vue.prototype.$http.interceptors.response.use((response) => {
  options.logLevel = response.headers['ta-log-level'] || process.env.VUE_APP_LOG_LEVEL || 'info';
  Vue.use(VueLogger, options);

  return response;
});
