import axios from 'axios';
import { workflow } from 'theagent-status';

const {
  IN_PROGRESS, DONE, ERROR, RE_TRY, NO_STATUS,
} = workflow;

// milli seconds
const DELAY = 5000;

export default function (endpoint, key, {
  commit, state, getters, dispatch,
}) {
  const keyList = `${key}List`;
  const keyListReduced = `${key}ListKey`;
  const isNew = typeof getters.history[key] === 'undefined' && getters.history[key] === endpoint;
  // if new but the endpoint have changed bug the state has already setted
  if (!isNew && state.state[key] === DONE) {
    commit('set_state', { key, value: NO_STATUS });
  }
  if (typeof state.state[key] === 'undefined') {
    return Promise.reject(new Error(`This state ${keyList} has not setted.`));
  }
  if (state.state[key] === RE_TRY) {
    return Promise.resolve([]);
  }
  if (state.state[key] === DONE) {
    return Promise.resolve(getters.list(key));
  }
  if (state.state[key] === IN_PROGRESS) {
    commit('set_state', { key, value: RE_TRY });
    return new Promise(resolve => setTimeout(() => resolve(dispatch(key)), DELAY));
  }
  commit('set_state', { key, value: IN_PROGRESS });
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint)
      .then((response) => {
        if (typeof response === 'undefined') {
          commit('set_state', { key, value: ERROR });
          throw new TypeError(`${endpoint} empty`);
        }
        const data = response.data.result || response.data || [];

        commit('set_history', { key, value: endpoint });
        commit('set_state', { key, value: DONE });
        commit('set_data', { key: keyList, value: data });
        commit('set_data', {
          key: keyListReduced,
          value: data.reduce((result, currentRow) => {
            let keyRow = currentRow.id;
            if (typeof currentRow.id === 'undefined') {
              keyRow = currentRow.key;
            }
            // eslint-disable-next-line no-param-reassign
            result[keyRow] = currentRow;
            return result;
          }, {}),
        });
        return resolve(data);
      })
      .catch((error) => {
        commit('set_state', { key, value: ERROR });
        return reject(error);
      });
  });
}
