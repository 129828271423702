<template>
  <v-flex class="d-flex justify-center" :class="dropActiveClass()">
    <div v-show="$refs.upload" class="drop-active">
      <h3>{{ label }} <material-markdown :src="markdown"></material-markdown></h3>
      <div v-for="file in files" :key="`${file.name}-${file.version}`">
        <v-alert type="success" v-if="!file.success && !file.error" dense text>
          {{ file.name }} ({{ file.progress }} %)
        </v-alert>
        <v-alert type="success" v-else-if="file.success" dense text>
          {{ file.name }}
        </v-alert>
        <v-alert type="error" v-else-if="file.error" dense text>
          {{ getMessageError(file) }}
        </v-alert>
      </div>
    </div>
    <file-upload
      class="btn btn-primary dropdown-toggle"
      :post-action="postAction"
      :extensions="extensions"
      :accept="accept"
      :multiple="multiple"
      :thread="thread < 1 ? 1 : thread > 5 ? 5 : thread"
      :headers="headers"
      :data="data"
      :drop="drop"
      v-model="files"
      @input-filter="inputFilter"
      @input-file="inputFile"
      ref="upload"
    >
      <v-btn color="blue" fab dark justify-center class="mx-2">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </file-upload>
  </v-flex>
</template>

<script>
/* eslint no-param-reassign: 0 */
import FileUpload from 'vue-upload-component';
import Mixins from '../../mixins';

export default {
  name: 'ImportUpload',
  components: {
    FileUpload,
  },
  mixins: Mixins,
  props: {
    label: {
      type: String, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: 'Drop here your csv, xls files',
    },
    postAction: {
      type: String,
      required: true,
    },
    postData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data: () => ({
    files: [],
    accept: 'text/plain',
    extensions: 'csv,txt,xls,xlsx',
    multiple: false,
    drop: true,
    thread: 1,
    markdown: 'sheetsupload.md',
    displayButton: false,
  }),
  mounted() {
    this.$store.commit('import/set_edit', {});
  },
  computed: {
    headers() {
      return {
        Authorization: `Bearer ${this.$auth.accessToken}`,
      };
    },
    data() {
      if (Object.keys(this.postData).length > 0) {
        return this.postData;
      }
      return {};
    },
  },
  methods: {
    dropActiveClass() {
      if (this.$refs.upload && this.$refs.upload.dropActive) {
        return 'blue lighten-4 elevation-24';
      }
      return '';
    },
    // eslint-disable-next-line consistent-return
    inputFilter: function inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        // Will not be added to files
        if (!/\.(csv|txt|xls|xlsx)$/i.test(newFile.name)) {
          this.notificationError({ message: 'The file format is not good' });
          return prevent();
        }
      }

      if (newFile && oldFile) {
        // Update file
        // Increase the version number
        if (!newFile.version) {
          newFile.version = 0;
        }
        newFile.version += 1;
      }

      if (!newFile && oldFile) {
        // Remove file

        // Refused to remove the file
        return prevent();
      }
    },
    /**
     * Has changed
     * @param  Object|undefined   newFile   Read only
     * @param  Object|undefined   oldFile   Read only
     * @return undefined
     */
    inputFile: function inputFile(newFile, oldFile) {
      // eslint-disable-line consistent-return
      // Automatically activate upload
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (!this.$refs.upload.active) {
          this.$emit('upload', true);
          this.$refs.upload.active = true;
        }
      }
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        this.$emit('upload', false);
        // Get update / response data
        if (newFile.xhr && newFile.xhr.status === 201) {
          this.$emit('create:upload', newFile.response);
        } else if (newFile.xhr && newFile.xhr.status === 200) {
          this.$emit('update:upload', newFile.response);
        } else {
          let errorResponse = 'XHR  has failed';
          try {
            errorResponse = JSON.parse(newFile.xhr.response).message;
          } catch (err) {} // eslint-disable-line no-empty
          this.$store.commit('import/set_edit_reset');
          this.$log.error(errorResponse);
          this.notificationError({ message: errorResponse });
        }
      }
    },
    getMessageError(file) {
      if (typeof file.response.error !== 'undefined') {
        if (
          file.response.error === 'Unauthorized'
          && this.$te(`error.api.common.${file.response.error.toLowerCase()}`)
        ) {
          return this.$t(`error.api.common.${file.response.error.toLowerCase()}`);
        }
        if (
          typeof file.response.message !== 'undefined'
          && this.$te(`error.${file.response.message}`)
        ) {
          return this.$t(`error.${file.response.message}`);
        }
        return file.response.message;
      }
      return `Error on ${file.name} ${file.error}`;
    },
  },
};
</script>

<style lang="css" scoped></style>
