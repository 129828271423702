export default {
  props: {
    productType: {
      type: String, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: 'product',
    },
    graphType: {
      type: String,
      required: false,
      default: 'Bar',
      validator: v => ['Bar', 'Line', 'Pie'].includes(v),
    },
    chartType: {
      type: [String, Object],
      required: false,
      default: null,
      validator: v => [null, 'week', 'month', 'hours', 'hours1to24', 'hours12to9', 'year'].includes(v),
    },
    period: {
      type: String, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: 'month',
      validator: v => ['full', 'month', 'year', 'day', 'week', 'hour'].includes(v),
    },
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
  },
  data: () => ({
    data: {
      labels: [],
      series: [[]],
    },
    title: {
      product: 'Products',
      variant: 'Variants',
      reference: 'Skus',
    },
    options: {},
  }),
  watch: {
    // https://stackoverflow.com/questions/44584292/how-to-listen-for-props-changes
    // anytime any props would change. the key point is to use deep: true so it not
    // only watches $props but also it's nested values
    $props: {
      handler() {
        this.hookForChartist();
      },
      deep: true,
      immediate: true,
    },
  },
  beforeDestroy() {
    this.options = {};
    this.data = {
      labels: [],
      series: [[]],
    };
  },
  methods: {
    /**
     * hook to updata chartist
     * @return {[type]} [description]
     *
     * @note this hook is not called when charttype change
     *       need to reload the page
     */
    hookForChartist() {
      this.$log.info('mixins.chart-base.hookforchartist.started');
    },
  },
};
