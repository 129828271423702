export default {
  data: () => ({
    showProgressBar: false,
    progressPercent: 0,
    camembert: null,
  }),
  computed: {
    // progreess bar for user
    progressing: {
      get() {
        return this.progressPercent;
      },
      set(value) {
        const total = this.items.length;
        this.progressPercent = Math.round((100 * parseInt(value, 10)) / total);
      },
    },
  },
  methods: {
    progressingByTimer(timer, timeFor1item) {
      const total = this.items.length;
      return Math.round((100 * parseInt(timer, 10)) / (total * timeFor1item));
    },
    /**
     * [showProgressCamembert description]
     * @param  {Number} timeFor1item time in millisecond
     * @param  {Boolean} simulation    true|false
     * @return {[type]}              [description]
     */
    showProgressCamembert(timeFor1item = 8, simulation = true) {
      this.progressPercent = 0;
      this.showProgressBar = true;
      if (!simulation) {
        return;
      }
      const period = 100;
      let timer = 0;
      // Called the function in each second
      this.camembert = setInterval(() => {
        timer += period;
        let progressPercent = this.progressingByTimer(timer, timeFor1item);
        if (progressPercent > 100) {
          progressPercent = 100;
        }
        this.progressPercent = progressPercent;
        if (this.progressPercent > 100) {
          clearInterval(this.camembert); // If exceeded 100, clear interval
        }
      }, period);
    },
    closeProgressCamember() {
      this.showProgressBar = false;
      clearInterval(this.camembert);
    },
  },
};
