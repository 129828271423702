export default {
  methods: {
    throwError(alert) {
      this.$store.commit('error/set_error', alert);
      this.notificationError({ message: alert.message });
    },
    throwWarning(alert) {
      this.$store.commit('error/set_warning', alert);
    },
    throwSuccess(alert) {
      this.$store.commit('error/set_success', alert);
    },
    throwInfo(alert) {
      this.$store.commit('error/set_info', alert);
    },
  },
};
