// https://vuex.vuejs.org/en/state.html
import settingsService, { settingsDefault } from '@/services/settingsstorage';
import theagentService from '@/services/theagentstorage';
import * as headers from '@/helpers/headers';

export default {
  settings: settingsService.get(),
  profile: settingsService.get().profile,
  profiles: [],
  theagent: theagentService.get(),
  settingsDefault,
  headers: Object.keys(headers).reduce((result, keyHeader) => {
    // eslint-disable-next-line no-param-reassign
    result[keyHeader.replace('Headers', '')] = headers[keyHeader];
    return result;
  }, {}),
  component: {},
};
