<template>
  <v-checkbox v-model="valueAttribute"
  :label="label"
  :loading="isCompletudeRequired(bindAttribute, currentIssues)"></v-checkbox>
</template>
<script>
import AttributeBaseMixins from '../../mixins/attribute-base';
import CompletudeBaseMixins from '../../mixins/completude-base';

export default {
  mixins: [AttributeBaseMixins, CompletudeBaseMixins],
  name: 'AttributeCheckbox',
  data: () => ({}),
};
</script>
