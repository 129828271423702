<template>
  <v-row>
    <v-col cols="6">
      <v-text-field
        v-model="numberValue"
        :label="getLabel(label || property)"
        class="vuetify-editor"
        :rules="decimalRules"
        @blur="focus = false"
        @focus="focus = true"
        auto-grow
        :hint="attribute.getHelp()"
        persistent-hint
        suffix="cm"
      >
      <template v-slot:label>
        <slot name="label"></slot>
      </template>
    </v-text-field>
    </v-col>
    <v-col cols="1">
     en taille
    </v-col>
    <v-col cols="5">
      <v-text-field
        v-model="sizeValue"
        :label="otherLabel"
      >
      </v-text-field>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
import AttributeBaseMixins from '@/mixins/attribute-base';
import LangBaseMixins from '@/mixins/lang-base';
import { mask, unmask } from '@/utils/number';

export default {
  mixins: [LangBaseMixins, AttributeBaseMixins],
  name: 'attributesDefault',
  data: () => ({
    focus: false,
  }),
  computed: {
    ...mapGetters('form/rules', {
      decimalRules: 'decimal',
    }),
    otherLabel() {
      return `Taille pour les mesures (${this.attribute.getLabel()})`;
    },
    numberValue: {
      get() {
        if (!this.valueAttribute) return '';
        const values = this.valueAttribute.split(' ');
        if (values.length > 2) {
          this.$log.error('components.attribute.number_size.numbervalue.too.many.values');
        }
        return mask(values[0], 2, this.focus);
      },
      set(value) {
        // actually space is used, but in the futur use json data value
        this.valueAttribute = `${unmask(value)} ${this.sizeValue}`;
      },
    },
    sizeValue: {
      get() {
        if (!this.valueAttribute) return '';
        const values = this.valueAttribute.split(' ');
        if (values.length > 2) {
          this.$log.error('components.attribute.number_size.sizevalue.too.many.values');
        }
        return values[1] || '';
      },
      set(value) {
        // actually space is used, but in the futur use json data value
        if (value) {
          this.valueAttribute = `${this.numberValue} ${value}`;
        } else {
          this.valueAttribute = this.numberValue;
        }
      },
    },
  },
};
</script>
