import Vue from 'vue';
import Log from '@/models/utils/log';
import Core from './core';
import { CoreError } from './exception';

const SKU_PATTERN = 'TA-{id}-{color}-{pattern}-{size}';
const REMOTE_CODE_PATTERN = 'pim.rct.{id}.{color}.{pattern}.{size}';
const DEFAULT_COLOR = 'default';

/**
 * Check if pattern need to be return
 *
 * @param  {String} pattern
 * @param  {String} color
 * @return {String}
 *
 * @todo this code need to be refactored with model variant
 *       and vue dashboard
 */
const diffPattern = (pattern, color) => {
  if (!pattern) {
    return '';
  }
  const tmpPattern = pattern.slugify().replace('-', '');
  const tmpColor = color.slugify().replace('-', '');
  // no need to add pattern if it s the same of color
  if (!tmpPattern || tmpPattern === tmpColor) {
    return '';
  }
  return tmpPattern.replace(tmpColor, '');
};

export default class Sku extends Core {
  constructor(data, parent = null) {
    super(data, parent);
    // this is a virtual datatype
    // it s really important to send the color each time from the parent instance
    // @see theagent-pim:node_modules/theagent-pim/models/reference.js
    this.color = Sku.getParentValue(parent, 'color');
    this.pattern = Sku.getParentValue(parent, 'pattern');
    this.gender = parent && typeof parent.attributes_temp.att_genre_rc !== 'undefined'
      ? parent.attributes_temp.att_genre_rc
      : null;
    this.classificationId = Sku.getParentValue(parent, 'classificationId');
    this.variantLegacyId = Sku.getParentValue(parent, 'legacyId');
    this.supplierReference = Sku.getParentValue(parent, 'supplierReference');
  }

  /**
   * [setName description]
   * @param {String} name  product name
   * @param {String} color color
   * @param {String|Number} size
   *
   * @see theagent-pim-core:models/variant.js:Variant.getname()
   */
  static setName(name, color, size) {
    if (typeof size === 'number') {
      size = size.toString(); // eslint-disable-line no-param-reassign
    }
    return `${name.toUpperCase()} ${color.toUpperCase()} ${size.toUpperCase()}`;
  }

  /**
   * [setSku description]
   * @param {Object} skuObj sku model instance
   *
   * @see theanget-pim:models/reference.js:getSku
   */
  static setSku(skuObj) {
    if (typeof skuObj !== 'object' || Object.keys(skuObj).length === 0) {
      throw new CoreError('models.pim.sku.setsku.object.invalid');
    }

    // if propety sealed didnt exist
    // if the sku instance is not sealed, we can change sku name
    if (typeof skuObj.sealed !== 'undefined' && skuObj.sealed === true) {
      return skuObj.sku;
    }
    // return null because in @see theagent-node-pim:models/reference.js
    // this part is essential to generate the good sku
    if (!skuObj.productId) {
      return null;
    }
    if (!skuObj.productId || !skuObj.color || !skuObj.size) {
      throw new CoreError('models.pim.sku.setsku.arguments.invalid');
    }
    let { size } = skuObj;
    if (typeof skuObj.size === 'number') {
      size = skuObj.size.toString(); // eslint-disable-line no-param-reassign
    }
    // models/reference.js:getSku
    return Sku.getSku(skuObj.productId, skuObj.color, size, skuObj.pattern);
  }

  static getSku(productId = '000000', color, size, pattern = false) {
    if (!productId) return null;
    // eslint-disable-next-line no-param-reassign
    color = color.colorize() || DEFAULT_COLOR;
    // eslint-disable-next-line no-param-reassign
    pattern = pattern ? diffPattern(pattern, color) : '';
    return (
      SKU_PATTERN.replace('{id}', productId)
        // let's remove all unwilling caracters like numeric value
        .replace('{color}', color.toUpperCase())
        .replace('-{pattern}', pattern ? `-${pattern.toUpperCase()}` : '')
        .replace('{size}', size.slugify().toUpperCase())
    );
  }

  /**
   * [setRemoteCode description]
   * @param {Object} skuObj sku model instance
   *
   * * @see theanget-pim:models/reference.js:getRemoteCode
   */
  static setRemoteCode(skuObj) {
    if (typeof skuObj !== 'object' || Object.keys(skuObj).length === 0) {
      throw new CoreError('models.pim.sku.setremotecode.object.invalid');
    }
    if (!skuObj.productId) {
      return Sku.generateRandomRemoteCode();
    }
    if (!skuObj.color || !skuObj.size) {
      throw new CoreError('models.pim.sku.setremotecode.arguments.invalid');
    }
    let { size } = skuObj;
    if (typeof skuObj.size === 'number') {
      size = skuObj.size.toString(); // eslint-disable-line no-param-reassign
    }
    return (
      REMOTE_CODE_PATTERN.replace('{id}', skuObj.productId)
        // let's remove all unwilling caracters like numeric value
        .replace('{color}', skuObj.color.colorize())
        .replace('.{pattern}', skuObj.pattern ? `.${skuObj.pattern.slugify()}` : '')
        .replace('{size}', size.toUpperCase())
    );
  }

  setRemoteCode(size) {
    if (!size) {
      throw new CoreError('models.pim.sku.setremotecode.arguments.size.invalid');
    }
    if (typeof size === 'number') {
      size = size.toString(); // eslint-disable-line no-param-reassign
    }
    return (
      REMOTE_CODE_PATTERN.replace('{id}', this.productId)
        // let's remove all unwilling caracters like numeric value
        .replace('{color}', this.color.colorize())
        .replace('.{pattern}', this.pattern ? `.${this.pattern.slugify()}` : '')
        .replace('{size}', size.toUpperCase())
    );
  }

  /**
   * convert instance in plain object
   * @return {Object}
   */
  toObject() {
    try {
      const rootSku = this.clone();
      rootSku.parent = null;
      delete rootSku.parent;
      return JSON.parse(JSON.stringify(Object.seal(rootSku)));
    } catch (e) {
      Log.error(e);
      return {};
    }
  }

  reset() {
    this.id = null;
    this.legacyId = null;
    this.ean = null;
  }

  static generateRandomRemoteCode() {
    return `temp.rct.${Math.floor(Math.random() * 99999999) + 1}`;
  }

  generateRandomRemoteCode() {
    this.remoteCode = Sku.generateRandomRemoteCode();
  }

  copy() {
    const reference = { ...this };
    delete reference.skus;
    delete reference.image;
    delete reference.parent;
    delete reference.parentError;
    delete reference.errors;
    delete reference.errorsOriginal;
    return reference;
  }

  async getCompletude(dispatch) {
    if (this.percent && ['0', '0.00', '0,00', 0].indexOf(this.percent) === -1) {
      return this.percent;
    }
    const reference = this.copy();
    return dispatch(
      'completude/calc',
      {
        endpoint: 'reference',
        data: reference,
      },
      { root: true },
    ).then((completude) => {
      Vue.set(this, 'percent', completude);
      return this;
    });
  }

  async setCompletude(dispatch) {
    const reference = this.copy();
    return dispatch(
      'completude/update',
      {
        endpoint: 'reference',
        data: reference,
      },
      { root: true },
    ).then((completude) => {
      Vue.set(this, 'percent', completude);
      return this;
    });
  }

  static createDefaultInstance(variant, size = false) {
    if (typeof variant.productId === 'undefined' || !variant.productId) {
      throw new CoreError('models.pim.sku.createdefaultinstance.productid.missing');
    }
    if (typeof variant.id === 'undefined' || !variant.id) {
      throw new CoreError('models.pim.sku.createdefaultinstance.variantid.missing');
    }
    if (typeof variant.color === 'undefined' || !variant.color) {
      throw new CoreError('models.pim.sku.createdefaultinstance.color.missing');
    }
    const defaultSize = size || 'LOREM';
    return Sku.create({
      parent: variant,
      productId: variant.productId,
      supplierReference: variant.supplierReference || '',
      variantId: variant.id,
      name: `${variant.name} [newsize]`,
      sku: Sku.getSku(variant.productId, variant.color, defaultSize).replace(
        '-LOREM',
        '-[newsize]',
      ),
      size: size || '',
      price: '0.00',
      retailPrice: '0.00',
      ean: '0000000000000',
    });
  }
}
