<template>
  <v-container>
    <v-autocomplete
      v-model="select"
      :search-input.sync="search"
      :loading="loading"
      :items="items"
      item-text="name"
      item-value="id"
      filled
      chips
      cache-items
      hide-no-data
      hide-details
      solo-inverted
      label="Search with your sku name?"
    >
      <template v-slot:selection="{ attrs, select, selected, item }">
        <v-chip v-bind="attrs" :input-value="selected" close @click="select" @click:close="remove">
          {{ item.name }}
        </v-chip>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-avatar>
          <img :src="$pimUrl(item.image, 'sq60')" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="item.name"></v-list-item-title>
          <v-list-item-subtitle>Size : {{ item.size }}</v-list-item-subtitle>
          <v-list-item-subtitle>ean : {{ item.ean }}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { EventBus } from '@/helpers/event-bus';

export default {
  name: 'AttributeVariant',
  data: () => ({
    loading: false,
    search: null,
    select: null,
    itemsPerPage: 10,
  }),
  computed: {
    items() {
      return this.$store.getters['sku/skus'];
    },
  },
  watch: {
    search(val) {
      if (!val || val.length <= 3) return null;
      if (val && this.select && val.trim() === this.select.trim()) return null;
      if (this.loading) return null;
      return this.querySelections();
    },
    select() {
      EventBus.$emit('list-options:id', this.select);
      this.$emit('input', this.select);
    },
  },
  methods: {
    querySelections() {
      Vue.$log.info('store.sku.actions.search.started', this.search);
      this.loading = true;
      setTimeout(() => {
        const params = {
          params: {
            search: this.search ? this.search.trim() : '',
            limit: parseInt(this.itemsPerPage, 10),
            page: 1,
          },
        };
        return this.$store
          .dispatch('sku/search', params)
          .catch(error => this.throwError(error))
          .finally(() => {
            this.loading = false;
          });
      }, 2000);
    },
    remove() {
      this.select = null;
    },
  },
};
</script>

<style lang="css" scoped></style>
