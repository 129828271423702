// https://vuex.vuejs.org/en/actions.html
/* eslint func-names:0 */

import Vue from 'vue';
import { workflow } from 'theagent-status';

import getFromApi from './helpers';

const { NO_STATUS } = workflow;
const BASE_URI = '/api/v1';

export default {
  brand(store, activeOnly = true) {
    const debug = store.rootGetters['settings/theagent'].sillyMode;
    if (debug) Vue.$log.debug('store.api.actions.brand.started');
    return getFromApi(
      `${BASE_URI}/pim/brand?limit=10000${activeOnly ? '' : '&active=0'}`,
      'brand',
      store,
    ).catch(e => (debug ? Vue.$log.warn(e.message) : ''));
  },
  classification(store) {
    const debug = store.rootGetters['settings/theagent'].sillyMode;
    if (debug) Vue.$log.debug('store.api.actions.classification.started');
    return getFromApi(
      `${BASE_URI}/pim/classification?limit=1000`,
      'classification',
      store,
    ).catch(e => (debug ? Vue.$log.warn(e.message) : ''));
  },
  classificationTree(store) {
    const debug = store.rootGetters['settings/theagent'].sillyMode;
    if (debug) Vue.$log.debug('store.api.actions.classificationtree.started');
    return getFromApi(
      `${BASE_URI}/pim/classification?limit=1000&mode=tree`,
      'classificationTree',
      store,
    ).catch(e => (debug ? Vue.$log.warn(e.message) : ''));
  },
  family(store) {
    const debug = store.rootGetters['settings/theagent'].sillyMode;
    if (debug) Vue.$log.debug('store.api.actions.family.started');
    return getFromApi(`${BASE_URI}/pim/family?limit=1000`, 'family', store).catch(e => (debug ? Vue.$log.warn(e.message) : ''));
  },
  distributor(store, activeOnly = true) {
    const debug = store.rootGetters['settings/theagent'].sillyMode;
    if (debug) Vue.$log.debug('store.api.actions.distributor.started');
    return getFromApi(
      `${BASE_URI}/pim/distributor?limit=10000${activeOnly ? '' : '&active=0'}`,
      'distributor',
      store,
    ).catch(e => (debug ? Vue.$log.warn(e.message) : ''));
  },
  size(store) {
    const debug = store.rootGetters['settings/theagent'].sillyMode;
    if (debug) Vue.$log.debug('store.api.actions.size.started');
    return getFromApi(`${BASE_URI}/pim/size?limit=10000`, 'size', store).catch(e => (debug ? Vue.$log.warn(e.message) : ''));
  },
  reset({ commit }, key) {
    const keyList = `${key}List`;
    const keyListReduced = `${key}ListKey`;
    commit('set_state', { key, value: NO_STATUS });
    commit('set_data', { key: keyList, value: [] });
    commit('set_data', { key: keyListReduced, value: [] });
  },
  'marketplace.brand': function (store) {
    const debug = store.rootGetters['settings/theagent'].sillyMode;
    if (debug) Vue.$log.debug('store.api.actions.brand.started');
    return getFromApi(
      `${BASE_URI}/marketplace/brand?limit=10000`,
      'marketplace.brand',
      store,
    ).catch(e => (debug ? Vue.$log.warn(e.message) : ''));
  },
  'marketplace.supplier': function (store) {
    const debug = store.rootGetters['settings/theagent'].sillyMode;
    if (debug) Vue.$log.debug('store.api.actions.supplier.started');
    return getFromApi(
      `${BASE_URI}/marketplace/supplier?limit=10000`,
      'marketplace.supplier',
      store,
    ).catch(e => (debug ? Vue.$log.warn(e.message) : ''));
  },
  'marketplace.reference': function (store) {
    const debug = store.rootGetters['settings/theagent'].sillyMode;
    if (debug) Vue.$log.debug('store.api.actions.reference.started');
    return getFromApi(
      `${BASE_URI}/marketplace/reference?limit=100`,
      'marketplace.reference',
      store,
    ).catch(e => (debug ? Vue.$log.warn(e.message) : ''));
  },
  'marketplace.variant': function (store, payload) {
    const debug = store.rootGetters['settings/theagent'].sillyMode;
    if (debug) Vue.$log.debug('store.api.actions.variant.started');
    return getFromApi(
      `${BASE_URI}/marketplace/variant?limit=${payload.limit}&page=${payload.page}`,
      'marketplace.variant',
      store,
    ).catch(e => (debug ? Vue.$log.warn(e.message) : ''));
  },
  'brand.data': function (store, brandId) {
    const debug = store.rootGetters['settings/theagent'].sillyMode;
    if (debug) Vue.$log.debug('store.api.actions.brand.data.started');
    return getFromApi(`${BASE_URI}/pim/brand/${brandId}/data`, 'brand.data', store).catch(e => (debug ? Vue.$log.warn(e.message) : ''));
  },
  channel(store) {
    const debug = store.rootGetters['settings/theagent'].sillyMode;
    if (debug) Vue.$log.debug('store.api.actions.channel.started');
    return getFromApi(`${BASE_URI}/pim/channel?limit=10000`, 'channel', store).catch(e => (debug ? Vue.$log.warn(e.message) : ''));
  },
};
