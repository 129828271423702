<template>
  <v-dialog
    v-model="dialog"
    persistent
    top
    left
    min-width="900"
    max-width="100vh"
    max-height="90vh"
    offset-x
    transition="slide-y-transition"
  >
    <!-- https://vuetifyjs.com/en/components/menus/#activator-and-tooltip -->
    <template v-slot:activator="{ on: openDialog }">
      <v-tooltip left>
        <template v-slot:activator="{ on: tooltip, attrs }">
          <v-btn
            color="pink"
            fab
            dark
            absolute
            right
            fixed
            class="mx-2"
            style="bottom: 280px !important;"
            v-bind="attrs"
            v-on="{ ...tooltip, ...openDialog }"
          >
            <v-icon dark>mdi-table-column-plus-after</v-icon>
          </v-btn>
        </template>
        <span>Mass edition by column</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-form v-model="isValid">
        <v-select
          v-model="columnSelected"
          :items="filterByFields()"
          menu-props="auto"
          hide-details
          item-text="text"
          item-value="value"
          label="Select your field"
        ></v-select>
        <core-attribute
          v-if="columnSelected"
          :csv="true"
          :property="columnSelected"
          :item="items[0]"
          item-value="name"
          filled
        ></core-attribute>
        <v-spacer />
        <v-card-actions>
          <v-btn color="grey darken-1" text outlined @click="close">Cancel</v-btn>
          <v-btn color="blue darken-1" dark :disabled="!isValid" @click="save">Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import ChipBaseMixins from '../../mixins/chip-base';
import ConfigBaseMixins from '../../mixins/config-base';

export default {
  mixins: [ConfigBaseMixins, ChipBaseMixins],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    dialog: false,
    columnSelected: false,
    isValid: true,
  }),
  computed: {
    ...mapGetters('form/rules', { textRules: 'text' }),
    headers() {
      return this.$store.getters['settings/headers'].csv;
    },
  },
  methods: {
    filterByFields() {
      const excluded = ['THEAGENT', 'Errors', 'Actions'];
      return this.headers.filter(header => excluded.indexOf(header.text) === -1);
    },
    save() {
      // eslint-disable-next-line array-callback-return
      this.items.forEach((item) => {
        if (typeof item[this.columnSelected] === 'undefined') {
          throw new TypeError(`Error on ${this.columnSelected}`);
        }
        // eslint-disable-next-line no-param-reassign
        item[this.columnSelected] = this.items[0][this.columnSelected];
      });
      this.dialog = false;
      return this.$emit('onSave');
    },
    close() {
      this.dialog = false;
      return this.$emit('onClose');
    },
  },
};
</script>

<style></style>
