<template>
  <v-container fill-height fluid>
    <v-row>
      <v-col cols="12">
        <dashboard-stats :stats="stats"></dashboard-stats>
      </v-col>
      <v-col cols="12" :lg="`${!isChecking || '9'}`">
        <pim-stepper-view :steps="steps" @onRollBack="rollback"></pim-stepper-view>
      </v-col>
      <v-col cols="12" lg="3" v-if="isChecking">
        <common-sheets-upload
          label="Re upload your CDV files (csv, xls, xslx)"
          :post-action="`${baseURL}/api/v1/import/csv/${importObj.id}`"
          v-on:update:upload="listProductFromImport()"
          v-on:upload="loader"
        >
        </common-sheets-upload>
      </v-col>
    </v-row>
    <v-flex md12 style="min-height: 658px">
      <v-toolbar flat>
        <v-flex xs12>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-flex>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn color="blue" rounded @click="openPanel(1)">
          <v-icon>mdi-package</v-icon>
          Files
        </v-btn>
        <v-btn rounded small text @click="openPanel(2)">
          <chips-stats
            :count="stats.errors"
            label="error"
            color="red"
            style="cursor: pointer;"
            :statIcon="{ type: 'icon', value: 'mdi-cancel' }"
          >
          </chips-stats>
        </v-btn>
        <helper-menu-filter v-model="mode"> </helper-menu-filter>
      </v-toolbar>
      <pim-list-files v-if="importedfiles.length > 0 && panelOpened === 1" v-model="importedfiles">
      </pim-list-files>
      <pim-list-errors v-if="panelOpened === 2" v-model="items"> </pim-list-errors>
      <core-alert></core-alert>
      <template v-if="mode === 0">
        <section>
          <v-data-table
            v-model="productSelected"
            :headers="filterByColumn()"
            class="elevation-2 ta-fix-column ta-product ta-table-pim"
            item-key="name"
            :item-class="checkError"
            :items="itemsRows"
            :items-per-page="itemsCount"
            :options.sync="itemsOptions"
            :loading="loading"
            loading-text="Loading... Please wait"
            :show-expand="settings.showExpand"
            :single-expand="settings.singleExpand"
            :dense="settings.dense"
            :search="search"
            hide-default-footer
            fixed-header
            :show-select="settings.showSelect"
            multi-sort
            :custom-filter="defaultFilter"
          >
            <template v-slot:item.data-table-expand="{ expand, isExpanded }">
              <div class="text-center ta-expand-icon">
                <v-btn fab x-small class="ta-product" @click="expand(!isExpanded)">R</v-btn>
                <v-icon v-if="isExpanded" @click="expand(!isExpanded)">mdi-chevron-up</v-icon>
                <v-icon v-else @click="expand(!isExpanded)">mdi-chevron-down</v-icon>
              </div>
            </template>
            <template v-for="head in filteredFields()" v-slot:[`header.${head.value}`]="{ header }">
              <material-table-header-title
                :key="head.value"
                v-model="errorHeaders[header.value].close"
                :header="errorHeaders[header.value]"
                v-on:header:filtered="errorsSelected($event)"
                v-on:header:unfiltered="resetErrorsSelected($event)"
              ></material-table-header-title>
            </template>
            <template v-for="head in filteredFields()" v-slot:[`item.${head.value}`]="{ item }">
              <div :key="head.value">
                <core-dialog v-model="item" :header="head" :saveOnApi="saveOnApi"> </core-dialog>
              </div>
            </template>
            <template v-slot:item.image="{ item }">
              <core-avatar
                v-if="typeof item.image !== 'undefined' || item.image !== null"
                :image="$pimUrl(item.image, 'sq60')"
              />
            </template>
            <template v-slot:item.id="{ item }">
              <dialog-noedit :item="item" property="id"> </dialog-noedit>
            </template>
            <template v-slot:item.distributorIds="{ item }">
              <dialog-distributor
                :item="item"
                property="distributorIds"
                labelName="Choose your supplier"
                :saveOnApi="saveOnApi"
              >
              </dialog-distributor>
            </template>
            <template v-slot:item.marketplace="{ item }">
              <v-chip :color="getColor(item.marketplace, 'marketplace')" dark>
                {{ getMessage(item.marketplace, "marketplace") }}
                <template v-if="item.marketplace === doneStatus && item.syncUpdatedAt">
                  <br />({{ item.syncUpdatedAt }})
                </template>
              </v-chip>
            </template>
            <template v-slot:item.quable="{ item }">
              <v-chip :color="getColor(item.quable)" dark>
                {{ getMessage(item.quable) }}
              </v-chip>
            </template>
            <template v-slot:item.active="{ item }">
              <v-switch v-model="item.active" @change="active(item)"></v-switch>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <pim-variant-view
                  :items="item.variant"
                  v-if="item.variant"
                  :saveOnApi="saveOnApi"
                  endpointType="variant"
                  no-edition
                  :show-select="settings.showSelect"
                ></pim-variant-view>
              </td>
            </template>
            <template v-slot:item.percent="{ item }">
              <material-percent :percent="item.percent" color="red"></material-percent>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil-circle
              </v-icon>
              <v-icon small @click="deleteItem(item)">
                mdi-delete-circle
              </v-icon>
            </template>
          </v-data-table>
        </section>
      </template>
      <template v-else-if="mode === 1">
        <pim-variant-view
          :items="itemsVariant"
          v-if="itemsVariant"
          :saveOnApi="saveOnApi"
          endpointType="variant"
          :show-select="settings.showSelect"
          :search="search"
        ></pim-variant-view>
      </template>
      <template v-else-if="mode === 2">
        <pim-skus-view
          :items="itemsSku"
          v-if="itemsSku"
          :saveOnApi="saveOnApi"
          :show-select="settings.showSelect"
          endpointType="sku"
          :search="search"
        ></pim-skus-view>
      </template>
      <div v-if="pageLoaded">
        <div class="text-center fix-on-function-btn" v-if="showProgressBar">
          <v-progress-circular
            :rotate="-90"
            :size="80"
            :width="20"
            :value="progressing"
            color="teal"
          >
            {{ progressing }}
          </v-progress-circular>
        </div>
        <v-tooltip left v-if="hasRightActions">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="currentFunction.color"
              fab
              dark
              absolute
              right
              fixed
              v-bind="attrs"
              v-on="on"
              class="mx-2"
              style="bottom: 420px !important;"
              :disabled="btnFunctionStepped"
              @click="functionStepped"
            >
              <v-icon dark>{{ currentFunction.icon }}</v-icon>
            </v-btn>
          </template>
          <template>
            <span v-if="isLastStep">To finish your importation</span>
            <span v-else>Next step: {{ titleStepped }}</span>
          </template>
        </v-tooltip>
        <v-tooltip left v-if="hasRight('dashboardapp.v1:button:resetimport:read')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="gray"
              fab
              dark
              absolute
              right
              fixed
              v-bind="attrs"
              v-on="on"
              class="mx-2"
              style="bottom: 360px !important;"
              :disabled="btnResetImport"
              @click="resetImport"
            >
              <v-icon dark>mdi-rotate-left</v-icon>
            </v-btn>
          </template>
          <span>Reset workflow</span>
        </v-tooltip>
        <dialog-product
          v-model="editedItem"
          :dialog="dialog"
          v-if="hasRightActions"
          v-on:update:dialog="setDialog($event)"
          :saveOnApi="saveOnApi"
          @onSave="saveItem"
          @onClose="closeItem"
        >
        </dialog-product>
        <v-tooltip left v-if="isExportCdv">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="purple"
              fab
              dark
              absolute
              right
              fixed
              v-bind="attrs"
              v-on="on"
              class="mx-2"
              style="bottom: 200px !important;"
              @click="exportCdv"
            >
              <v-icon dark>mdi-set-merge</v-icon>
              CDV
            </v-btn>
          </template>
          <span>Export to CDV file</span>
        </v-tooltip>
        <dialog-settings> </dialog-settings>
      </div>
    </v-flex>
  </v-container>
</template>

<script>
/* eslint no-plusplus:0 */
/* eslint no-param-reassign:0 */

import Vue from 'vue';
import { mapActions } from 'vuex';
import { map } from 'bluebird';
import { marketplace } from 'theagent-status';
import { Product, Variant } from '@/models';
import {
  pimFunctions,
  STEPPER_CONVERT_PIM,
  STEPPER_GENERATE_PIM,
  STEPPER_VALID_IMPORT,
  STEPPER_START,
} from '@/helpers/steps';
import Mixins from '@/mixins';
import ItemsBaseMixins from '@/mixins/items-base';
import ProductBaseMixins from '@/mixins/product-base';
import StepBaseMixins from '@/mixins/step-base';
import SyncedBaseMixins from '@/mixins/synced-base';
import EditMixins from '@/mixins/edit-base';
import ProfileMixins from '@/mixins/profile-base';
import ProgressMixins from '@/mixins/progress-base';
import ErrorMixins from '@/mixins/error-base';
import { EventBus } from '@/helpers/event-bus';

const sleep = ms => new Promise(r => setTimeout(r, ms));

export default {
  mixins: [
    ItemsBaseMixins,
    StepBaseMixins,
    SyncedBaseMixins,
    EditMixins,
    ProfileMixins,
    ProgressMixins,
    ProductBaseMixins,
    ErrorMixins,
    ...Mixins,
  ],
  data: () => ({
    itemsPerPage: 10,
    pageLoaded: false,
    btnFunctionStepped: false,
    btnResetImport: false,
    panelOpened: 0,
    productSelected: [],
    itemsCount: 0,
    itemsOptions: {},
    editedItem: Product.create({}),
    loading: true,
    saveOnApi: false,
    defaultStep: STEPPER_CONVERT_PIM,
    functionsStepped: pimFunctions,
    defaultFunctionIcon: 'mdi-thumb-up',
    defaultItem: {},
    mode: 0,
    baseURL: process.env.VUE_APP_PIM_BASEURL || 'http://localhost:3128/',
    search: '',
    doneStatus: marketplace.DONE,
    CHECK_MEDIAS_TIMEOUT: 5000,
    THRESHOLD_CHECKING_MEDIAS_LIMIT: 36, // 3 minutes
  }),

  mounted() {
    this.$log.log('components.pim.importeditpimview.mounted');
    this.notificationInfo({
      message: `Ready to edit your data. (import: ${this.$route.params.importId})`,
    });

    // eslint-disable-next-line max-len
    this.getStep(this.$route.params.importId).then(stepNumber => this.setSteps(Object.values(pimFunctions).map(fnct => fnct.workflow)).then(() => {
      if (stepNumber <= STEPPER_CONVERT_PIM) {
        this.$router.push({ path: `/pim/import/${this.$route.params.importId}/csv` });
        return Promise.resolve(true);
      }
      if (stepNumber === STEPPER_VALID_IMPORT) {
        this.loader(true);
        this.checkMedias(this.$route.params.importId).then((isReady) => {
          if (isReady) {
            this.loader(false);
          } else {
            this.notificationError({
              message: `Come back later. Medias downloading in progress (import: ${this.$route.params.importId})`,
            });
          }
        });
      }
      this.listProductFromImport().then(({ itemsCount, itemsPerPage }) => {
        this.itemsCount = itemsCount;
        this.itemsPerPage = itemsPerPage;
        this.setDefaultProductItem(this.items[0]);
      });
      // eslint-disable-next-line no-param-reassign
      return this.setNextStep(--stepNumber || this.defaultStep);
    }));
    this.apiDistributor();
    this.apiBrand();
    this.apiClassification();
    // Listen for the onCollectionUpdate even, everywhere among all children
    EventBus.$on('onCollectionUpdate', async (done = () => {}) => {
      await this.importObjUpdate();
      return done();
    });
  },
  beforeDestroy() {
    this.$log.log('components.pim.importeditpimview.beforedestroy');
    this.$store.commit('import/set_products', []);
    this.$store.commit('import/set_rows', []);
    this.pageLoaded = false;
    EventBus.$off('onCollectionUpdate');
  },
  computed: {
    importObj() {
      return this.$store.getters['import/import'];
    },
    items() {
      return this.$store.getters['import/products'];
    },
    settings() {
      return this.$store.getters['settings/settings'];
    },
    optionsTa() {
      return this.$store.getters['settings/theagent'];
    },
    headers() {
      return this.$store.getters['settings/headers'].product;
    },
    isChecking() {
      return this.importObj.id && STEPPER_GENERATE_PIM >= this.currentStep;
    },
    isExportCdv() {
      return STEPPER_GENERATE_PIM === this.currentStep;
    },
    /**
     * calculate stas
     * @return {[type]} [description]
     */
    stats() {
      const products = this.$store.getters['import/products'];
      const rows = this.$store.getters['import/rows'];
      const stats = products.reduce(
        (pt, p) => {
          pt.referencescol += p.variant.length;
          pt.errors += Object.keys(p.errors).length;
          pt = p.variant.reduce((vt, v) => {
            vt.errors += v.errors ? Object.keys(v.errors).length : 0;
            vt.skus += v.skus.length;
            vt.medias += v.medias && v.medias.medias ? v.medias.medias.length : 0;
            vt.errors = v.skus.reduce((st, s) => {
              st += s.errors ? Object.keys(s.errors).length : 0;
              return st;
            }, vt.errors);
            return vt;
          }, pt);
          return pt;
        },
        {
          referencescol: 0,
          skus: 0,
          medias: 0,
          errors: 0,
        },
      );
      stats.csv = rows.length;
      stats.references = products.length;
      return stats;
    },
    importedfiles() {
      return this.$store.getters['import/files'];
    },
  },
  beforeCreate() {
    this.$store.dispatch('api/attribute/list');
    this.$store.dispatch('completude/families');
  },
  methods: {
    ...mapActions('api', {
      apiBrand: 'brand',
      apiClassification: 'classification',
      apiDistributor: 'distributor',
    }),
    ...mapActions('import', ['convert', 'generate', 'mediasIsReady']),
    /**
     * This method allow to initialize default data to all dialog
     * @param {[type]} item [description]
     */
    setDefaultProductItem(item) {
      this.$log.info('components.pim.importeditpimview.setdefaultproductitem.started', item);
      if (this.items.length === 0) {
        this.$log.error('components.pim.importeditpimview.setdefaultproductitem.items.empty');
        return;
      }
      // for some unknown reason item is undefined
      // I think it s the cycle between vuejs and code updating
      // who has not finished
      if (typeof item === 'undefined' || !item) {
        this.notificationWarning({ message: 'For some unknown reasons item is undefined' });
        return;
      }
      const defaultItem = item.clone();
      defaultItem.name = '[product-name]';
      // generate a random remoteCode
      defaultItem.remoteCode = `temp.r.${Math.floor(Math.random() * 99999999) + 1}`;
      delete defaultItem.id;
      this.$log.debug(
        'components.pim.importeditpimview.setdefaultproductitem.defaultitem',
        defaultItem,
      );
      this.defaultItem = defaultItem;
      this.editedItem = defaultItem.clone();
    },
    listProductFromImport(reset = false) {
      this.$log.info('components.pim.importeditpimview.listproductfromimport.started');
      return new Promise((resolve) => {
        const { page, itemsPerPage } = this.itemsOptions;
        const params = {
          id: this.$route.params.importId,
          params: {
            limit: parseInt(itemsPerPage, 10),
            page,
          },
          reset,
        };
        return this.convert(params).then((data) => {
          // for some unknown reason parameters can be empty
          const count = data.parameters ? data.parameters.count : data.result.length;
          if (!data.parameters) {
            this.$log.error('components.pim.importeditpimview.parameters.empty');
          }
          this.loading = false;
          this.pageLoaded = true;
          resolve({
            itemsCount: count,
            itemsPerPage,
          });
        });
      });
    },
    async saveProduct(product) {
      this.$log.debug('components.pim.productbyidview.saveproduct.started');
      if (!(product instanceof Product)) {
        product = Product.create(product);
      }
      const productObj = product.toObject();
      return this.$store
        .dispatch('product/update', productObj)
        .then((response) => {
          this.items[this.editedIndex].update(response);
          // return this.product.setCompletude(this.$store.dispatch);
        })
        .then(() => map(product.variant || [], variant => this.saveVariant(variant)))
        .catch(error => this.throwError(error));
    },
    saveVariant(variant) {
      this.$log.debug('components.pim.productbyidview.savevariant.started');
      if (!(variant instanceof Variant)) {
        variant = Variant.create(variant);
      }
      const variantObj = variant.toObject();
      return this.$store
        .dispatch('variant/update', variantObj)
        .then(response => variant.update(response))
        .catch(error => this.throwError(error));
    },
    saveItem(done = () => {}) {
      this.$log.debug('components.pim.importeditpimview.saveItem.started', this.editedItem);
      this.loader(true);
      // if editedItem has already stored and have a id from database
      if (typeof this.editedItem.id !== 'undefined' && this.editedItem.id) {
        return this.saveProduct(this.editedItem)
          .then(() => this.updateCollection())
          .then(async () => {
            await this.closeItemProduct();
            return this.notificationSuccess({
              message: `Your product has been saved. (id: ${this.editedItem.id})`,
            });
          })
          .catch((error) => {
            this.throwError(error);
            return this.notificationError({
              message: `Your product failed to be stored. (id: ${this.editedItem.id})`,
            });
          })
          .finally(() => {
            this.loader(false);
            this.$log.debug('components.pim.importeditpimview.saveItem.finished');
            done();
          });
      }
      if (this.editedIndex === -1) {
        this.items.push(this.editedItem);
      } else {
        this.items[this.editedIndex].update(this.editedItem);
      }
      return this.updateCollection()
        .then(async () => {
          await this.closeItemProduct();
          return this.notificationSuccess({
            message: 'Your product has been saved',
          });
        })
        .catch((error) => {
          this.throwError(error);
          return this.notificationError({
            message: 'Your product failed to be stored',
          });
        })
        .finally(() => {
          this.loader(false);
          done();
        });
    },
    async closeItemProduct(checkpoint = true) {
      this.$log.log('components.pim.importeditpimview.closeitemproduct.started');
      const rollbackStep = this.currentStep >= STEPPER_CONVERT_PIM;
      this.withSku = false;
      const listProms = [this.closeItem()];
      if (rollbackStep && checkpoint) {
        // if the step is old, no need to go to the following step
        listProms.push(this.stepInProgress(STEPPER_CONVERT_PIM));
      }
      return Promise.all(listProms).then(() => {
        // reset data
        // regenerate a new defaultItem and editItem
        this.setDefaultProductItem(this.items[0]);
      });
    },
    updateCollection() {
      this.$log.debug('components.pim.importeditpimview.updatecollection.started', this.items);
      if (this.items.length === 0) {
        this.$log.error('components.pim.importeditpimview.updatecollection.items.isempty');
        return false;
      }
      // due to change in dispatch import/update
      // error: _this10.items.toObject is not a function
      const dataValues = this.items.toObject();
      return this.$store
        .dispatch('import/update', {
          id: this.$route.params.importId,
          structured_data: {
            result: dataValues,
            parameters: {
              count: this.itemsCount,
              page: 1,
              limit: this.itemsPerPage,
            },
          },
        })
        .then(() => this.notificationSuccess({
          message: `Your data has been saved. (import: ${this.$route.params.importId})`,
        }))
        .catch(error => this.throwError(error));
    },
    generateIdImport() {
      this.$log.debug('components.pim.importeditpimview.generateidimport.started');
      this.notificationWarning({ message: 'Are you sure to generate all ids and store data)?' });
      // eslint-disable-next-line no-alert
      if (!window.confirm('This action is irreversible?')) {
        return false;
      }
      this.loader(true);
      this.showProgressCamembert(1300);
      return this.updateCollection()
        .then(() => this.$store.dispatch('import/generate_id', {
          id: this.$route.params.importId,
        }))
        .then((data) => {
          const { itemsCount, itemsPerPage } = this.options(
            data.result,
            data.parameters.count,
            this.itemsOptions,
          );
          this.hookSyncIds(this.items);
          // https://fr.vuejs.org/v2/guide/list.html#Limitations
          // items.forEach((item, indexOfItem) => {
          //   this.items.add(item, indexOfItem);
          // });
          this.itemsCount = itemsCount;
          this.itemsPerPage = itemsPerPage;
          return Promise.resolve();
        })
        .then(() => this.stepInProgress(this.currentStep))
        .then(() => this.checkMedias(this.$route.params.importId))
        .catch((error) => {
          this.setError(error);
          this.throwError(error);
          return this.listProductFromImport(true).then(({ itemsCount, itemsPerPage }) => {
            this.itemsCount = itemsCount;
            this.itemsPerPage = itemsPerPage;
            this.setDefaultProductItem(this.items[0]);
          });
        })
        .finally(() => {
          this.notificationSuccess({
            message: `All Ids has been generated. (import: ${this.$route.params.importId})`,
          });
          this.loader(false);
          this.closeProgressCamember();
        });
    },
    /**
     * [checkMedias description]
     * @param  {Number} id Import Id from url
     * @param  {Number} i  counter to determine threshold for stopping this method
     * @return {Boolean}    [description]
     */
    async checkMedias(id, i = 0) {
      this.$log.debug(`components.pim.importeditpimview.checkmedias.${id}.started`);
      const isReady = await this.mediasIsReady(id);
      if (isReady) {
        this.$log.debug(`components.pim.importeditpimview.checkmedias.${id}.ready`);
        this.notificationSuccess({
          message: `Your medias are ready. (import: ${this.$route.params.importId})`,
        });
        return isReady;
      }
      if (this.THRESHOLD_CHECKING_MEDIAS_LIMIT <= i) {
        this.notificationError({
          message: `Come back later. Medias downloading in progress (import: ${this.$route.params.importId})`,
        });
        this.$log.error(`components.pim.importeditpimview.checkmedias.${id}.come.back.later`);
        throw new TypeError(`components.pim.importeditpimview.checkmedias.${id}.failed`);
      }
      this.$log.error(`components.pim.importeditpimview.checkmedias.${id}.not.ready`);
      this.notificationWarning({
        message: `I need to if your medias are ready (import: ${this.$route.params.importId})`,
      });
      await sleep(this.CHECK_MEDIAS_TIMEOUT);
      return this.checkMedias(id, ++i);
    },
    /**
     * This endpoint retrieve all medias from variant object
     * For all variant which already existed
     *
     * @return {[type]} [description]
     *
     * @todo this action can be move to a specific action
     * and not a step in the workflow
     *       can be done by each variant and not by import data
     *  @deprecated
     */
    syncWithLegacy() {
      this.$log.debug('components.pim.importeditpimview.syncwithlegacy.started');
      this.loader(true);
      this.showProgressCamembert(60);
      return this.updateCollection()
        .then(() => this.$store.dispatch('import/updateMedia', this.$route.params.importId))
        .then(() => this.stepInProgress(this.currentStep))
        .catch((error) => {
          this.setError(error);
          return this.throwError(error);
        })
        .finally(() => {
          this.loader(false);
          this.closeProgressCamember();
        });
    },
    syncMarketplace() {
      // eslint-disable-next-line no-alert
      if (window.confirm("Finaliser l'import?")) {
        this.loader(true);
        this.showProgressCamembert(80);
        return this.updateCollection().then(() => this.$store
          .dispatch('import/syncMarketplace', this.$route.params.importId)
          .then(() => this.stepInProgress(this.currentStep))
          .catch((error) => {
            this.throwError(error);
            this.setError(error);
          })
          .finally(() => {
            this.notificationSuccess({
              message: `Import terminated. (import: ${this.$route.params.importId})`,
            });
            this.loader(false);
            this.closeProgressCamember();
          }));
      }
      return false;
    },
    resetImport() {
      this.loader(true);
      this.showProgressCamembert(367);
      return this.$store
        .dispatch('import/csv', {
          id: this.$route.params.importId,
          reset: true,
        })
        .then((data) => {
          const { itemsCount, itemsPerPage } = this.options(
            data.result,
            data.parameters.count,
            this.itemsOptions,
          );
          this.itemsCount = itemsCount;
          this.itemsPerPage = itemsPerPage;
          return Promise.resolve();
        })
        .catch(error => this.throwError(error))
        .finally(() => {
          this.loader(false);
          this.closeProgressCamember();
          this.notificationSuccess({
            message: `Your data has been reseted. (import: ${this.$route.params.importId})`,
          });
          this.updateStep({
            step: STEPPER_START,
            id: this.$route.params.importId,
          }).then(() => this.$router.push({ path: `/pim/import/${this.$route.params.importId}/csv` }));
        });
    },
    goToLink(url) {
      if (url.indexOf('tmp/storage') !== -1) {
        url = url.replace('tmp/storage', 'storage');
      }
      window.open(`${this.baseURL}/${url}`, '_blank');
    },
    rollback({ nextStep, editable }) {
      Vue.$log.debug(`components.pim.importeditpimview.rollback.step.${nextStep}`);
      if (editable === false) {
        return Promise.resolve(false);
      }
      const stepInd = parseInt(nextStep, 10);
      return this.updateStep({
        step: stepInd,
        id: this.$route.params.importId,
      }).then(async () => {
        let stepNumber = await this.getStep(this.$route.params.importId);
        if (stepNumber <= STEPPER_CONVERT_PIM) {
          this.$router.push({ path: `/pim/import/${this.$route.params.importId}/csv` });
          return Promise.resolve(true);
        }
        return this.setNextStep(--stepNumber || this.defaultStep);
      });
    },
    importObjUpdate() {
      Vue.$log.info('components.pim.importeditpimview.importobjupdate.started');
      Vue.$log.debug('components.pim.importeditpimview.importobjupdate.data', this.items);
      this.loader(true);
      // checkpoint to check integriy after a moodification in the dialog
      return this.updateCollection()
        .catch((error) => {
          this.setError(error);
          return this.throwError(error);
        })
        .finally(() => this.loader(false));
    },
    exportCdv() {
      Vue.$log.info('components.pim.importeditpimview.exportcdv.started');
      this.loader(true);
      this.showProgressCamembert(80);
      return this.updateCollection().then(() => this.$store
        .dispatch('export/cdvfrompim', this.$route.params.importId)
        .then(([filepath]) => {
          if (filepath) {
            Vue.downloadUrl(`${this.baseURL}/${filepath}`);
          } else {
            this.$log.error('components.pim.importeditpimview.exportcdv.filepath.not.okay');
          }
        })
        .catch((error) => {
          this.throwError(error);
          this.setError(error);
        })
        .finally(() => {
          this.notificationSuccess({
            message: `Export Cdv terminated. (import: ${this.$route.params.importId})`,
          });
          this.loader(false);
          this.closeProgressCamember();
        }));
    },
    async loader(value) {
      this.loading = value;
      this.btnFunctionStepped = value;
    },
    openPanel(value) {
      if (this.panelOpened === value) {
        this.panelOpened = 0;
      } else {
        this.panelOpened = value;
      }
    },
    setDialog(event) {
      this.dialog = event;
    },
  },
};
</script>

<style>
.fix-on-function-btn {
  right: 12px;
  z-index: 5;
  bottom: 420px !important;
  position: fixed;
}

.import-card button.v-btn--fab.v-btn--fixed {
  z-index: 5;
}
</style>
