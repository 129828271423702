// https://vuex.vuejs.org/en/actions.html
import Vue from 'vue';
import axios from 'axios';
import { workflow } from 'theagent-status';
import { errorHandler } from '@/store/modules/global_helpers';

const {
  IN_PROGRESS, DONE, ERROR, RE_TRY,
} = workflow;

// milli seconds
const DELAY = 5000;

const BASE_URI = '/api/v1/pim/family';
const BASE_URI_V2 = '/api/v2/pim/family';
const namespace = 'store.modules.family.actions';

export default {
  list({
    state, commit, getters, dispatch,
  }) {
    if (state.state === ERROR) {
      return Promise.reject(new Error(`${namespace}.something.occured.on.server`));
    }
    if (state.state === RE_TRY) {
      return Promise.resolve([]);
    }
    if (state.state === DONE) {
      return Promise.resolve(getters.families);
    }
    if (state.state === IN_PROGRESS) {
      commit('set_state', RE_TRY);
      return new Promise(resolve => setTimeout(() => resolve(dispatch('list')), DELAY));
    }
    commit('set_state', IN_PROGRESS);
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URI}`)
        .then((response) => {
          if (typeof response === 'undefined') {
            commit('set_state', ERROR);
            throw new TypeError(`${namespace}.response.failed`);
          }
          commit('set_state', DONE);
          commit('set_families', response.data.result);
          return resolve(response.data);
        })
        .catch((error) => {
          commit('set_state', ERROR);
          return reject(error);
        });
    });
  },
  get({ commit, getters }, remoteCode) {
    Vue.$log.info(`store.product.actions.get.${remoteCode}.started`);
    const family = getters.families.find(a => a.remoteCode === remoteCode);
    if (family) {
      commit('set_family', { ...family });
      return { ...family };
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URI}/${remoteCode}`)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`.api.v1.pim.family.${remoteCode}.not.found`);
          }
          commit('set_family', response.data.result);
          return resolve(response.data.result);
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return errorHandler(reject)(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  update({ commit }, payload) {
    Vue.$log.info('store.product.actions.update.started');
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI_V2}/${payload.remoteCode}`, payload)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`.api.v2.pim.family.${payload.remoteCode}.not.found`);
          }
          commit('set_family', response.data.result);
          return resolve(response.data.result);
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return errorHandler(reject)(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  active({ commit }, payload) {
    Vue.$log.info('store.product.actions.active.started');
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI_V2}/${payload.remoteCode}/active`, {
          active: payload.active,
        })
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`.api.v2.pim.family.${payload.remoteCode}.not.found`);
          }
          commit('set_family', response.data.result);
          return resolve(response.data.result);
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return errorHandler(reject)(error);
        });
    });
  },
};
