import Vue from 'vue';
import SyncedBaseMixins from '@/mixins/synced-base';
import FormBaseMixins from '@/mixins/form-base';
import { Sku } from '@/models';
import { EventBus } from '@/helpers/event-bus';

export default {
  mixins: [SyncedBaseMixins, FormBaseMixins],
  props: {
    item: {
      type: Object,
    },
    property: {
      type: String,
    },
    /**
     * this is the key from quable
     *
     * @type {String}
     * @example
     *   - attr_descrption_r
     */
    keyAttr: {
      type: String,
    },
    labelName: {
      type: String,
      default: () => {
        'Choose your item';
      },
    },
    messageError: {
      type: String,
      default: () => {
        'Item, no id';
      },
    },
    store: {
      type: String,
      default: 'product',
    },
    saveOnApi: {
      type: Boolean,
      default: true,
    },
    updateChildren: {
      type: Boolean,
      default: false,
    },
    noDialog: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    // this item comes from variant
    if (typeof this.item.dataJson !== 'undefined') {
      this.hasDataModel = true;
    }
  },
  beforeDestroy() {
    this.hasDataModel = false;
    this.isClicked = false;
  },
  computed: {
    titleName() {
      if (typeof this.itemsKey === 'undefined' || this.itemsKey.length === 0) {
        return this.valueAttribute;
      }
      if (typeof this.itemsKey[this.item[this.property]] === 'undefined') {
        return '';
      }
      return this.itemsKey[this.item[this.property]].name;
    },
    itemsValue() {
      if (!this.type) {
        return [];
      }
      return this.$store.getters['api/list'](this.type);
    },
    itemsKey: function itemsKey() {
      if (!this.type) {
        return [];
      }
      return this.$store.getters['api/keys'](this.type);
    },
  },
  data: () => ({
    // type property apply only for itemsValue, itemsKey are computed
    type: false,
    newObject: '',
    hasDataModel: false,
    isClicked: false,
  }),
  methods: {
    saveInline() {
      const namespace = `mixin.dialogbase.saveinline.${this.property}`;
      this.$log.info(`${namespace}.started`);
      if (this.isClicked) {
        return null;
      }
      this.$log.debug(`${namespace}.data`, this.item);
      const isValid = true;
      this.isClicked = true;
      // const isValid = this.$refs.form.validate();
      if (!isValid) {
        this.showError(true);
        this.$log.error(`${namespace}.error`);
      }
      const endpoint = `${this.store}/update`;
      const isNotNew = this.item.id > 0;
      if (!isNotNew && this.saveOnApi) {
        // for some vue constraints, we can add Mixins global here
        // we need to add the mixins directly in the component parent
        return this.notificationError({ message: this.messageError });
      }
      // on some component dialog, newObject is not setted
      if (this.newObject) {
        this.$log.debug(`${namespace}.newobject`);
        this.valueAttribute = this.newObject;
      }
      // for some dialog component like textarea we use directement v-model and not v-on:change
      // This portion code is a hack because for some unknow reason
      // on dialog-textarea (att_description_rc) attribute_temp.att_description_rc is not
      // synchronized with attribute.dataJson.att_description_rc the first time.
      // We need to force it before sending it in the database
      if (
        typeof this.keyAttr !== 'undefined'
        && this.keyAttr
        && typeof this.item.attributes !== 'undefined'
      ) {
        this.$log.debug(
          `${namespace}.property.${this.property}.keyattr.${this.keyAttr}`,
          this.item[this.property][this.keyAttr],
        );
        this.item.attributes.forEach((attribute) => {
          if (typeof attribute.dataJson[this.keyAttr] !== 'undefined') {
            Vue.set(attribute.dataJson, this.keyAttr, this.item[this.property][this.keyAttr]);
          }
        });
      }
      let data = {};
      // for sku instance we need to send parent instance
      // it s important to retrieve color
      if (this.item instanceof Sku) {
        data = {
          sku: this.item.toObject(),
          parent: this.item.parent,
        };
      } else {
        data = this.item.toObject();
      }

      if ((this.saveOnApi === false && !isNotNew) || !isValid) {
        this.$log.info(`${namespace}.noapi.saving`);
        this.defaultValues();
        return this.item;
      }
      this.$log.info(`${namespace}.dispatch.wip`, data);
      return (
        this.$store
          .dispatch(endpoint, data)
          .then((response) => {
            this.item = Object.assign(this.item, response);
            if (this.updateChildren) {
              const listUpdateProm = this.updateAllChildren();
              return Promise.all(listUpdateProm);
            }
            return Promise.resolve(true);
          })
          // for some vue constraints, we can add Mixins global (notification-base) here
          // we need to add the mixins (notification-base) directly in the component parent
          .catch(error => this.throwError(error))
          .finally(() => Promise.all([
            // this event name doesnt exist inside all page
            EventBus.$emit('onCollectionUpdate', () => {}),
            this.defaultValues(),
          ]))
      );
    },
    /**
     * this method is called by update:error event from v-edit-dialog
     *   (text-field, textarea, input, button radio)
     * @param  {Boolean} isinvalid
     */
    showError(isinvalid) {
      this.$log.debug(`mixin.dialogbase.showerror.${this.property}.isinvalid`, isinvalid);
      if (typeof this.item.errors === 'undefined') {
        Vue.set(this.item, 'errors', {});
      }
      if (typeof this.item.errors[this.property] === 'undefined') {
        Vue.set(this.item.errors, this.property, []);
      }
      // if invalid, need to check invalid attribute
      // if invalid with invalid existing, no need to add it
      if (isinvalid === true) {
        if (!this.item.errors[this.property].includes('invalid')) {
          this.item.errors[this.property].push('invalid');
        }
      } else {
        this.item.errors[this.property] = [];
      }
    },
    updateAllChildren() {
      // load all ids to update when there are a nested chidren with modifications
      const listChildren = this.item.getChildren();
      this.$log.debug('mixin.dialogbase.updatechildren.getchildrenids.started', listChildren);
      return Object.keys(listChildren).reduce((proms, key) => {
        if (key === 'sku' && listChildren[key].length > 0) {
          listChildren[key].forEach((child) => {
            proms.push(
              this.$store
                .dispatch('sku/update', {
                  sku: child.toObject(),
                  parent: child.parent,
                })
                .then((skuChildObj) => {
                  // eslint-disable-next-line no-param-reassign
                  child = Object.assign(child, skuChildObj);
                }),
            );
          });
        } else if (key === 'variant' && listChildren[key].length > 0) {
          listChildren[key].forEach((child) => {
            proms.push(
              this.$store.dispatch('variant/update', child.toObject()).then((variantChildObj) => {
                // eslint-disable-next-line no-param-reassign
                child = Object.assign(child, variantChildObj);
              }),
            );
          });
        }
        return proms;
      }, []);
    },
    defaultValues() {
      this.hasDataModel = false;
      this.isClicked = false;
    },
  },
};
