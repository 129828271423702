// https://vuex.vuejs.org/en/actions.html
import Vue from 'vue';
import axios from 'axios';
import { errorHandler } from '@/store/modules/global_helpers';
import { Variant, VariantList } from '@/models';

const BASE_URI = '/api/v1/pim';
const BASE_URI_V2 = '/api/v2/pim';

export default {
  list({ commit }) {
    Vue.$log.info('store.step.actions.started');
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URI}/variant`)
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError('/api/v1/pim/variant empty');
          }
          const variantList = VariantList.create(response.data.result);
          commit('set_variants', variantList);
          return resolve(variantList);
        })
        .catch(errorHandler(reject));
    });
  },
  // eslint-disable-next-line no-unused-vars
  new({ commit }, variant) {
    Vue.$log.info('store.step.actions.started');
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URI}/variant`, variant)
        .then((response) => {
          Vue.$log.log('variant has been created');
          const variantObj = Variant.create(response.data);
          return resolve(variantObj);
        })
        .catch(errorHandler(reject));
    });
  },
  // eslint-disable-next-line no-unused-vars
  update({ commit }, variant) {
    Vue.$log.info('store.step.actions.started');
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI}/variant/${variant.id}`, variant)
        .then((response) => {
          Vue.$log.log(`variant ${variant.id} has been updated`);
          const variantObj = Variant.create(response.data);
          return resolve(variantObj);
        })
        .catch(errorHandler(reject));
    });
  },
  // eslint-disable-next-line no-unused-vars
  delete({ commit }, id) {
    Vue.$log.info('store.step.actions.started');
    return new Promise((resolve, reject) => {
      axios
        .delete(`${BASE_URI}/variant/${id}`)
        .then((response) => {
          Vue.$log.log(`variant ${id} has been deleted`);
          return resolve(response);
        })
        .catch(errorHandler(reject));
    });
  },
  // eslint-disable-next-line no-unused-vars
  status({ commit }, payload) {
    Vue.$log.info('store.variant.actions.status.started');
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI}/variant/${payload.id}/status/${payload.status}`)
        .then((response) => {
          Vue.$log.log(`variant ${payload.id} status has been updated`);
          return resolve(Variant.create(response.data));
        })
        .catch(errorHandler(reject));
    });
  },

  // eslint-disable-next-line no-unused-vars
  active({ commit }, payload) {
    Vue.$log.info('store.variant.actions.active.started');
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI}/variant/${payload.id}/active`, {
          active: payload.active,
        })
        .then((response) => {
          Vue.$log.info(`store.variant.actions.active.${payload.id}.updated`);
          Vue.$log.log(`variant ${payload.id} active has been updated`);
          const variantObj = Variant.create(response.data);
          return resolve(variantObj);
        })
        .catch((error) => {
          Vue.$log.error(`store.variant.actions.active.${payload.id}.error`, {
            error: error.message,
            stack: error.stack,
          });
          Vue.$log.error(error.message);
          return errorHandler(reject)(error);
        });
    });
  },

  // eslint-disable-next-line no-unused-vars
  sealed({ commit }, payload) {
    Vue.$log.info('store.variant.actions.sealed.started');
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI}/variant/${payload.id}/sealed`, {
          sealed: payload.sealed,
        })
        .then((response) => {
          Vue.$log.info(`store.variant.actions.sealed.${payload.id}.updated`);
          Vue.$log.log(`variant ${payload.id} sealed has been updated`);
          const variantObj = Variant.create(response.data);
          return resolve(variantObj);
        })
        .catch((error) => {
          Vue.$log.error(`store.variant.actions.sealed.${payload.id}.error`, {
            error: error.message,
            stack: error.stack,
          });
          Vue.$log.error(error.message);
          return errorHandler(reject)(error);
        });
    });
  },
  // eslint-disable-next-line no-empty-pattern
  search({ commit }, payload) {
    Vue.$log.info('store.variant.actions.search.started');
    return new Promise((resolve) => {
      Vue.$log.debug('store.variant.actions.search.payload', payload);
      axios
        .get(`${BASE_URI}/variant/search`, payload)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError('/api/v1/pim/variant/search empty');
          }
          const variantList = VariantList.create(response.data.result);
          commit('set_variants', variantList);
          return resolve(variantList);
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return resolve({ result: [], parameters: { count: 0 } });
        });
    });
  },
  changeColor(store, payload) {
    Vue.$log.info('store.variant.actions.changecolor.started');
    return new Promise((resolve, reject) => {
      Vue.$log.debug('store.variant.actions.changecolor.payload', payload);
      axios
        .put(`${BASE_URI_V2}/variant/${payload.id}/changecolor`, payload)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError('store.variant.actions.changecolor.response.empty');
          }
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  changeFamily(store, payload) {
    Vue.$log.info('store.variant.actions.changefamily.started', payload);
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI_V2}/variant/${payload.id}/changefamily/${payload.familyId}`)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError('store.variant.actions.changefamily.response.empty');
          }
          return resolve(Variant.create(response.data));
        })
        .catch(errorHandler(reject));
    });
  },
  move(store, payload) {
    Vue.$log.info('store.variant.actions.move.started');
    return new Promise((resolve, reject) => {
      Vue.$log.debug('store.variant.actions.move.payload', payload);
      axios
        .put(`${BASE_URI_V2}/variant/${payload.id}/move/${payload.productId}`, payload)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError('store.variant.actions.move.response.empty');
          }
          return resolve(Variant.create(response.data));
        })
        .catch(errorHandler(reject));
    });
  },
  duplicate(store, payload) {
    Vue.$log.info('store.variant.actions.move.started');
    return new Promise((resolve, reject) => {
      Vue.$log.debug('store.variant.actions.move.payload', payload);
      axios
        .post(`${BASE_URI_V2}/variant/${payload.id}/duplicate`, payload)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError('store.variant.actions.move.response.empty');
          }
          return resolve(Variant.create(response.data));
        })
        .catch(errorHandler(reject));
    });
  },
};
