<template>
  <v-container fill-height>
    <v-flex md12>
      <v-toolbar flat>
        <v-toolbar-title>
          Export
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <dialog-search v-model="search" @onSave="saveAndSearch"> </dialog-search>
        <v-divider class="mx-4" inset vertical></v-divider>
        <chips-stats
          :count="countProducts"
          label="R"
          color="blue"
          style="cursor: pointer;"
          noIcon
        >
        </chips-stats>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn :disabled="!downloadIsOkay" color="green darken-1" @click="exportProducts">
          <v-icon>mdi-database-export</v-icon>
        </v-btn>
      </v-toolbar>
      <core-alert></core-alert>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th v-for="header in filterByColumn('csv')" :key="header.text" class="text-left">
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.ean">
              <td v-for="header in filterByColumn('csv')" :key="header.text" class="text-left">
                <template v-if="header.text.includes('Image')">
                  {{ item[header.text].map(m => m.original).join(";") }}
                </template>
                <template v-else>
                  {{ item[header.text] }}
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-flex>
    <div>
      <dialog-settings :dialog="dialog"> </dialog-settings>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import { marketplace } from 'theagent-status';
import { Product } from '../../models';
import Mixins from '../../mixins';
import ItemsBaseMixins from '../../mixins/items-base';
import ProductBaseMixins from '../../mixins/product-base';
import EditMixins from '../../mixins/edit-base';
import ProfileMixins from '../../mixins/profile-base';

const baseURL = process.env.VUE_APP_PIM_BASEURL || 'http://localhost:3128/';

const PAGE_LIMIT = 5;

export default {
  mixins: [ItemsBaseMixins, EditMixins, ProfileMixins, ProductBaseMixins, ...Mixins],
  data: () => ({
    baseURL,
    items: [],
    search: {}, // data come from dialog-search via a two way binding
    loading: false,
    mode: 0,
    countProducts: 0,
    doneStatus: marketplace.DONE,
  }),
  beforeCreate() {
    this.$store.dispatch('api/attribute/list');
  },
  created() {
    this.editedItem = this.defaultItem.clone();
    if (Object.keys(this.$route.query).length > 0) {
      const { search = '', filters = {}, limit = PAGE_LIMIT } = this.$route.query;
      this.search = {
        limit,
        text: search,
        filters: JSON.parse(filters),
      };
    }
  },
  computed: {
    settings() {
      return this.$store.getters['settings/settings'];
    },
    headers() {
      return this.$store.getters['settings/headers'].csv;
    },
    defaultItem: function defaultItem() {
      const defaultValues = this.$store.getters['settings/headers'].product.reduce(
        (item, header) => {
          // eslint-disable-next-line no-param-reassign
          item[header.value] = '';
          return item;
        },
        {},
      );
      return Product.create(defaultValues);
    },
    downloadIsOkay() {
      return typeof this.countProducts === 'number' && this.countProducts > 0;
    },
  },
  methods: {
    ...mapActions('export', {
      apiExportSearch: 'search',
    }),
    searchProducts() {
      this.$log.log('components.pim.exportview.searchproducts.started');
      this.$log.debug('components.pim.exportview.searchproducts.filters', this.search);
      this.loading = true;
      let filters = {};
      if (Object.keys(this.search).length > 0) {
        // eslint-disable-next-line prefer-destructuring
        filters = this.search.filters;
      }
      const params = {
        params: {
          search: this.search.text ? this.search.text.trim() : '',
          limit: this.search.limit || PAGE_LIMIT,
          filters,
          dryRun: true,
          headers: this.filterByColumn('csv').map(h => h.text),
        },
      };
      this.$router
        .push({
          name: 'pim.export',
          query: {
            search: params.params.search,
            limit: params.params.limit,
            filters: JSON.stringify(filters),
          },
        })
        .catch(() => {}); // NavigationDuplicated error quiet

      return this.apiExportSearch(params)
        .then(({ result, parameters }) => {
          this.items = result;
          this.countProducts = parameters.count;
          this.$log.debug('components.pim.exportview.searchproducts.result');
        })
        .catch(error => this.throwError(error))
        .finally(() => {
          this.loading = false;
        });
    },
    saveAndSearch() {
      this.$log.info('components.pim.exportview.saveandsearch.started');
      return this.searchProducts();
    },
    exportProducts() {
      this.$log.log('components.pim.exportview.exportproducts.started');
      this.$log.debug('components.pim.exportview.exportproducts.filters', this.search);
      this.loading = true;
      let filters = {};
      if (Object.keys(this.search).length > 0) {
        // eslint-disable-next-line prefer-destructuring
        filters = this.search.filters;
      }
      const params = {
        params: {
          search: this.search.text ? this.search.text.trim() : '',
          limit: this.countProducts || PAGE_LIMIT,
          filters,
          headers: this.filterByColumn('csv').map(h => h.text),
        },
      };
      return this.apiExportSearch(params)
        .then(([filepath]) => {
          if (filepath) {
            Vue.downloadUrl(`${this.baseURL}/${filepath}`);
          } else {
            this.$log.error('components.pim.importvalidationview.exportproducts.filepath.not.okay');
          }
        })
        .catch(error => this.throwError(error))
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
