<template>
  <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="960px">
    <template v-slot:activator="{ on: openDialog, attrs }" v-if="!hideButton">
      <v-tooltip left>
        <template v-slot:activator="{ on: tooltip, attrs }">
          <v-icon
            :small="!xLarge"
            :x-large="xLarge"
            class="mr-2"
            v-bind="attrs"
            v-on="{ ...tooltip, ...openDialog }"
          >
            mdi-dots-vertical-circle
          </v-icon>
        </template>
        <span>Open all options</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>
        <slot name="title"></slot>
      </v-card-title>
      <v-form v-model="isValid">
        <v-card-text>
          <core-alert></core-alert>
          <v-autocomplete
            v-model="selectedOption"
            :items="options"
            menu-props="auto"
            item-text="name"
            item-value="id"
            label="Choose your option"
            v-on:change="loadComponents"
            persistent-hint
            chips
          >
          </v-autocomplete>
          <template v-for="(component, index) in components">
            <core-attribute
              v-if="hasCustomComponent(component)"
              :key="index"
              :property="component.name"
              :customComponent="component"
              auto-grow
              :item="item"
              item-value="name"
              filled
            >
            </core-attribute>
            <core-attribute
              v-else
              :key="index"
              :property="component.name || component"
              auto-grow
              :item="item"
              item-value="name"
              filled
            >
            </core-attribute>
          </template>
        </v-card-text>
        <v-spacer />
        <v-card-actions>
          <v-btn color="grey darken-1" text outlined @click="close">Cancel</v-btn>
          <v-btn color="blue darken-1" dark :disabled="!(isValid && isOptionValid)" @click="save"
            >Save</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/helpers/event-bus';
import Mixins from '@/mixins';

export default {
  name: 'ListOptions',
  mixins: Mixins,
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
    listOptions: {
      type: [Array, Object],
      required: true,
      default: () => [],
    },
    current: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  data: () => ({
    selectedOption: null,
    isValid: false,
    components: [],
    item: {},
  }),
  mounted() {
    EventBus.$on('list-options:id', (parentId) => {
      this.parentId = parentId;
    });
  },
  computed: {
    // https://fr.vuejs.org/v2/guide/computed.html
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    useCurrent() {
      // if current option exist check if field have id
      // if current option doesnt exist hide slot fieldCurrent
      if (!this.currentOption) {
        return true;
      }
      // if fields has id return true
      // if fields has not id return false
      return this.currentOption.fields.some(f => f === 'id');
    },
    options() {
      return this.listOptions.map(o => ({
        name: o.name,
        id: o.id || o.name.slugify(),
      }));
    },
    isOptionValid() {
      if (!this.currentOption || this.currentOption.name === 'Default') {
        return false;
      }
      if (!this.currentOption.store) {
        return false;
      }
      return true;
    },
    currentOption() {
      if (!this.selectedOption || this.selectedOption === 'default') {
        return false;
      }
      return this.listOptions.find((v) => {
        if (typeof v.id !== 'undefined' && this.selectedOption === v.id) {
          return true;
        }
        if (this.selectedOption === v.name.slugify()) {
          return true;
        }
        return false;
      });
    },
  },
  methods: {
    reset() {
      this.components = [];
      this.item = {};
    },
    loadComponents() {
      if (
        this.currentOption
        && typeof this.currentOption.fields !== 'undefined'
        && this.currentOption.fields.length > 0
      ) {
        this.components = this.currentOption.fields.filter(f => f !== 'id');
      } else {
        this.reset();
      }
    },
    hasCustomComponent(component) {
      return typeof component === 'object' && typeof component.customTemplate !== 'undefined';
    },
    // @see copy from src/mixins/edit-base.js:closeItem
    async save() {
      this.$log.debug('components.dialog.list-options.save.started');
      const params = {
        debug: this.hasRight('pimapp.v2:debug:read'),
        force: this.hasRight('pimapp.v2:debug:read'),
        ...(this.currentOption.payload || {}),
        ...this.item,
        id: this.useCurrent ? this.current.id : undefined,
      };
      try {
        await this.$store.dispatch(this.currentOption.store, params);
        this.throwSuccess({ message: 'components.dialog.list-options.save.succeded' });
        this.close();
        EventBus.$emit('import.importid.edit:reload');
        EventBus.$emit('pim.products:reload');
      } catch (err) {
        this.throwError(err);
      }
    },
    // @see copy from src/mixins/edit-base.js:closeItem
    close() {
      this.$log.debug('components.dialog.list-options.close.started');
      this.dialog = false;
      this.selectedOption = null;
      this.isValid = false;
      this.reset();
    },
  },
};
</script>

<style lang="css" scoped></style>
