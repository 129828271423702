<template>
  <v-dialog v-model="openDialog" persistent max-width="96vw">
    <!-- https://vuetifyjs.com/en/components/menus/#activator-and-tooltip -->
    <template v-slot:activator="{ on: openDialogViaBtn }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip, attrs }">
          <v-btn
            color="green"
            fab
            dark
            absolute
            right
            fixed
            class="mx-2"
            style="bottom: 280px !important;"
            v-bind="attrs"
            v-on="{ ...tooltip, ...openDialogViaBtn }"
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Create a new row</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-form v-model="isValid">
        <v-row style="background-color: rgb(128 128 128 / 5%);">
          <v-col cols="12">
            <pim-product-edit v-model="item"></pim-product-edit>
          </v-col>
        </v-row>
        <v-row style="background-color: rgb(128 128 128 / 5%);">
          <v-col cols="12">
            <v-tabs vertical color="blackgrey darken-3" v-model="tabColumn">
              <v-tab
                v-for="variant in variants"
                :key="variant.supplierReference"
                :style="styleColorTitle(variant.color)"
              >
                <v-icon left>
                  mdi-account
                </v-icon>
                {{ variant.color }}/{{ variant.pattern }}
              </v-tab>
              <v-tab-item v-for="(variant, index) in variants" :key="variant.supplierReference">
                <v-card flat>
                  <v-tabs v-model="tabSlider" grow centered color="blackgrey darken-3">
                    <v-tabs-slider
                      :style="`background-color:${convertBackgroundColor(variant.color)}`"
                    ></v-tabs-slider>
                    <v-tab v-for="tabLabel in tabLabels" :key="tabLabel">
                      {{ tabLabel }}
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tabSlider">
                    <v-tab-item>
                      <pim-variant-edit v-model="variants[index]"></pim-variant-edit>
                    </v-tab-item>
                    <v-tab-item>
                      <pim-skus-view
                        :items="variant.skus"
                        v-if="variant.skus"
                        :parentItem="variant"
                        endpointType="sku"
                      ></pim-skus-view>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
                <common-media-list-view
                  :hasMedia="variant.hasMedias() && variant.id"
                  :variantMedia="variant.medias"
                  :variantId="variant.id"
                  actions
                  upload
                >
                </common-media-list-view>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-spacer />
        <v-card-actions>
          <v-btn color="grey darken-1" fab dark fixed top right  style="top: 80px;" @click="close">
            <v-icon dark>mdi-cancel</v-icon>
          </v-btn>
          <v-btn color="blue darken-1"
            fab
            dark
            fixed
            top
            right
            :disabled="!isValid || isClicked"
            @click="save">
            <v-icon dark>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { Product } from '@/models';
import ColorBaseMixins from '@/mixins/color-base';
import DialogEditBaseMixins from '@/mixins/dialog-edit-base';

export default {
  mixins: [ColorBaseMixins, DialogEditBaseMixins],
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Product,
      default: () => Product.create(),
    },
  },
  data: () => ({
    tabColumn: null,
    tabSlider: null,
    tabLabels: ['Variant color', 'Variant size'],
  }),
  watch: {
    openDialog(val, prev) {
      // if activator is enabled need to return dialog via event emitter
      if (prev === false && val === true) {
        this.$emit('update:dialog', true);
      }
    },
  },
  computed: {
    variants() {
      try {
        return this.value.variant.slice(0).sort((a, b) => {
          if (a.color < b.color) { return -1; }
          if (a.color > b.color) { return 1; }
          if (a.pattern < b.pattern) { return -1; }
          if (a.pattern > b.pattern) { return 1; }
          return 0;
        });
      } catch (err) {
        return [];
      }
    },
  },
  methods: {
    styleColorTitle(color) {
      return `background-color:${this.convertBackgroundColor(color)};color:${this.convertColor(color)}`;
    },
  },
};
</script>

<style></style>
