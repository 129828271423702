/* eslint no-param-reassign:0 */

export default {
  computed: {
    itemsVariant() {
      if (!Array.isArray(this.items) || this.items.length === 0) {
        return [];
      }
      return this.items.reduce((resultV, itemP) => {
        resultV = [...resultV, ...itemP.variant];
        return resultV;
      }, []);
    },
    itemsSku() {
      if (!Array.isArray(this.items) || this.items.length === 0) {
        return [];
      }
      return this.items.reduce((resultS, itemP) => {
        itemP.variant.forEach((itemV) => {
          resultS = [...resultS, ...itemV.skus];
        });
        return resultS;
      }, []);
    },
  },
};
