/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://routerjs.org/en/
 */
import { authenticationGuard } from '@/services/watchguard';

export default [
  {
    path: '*',
    meta: {
      name: '',
      requiresAuth: true,
    },
    redirect: {
      path: '/',
    },
  },
  // This  allows you to have pages apart of the app but no rendered inside the dash
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Login',
      requiresAuth: false,
      icon: 'mdi-shield-account',
      visible: true,
    },
    component: () => import('@/views/LoginHome'),
  },
  {
    path: '/',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/Logued'),
    beforeEnter: authenticationGuard,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import('@/components/dashboard/MainView'),
        beforeEnter: authenticationGuard,
        meta: {
          title: 'Dashboard',
          noBreadcrumb: true,
        },
      },
    ],
  },
  {
    path: '/pim',
    meta: {
      requiresAuth: true,
      title: 'Pim',
    },
    props: (route) => {
      const props = {};
      if (route.name.indexOf('import') === 0) {
        props.commiters = ['set_countries_by_key'];
      }
      return props;
    },
    component: () => import('@/views/Logued'),
    children: [
      {
        path: 'pim/dashboard',
        name: 'pim',
        component: () => import('@/components/pim/DashBoardView'),
        beforeEnter: authenticationGuard,
      },
      {
        path: 'import',
        name: 'import',
        component: () => import('@/components/pim/ImportView'),
        beforeEnter: authenticationGuard,
      },
      {
        path: 'import/:importId/csv',
        name: 'import.importid.csv',
        component: () => import('@/components/pim/ImportCsvEdit'),
        beforeEnter: authenticationGuard,
      },
      {
        path: 'import/:importId/edit',
        name: 'import.importid.edit',
        component: () => import('@/components/pim/ImportEditPimView'),
        beforeEnter: authenticationGuard,
      },
      {
        path: 'import-data',
        name: 'import.import-data',
        component: () => import('@/components/pim/ImportDataView'),
        beforeEnter: authenticationGuard,
      },
      {
        path: 'export',
        name: 'pim.export',
        component: () => import('@/components/pim/ExportView'),
        beforeEnter: authenticationGuard,
      },
      {
        path: 'size/checking',
        name: 'pim.size.checking',
        component: () => import('@/components/pim/CheckSizeView'),
        beforeEnter: authenticationGuard,
      },
      {
        path: 'product',
        name: 'pim.products',
        component: () => import('@/components/pim/ProductListView'),
        beforeEnter: authenticationGuard,
        meta: {
          title: 'Product List',
        },
      },
      {
        path: 'product/:productId/edit',
        name: 'pim.product.productid',
        component: () => import('@/components/pim/ProductByIdView'),
        beforeEnter: authenticationGuard,
        meta: {
          title: 'Product edit',
        },
      },
    ],
  },
  {
    path: '/export',
    meta: {
      requiresAuth: true,
    },
    name: 'Export',
    component: () => import('@/views/Logued'),
    beforeEnter: authenticationGuard,
    children: [
      {
        path: 'settings',
        name: 'export.settings',
        component: () => import(/* webpackChunkName: "export" */ '@/components/export/SettingsView'),
        beforeEnter: authenticationGuard,
      },
      {
        path: 'marketplace',
        meta: {
          requiresAuth: true,
          title: 'Marketplaces',
        },
        component: () => import(/* webpackChunkName: "export" */ '@/components/export/MarketplaceView'),
      },
    ],
  },
  {
    path: '/settings',
    meta: {
      noBreadcrumb: true,
      requiresAuth: true,
    },
    name: 'settings',
    component: () => import('@/views/Logued'),
    beforeEnter: authenticationGuard,
    children: [
      {
        path: 'properties',
        name: 'settings.properties',
        component: () => import(/* webpackChunkName: "settings" */ '@/components/settings/PropertyView'),
        beforeEnter: authenticationGuard,
      },
      {
        path: 'family',
        meta: {
          requiresAuth: true,
          title: 'Family List',
        },
        component: () => import('@/views/PageFamily'),
        children: [
          {
            path: '',
            name: 'settings.family',
            component: () => import(/* webpackChunkName: "settings" */ '@/components/settings/FamilyListView'),
            beforeEnter: authenticationGuard,
          },
          {
            path: ':remoteCode/edit',
            name: 'settings.family.remoteCode',
            component: () => import(/* webpackChunkName: "settings" */ '@/components/settings/FamilyByIdView'),
            beforeEnter: authenticationGuard,
          },
        ],
      },
      {
        path: 'classification',
        meta: {
          requiresAuth: true,
          title: 'Classification List',
        },
        component: () => import('@/views/PageClassification'),
        children: [
          {
            path: '',
            name: 'settings.classification',
            component: () => import(/* webpackChunkName: "settings" */ '@/components/settings/ClassificationListView'),
            beforeEnter: authenticationGuard,
          },
          {
            path: ':remoteCode/edit',
            name: 'settings.classification.remoteCode',
            component: () => import(/* webpackChunkName: "settings" */ '@/components/settings/ClassificationByIdView'),
            beforeEnter: authenticationGuard,
          },
        ],
      },
      {
        path: 'attribute',
        meta: {
          requiresAuth: true,
          title: 'Attribute List',
        },
        component: () => import('@/views/PageAttribute'),
        children: [
          {
            path: '',
            name: 'settings.attribute',
            component: () => import(/* webpackChunkName: "settings" */ '@/components/settings/AttributeListView'),
            beforeEnter: authenticationGuard,
            meta: {
              noBreadcrumb: true,
            },
          },
          {
            path: ':remoteCode/edit',
            name: 'settings.attribute.remoteCode',
            component: () => import(/* webpackChunkName: "settings" */ '@/components/settings/AttributeByIdView'),
            beforeEnter: authenticationGuard,
          },
          {
            path: ':aRemoteCode/values/:remoteCode/edit',
            name: 'settings.attribute.values.remoteCode',
            component: () => import(/* webpackChunkName: "settings" */ '@/components/settings/AttributeValueByIdView'),
            beforeEnter: authenticationGuard,
          },
        ],
      },
      {
        path: 'translations',
        name: 'settings.translations',
        component: () => import(/* webpackChunkName: "settings" */ '@/components/settings/TranslateListView'),
        beforeEnter: authenticationGuard,
      },
    ],
  },
  {
    path: '/dam',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/Logued'),
    beforeEnter: authenticationGuard,
    children: [
      {
        path: 'dashboard',
        name: 'dam.dashboard',
        component: () => import('@/components/dam/DashBoardView'),
        beforeEnter: authenticationGuard,
      },
      {
        path: 'medias',
        name: 'dam.medias',
        component: () => import('@/components/dam/MediaListView'),
        beforeEnter: authenticationGuard,
      },
      {
        path: 'import',
        name: 'dam.import',
        component: () => import('@/components/dam/MediaImport'),
        beforeEnter: authenticationGuard,
      },
      {
        path: 'export',
        name: 'dam.export',
        component: () => import('@/components/dam/ExportView'),
        beforeEnter: authenticationGuard,
      },
      {
        path: 'download',
        name: 'dam.download',
        component: () => import('@/components/dam/DownloadView'),
        beforeEnter: authenticationGuard,
      },
    ],
  },
  {
    path: '/marketplace',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/Logued'),
    beforeEnter: authenticationGuard,
    children: [
      {
        path: 'dashboard',
        name: 'marketplace.dashboard',
        component: () => import('@/components/marketplace/DashBoardView'),
        beforeEnter: authenticationGuard,
      },
      {
        path: 'catalog',
        name: 'marketplace.catalog',
        component: () => import('@/components/marketplace/CatalogView'),
        beforeEnter: authenticationGuard,
      },
    ],
  },
];
