// https://vuex.vuejs.org/en/mutations.html

import stateDefault from './state';

const getClean = (state) => {
  state.isLoaded = stateDefault.isLoaded;
  state.classifications = stateDefault.classifications;
  state.classification = stateDefault.classification;
  state.state = stateDefault.state;
};

export default {
  set_classifications(state, payload) {
    state.classifications = payload;
  },
  set_classification(state, payload) {
    state.classification = payload;
  },
  set_afm(state, payload) {
    if (state.classification.fromMarketplace) {
      const indexCfm = state.classification.fromMarketplace.findIndex(c => c.remoteCode === payload.cfmRemoteCode);
      if (indexCfm !== -1) {
        const indexAfm = state.classification.fromMarketplace[indexCfm]
          .options
          .family_attributes
          .findIndex(a => a.remoteCode === payload.afmRemoteCode);
        if (indexAfm !== -1) {
          state.classification.fromMarketplace[indexCfm]
            .options
            .family_attributes[indexAfm].attributesAssociated.splice(0);
          state.classification.fromMarketplace[indexCfm]
            .options
            .family_attributes[indexAfm].attributesAssociated.push(...payload.attributes);
        }
      }
    }
  },
  set_state(state, payload) {
    state.state = payload;
  },
  clear(state) {
    getClean(state);
  },
};
