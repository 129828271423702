// https://vuex.vuejs.org/en/actions.html
import Vue from 'vue';
import axios from 'axios';
import { errorHandler } from '@/store/modules/global_helpers';

const BASE_URI = '/api/v1/pim/classification';
const BASE_URI_V2 = '/api/v2/pim/classification';
const namespace = 'store.modules.classification.actions';

export default {
  get({ commit }, remoteCode) {
    Vue.$log.info(`${namespace}.get.${remoteCode}.started`);
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URI}/${remoteCode}`)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`.api.v1.pim.classification.${remoteCode}.not.found`);
          }
          commit('set_classification', response.data.result);
          return resolve(response.data.result);
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return errorHandler(reject)(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  update({ commit }, payload) {
    Vue.$log.info(`${namespace}.update.started`);
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI_V2}/${payload.remoteCode}`, payload)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`.api.v2.pim.classification.${payload.remoteCode}.not.found`);
          }
          commit('set_classification', response.data.result);
          return resolve(response.data.result);
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return errorHandler(reject)(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  active({ commit }, payload) {
    Vue.$log.info(`${namespace}.active.started`);
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI_V2}/${payload.remoteCode}/active`, {
          active: payload.active,
        })
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`.api.v2.pim.classification.${payload.remoteCode}.not.found`);
          }
          commit('set_classification', response.data.result);
          return resolve(response.data.result);
        })
        .catch((error) => {
          Vue.$log.error(error.message);
          return errorHandler(reject)(error);
        });
    });
  },
};
