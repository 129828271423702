// https://vuex.vuejs.org/en/state.html

export default {
  email: state => state.emailRules,
  password: state => state.passwordRules,
  text: state => state.textRules,
  title: state => state.titleRules,
  barcode: state => state.barcodeRules,
  sku: state => state.skuRules,
  color: state => state.colorRules,
  percent: state => state.percentRules,
  decimal: state => state.decimalRules,
};
