<template>
  <material-card color="blue" title="Import your csv file" offset="10">
    <v-layout wrap>
      <v-flex xs12>
        <common-sheets-upload
          :post-action="`${baseURL}/api/v1/import/csv`"
          v-on:create:upload="loadList"
        >
        </common-sheets-upload>
      </v-flex>
    </v-layout>
  </material-card>
</template>

<script>
const baseURL = process.env.VUE_APP_PIM_BASEURL || 'http://localhost:3128/';

export default {
  name: 'ImportUpload',
  data: () => ({
    baseURL,
  }),
  methods: {
    loadList(response) {
      this.$store
        .dispatch('import/reset')
        .then(() => this.$store.dispatch('import/list'))
        .then(() => this.$store.commit('import/set_edit', response));
    },
  },
};
</script>

<style lang="css" scoped></style>
