<template>
  <v-card>
    <v-textarea
      v-model="valueAttribute"
      filled
      :label="label || property"
      :value="valueAttribute"
      class="vuetify-editor"
      auto-grow
      :messages="getDefaultValues()"
      :loading="isCompletudeRequired(bindAttribute, currentIssues)"
    ></v-textarea>
  </v-card>
</template>
<script>
import AttributeBaseMixins from '../../mixins/attribute-base';
import CompletudeBaseMixins from '../../mixins/completude-base';

export default {
  mixins: [AttributeBaseMixins, CompletudeBaseMixins],
  name: 'attributeTextareaNoLang',
  data: () => ({}),
};
</script>
