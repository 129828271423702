import Vue from 'vue';
import Log from '@/models/utils/log';

export default class Core {
  constructor(data, parent = null) {
    this.objectInitializer(data);
    Log.debug(`models.pim.corelist.${this.constructor.name.toLowerCase()}.started`);
    this.defineProperty();
    if (parent) {
      this.parent = parent;
    }
    // initialize errors property before doing anything
    if (typeof this.errors === 'undefined' || !this.errors) {
      this.errors = {};
      this.errorsOriginal = {};
    } else {
      this.errorsOriginal = this.errors;
    }
    this.parentError = false;
  }

  objectInitializer(data) {
    if (!data || Object.keys(data).length === 0) {
      return;
    }
    Object.assign(this, data);
  }

  static create(data, parent = null) {
    if (typeof data !== 'object') {
      return false;
    }
    const instance = new this(data, parent);
    return instance.applyModel();
  }

  applyModel() {
    return this;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  update(data) {
    if (Object.keys(data).length > 0) {
      Object.keys(data).forEach((property) => {
        Vue.set(this, property, data[property]);
      });
    }
  }

  static getParentValue(parent, key, defaultValue = null) {
    if (!parent) {
      return defaultValue || null;
    }
    if (parent && typeof parent[key] !== 'undefined') {
      return parent[key];
    }
    return defaultValue || null;
  }

  reset() {
    delete this.id;
  }

  resetErrors() {
    this.errors = Object.assign({}, this.errorsOriginal);
    delete this.errors.id;
  }

  /**
   * convert instance in plain object
   * @return {Object}
   */
  toObject() {
    Log.debug(`models.pim.core.${this.constructor.name.toLowerCase()}.toobject.started`);
    // dont use Object.assign because circular json error due to parent property
    return JSON.parse(JSON.stringify(Object.seal(this)));
  }

  defineProperty() {
    Log.debug(`models.pim.core.${this.constructor.name.toLowerCase()}.defineproperty.started`);
  }
}
