<template>
  <v-container fill-height fluid>
    <v-layout wrap>
      <v-flex md12>
        <material-card
          color="brown"
          title="Catalog"
          text="List width products, variant color and variant size"
          class="col-lg-12"
        >
          <template v-slot:dialog>
            <dialog-settings :dialog="dialog"> </dialog-settings>
          </template>
          <v-data-table
            v-model="productSelected"
            :headers="filterByColumn('catalog')"
            class="elevation-2"
            item-key="barcode"
            :items="items"
            :server-items-length="itemsCount"
            :options.sync="itemsOptions"
            :loading="loading"
            :footer-props="{ 'items-per-page-options': [15, 30, 50, 100, -1] }"
            loading-text="Loading... Please wait"
            :dense="settings.dense"
            :show-select="settings.showSelect"
          >
            <template v-slot:item.images.1="{ item }">
              <core-avatar v-if="hasImages(item)" :image="item.images[1]"></core-avatar>
            </template>
            <template v-slot:item.referenceWs.idBrand="{ item }">
              <dialog-marketplace-brand
                :item="item.referenceWs"
                property="idBrand"
                labelName="Choose your brand"
              >
              </dialog-marketplace-brand>
            </template>
            <template v-slot:item.referenceWs.idSupplier="{ item }">
              <dialog-marketplace-supplier
                :item="item.referenceWs"
                property="idSupplier"
                labelName="Choose your supplier"
              >
              </dialog-marketplace-supplier>
            </template>
            <template v-slot:item.size="{ item }">
              {{ valueJson(item.size) }}
            </template>
            <template v-slot:item.sizeCountry="{ item }">
              {{ valueJson(item.sizeCountry, "sizes") }}
            </template>
            <template v-slot:item.active="{ item }">
              <v-chip :color="getEnableDisable(item.active)" dark>{{
                getEnableDisableMessage(item.active)
              }}</v-chip>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
/* eslint no-plusplus:0 */
/* eslint no-param-reassign:0 */

import { mapActions } from 'vuex';
import Mixins from '../../mixins';
import ItemsBaseMixins from '../../mixins/items-base';
import EditMixins from '../../mixins/edit-base';
import ProfileMixins from '../../mixins/profile-base';

export default {
  name: 'CatalogView',
  mixins: [ItemsBaseMixins, EditMixins, ProfileMixins, ...Mixins],
  data: () => ({
    itemsPerPage: 10,
    btnFunctionStepped: false,
    btnResetImport: false,
    productSelected: [],
    itemsCount: 0,
    itemsOptions: {},
    loading: true,
    defaultItem: {},
  }),
  watch: {
    itemsOptions: {
      handler() {
        this.searchCatalog();
      },
      deep: true,
    },
  },

  created() {
    this.itemsOptions = this.itemsOptionsDefault();
  },
  mounted() {
    this.notificationSuccess({ message: 'Connected to marketplace database' });
    this.notificationInfo({ message: 'Load referencews and variantws' });
    this.apiBrand();
    this.apiSupplier();
  },
  computed: {
    items() {
      const { items } = this.options(
        this.$store.getters['api/list']('marketplace.variant'),
        this.itemsCount,
        this.itemsOptions,
      );
      return items;
    },
    settings() {
      return this.$store.getters['settings/settings'];
    },
    optionsTa() {
      return this.$store.getters['settings/theagent'];
    },
    headers() {
      return this.$store.getters['settings/headers'].catalog;
    },
  },
  methods: {
    ...mapActions('api', {
      apiBrand: 'marketplace.brand',
      apiSupplier: 'marketplace.supplier',
      apiVariant: 'marketplace.variant',
    }),
    searchCatalog() {
      this.$log.log('components.marketplace.catalogview.searchcatalog.started');
      this.loading = true;
      const { page, itemsPerPage } = this.itemsOptions;
      const params = {
        limit: parseInt(itemsPerPage, 10),
        page,
      };
      this.$router
        .push({
          path: '/marketplace/catalog',
          query: {
            limit: parseInt(itemsPerPage, 10),
            page,
          },
        })
        .catch(() => {}); // NavigationDuplicated error quiet
      this.$log.debug('components.marketplace.catalogview.searchcatalog.query', {
        limit: parseInt(itemsPerPage, 10),
        page,
      });

      return this.apiVariant(params).then((data) => {
        this.$log.debug('components.marketplace.catalogview.searchcatalog.result', {
          itemsCount: data.parameters.count,
          itemsPerPage,
          itemsOptions: this.itemsOptions,
        });
        this.loading = false;
        this.itemsCount = data.parameters.count;
        this.itemsPerPage = itemsPerPage;
      });
    },
    hasImages(item) {
      if (typeof item.images === 'undefined' || !item.images) {
        return false;
      }
      return typeof item.images[1] !== 'undefined' || item.images[1] !== null;
    },
    valueJson(values, type = 'size') {
      if (!values || typeof values[type] === 'undefined') {
        return null;
      }
      return values[type];
    },
  },
};
</script>

<style lang="css" scoped></style>
