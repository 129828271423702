/* eslint no-param-reassign:0 */

import Vue from 'vue';
import Log from '@/models/utils/log';
import Core from './core';
import { CoreListError } from './exception';
import { makeReactive } from './utils';

export default class CoreList extends Array {
  constructor(...list) {
    super(...list);
    makeReactive(this);
    Log.debug(`models.pim.corelist.${this.constructor.name.toLowerCase()}.started`);
  }

  static create(list, parent = null) {
    let listObj = [];
    if (Array.isArray(list) && list.length > 0) {
      listObj = list.reduce((result, row) => {
        if (typeof row === 'object') {
          result.push(row);
        }
        return result;
      }, []);
    } else {
      Log.error('models.pim.corelist.list.empty');
    }
    const instance = new this(...listObj).applyModel(parent);
    if (!(instance instanceof CoreList)) {
      throw new CoreListError('Cannot call a class as a function');
    }
    return instance;
  }

  applyModel(parent) {
    if (this.length > 0) {
      this.forEach((row, index) => {
        if (typeof row === 'object') {
          this.add(Core.create(row, parent), index);
        }
      });
    }
    return this;
  }

  add(row, index = false) {
    if (index !== false && index !== -1) {
      Vue.set(this, index, row);
    } else {
      this.push(row);
    }
  }

  /**
   * convert instance in plain object
   * @return {Object}
   */
  toObject() {
    Log.debug(`models.pim.corelist.${this.constructor.name.toLowerCase()}.toobject.started`);
    const rootList = this.map(row => row.toObject());
    return JSON.parse(JSON.stringify(Object.seal(rootList)));
  }

  reset() {
    this.splice(0, this.length);
  }

  // @todo remove all the logic code about duplicated
  //   and move it to the schema errors in theagent pim
  static duplicated(row, property, error) {
    let keyValue = row[property];
    if (property === 'color') {
      let colors = row.attributes_temp.att_couleur_detail_rc;
      if (!Array.isArray(colors)) {
        colors = [colors];
      }
      keyValue = `${keyValue.toLowerCase()}.${[...colors].sort().join('.').toLowerCase()}`;
    }
    if (typeof error[property] === 'undefined') {
      error[property] = {};
    }
    if (typeof error[property][keyValue] !== 'undefined') {
      if (typeof row.errors[property] === 'undefined') {
        row.errors[property] = ['duplicated'];
      } else {
        row.errors[property].push('duplicated');
      }
    }
    error[property][keyValue] = true;
    return error;
  }
}
