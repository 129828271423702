import Log from '@/models/utils/log';
import AttributeAbstract from '@/models/attribute/abstract';

const namespace = 'models.attribute.classificationfrommarketplace';

export default class ClassificationFromMarketplace extends AttributeAbstract {
  objectInitializer(data) {
    super.objectInitializer(data);
    this.remoteCode = data.remoteCode.replace('pim.cfm.', '');
  }

  static buildNew(data) {
    return {
      options: {
        code: data.code,
      },
      name: data.label,
      remoteCode: data.remoteCode || `${data.marketplaceId}.${data.code.slugify()}`,
      marketplaceId: data.marketplaceId || 0,
      parentId: undefined,
      path: `/${data.marketplaceName}/${data.code}`,
      active: 1,
      classifications: [],
    };
  }
}
