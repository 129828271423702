<template>
  <div>
    <v-card flat tile>
      <v-lazy
        v-model="isActive"
        :options="{
          threshold: 0.5
        }"
        transition="fade-transition"
      >
        <v-img
          max-width="250"
          @mouseover="hover = true"
          @mouseleave="hover = false"
          :src="getUrl()"
          class="grey lighten-2"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
          <slot name="placeholder"></slot>
          <v-expand-transition>
            <div :class="{ 'v-card-reveal': hasHover, 'v-card-hide': !hasHover }">
              <div class="v-card-overlay"></div>
              <v-row class="d-flex justify-end mb-6">
                <slot name="actions"></slot>
              </v-row>
            </div>
          </v-expand-transition>
        </v-img>
      </v-lazy>
    </v-card>
  </div>
</template>
<script>

export default {
  name: 'attributeMedia',
  props: {
    alias: {
      type: String,
      required: false,
      default: null,
    },
    ratio: {
      type: Number,
      required: false,
      default: 0.75,
    },
    noHover: {
      type: Boolean,
      required: false,
    },
    value: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    hover: false,
    isActive: false,
  }),
  computed: {
    hasHover() {
      return !this.noHover && this.hover;
    },
  },
  methods: {
    getUrl() {
      if (!this.value) {
        return '';
      }
      if (typeof this.value.url !== 'function') {
        this.$log.error('components.attribute.media.geturl.url.invalid');
        return '';
      }
      return this.value.url(this.alias);
    },
  },
};
</script>

<style type="text/css">
.v-card-reveal {
  display: block !important;
}

.v-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.2s ease;
}
.v-card-reveal:hover .v-card-overlay {
  display: block;
  background: rgba(0, 0, 0, 0.3);
}

.v-card-reveal:hover .button {
  opacity: 1;
}
.v-card-hide {
  display: none !important;
}
</style>
