// @todo create a function to generate automatically theses properties
// authorized lets you know if the token is true or not
import { AttributeValue } from '@/models';

export default {
  attributeValue: (state) => {
    if (!state.attribute_av) return null;
    return new AttributeValue(state.attribute_av);
  },
};
